export default {
    getLiquidationAuctionOpen({ commit }) {
        return new Promise((resolve, reject) => {
            $axios.get(`/liquidationAuction/open`).then((res) => {
                commit('SET_LIQUIDATION_AUCTION_DATA', res.data);
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },

    getLiquidationAuctionHistory({ commit }, {params}) {
        return new Promise((resolve, reject) => {
            $axios.get(`/liquidationAuction/history`, {params}).then((res) => {
                commit('SET_LIQUIDATION_AUCTION_HISTORY', res.data);
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },

    liquidationAuctionPlace({ commit }, params) {
        return new Promise((resolve, reject) => {
            $axios.post(`/liquidationAuction/place`, params).then((res) => {
                // commit('SET_ORDERS_OPEN_DATA', res.data);
                resolve(res.data);
            })
                .catch((err) => {
                    reject(err);
                });
        });
    },

  };
