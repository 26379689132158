<template>
  <v-dialog
      v-model="activeDialog"
      persistent
  >
    <div class="export-popup-wrapper primary">
      <div
          class="close__popup"
          @click="closePopups"
      >
        <svg
            fill="none"
            height="16"
            viewBox="0 0 16 16"
            width="16"
            xmlns="http://www.w3.org/2000/svg"
        >
          <path
              d="M2.25 2.25L7.99995 7.99995M7.99995 7.99995L13.7499 2.25M7.99995 7.99995L13.75 13.7498M7.99995 7.99995L2.2501 13.7498"
              stroke="#A4A5AF"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
          />
        </svg>
      </div>
      <div class="export-popup__header">
        <h2>{{ popupTitle }}</h2>
      </div>
      <div class="export-popup__body">
        <div class="export-popup__body__popular-wrapper">
          <div class="export-popup__body__popular-title">
            <h3>{{ $t('Popular periods') }}</h3>
          </div>
          <div class="export-popup__body__popular-content">
            <ul class="popular-content-list">
              <li
                  v-for="(period, index) in periodList"
                  :key="index"
                  class="popular-content-item"
              >
                <label
                    :for="`checkbox_${index}`"
                    class="label-checkbox"
                >
                  <div
                      :class="{active: selectPeriod === period.val}"
                      class="popular-content-checkbox-wrap"
                  >
                    <input
                        :id="`checkbox_${index}`"
                        v-model="selectPeriod"
                        :value="period.val"
                        class="input-radio"
                        name="group"
                        type="radio"
                        @change="selectPopularPeriods"
                    >
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">{{ $t(period.name) }}</span>
                      </template>
                      <span>{{$t(period.name)}}</span>
                    </v-tooltip>
                  </div>
                </label>
              </li>
            </ul>
          </div>
        </div>
        <div class="export-popup__body__custom-wrapper">
          <div class="export-popup__body__custom-title">
            <h3>{{ $t('Custom periods') }}</h3>
          </div>
          <div class="export-popup__body__custom-content">
            <div class="custom-content__datepicker">
              <div class="custom-content__datepicker-title">{{ $t('Date') }}</div>
              <div class="custom-content__datepicker-body">
                <date-picker
                    v-model="customPeriods"
                    :disabled-date="disabledRange"
                    :editable="false"
                    :format="'DD/MM/YYYY'"
                    :input-class="$vuetify.theme.dark ? 'theme-dark' : 'theme-light'"
                    :lang="$i18n.locale"
                    :placeholder="'01/07/2021 – 08/07/2021'"
                    :popup-class="$vuetify.theme.dark ? 'theme-dark' : 'theme-light'"
                    :range-separator="' - '"
                    range
                    @focus="selectCustomPeriods"
                    @pick="changeDates"
                />
                <span>{{ $t('Max 3 months range.') }}</span>
              </div>
            </div>
            <!--            <div class="custom-content__datepicker-checkbox">-->
            <!--              <div class="datepicker-checkbox__withSixMonth" v-if="withSixMonth">-->
            <!--                <ul class="datepicker-checkbox-list">-->
            <!--                  <li-->
            <!--                      v-for="(checkBox, index) in checkBoxList"-->
            <!--                      :key="index"-->
            <!--                      class="popular-content-item"-->
            <!--                  >-->
            <!--                    <label :for="`datepickerCheckbox_${index}`" class="label-checkbox">-->
            <!--                      <div class="popular-content-checkbox-wrap" :class="{active: formData.selectDatepickerCheckbox === checkBox.val}">-->
            <!--                        <input-->
            <!--                            type="radio"-->
            <!--                            name="group"-->
            <!--                            :id="`datepickerCheckbox_${index}`"-->
            <!--                            v-model="formData.selectDatepickerCheckbox"-->
            <!--                            :value="checkBox.val"-->
            <!--                            class="input-radio">-->
            <!--                        <span>{{checkBox.name}}</span>-->
            <!--                      </div>-->
            <!--                    </label>-->
            <!--                  </li>-->
            <!--                </ul>-->
            <!--              </div>-->
            <!--              <div class="datepicker-checkbox__withoutSixMonth" v-else>-->
            <!--                <label :for="`datepickerCheckbox_custom`" class="label-checkbox">-->
            <!--                  <div class="popular-content-checkbox-wrap" :class="{active: formData.selectDatepickerCheckbox === 'custom'}">-->
            <!--                    <input-->
            <!--                        type="radio"-->
            <!--                        name="group"-->
            <!--                        :id="`datepickerCheckbox_custom`"-->
            <!--                        v-model="formData.selectDatepickerCheckbox"-->
            <!--                        :value="'custom'"-->
            <!--                        class="input-radio">-->
            <!--                    <span>Custom</span>-->
            <!--                  </div>-->
            <!--                </label>-->
            <!--                <div>Max 3 months range.</div>-->
            <!--              </div>-->
            <!--            </div>-->
          </div>
        </div>
      </div>
      <div class="export-popup__buttons">
        <div class="export-popup__buttons-wrapper">
          <button
              class="export-popup__button cancel"
              @click="closePopups"
          >{{ $t('Cancel') }}
          </button>
          <button
              :disabled="disabledButton"
              class="export-popup__button export"
              @click="exportForm"
          >{{ $t('Export') }}
          </button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>
<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';
import 'vue2-datepicker/locale/hi';
import 'vue2-datepicker/locale/es';
import 'vue2-datepicker/locale/zh-cn';
import moment from 'moment';

export default {
  name: 'ExportPopupHistoryPopup',
  props: {
    popupTitle: String,
    asset: String,
    searchedText: String,
    symbol: String,
    side: String,
    type: String,
    formData: Object,
    periodList: {
      type: Array,
      required: true,
    },
  },
  components: { DatePicker },
  data() {
    return {
      activeDialog: false,
      selectPeriod: null,
      popupFormData: {},
      popularPeriods: {
        dateFrom: null,
        dateTo: null,
      },
      customPeriods: [],
      disabledButton: true,
      disabledRange: null,
    };
  },
  watch: {
    customPeriods(val) {

      this.popupFormData.dateFrom = isNaN(Date.parse(val[0])) == false ? (String(Date.parse(val[0])) / 1000) : '';
      this.popupFormData.dateTo = isNaN(Date.parse(val[1])) == false ? (String(Date.parse(val[1]) + 86400000) / 1000) : '';

      this.changeDate();
    },
    selectPeriod(val) {
      switch (val) {
        case 'LAST_24_HOURS':
          this.popupFormData.dateFrom = String(Date.parse(this.yesterday)) / 1000
          this.popupFormData.dateTo = String(Date.parse(this.currentDate)) / 1000
          break;
        case 'YESTERDAY':
          this.popupFormData.dateFrom = String(Date.parse(this.yesterdayStart)) / 1000
          this.popupFormData.dateTo = String(Date.parse(this.yesterdayEnd)) / 1000
          break;
        case 'LAST_2_WEEKS':
          this.popupFormData.dateFrom = String(Date.parse(this.twoWeeksAgo)) / 1000
          this.popupFormData.dateTo = String(Date.parse(this.currentDate)) / 1000
          break;
        case 'PAST_MONTH':
          this.popupFormData.dateFrom = String(Date.parse(this.getDateFewMonthsAgo(1))) / 1000
          this.popupFormData.dateTo = String(Date.parse(this.currentDate)) / 1000
          break;
        case 'PAST_3_MONTHS':
          this.popupFormData.dateFrom = String(Date.parse(this.getDateFewMonthsAgo(3))) / 1000
          this.popupFormData.dateTo = String(Date.parse(this.currentDate)) / 1000
          break;
        case 'PAST_6_MONTHS':
          this.popupFormData.dateFrom = String(Date.parse(this.getDateFewMonthsAgo(6))) / 1000
          this.popupFormData.dateTo = String(Date.parse(this.currentDate)) / 1000
          break;
        case 'MOUNT_TO_DATE':
          this.popupFormData.dateFrom = String(Date.parse(this.monthStart)) / 1000
          this.popupFormData.dateTo = String(Date.parse(this.currentDate)) / 1000
          break;
      }

      this.changeDate();
    },
  },
  computed: {
    currentDate() {
      return moment();
    },
    twoWeeksAgo() {
      return moment().subtract(2, 'weeks');
    },
    yesterday() {
      return moment().subtract(1, 'days');
    },
    yesterdayStart() {
      return moment().subtract(1, 'days').startOf('day');
    },
    yesterdayEnd() {
      return moment().subtract(1, 'days').endOf('day');
    },
    monthStart() {
      return moment().startOf('month');
    },
  },
  methods: {
    getDateFewMonthsAgo(months) {
      return moment().subtract(months, 'months');
    },
    changeDates(val) {
      this.selectPeriod = null;

      this.disabledRange = function (date) {
        return date < new Date(val).setMonth(new Date(val).getMonth() - 3) || date > new Date(val).setMonth(new Date(val).getMonth() + 3) || date > new Date();
      };
    },
    changeDate() {
      this.disabledButton = !this.selectPeriod && !this.customPeriods[0] && !this.customPeriods[1];
    },
    selectCustomPeriods() {
      this.changeDate();
    },
    selectPopularPeriods() {
      this.customPeriods = [
        '',
        '',
      ];
      this.changeDate();
    },
    openPopups() {
      this.activeDialog = true;
      this.popupFormData = { ...this.formData };
    },
    closePopups() {
      this.customPeriods = [
        '',
        '',
      ];
      this.selectPeriod = null;
      this.activeDialog = false;
    },
    getRequestParams(formData) {
      const params = {};

      for (const data in formData) {
        if(formData[data]) {
          params[data] = formData[data];
        }
      }

      return params;
    },
    exportForm() {
      const params = this.getRequestParams(
          this.popupFormData,
      );

      this.$emit('exportForm', params);
    },
  },
  mounted() {
    this.disabledRange = function (date) {
      return date > new Date();
    };
  },
};
</script>
<style>
li {
  list-style: none;
}

.v-dialog {
  margin: 15px;
  box-shadow: none;
  border-radius: 0;
  /*max-width: 740px;*/
}

.export-popup-wrapper {
  position: relative;
  margin: 0 auto;
  border: 1px solid;
  border-radius: 8px;
  max-width: 740px;
  padding: 20px 0;
}

.export-popup__header {
  border-bottom: 1px solid var(--v-loginDropduwnHoverBackgroundColor-base);
  padding: 0 45px 20px;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  line-height: 150%;
  text-align: center;
}

.export-popup__body {
  border-bottom: 1px solid var(--v-loginDropduwnHoverBackgroundColor-base);
  padding: 20px 0;
}

.export-popup__body__popular-wrapper {
  border-bottom: 1px solid var(--v-loginDropduwnHoverBackgroundColor-base);
}

.export-popup__body__popular-title, .export-popup__body__custom-title,
.export-popup__body__popular-content {
  margin-bottom: 15px;
}

.export-popup__body__popular-title, .export-popup__body__popular-content,
.export-popup__body__custom-title, .export-popup__body__custom-content {
  padding: 0 40px;
}

.export-popup__body__custom-wrapper {
  padding-top: 20px;
}

.popular-content-checkbox-wrap {
  display: flex;
  cursor: pointer;
}

.popular-content-checkbox-wrap span {
  margin-left: 24px;
}

.popular-content-checkbox-wrap:before {
  content: "";
  position: absolute;
  top: 4px;
  border: 1.5px solid #3e3f55;
  border-radius: 5px;
  width: 16px;
  height: 16px;
}

.popular-content-checkbox-wrap.active:after {
  content: "";
  position: absolute;
  top: 12px;
  left: 8px;
  transform: translate(-50%, -50%);
  width: 6px;
  height: 6px;
  background: #02c9bf;
}

.popular-content-checkbox-wrap.active:before {
  border-color: #02c9bf;
}

.popular-content-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.popular-content-item {
  position: relative;
  margin-bottom: 12px;
  width: 150px;
  cursor: pointer;
}

.popular-content-item span {
  overflow: hidden;
  width: 115px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.close__popup {
  position: absolute;
  top: 30px;
  right: 20px;
  cursor: pointer;
}

.input-radio {
  opacity: 0;
  width: 0;
  height: 0;
}

.export-popup__body__custom-content {
  display: flex;
  justify-content: space-between;
}

.custom-content__datepicker {
  position: relative;
  width: 100%;
}

.custom-content__datepicker-body {
  width: calc(50% - 10px);
}

.custom-content__datepicker-body span {
  position: absolute;
  top: 50%;
  left: 54%;
  transform: translateY(-50%);
  font-size: 11px;
  color: #A4A5AF;
}

.datepicker-checkbox-list li {
  margin: 10px 0 0 0;
  width: 100%;
}

.export-popup__buttons {
  margin-top: 16px;
}

.export-popup__buttons-wrapper {
  display: flex;
  justify-content: center;
  padding: 0 40px;
}

.export-popup__button {
  border-radius: 6px;
  width: 120px;
  padding: 12px 0;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  text-align: center;
}

.export-popup__button.cancel {
  transition: background-color .5s;
  margin-right: 20px;
  border: 1px solid var(--v-marketHeaderButton-base);
  background-color: var(--v-marketHeaderButton-base);
  color: var(--v-loginButtonCountOpenColor-base);
}

.export-popup__button.cancel:hover {
  background-color: var(--var-headerColor-base);
}

.export-popup__button.export {
  transition: color .5s, background-color .5s;
  border: 1px solid var(--v-accent-base);
  background-color: var(--v-accent-base);
  color: #fff;
}

.export-popup__button.export:hover {
  background-color: #fff;
  color: var(--v-accent-base);
}

.export-popup__button:disabled {
  background-color: var(--v-disableButtonColor-base);
}

.datepicker-checkbox__withoutSixMonth > div {
  margin-left: 24px;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  line-height: 18px;
  color: #a4a5af;
}

/* datepicker */

.mx-calendar {
  padding-bottom: 0;
}

.vfc-multiple-input {
  overflow: hidden;
  box-shadow: 0 4px 17px rgb(21 83 80 / 8%);
  border-radius: 6px;
  height: 48px;
}

.vfc-single-input,
.vfc-multiple-input input {
  border: none;
  border-radius: 0;
  width: 50%;
  height: 100%;
  background-color: var(--v-loginDropduwnBackgroundColor-base);
}

.vfc-main-container {
  background-color: var(--v-loginDropduwnBackgroundColor-base);
  font-family: "Helvetica Neue", Helvetica, Arial, "Microsoft Yahei", sans-serif;
}

.vfc-main-container .vfc-calendar:first-child {
  border-right: 1px solid var(--v-calendarBorderColor-base);
}

.vfc-arrow-left,
.vfc-arrow-right {
  border-color: #92939c !important;
}

.vfc-canendars-container {
  width: 660px;
}

.vfc-months-container .vfc-content {
  border: 1px solid var(--v-calendarBorderColor-base);
  background-color: var(--v-loginDropduwnBackgroundColor-base);
}

/* переделать .vfc-popover-care */

.vfc-months-container .vfc-content .vfc-months div.vfc-item.vfc-selected {
  background-color: #42e8e0;
}

.vfc-top-date {
  font-family: "Helvetica Neue", Helvetica, Arial, "Microsoft Yahei", sans-serif;
}

.vfc-top-date span {
  color: var(--v-selectColor-base);
}

.vfc-dayNames {
  color: var(--v-selectColor-base);
}

.vfc-dayNames span {
  font-family: "Helvetica Neue", Helvetica, Arial, "Microsoft Yahei", sans-serif;
  color: #92939c;
}

.vfc-week .vfc-day {
  color: var(--v-selectColor-base);
}

.vfc-week span.vfc-span-day {
  border-radius: 4px !important;
}

.vfc-week span.vfc-span-day.vfc-today {
  background-color: var(--v-calendarSelectedDay-base) !important;
}

.vfc-week span.vfc-span-day.vfc-marked {
  border-radius: 4px !important;
  background-color: #42e8e0 !important;
  color: #000;
}

.vfc-week span.vfc-span-day.vfc-hide {
  color: var(--v-calendarHideDay-base);
}

.vfc-week span.vfc-span-day.vfc-hover:hover,
.vfc-week span.vfc-span-day.vfc-hovered {
  background-color: #42e8e0;
}

.vfc-week .vfc-day:last-child {
  color: inherit;
}

</style>