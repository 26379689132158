export default {
  publicLayouts: {
    lg: [
      { x: 0, y: 4, w: 85, h: 4, i: '1', minW: 22, minH: 4, class: 'market-wrapper' },
      { x: 0, y: 8, w: 70, h: 31.5, i: '2', minW: 26, minH: 15, class: 'chart-wrapper' },
      { x: 70, y: 8, w: 15, h: 31.5, i: '3', minW: 20, minH: 10, class: 'orderbook-wrapper' },
      { x: 0, y: 41, w: 85, h: 17, i: '4', minW: 35, minH: 9 },
      { x: 85, y: 0, w: 15, h: 22, i: '5', minW: 20, minH: 22 },
      { x: 85, y: 32, w: 15, h: 15, i: '7', minW: 20, minH: 15 },
    ],
    md: [
      { x: 0, y: 4, w: 83, h: 4, i: '1', minW: 22, minH: 4, class: 'market-wrapper' },
      { x: 0, y: 8, w: 66, h: 31.5, i: '2', minW: 26, minH: 15, class: 'chart-wrapper' },
      { x: 66, y: 8, w: 17, h: 31.5, i: '3', minW: 17, minH: 10, class: 'orderbook-wrapper' },
      { x: 0, y: 41, w: 83, h: 17, i: '4', minW: 35, minH: 9 },
      { x: 83, y: 0, w: 17, h: 22, i: '5', minW: 17, minH: 22 },
      { x: 83, y: 32, w: 17, h: 15, i: '7', minW: 17, minH: 15 },
    ],
    sm: [
      { x: 0, y: 4, w: 100, h: 4, i: '1', minH: 4, minWPx: 200, class: 'market-wrapper' },
      { x: 0, y: 8, w: 100, h: 31.5, i: '2', minH: 15 },
      { x: 0, y: 41, w: 100, h: 31.5, i: '3', minH: 20 },
      { x: 0, y: 58, w: 100, h: 17, i: '4', minH: 9 },
      { x: 0, y: 41,w: 100, h: 20.5, i: '5', minH: 20.5 },
      { x: 0, y: 41,w: 100, h: 17, i: '7', minH: 15 },
    ],
  },
  privateLayouts: {
    lg: [
      { x: 0, y: 4, w: 83, h: 4, i: '1', minW: 22, minH: 4, class: 'market-wrapper' },
      { x: 0, y: 8, w: 66, h: 31.5, i: '2', minW: 26, minH: 15, class: 'chart-wrapper' },
      { x: 66, y: 8, w: 17, h: 31.5, i: '3', minW: 17, minH: 20, class: 'orderbook-wrapper' },
      { x: 0, y: 41, w: 83, h: 31, i: '4', minW: 35, minH: 9 },
      { x: 83, y: 0, w: 17, h: 22, i: '5', minW: 17, minH: 24 },
      { x: 83, y: 22, w: 17, h: 29, i: '6', minW: 17, minH: 29, class: 'spot-account-wrapper' },
      { x: 83, y: 55, w: 17, h: 20, i: '7', minW: 17, minH: 20 },
    ],
    md: [
      { x: 0, y: 4, w: 83, h: 4, i: '1', minW: 22, minH: 4, class: 'market-wrapper' },
      { x: 0, y: 8, w: 66, h: 31.5, i: '2', minW: 26, minH: 15, class: 'chart-wrapper' },
      { x: 66, y: 8, w: 17, h: 31.5, i: '3', minW: 17, minH: 20, class: 'orderbook-wrapper' },
      { x: 0, y: 41, w: 83, h: 31, i: '4', minW: 35, minH: 9 },
      { x: 83, y: 0, w: 17, h: 22, i: '5', minW: 17, minH: 24 },
      { x: 83, y: 22, w: 17, h: 28, i: '6', minW: 17, minH: 29, class: 'spot-account-wrapper' },
      { x: 83, y: 55, w: 17, h: 20, i: '7', minW: 17, minH: 20 },
    ],
    sm: [
      { x: 0, y: 0, w: 100, h: 4, i: '0', minH: 4, },
      { x: 0, y: 4, w: 100, h: 4, i: '1', minH: 4, class: 'market-wrapper', },
      { x: 0, y: 8, w: 100, h: 31.5, i: '2', minH: 15, },
      { x: 0, y: 41, w: 100, h: 31.5, i: '3', minH: 19, },
      { x: 0, y: 77, w: 100, h: 17, i: '4', minH: 9, },
      { x: 0, y: 73, w: 100, h: 24, i: '5', minH: 24, },
      { x: 0, y: 41, w: 100, h: 32, i: '6', minH: 19, },
      { x: 0, y: 73, w: 100, h: 24, i: '7', minH: 15, },
    ],
  },
  isStatic: true,
  layout: [],
};
