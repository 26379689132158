import { deepCopyObject } from '@/helpers';

export default {
  SET_PUBLIC_LAYOUT(state, { publicLayouts, breakpoint }) {
    if(publicLayouts) {
      state.layout = deepCopyObject(publicLayouts[breakpoint]);
    } else {
      state.layout = deepCopyObject(state.publicLayouts[breakpoint]);
    }
  },

  SET_LAYOUT(state, { privateLayouts, breakpoint }) {
    if(privateLayouts) {
      state.layout = deepCopyObject(privateLayouts[breakpoint]);
    } else {
      state.layout = deepCopyObject(state.privateLayouts[breakpoint]);
    }
  },

  SET_PRIVATE_LAYOUT(state, { privateLayouts, breakpoint }) {
    if(privateLayouts) {
      state.layout = deepCopyObject(privateLayouts[breakpoint]);
    } else {
      state.layout = deepCopyObject(state.privateLayouts[breakpoint]);
    }
  },

  CHANGE_STATIC(state, isStatic) {
    state.isStatic = isStatic || !state.isStatic;
  },

};
