import { render, staticRenderFns } from "./TakerVolume.vue?vue&type=template&id=e8c5bdc4&scoped=true&"
import script from "./TakerVolume.vue?vue&type=script&lang=js&"
export * from "./TakerVolume.vue?vue&type=script&lang=js&"
import style0 from "./TakerVolume.vue?vue&type=style&index=0&id=e8c5bdc4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e8c5bdc4",
  null
  
)

export default component.exports