<template>
  <v-dialog :content-class="popupClass" v-model="activeDialog" persistent>
<!--    :style="[isLoading && { 'pointer-events': 'none', 'user-select': 'none'}]"-->
    <div class="service-message-wrapper">
      <div class="close-dialog" @click="closePopups">
        <img src="../../assets/img/close.svg" alt="close-icon" />
      </div>
      <h2 v-if="title" class="sevice-message-title">{{ $t(title) }}</h2>
      <slot></slot>
      <div v-if="showFooter" class="service-btn-block">
<!--        <button class="service-btn btn-cancel mainBorderColor" @click="closePopups">{{ $t('Cancel') }}</button>-->
        <button class="service-btn btn-try accent" @click="activeDialog = false">{{ $t('Accepted') }}</button>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "CommonDialogWithdraw",
    props: {
      title: {
          type: String
      },
      showFooter: {
          type: Boolean
      },
      popupClass: {
          type: String
      },
      isLoading: {
          type: Boolean
      },
  },

  data() {
    return {
        activeDialog: false,
    };
  },
  methods: {
    openPopups () {
      this.activeDialog = true;
    },
    closePopups () {
      this.$emit('closePopup', null)
      this.activeDialog = false;
    },
  }
};
</script>

<style>
.v-dialog {
  box-shadow: none;
  margin: 0;
  border-radius: 0;
}

.close-dialog {
  width: 12px;
  position: absolute;
  top: 25px;
  right: 23px;
  cursor: pointer;
}

.close-dialog img {
  display: block;
  width: 100%;
}

.sevice-message-description {
  margin: 16px 0 0 0!important;
  text-align: center;
  font-size: 14px;
  line-height: 150%;
}

.service-btn {
  min-width: 120px;
  min-height: 48px;
  border-radius: 6px;
  font-weight: bold;
  font-size: 16px;
  transition: .5s all;
}

.btn-cancel {
  margin: 0 20px 0 0;
}

.btn-try {
  color: #111222;
}

.v-dialog.rate-popup {
  max-width: 440px;
  margin: 0 auto;
}

</style>
