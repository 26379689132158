<template>
  <div class="public-page-content">
<!--    <FaqItemList :data="data" />-->
    <h1>FAQ</h1>
    <FaqItemList />
  </div>
</template>

<script>
import FaqItemList from "../faq/FaqItemList";

export default {
  name: "Faq",
  data() {
    return {
      data: [],
    }
  },
  components: {
    FaqItemList
  },
  methods: {
    changeTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem(
          "theme",
          this.$vuetify.theme.dark ? "dark" : "light"
      );
    },
  },
}
</script>

<style scoped>

</style>