<template>
  <nav class="nav-wrapper" :style="{left: isOpen ? 0 : -600 + 'px'}">
    <div class="close-button" @click="closeSideBar">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none" class="css-1yweqd7">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M14 12.676l-1.324 1.316-4.683-4.675L3.324 14l-1.316-1.324L6.676 8 2 3.324l1.324-1.317 4.669 4.676L12.676 2l1.31 1.324L9.315 8 14 12.676z"
              fill="currentColor"></path>
      </svg>
    </div>
    <div v-for="(item, index) in itemList"
         :key="index"
         class="nav-items"
         :class="{'active': $route.path === item.routeTo}"
         @click="pushToEvent(item.routeTo)"
    >
      <div class="textSecondaryColor nav-items__name">
        <span>{{ $t(item.name) }}</span>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "tabletVersionOfSidebar",
  props: {
    itemList: Array,
  },
  data() {
    return {
      isOpen: false
    }
  },
  methods: {
    openSidebar() {
      this.isOpen = true
    },
    closeSideBar() {
      this.isOpen = false
    },
    pushToEvent(item) {
      this.$router.push({path: item})
      this.closeSideBar()
    }
  },

}
</script>

<style scoped>
.nav-wrapper {
  transition: all 0.5s ease-in-out;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 280px;
  min-height: calc(100vh - 67px);
  padding: 50px 15px 0;
  display: flex;
  flex-direction: column;
  background: var(--v-primary-base) !important;
}

.nav-items {
  margin-bottom: 20px;
  position: relative;
  cursor: pointer;
}


.nav-wrapper .nav-items .nav-items__name span:before {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 1px;
  background: #42E8E0;
  transition: all .5s;
}

.nav-wrapper .nav-items.active .nav-items__name span:before {
  opacity: 1;
}

.nav-wrapper .nav-items.active .nav-items__name {
  color: var(--v-textMainColor-base) !important;
}

.close-button svg {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
</style>
