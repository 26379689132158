<template>
  <div>
    <CommonDialog
        ref="commonDialogConnectWallet"
        :title="$t('Connect Wallet')"
        :isLoading="isLoading"
        :popupClass="'connect-wallet-popup'"
    >
      <div class="step-header" v-if="type === 'register'">
        <div class="step-progress">
          <div class="bar progressbar" style="width: 50%;"></div>
        </div>
        <ul class="step-pills step-pills-invite">
          <li
              class="step-item active"
              :class="connectionState ? 'validated' : ''"
          >
            <span class="step-link">
              <span class="tabStatus">
                <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 5.65385L5.9 10.5L15 1.5" stroke="#42E8E0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <span>1</span>
              </span>
            <span class="tabLabel">{{ $t('Wallet Mapping') }}</span>
            </span>
          </li>
          <li
              class="step-item"
              :class="connectionState ? 'active' : ''"
          >
            <span class="step-link">
              <span class="tabStatus">
                <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 5.65385L5.9 10.5L15 1.5" stroke="#42E8E0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <span>2</span>
              </span>
              <span class="tabLabel">{{ $t('Wallet Connection') }}</span>
            </span>
          </li>
        </ul>
      </div>
      <div class="connect-login-header" v-if="type === 'login'">
        <img :src="require('@/assets/img/metamask.svg')" alt="">
      </div>
      <div class="service-btn-block">
        <div class="connect-popup-body">
          <div class="connect-popup-content">
            <div class="connect-popup-content-top" v-if="mappingState && type === 'register'">
              <span class="connect-popup-content-title">
                {{$t('Step')}} 1
              </span>
              <span class="connect-popup-content-descr">
                {{$t(`Connect your Ethereum wallet with Dexilon Blockchain`)}}
              </span>
            </div>
            <div class="connect-popup-content-top" v-if="connectionState && type === 'register'">
              <span class="connect-popup-content-title">
                {{$t('Step')}} 2
              </span>
              <span class="connect-popup-content-descr">
                {{$t(`Enable secure access to your funds  and start trading`)}}
              </span>
            </div>
            <div class="connect-popup-content-top" v-if="type === 'login'">
              <span class="connect-popup-content-title">
                {{$t('Verify Ownership')}}
              </span>
              <span class="connect-popup-content-descr">
                {{$t(`Check your wallet for a request.`)}}
              </span>
            </div>
            <div class="connect-popup-info-description">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.20868 5.19649C7.20868 4.82968 7.55615 4.51114 8.00019 4.51114C8.43455 4.51114 8.78211 4.82968 8.78211 5.19649C8.78211 5.56333 8.43458 5.89152 8.00019 5.89152C7.55618 5.89152 7.20868 5.56333 7.20868 5.19649ZM7.25686 7.23327C7.25686 6.963 7.56575 6.77955 8.00013 6.77955C8.41522 6.77955 8.75304 6.96297 8.75304 7.23322V10.911C8.75304 11.1427 8.41522 11.3743 8.00013 11.3743C7.56575 11.3743 7.25686 11.1427 7.25686 10.911V7.23327Z" fill="#92939C"/>
                <circle cx="8" cy="8" r="7" stroke="#92939C" stroke-width="1.5"/>
              </svg>
              <p> {{ $t('You will receive a signature request. Signing is free and won’t sent a transaction.') }}</p>
            </div>
          </div>
          <button
              class="service-btn btn-try accent"
              :disabled="isDisableButton"
              @click="mappingState ? registration() : connect()"
              v-if="type === 'register'"
          >
            <div
                class="spinner rounded-circle"
                v-if="isLoading"
            >
            </div>
            <span v-if="isDisableButton">{{$t('Waiting for signing...')}}</span>
            <span v-if="!isDisableButton && mappingState">{{ $t('Connect Wallet') }}</span>
            <span v-if="!isDisableButton && connectionState">{{ $t('Enable secure access') }}</span>
          </button>
          <button
              class="service-btn btn-try accent"
              :disabled="isDisableButton"
              @click="connect()"
              v-if="type === 'login'"
          >
            <div
                class="spinner rounded-circle"
                v-if="isLoading"
            >
            </div>
            <span v-if="isDisableButton">{{$t('Waiting for signing...')}}</span>
            <span v-if="!isDisableButton">{{ $t('Verify Ownership') }}</span>
          </button>
        </div>
      </div>
    </CommonDialog>
  </div>
</template>

<script>
import CommonDialog from "@/components/popups/CommonDialog.vue";
import store from "@/store";
import ToasterMetamask from "@/components/toasters/ToasterMetamask.vue";
export default {
  name: "Connect",
  components: {
    CommonDialog
  },
  props: {
    authData: {}
  },
  data() {
    return {
      type: null,
      isLoading: false,
      isDisableButton: false,
      mappingState: true,
      connectionState: false,
      grant: null,
    }
  },
  computed: {
    walletAddress() {
      return this.$store.getters["wallets/getMetamaskWallet"].address
    },
  },
  methods: {
    open(type) {
      this.type = type
      this.$refs.commonDialogConnectWallet.openPopups();
      setTimeout(() => {
        this.$store.commit('wallets/CHANGE_PRELOAD_STATUS', false)
      }, 200)
    },

    close() {
      this.$refs.commonDialogConnectWallet.closePopups();
    },

    async registration() {
      const cosmosData = this.authData
      this.isLoading = true
      this.isDisableButton = true
      this.showToasterMetamask(
          this.$t('New Signature Request'),
          this.$t('Check MetaMask for a new Signature Request'),
      );
      await this.$store.dispatch('wallets/registration', cosmosData).then((res) => {
        this.isLoading = false
        this.isDisableButton = false
        this.mappingState = false
        this.connectionState = true
      }).catch((err)  => {
        this.showToasterMetamask(
            this.$t('Metamask Error'),
            this.$t(err.message),
        );
        this.isLoading = false
        this.isDisableButton = false
      })
    },

    async connect() {
      const cosmosData = this.authData
      this.isLoading = true
      this.isDisableButton = true
      this.showToasterMetamask(
          this.$t('New Signature Request'),
          this.$t('Check MetaMask for a new Signature Request'),
      );
      await this.$store.dispatch('wallets/grantPermissions', cosmosData).then((res) => {
        this.grant = res;
        this.grant.ethAddress = cosmosData.ethAddress
        this.isLoading = false
        this.isDisableButton = false
        this.auth(this.grant);
        this.close();
      }).catch((err)  => {
        this.showToasterMetamask(
            this.$t('Metamask Error'),
            this.$t(err.message),
        );
        this.isLoading = false
        this.isDisableButton = false
      })
    },


    async auth(grant) {
      this.$store.commit('wallets/CHANGE_PRELOAD_STATUS', true)
      await this.$store.dispatch('wallets/authRequest', {
        nonce: grant.signedMessage,
        signedNonce: grant.granterSinature,
        ethAddress: grant.ethAddress
      }).then(  async () => {
        this.$store.commit('wallets/CHANGE_PRELOAD_STATUS', false)
        await this.$store.dispatch('wallets/getSwapInfo');
        window.dataLayer.push({
          event: 'authentication',
          action: 'user-sign-in', //user-sign-up, user-sign-in, user-log-out
          userId: this.walletAddress
        });
      }).catch((err) => {
        this.showToasterMetamask(
            this.$t('Metamask Error'),
            this.$t(err.message),
        );
        this.$store.commit('wallets/CHANGE_PRELOAD_STATUS', false)
      });
    },

    showToasterMetamask(title, description) {
      this.$toast.success({
        component: ToasterMetamask,
        props:  {
          title,
          description,
        },
      });
    },
  }
}
</script>

<style scoped>
.step-header {
  position: relative;
  margin-top: 20px;
}

.step-progress {
  position: absolute;
  z-index: 1;
  width: 100%;
  bottom: 79px;
  margin: 0;
  height: 1px;
  background: var(--v-mainBorderColor-base) !important;
  display: none;
}

.step-pills {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
}

.step-pills .step-item {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.step-pills .step-item.active {
  border: none;
}

.step-pills .step-item.validated {
  border: none;
}

.tabStatus {
  display: flex;
  position: relative;
  z-index: 2;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--v-marketHeaderButton-base);
  border-radius: 50%;
  width: 46px;
  height: 46px;
  background-color: var(--v-primary-base);
  text-align: center;
  color: var(--v-textMainColor-base);
  margin: 0 auto;
}

.tabStatus:after {
  content: "";
  position: absolute;
  left: calc(100% + 1px);
  top: 50%;
  display: block;
  z-index: 1;
  /*width: 100%;*/
  width: 90px;
  height: 1px;
  background: var(--v-marketHeaderButton-base) !important;
}

.step-pills .step-item a {
  text-decoration: none;
  color: var(--secondary-text-color);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}

.tabLabel {
  display: block;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: var(--input-placeholder-value-color);;
  margin-top: 10px;
}

.step-progress .bar {
  height: 1px;
}

.step-pills .step-item.active .tabStatus {
  border: 1px solid #42E8E0 !important;
  color: #42E8E0 !important;
}

.step-pills .step-item.validated .tabStatus {
  border: 1px solid #42E8E0 !important;
  color: #fff;
}

.step-pills .step-item .tabStatus svg {
  display: none;
}

.step-pills .step-item.validated .tabStatus svg {
  display: block;
}

.step-pills .step-item.validated .tabStatus span {
  display: none;
}

.step-pills .step-item:last-child .tabStatus:after {
  display: none;
}

.step-pills .step-item.validated .tabStatus:after {
  background: #42E8E0 !important;
}

.step-pills .step-item.active .tabLabel {
  color: #42E8E0 !important;
}

.step-pills .step-item.validated .tabLabel {
  color: #42E8E0 !important;
}

.connect-popup-body {
  position: relative;
  margin-bottom: 20px;
}

.connect-popup-content {
  background: #393939;
  border-radius: 6px;
  margin-bottom: 40px;
}

.connect-popup-content-top {
  padding: 21px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: 1px solid #2e2d2d;
}

.connect-popup-content-title {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #2DCAC2;
  display: block;
  width: 100%;
  margin-bottom: 5px;
  text-align: center;
}

.connect-popup-content-descr {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #E9E9E9;
  text-align: center;
  max-width: 230px;
  margin: 0 auto;
}

.connect-popup-info-description {
  padding: 21px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.connect-popup-info-description p {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #92939C;
  width: calc(100% - 25px);
  margin-left: auto;
  margin-bottom: 0;
}






.v-application .accent.service-btn {
  width: 100%;
}

.v-application .accent.service-btn:disabled,
.v-application .accent.service-btn:disabled:hover {
  background: rgba(2,201,191,.2)!important;
  border-color: transparent!important;
  color: #e9e9e9!important;
  opacity: 1!important;
}

.connect-login-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 45px;
  margin-bottom: 45px;
}



</style>