import { getFromLocalStorage, saveToLocalStorage, getWindowSize } from '@/helpers';

export default {
  getLayout({ state, commit }) {
    const breakpoint = getWindowSize();
    if(getFromLocalStorage('token')) {
      // const privateLayouts = getFromLocalStorage('privateLayouts');
      const privateLayouts = state.privateLayouts;
      commit('SET_PRIVATE_LAYOUT', { privateLayouts, breakpoint });
    } else {
      // const publicLayouts = getFromLocalStorage('publicLayouts');
      const publicLayouts = state.publicLayouts;
      commit('SET_PUBLIC_LAYOUT', { publicLayouts, breakpoint });
    }
  },

  setLayout({ state }, { layout }) {
    const breakpoint = getWindowSize();

    if(getFromLocalStorage('token')) {
      // const privateLayouts = getFromLocalStorage('privateLayouts') || state.privateLayouts;
      const privateLayouts = state.privateLayouts;

      saveToLocalStorage('privateLayouts', { ...privateLayouts, [breakpoint]: layout })
    } else {
      // const publicLayouts = getFromLocalStorage('publicLayouts') || state.publicLayouts;
      const publicLayouts = state.publicLayouts;

      saveToLocalStorage('publicLayouts', { ...publicLayouts, [breakpoint]: layout });
    }
  },

  updateLayout({ state, commit }, {layout}) {
    const breakpoint = getWindowSize();
    const privateLayouts = layout;
    commit('SET_LAYOUT', { privateLayouts, breakpoint });
  },

  setDefaultLayout({ commit }) {
    const breakpoint = getWindowSize();

    getFromLocalStorage('token')
      ? commit('SET_PRIVATE_LAYOUT', { breakpoint })
      : commit('SET_PUBLIC_LAYOUT', { breakpoint });
  },

  changeStatic({ commit }, { isStatic }) {
    commit('CHANGE_STATIC', isStatic);
  },
};
