<template>
  <div class="public-page-content knowledge-base-template">
    <div class="public-page-header">
      <h1>{{$t('How to make a deposit at Dexilon?')}}</h1>
      <span>Last updated 27 May, 2022</span>
    </div>
    <div class="knowledge-base-list">
      <div class="knowledge-base-item">
        <h3>{{$t('General Information')}}</h3>
        <span class="knowledge-base-item-subtitle">{{$t('RPC Node Link:')}} </span>
        <a href="https://polygon-mumbai.g.alchemy.com/v2/xuv0OmzjOtEKeck2dshgZhl-W9kFsMSC" class="node-link">https://polygon-mumbai.g.alchemy.com/v2/xuv0OmzjOtEKeck2dshgZhl-W9kFsMSC</a>
        <ul class="knowledge-base-item-sublist">
          <li class="knowledge-base-item-subitem">
            <span>{{$t('Video')}}:</span>
            <a href="https://drive.google.com/file/d/1g1zdpXbyFTVRFFFkdaq8lcb4nki75n8a/view" target="_blank">{{$t('How to install Metamask extensions to the Chrome')}}</a>
          </li>
          <li class="knowledge-base-item-subitem">
            <span>{{$t('Video')}}:</span>
            <a href="https://drive.google.com/file/d/1y5PG1B1fwr2PMc13Js5VqB1u6hP0NDns/view" target="_blank">{{$t('How to change RPC node to a stable one')}}</a>
          </li>
        </ul>
      </div>
      <div class="knowledge-base-item">
        <h3>{{$t('How to add Metamask extension to Chrome')}}</h3>
        <p>1. {{$t('If you do not have Metamask extension, open Chrome extensions webstore using a link')}}</p>
        <p>2. {{$t('Search for Metamask extension using Search Field:')}}</p>
        <img src="@/assets/img/knowledge-base-images/knowledge-base-image-1.png" alt="">
        <p>3. {{$t('Click on Metamask extension, you will see full extension information. Press the button')}} <span>{{$t('Add to Chrome:')}}</span></p>
        <img src="@/assets/img/knowledge-base-images/knowledge-base-image-2.png" alt="">
        <p>4. {{$t('After Metamask will be installed, you need to follow Metamask flow for')}} <span>{{$t('SingIn')}}</span> {{$t('or')}} <span>{{$t('SignUp:')}}</span></p>
        <img src="@/assets/img/knowledge-base-images/knowledge-base-image-3.png" alt="">
        <p>5. {{$t('After Metamask will be installed, you will be able to log in to Dexilon website:')}}</p>
        <div class="knowledge-base-item-image-wrap">
          <img src="@/assets/img/knowledge-base-images/knowledge-base-image-4.png" alt="">
          <img src="@/assets/img/knowledge-base-images/knowledge-base-image-5.png" alt="">
        </div>
        <p>6. {{$t('Dexilon will ask you to set Polygon Mumbai as a preferred network for Dexilon website. Agree on the step.')}}</p>
      </div>
      <div class="knowledge-base-item">
        <h3>{{$t('How to add speedy non faulty RPC node to Metamask Extension')}}</h3>
        <div class="knowledge-base-item-content-container">
          <span class="knowledge-base-item-content-container-title">{{$t('RPC Node Link:')}} </span>
          <a href="https://polygon-mumbai.g.alchemy.com/v2/xuv0OmzjOtEKeck2dshgZhl-W9kFsMSC">https://polygon-mumbai.g.alchemy.com/v2/xuv0OmzjOtEKeck2dshgZhl-W9kFsMSC</a>
        </div>
        <p>1. {{$t('Open your Metamask extension and press your account icon:')}}</p>
        <img src="@/assets/img/knowledge-base-images/knowledge-base-image-6.png" alt="">
        <p>2. {{$t('In the following menu click Settings menu item:')}}</p>
        <img src="@/assets/img/knowledge-base-images/knowledge-base-image-7.png" alt="">
        <p>3. {{$t('Select Mumbai Polygon Network:')}}</p>
        <img src="@/assets/img/knowledge-base-images/knowledge-base-image-8.png" alt="">
        <p>4. {{$t('In field New RPC URL paste the speedy node URL:')}}</p>
        <div class="knowledge-base-item-content-container">
          <span class="knowledge-base-item-content-container-title">{{$t('RPC Node Link:')}} </span>
          <a href="https://polygon-mumbai.g.alchemy.com/v2/xuv0OmzjOtEKeck2dshgZhl-W9kFsMSC">https://polygon-mumbai.g.alchemy.com/v2/xuv0OmzjOtEKeck2dshgZhl-W9kFsMSC</a>
        </div>
        <img src="@/assets/img/knowledge-base-images/knowledge-base-image-9.png" alt="">
        <p>5. {{$t('Scroll down and press')}} <span>{{$t('Save')}}</span> {{$t('button')}}</p>
        <img src="@/assets/img/knowledge-base-images/knowledge-base-image-10.png" alt="">
        <div class="knowledge-base-item-content-container">
          <p>{{$t('Confusing part here is Metamask highlights some of the fields with red border, however do not pay attention to it and just press Save.')}}</p>
          <p>{{$t('One more tricky thing here - Metamask refreshes layout in some period of time and it might wipe out your changes before you press Save button.')}}</p>
          <p>{{$t('(My personal opinion is that is really bad user experience, however that should be solved by Metamask team and this is what we have for today).')}}</p>
        </div>
        <p>6. {{$t('Refresh your page and now you are on more stable RPC Mumbai Polygon Node and it should work without any connection issues')}}</p>
      </div>
      <div class="knowledge-base-item">
        <h3>{{$t('How to import USDC token and deposit Matic for testing')}}</h3>
        <p>1. {{$t('Click Import USDC token button in the header of the Dexilon website')}}</p>
        <img src="@/assets/img/knowledge-base-images/knowledge-base-image-11.png" alt="">
        <p>2. {{$t('Click')}} <span>{{$t('Add Token')}}</span> {{$t('in the Metamask popup window:')}}</p>
        <img src="@/assets/img/knowledge-base-images/knowledge-base-image-12.png" alt="">
      </div>
      <div class="knowledge-base-item">
        <h3>{{$t('How to deposit funds into your Metamask Account')}}</h3>
        <p>1. <span>{{$t('Click Deposit funds into your metamask')}}</span> {{$t('button')}} :</p>
        <img src="@/assets/img/knowledge-base-images/knowledge-base-image-13.png" alt="">
        <p>2. {{$t('Click')}} <span>{{$t('Accepted')}}</span> {{$t('button')}} :</p>
        <img src="@/assets/img/knowledge-base-images/knowledge-base-image-14.png" alt="">
        <p>3. {{$t('Check your Metamask wallet. You should see')}} <span>0.0005</span> {{$t('MATIC was added to your balance:')}}</p>
        <img src="@/assets/img/knowledge-base-images/knowledge-base-image-15.png" alt="">
      </div>
      <div class="knowledge-base-item">
        <h3>{{$t('How to deposit USDC to your Dexilon account')}}</h3>
        <p>1. {{$t('Press')}} <span>{{$t('Deposit')}}</span> {{$t('button at main trading view page:')}}</p>
        <img src="@/assets/img/knowledge-base-images/knowledge-base-image-16.png" alt="">
        <p>2. {{$t('Select amount you want to deposit. Max is 100000 USDC for provided test Matic and click')}} <span>{{$t('Approve')}}</span> {{$t('button')}}</p>
        <img src="@/assets/img/knowledge-base-images/knowledge-base-image-17.png" alt="">
        <p>3. {{$t('Press')}} <span>{{$t('Confirm')}}</span> {{$t('button in the Metamask popUp window:')}}</p>
        <img src="@/assets/img/knowledge-base-images/knowledge-base-image-18.png" alt="">
        <p>4. {{$t('Now you are at the Dexilon website Deposit popUp window stage 2. You have to press')}} <span>{{$t('Deposit')}}</span> {{$t('button')}}:</p>
        <img src="@/assets/img/knowledge-base-images/knowledge-base-image-19.png" alt="">
        <p>5. {{$t('Press Confirm button in the Metamask popUp window:')}}</p>
        <img src="@/assets/img/knowledge-base-images/knowledge-base-image-20.png" alt="">
        <div class="knowledge-base-item-content-container">
          <p>{{$t('In case you have')}} <span>{{$t('Insufficient funds')}}</span> {{$t('notification and Confirm button is grayed out you have to press Edit button above the notification and tweak transaction’s Gas fee to make the transaction acceptable(very rare case)')}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "HowToMakeDeposit",
  methods: {
    changeTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem(
          "theme",
          this.$vuetify.theme.dark ? "dark" : "light"
      );
    },
  },
}
</script>

<style scoped>

  .knowledge-base-template {
    width: 100%;
    max-width: initial;
  }

  .knowledge-base-list {
    max-width: 750px;
  }

  .knowledge-base-item {
    margin-bottom: 64px;
    padding-bottom: 64px;
    border-bottom: 3px solid var(--v-publicPagesBorder-base);
  }

  .knowledge-base-item h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    margin-top: 0;
    margin-bottom: 25px;
  }

  .knowledge-base-item-subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    display: block;
    margin-bottom: 16px;
  }

  .knowledge-base-item-sublist {
    margin-left: 20px;
    margin-bottom: 0;
  }

  .knowledge-base-item-subitem {
    list-style: disc;
    margin-bottom: 16px;
  }

  .knowledge-base-item-subitem:last-child {
    margin-bottom: 0;
  }

  .knowledge-base-item-subitem span {
    display: inline-block;
    margin-right: 8px;
  }

  .knowledge-base-item-subitem a {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-decoration-line: underline;
    color: #42E8E0;
  }

  .knowledge-base-item-subitem a:hover {
    text-decoration: none;
  }

  .knowledge-base-item p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 16px;
  }

  .knowledge-base-item img {
    display: block;
    margin-bottom: 24px;
    max-width: 750px;
    width: auto;
  }

  .knowledge-base-item-image-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .knowledge-base-item-image-wrap img:first-child {
    margin-right: 16px;
  }

  .knowledge-base-item-content-container {
    background: #262626;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 24px;
    max-width: 590px;
  }

  .knowledge-base-item-content-container-title {
    display: block;
    width: 100%;
    margin-bottom: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
  }

  .knowledge-base-item-content-container a {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    text-decoration-line: underline;
    color: #42E8E0;
  }

  .knowledge-base-item-content-container a:hover {
    text-decoration: none;
  }

  .knowledge-base-item-content-container p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
  }

  .knowledge-base-item:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }

  .public-page-content .knowledge-base-item-content-container p {
    color: #fff;
  }

  .public-page-content .knowledge-base-item-content-container .knowledge-base-item-content-container-title {
    color: #fff;
  }

  .node-link {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    -webkit-text-decoration-line: underline;
    text-decoration-line: underline;
    color: #42E8E0;
    display: inline-block;
    margin-bottom: 25px;
  }


</style>