<template>
  <div class="container success-toast">
    <div class="toaster-icon toaster-icon-error">
      <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M23.0122 2.3335L15 8.16892L16.4817 4.7261L23.0122 2.3335Z" fill="#E2761B"/>
        <path d="M4.51562 2.3335L12.4634 8.2242L11.0542 4.7261L4.51562 2.3335Z" fill="#E4761B"/>
        <path d="M20.13 15.8604L17.9961 19.0663L22.5618 20.2981L23.8744 15.9314L20.13 15.8604Z" fill="#E4761B"/>
        <path d="M3.66797 15.9314L4.97247 20.2981L9.53821 19.0663L7.40431 15.8604L3.66797 15.9314Z" fill="#E4761B"/>
        <path d="M9.2801 10.4419L8.00781 12.3292L12.5413 12.5266L12.3803 7.74927L9.2801 10.4419Z" fill="#E4761B"/>
        <path d="M18.2451 10.4439L15.1047 7.69592L15 12.5285L19.5255 12.3311L18.2451 10.4439Z" fill="#E4761B"/>
        <path d="M9.53906 19.0662L12.2608 17.7633L9.90948 15.9629L9.53906 19.0662Z" fill="#E4761B"/>
        <path d="M15.2617 17.7633L17.9915 19.0662L17.613 15.9629L15.2617 17.7633Z" fill="#E4761B"/>
        <path d="M17.9915 19.0677L15.2617 17.7648L15.4791 19.5099L15.455 20.2442L17.9915 19.0677Z" fill="#D7C1B3"/>
        <path d="M9.53906 19.0677L12.0756 20.2442L12.0595 19.5099L12.2608 17.7648L9.53906 19.0677Z" fill="#D7C1B3"/>
        <path d="M12.1145 14.8101L9.84375 14.1547L11.4462 13.4362L12.1145 14.8101Z" fill="#233447"/>
        <path d="M15.4102 14.8101L16.0785 13.4362L17.689 14.1547L15.4102 14.8101Z" fill="#233447"/>
        <path d="M9.53624 19.0663L9.92276 15.8604L7.40234 15.9314L9.53624 19.0663Z" fill="#CD6116"/>
        <path d="M17.6055 15.8604L17.992 19.0663L20.1259 15.9314L17.6055 15.8604Z" fill="#CD6116"/>
        <path d="M19.5255 12.3302L15 12.5276L15.4187 14.8097L16.0871 13.4357L17.6976 14.1543L19.5255 12.3302Z" fill="#CD6116"/>
        <path d="M9.84377 14.1543L11.4543 13.4357L12.1146 14.8097L12.5413 12.5276L8.00781 12.3302L9.84377 14.1543Z" fill="#CD6116"/>
        <path d="M8.00781 12.3302L9.90819 15.9625L9.84377 14.1543L8.00781 12.3302Z" fill="#E4751F"/>
        <path d="M17.6977 14.1543L17.6172 15.9625L19.5256 12.3302L17.6977 14.1543Z" fill="#E4751F"/>
        <path d="M12.5401 12.5281L12.1133 14.8101L12.6447 17.5028L12.7655 13.9573L12.5401 12.5281Z" fill="#E4751F" stroke="#E4751F" stroke-width="0.777778" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M14.9987 12.5281L14.7812 13.9494L14.8779 17.5028L15.4174 14.8101L14.9987 12.5281Z" fill="#E4751F" stroke="#E4751F" stroke-width="0.777778" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M15.4184 14.8108L14.8789 17.5035L15.2654 17.764L17.6167 15.9637L17.6973 14.1554L15.4184 14.8108Z" fill="#F6851B"/>
        <path d="M9.84375 14.1554L9.90817 15.9637L12.2595 17.764L12.646 17.5035L12.1145 14.8108L9.84375 14.1554Z" fill="#F6851B"/>
        <path d="M15.4576 20.2421L15.4818 19.5077L15.2805 19.334H12.2447L12.0595 19.5077L12.0756 20.2421L9.53906 19.0656L10.4248 19.7762L12.2205 21.0002H15.3046L17.1084 19.7762L17.9941 19.0656L15.4576 20.2421Z" fill="#C0AD9E"/>
        <path d="M15.2596 17.7639L14.873 17.5033H12.6425L12.256 17.7639L12.0547 19.509L12.2399 19.3353H15.2757L15.477 19.509L15.2596 17.7639Z" fill="#161616"/>
        <path d="M23.3464 8.54795L24.0308 5.32622L23.0082 2.3335L15.2617 7.97151L18.2411 10.4431L22.4526 11.6512L23.3866 10.5852L22.984 10.3009L23.6282 9.72451L23.129 9.34548L23.7732 8.8638L23.3464 8.54795Z" fill="#763D16"/>
        <path d="M3.5 5.3261L4.18446 8.54783L3.74963 8.86368L4.39382 9.34536L3.90262 9.72439L4.54682 10.3008L4.1442 10.5851L5.07023 11.6511L9.28166 10.443L12.2611 7.97139L4.51461 2.33337L3.5 5.3261Z" fill="#763D16"/>
        <path d="M22.4567 11.6513L18.2453 10.4431L19.5256 12.3304L17.6172 15.9627L20.1296 15.9311H23.8739L22.4567 11.6513Z" fill="#F6851B"/>
        <path d="M9.28053 10.4431L5.0691 11.6513L3.66797 15.9311H7.40431L9.90862 15.9627L8.00824 12.3304L9.28053 10.4431Z" fill="#F6851B"/>
        <path d="M14.9965 12.5273L15.2622 7.97112L16.4862 4.72571H11.0508L12.2586 7.97112L12.5405 12.5273L12.6371 13.9645L12.6452 17.5021H14.8757L14.8918 13.9645L14.9965 12.5273Z" fill="#F6851B"/>
        <rect x="14.5" y="13.5" width="13" height="13" rx="3.5" fill="#267BD5"/>
        <path d="M22.0458 17.1818V23H20.8157V18.3494H20.7816L19.4492 19.1847V18.0938L20.8896 17.1818H22.0458Z" fill="#E9E9E9"/>
        <rect x="14.5" y="13.5" width="13" height="13" rx="3.5" stroke="#1E1E1E"/>
      </svg>
    </div>
    <div class="toast-content">
      <span class="toast-title">{{title}}</span>
      <p class="toast-description">{{description}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ToasterMetamask",
  props: {
    title: String,
    description: String,
  },
}
</script>

<style scoped>

</style>