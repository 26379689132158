<template>
  <tr class="table-body">
<!--    {{lotItem}}-->
    <td :data-label="$t('Trading Pair')">
      <div class="trading-pair">
        <div class="logo">
          <img :src="lotItem.image" alt="">
        </div>
        <div class="pair-info">
          <p class="textMainColor">{{lotItem.symbol.split('_').join('/').toUpperCase()}}</p>
          <p class="textSecondaryColor">{{ lotItem.totalBids }} bids</p>
        </div>
      </div>
    </td>
    <td>
      <div class="quantity">
        <span>{{ $t('Quantity') }}</span>
        <span>
              <span class="amount" :class="lotItem.amount > 0 ? 'up' : 'down'">
                {{ lotItem.amount.toFixed(2) }}
              </span>
              {{lotItem.symbol.split('_')[0].toUpperCase()}}
            </span>
      </div>
      <div class="remaining">
        <span>{{ $t('Remaining') }}</span>
        <span>
              <span class="amount" :class="(lotItem.amount - lotItem.filledAmount) > 0 ? 'up' : 'down'">
                {{ (lotItem.amount - lotItem.filledAmount).toFixed(2) }}
              </span>
              {{lotItem.symbol.split('_')[0].toUpperCase()}}
            </span>
      </div>
    </td>
    <td :data-label="$t('Bid Price')">
      <div class="bid-price">
              <span class="textMainColor">
                {{ prettifyToSix(toPrecisionNumber(lotItem.currentBidPrice)) }}
              </span>
        {{lotItem.symbol.split('_')[1].toUpperCase()}}
      </div>
    </td>
    <td>
      <div class="index-price">
        <span>{{ $t('Index Price') }}</span>
        <span>
              <span class="textMainColor">
                {{ prettifyToSix(toPrecisionNumber(lotItem.indexPrice)) }}
              </span>
              {{lotItem.symbol.split('_')[1].toUpperCase()}}
            </span>
      </div>
      <div class="mark-price">
        <span>{{ $t('Mark Price') }}</span>
        <span>
              <span class="textMainColor">
                {{ prettifyToSix(toPrecisionNumber(lotItem.markPrice)) }}
              </span>
              {{lotItem.symbol.split('_')[1].toUpperCase()}}
            </span>
      </div>
    </td>
    <td :data-label="$t('Notional Value')">
            <span class="textMainColor">
              {{ prettifyToSix(toPrecisionNumber(lotItem.notionalValue)) }}
            </span>
      {{lotItem.symbol.split('_')[1].toUpperCase()}}
    </td>
    <td :data-label="$t('Next Step')">
          <span>
            <span class="textMainColor">
            {{nextStep}}
          </span>
            sec
          </span>
    </td>
    <td :data-label="$t('Discount')">
      <div class="to-mp">
        <span>{{ $t('to MP') }}</span>
        <span>
              <span :class="lotItem.currentBidPrice / lotItem.markPrice > 0 ? 'up' : lotItem.currentBidPrice / lotItem.markPrice < 0 ? 'down' : ''">
                {{ isNaN((lotItem.currentBidPrice / lotItem.markPrice).toFixed(2)) ? 0 : (lotItem.currentBidPrice / lotItem.markPrice).toFixed(2) }}
              </span>
              %
            </span>
      </div>
      <div class="to-index">
        <span>{{ $t('to Index') }}</span>
        <span>
              <span :class="lotItem.currentBidPrice / lotItem.indexPrice > 0 ? 'up' : lotItem.currentBidPrice / lotItem.indexPrice < 0 ? 'down' : ''">
                {{ isNaN((lotItem.currentBidPrice / lotItem.indexPrice).toFixed(2)) ? 0 : (lotItem.currentBidPrice / lotItem.indexPrice).toFixed(2) }}
              </span>
              %
            </span>
      </div>
    </td>
    <td>
      <button
          class="order-form-callateral-button"
          @click="placeBidAction(lotItem)"
          :disabled="!!!token"
      >
        {{ $t('Place a bid') }}
      </button>
    </td>
  </tr>
</template>

<script>
import toPrecisionNumber from "../../mixins/toPrecisionNumber";
import moment from "moment";
export default {
  name: "lot",
  data() {
    return {
      countdownFlag: true,
      nextStep: null,
      intervalId: null,
    }
  },
  props: {
    token: {},
    lot: {
      default() {
        return {};
      }
    }
  },
  mixins: [toPrecisionNumber],
  watch: {
    lot: {
      deep: true,
      handler(newValue) {
      }
    },
    'lotItem.nextStepTime': function(val) {
      if(this.countdownFlag) {
        clearInterval(this.intervalId);
        this.countdownStart(val)
      }
    }
  },
  computed: {
    lotItem() {
      return this.lot[1]
    }
  },
  methods: {
    countdownStart(startTime) {
      let now = moment.utc(new Date())
      this.countdown(startTime, now)
      this.countdownFlag = false
    },
    countdown(time, nowTime) {
      // console.log('time1', moment.utc(time).format())
      // console.log('time2', moment.utc(nowTime).format())
      let duration = moment.duration(moment(nowTime).diff(time), 'milliseconds');
      const interval = 1000;
      duration = Math.floor(Math.abs(Number((duration._milliseconds / 1000)))) + 1
      this.nextStep = duration
      this.intervalId = setInterval(() => {
        duration = duration - (interval / 1000)
        this.nextStep = duration
        if (duration <= 0) {
          clearInterval(this.intervalId);
          this.countdownFlag = true
        }
      }, interval);
    },
    prettifyToSix(num) {

      if(num === '-' || isNaN(num)) {
        return '-'
      }

      const options = {
        style: 'currency',
        currency: 'USD',
        maximumSignificantDigits: num === 0 ? 6 : num < 1 ? 5 : 6,
        minimumSignificantDigits: num === 0 ? 6 : num < 1 ? 5 : 6,
      }
      num = Number(num).toLocaleString("en-US", options)
      return num.slice(1)
    },
    placeBidAction(lot) {
      this.$emit('placeBid', lot)
    }
  },
  mounted() {
    this.countdownStart(this.lotItem.nextStepTime)
  },
  destroyed() {
    clearInterval(this.intervalId);
  }
}
</script>

<style lang="scss" scoped>

.table-body:nth-child(even) {
  background: var(--v-primary-base) !important;
}


.v-application.theme--dark .textMainColor {
  color: #E9E9E9 !important;
}

td {
  vertical-align: top;

@media (min-width: 901px) {
  padding: 30px 0;

&:not(:last-child) {
   padding-right: 15px;
 }
}
}

.trading-pair {
  display: flex;

.logo {
  margin-right: 9px;
  display: block;
  width: 20px;
  height: 20px;
}

.logo img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

@media (max-width: 900px) {
  justify-content: flex-end;
}
}

.order-form-callateral-button:disabled {
  background-color: var(--v-disableButtonColor-base);
}

.to-mp,
.to-index,
.quantity,
.remaining,
.index-price,
.mark-price {
  display: flex;
  justify-content: space-between;
  max-width: 160px;

@media (max-width: 900px) {
  max-width: unset;
}
}

.index-price,
.mark-price {
  max-width: 220px;

@media (max-width: 900px) {
  max-width: unset;
}
}

.bid-price {
  border: 1px dashed var(--v-loginButtonBackgroundColor-base);
  border-radius: 6px;
  padding: 10px 0;
  text-align: center;
  max-width: 160px;

@media (max-width: 900px) {
  margin-left: auto;
}
}
</style>
