export default {
    // getOrders({ commit }, params) {
    //     return new Promise((resolve, reject) => {
    //       $axios.get('/orders', {params}).then((res) => {
    //         // commit('SET_ORDERS_DATA', res.data);
    //         resolve(res.data);
    //       })
    //         .catch((err) => {
    //           reject(err);
    //         });
    //     });
    //   },

    getAccountInfo({ commit }) {
        return new Promise((resolve, reject) => {
            $axios.get(`/accounts`).then((res) => {
                commit('SET_ORDERS_POSITION_DATA', res.data.positions);
                resolve(res.data);
            })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getOrdersOpen({ commit }) {
        return new Promise((resolve, reject) => {
            $axios.get(`/orders/open`, ).then((res) => {
                commit('SET_ORDERS_OPEN_DATA', res.data);
                resolve(res.data);
            })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getOrdersReportOpen({ commit }, {params}) {
        return new Promise((resolve, reject) => {
            $axios.get(`/orders/open`, { params }).then((res) => {
                commit('SET_ORDERS_OPEN_DATA', res.data);
                resolve(res.data);
            })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getOrdersReportHistory({ commit }, {params}) {
        return new Promise((resolve, reject) => {
            $axios.get(`/orders/history`, { params }).then((res) => {
                commit('SET_ORDERS_OPEN_DATA', res.data);
                resolve(res.data);
            })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getOrdersReportTrade({ commit }, {params}) {
        return new Promise((resolve, reject) => {
            $axios.get(`/trades/history`, {params}).then((res) => {
                commit('SET_ORDERS_OPEN_DATA', res.data);
                resolve(res.data);
            })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    // getOrdersReportTransactions({ commit }, {params}) {
    //     return new Promise((resolve, reject) => {
    //         $axios.get(`/transactions/history`, {params}, {headers: {
    //                 'Content-Type': 'application/json'
    //             }}).then((res) => {
    //             commit('SET_ORDERS_OPEN_DATA', res.data);
    //             resolve(res.data);
    //         })
    //             .catch((err) => {
    //                 reject(err);
    //             });
    //     });
    // },

    getOrdersHistory({ commit }, { params }) {
        return new Promise((resolve, reject) => {
            $axios.get(`/orders/history`, { params }).then((res) => {
                commit('SET_ORDERS_HISTORY_DATA', res.data);
                resolve(res.data);
            })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    deleteOrderById({commit}, {symbol, orderId}) {
      return new Promise((resolve, reject) => {
        $axios.delete(`/orders?symbol=${symbol}&orderId=${orderId}`).then((res) => {
          // commit('SET_ORDERS_DATA', res.data);
          resolve(res.data);
        })
          .catch((err) => {
            reject(err);
          });
      });
    },

    deleteOrderAll({commit}) {
        return new Promise((resolve, reject) => {
            $axios.delete(`/orders/all`).then((res) => {
                // commit('SET_ORDERS_DATA', res.data);
                resolve(res.data);
            })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    depositTestMoney({ commit }) {
        return new Promise((resolve, reject) => {
            $axios.post(`/balance/test/deposit`, {
                "amount": 100000,
                "asset" : "usdt"
            }).then((res) => {
                // commit('SET_ORDERS_OPEN_DATA', res.data);
                resolve(res.data);
            })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    changeTypeFee({ commit }, asset) {
        return new Promise((resolve, reject) => {
            $axios.put(`/accounts/tradeFeeAsset`, asset).then((res) => {
                // commit('SET_ORDERS_OPEN_DATA', res.data);
                resolve(res.data);
            })
                .catch((err) => {
                    reject(err);
                });
        });
    },

  };
