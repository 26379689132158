import { render, staticRenderFns } from "./HowToEarn.vue?vue&type=template&id=6e1e54b9&scoped=true&"
import script from "./HowToEarn.vue?vue&type=script&lang=js&"
export * from "./HowToEarn.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e1e54b9",
  null
  
)

export default component.exports