export default {
    getOrderHistory({commit}, {params}) {
        return new Promise((resolve, reject) => {
            $axios.get('orders/history/download', {params})
                .then((res) => {
                    commit('SET_ORDER_HISTORY', res.data);
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getTradeHistory({commit}, {params}) {
        return new Promise((resolve, reject) => {
            $axios.get('trades/history/download', {params})
                .then((res) => {
                    commit('SET_TRADE_HISTORY', res.data);
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getOrdersReportTransactions(_, {params}) {
        return new Promise((resolve, reject) => {
            $axios.get(`/transactions/history`, {params}, {headers: {
                    'Content-Type': 'application/json'
                }}).then((res) => {
                // commit('SET_TRANSACTION_HISTORY', res.data.content);
                resolve(res.data);
            })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getFeeTiersStructure() {
        return new Promise((resolve, reject) => {
            $axios.get('feeTiers/structure').then((res) => {
                resolve(res);
            })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getFeeTiersDiscount() {
        return new Promise((resolve, reject) => {
            $axios.get('feeTiers/discount').then((res) => {
                resolve(res);
            })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};
