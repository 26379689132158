<template>
  <section>
    <h4 class="section-title">{{ $t('Lots') }}</h4>
    <table>
      <thead class="holder-table">
      <tr class="table-head">
        <th class="trading-pair-header" :data-label="$t('Trading Pair')">
          {{$t('Trading Pair')}}
        </th>
        <th :data-label="$t('Quantity') + '/' + $t('Remaining')">
          {{ $t('Quantity') }} / {{ $t('Remaining') }}
        </th>
        <th :data-label="$t('Bid Price')">
          {{ $t('Bid Price') }}
        </th>
        <th :data-label="$t('Index') + '/' + $t('Mark price')">
          {{ $t('Index') }} / {{ $t('Mark price') }}
        </th>
        <th :data-label="$t('Notional Value')">
          {{ $t('Notional Value') }}
        </th>
        <th :data-label="$t('Next Step')">
          {{ $t('Next Step') }}
        </th>
        <th :data-label="$t('Discount')">
          {{ $t('Discount') }}
        </th>
        <th :data-label="$t('Place a bid')">
          {{ $t('Place a bid') }}
        </th>
      </tr>
      </thead>
      <tbody>
      <template v-if="lots.value && lots.value.size">
        <lot
            v-for="(lot, index) in lots.value"
            class="table-body"
            :key="index"
            :lot="lot"
            :token="token"
            @placeBid="placeBidAction"
        ></lot>
      </template>
      <tr v-else>
        <td colspan="8">
          <div class="holder-not-logged-text">
            <p class="secondary-text">{{$t('emptyPositionsText')}}</p>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </section>
</template>

<script>
import Lot from "./lot";

export default {
  name: 'LotsSection',
  components: {Lot},
  props: {
    token: {},
    lots: {
      default: () => [],
    },
  },
  watch: {
    'lots': {
      deep: true,
      handler(newValue, oldValue) {
      }
    }
  },
  methods: {
    prettifyToSix(num) {

      if(num === '-' || isNaN(num)) {
        return '-'
      }

      const options = {
        style: 'currency',
        currency: 'USD',
        maximumSignificantDigits: num === 0 ? 6 : num < 1 ? 5 : 6,
        minimumSignificantDigits: num === 0 ? 6 : num < 1 ? 5 : 6,
      }
      num = Number(num).toLocaleString("en-US", options)
      return num.slice(1)
    },
    placeBidAction(lot) {
      this.$emit('placeBid', lot)
    }
  }
};
</script>

<style lang="scss" scoped>
section {
  @media (min-width: 901px) {
    min-width: 1300px;
  }
}
</style>
