<template>
  <div class="report-template">
    <ConnectWallet
        ref="connectWallet"
    />
    <div class="report-content">

      <div class="referral-program-wrapper">
        <div class="referral-program-left">
          <div class="referral-program-generate">
            <div class="report-header">
              <h1 class="report-title public-page-title textMainColor">{{ $t('Referral Program') }}</h1>
            </div>
            <div class="referral-program-generate-form">
              <input
                  type="text"
                  class="referral-program-generate-input"
                  placeholder="Generate your affiliate link to participate"
                  readonly
                  v-model="referralLink"
              >
              <button
                  class="referral-program-generate-btn dashboard-btn"
                  v-text="!token ? `${$t('Connect Wallet')}` : `${$t('Copy Link')}`"
                  @click="token ? copyLink() : openConnectWalletDialog()"
                  :disabled="disableBtn"
              ></button>
            </div>
          </div>
          <div class="referal-subtitle-wrap">
            <h2 class="public-page-subtitle referal-subtitle">Affiliate</h2>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <svg v-bind="attrs" v-on="on" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.20868 5.19649C7.20868 4.82968 7.55615 4.51114 8.00019 4.51114C8.43455 4.51114 8.78211 4.82968 8.78211 5.19649C8.78211 5.56333 8.43458 5.89152 8.00019 5.89152C7.55618 5.89152 7.20868 5.56333 7.20868 5.19649ZM7.25686 7.23327C7.25686 6.963 7.56575 6.77955 8.00013 6.77955C8.41522 6.77955 8.75304 6.96297 8.75304 7.23322V10.911C8.75304 11.1427 8.41522 11.3743 8.00013 11.3743C7.56575 11.3743 7.25686 11.1427 7.25686 10.911V7.23327Z" fill="#92939C"/>
                  <circle cx="8" cy="8" r="7" stroke="#92939C" stroke-width="1.5"/>
                </svg>
              </template>
              <span>{{ $t('This table shows a reward a user is able to generate. The more people affiliated, the bigger a reward.') }}</span>
            </v-tooltip>
            <div class="referal-subtitle-info">
              <span class="referal-subtitle-info-value">%</span>
              <span class="secondary-text">{{ $t('of own comission returned')}}</span>
            </div>
          </div>
          <div class="referal-affiliate-wrap">
            <table class="rewards-table referal-affiliate-table">
              <thead class="holder-table">
              <tr class="table-head">
                <th class="people-attracted">
                  <p>
                    <span class="textSecondaryColor">{{ $t('People attracted') }}</span>
                  </p>
                </th>
                <th class="referral-bonus">
                  <p>
                    <span class="textSecondaryColor">{{ $t('Referral Bonus') }}</span>
                  </p>
                </th>
              </tr>
              </thead>
              <tbody class="order-history-table-main">
              <tr
                  class="table-body"
                  v-for="(program, i) in referralProgram"
                  :key="i"
              >
                <td class="people-attracted">{{program.finalNumberOfUsers}}</td>
                <td class="referral-bonus">
                  {{program.coefficient * 100}}
                  <span class="textSecondaryColor">%</span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="referral-program-right">
          <div class="referral-program-how-it-works">
            <div class="referal-subtitle-wrap">
              <h2 class="public-page-subtitle referal-subtitle">How it works</h2>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <svg v-bind="attrs" v-on="on" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.20868 5.19649C7.20868 4.82968 7.55615 4.51114 8.00019 4.51114C8.43455 4.51114 8.78211 4.82968 8.78211 5.19649C8.78211 5.56333 8.43458 5.89152 8.00019 5.89152C7.55618 5.89152 7.20868 5.56333 7.20868 5.19649ZM7.25686 7.23327C7.25686 6.963 7.56575 6.77955 8.00013 6.77955C8.41522 6.77955 8.75304 6.96297 8.75304 7.23322V10.911C8.75304 11.1427 8.41522 11.3743 8.00013 11.3743C7.56575 11.3743 7.25686 11.1427 7.25686 10.911V7.23327Z" fill="#92939C"/>
                    <circle cx="8" cy="8" r="7" stroke="#92939C" stroke-width="1.5"/>
                  </svg>
                </template>
                <span>{{ $t('In order to start attracting affiliates and earn bonuses, you should follow these steps.') }}</span>
              </v-tooltip>
            </div>
            <div class="round-list">
              <div class="round-item">
                <div class="round-item-icon">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.04 13.55C17.62 13.96 17.38 14.55 17.44 15.18C17.53 16.26 18.52 17.05 19.6 17.05H21.5V18.24C21.5 20.31 19.81 22 17.74 22H7.63C7.94 21.74 8.21 21.42 8.42 21.06C8.79 20.46 9 19.75 9 19C8.99992 18.2464 8.78693 17.5083 8.38558 16.8705C7.98422 16.2327 7.41084 15.7213 6.73149 15.3952C6.05215 15.0691 5.29449 14.9416 4.54584 15.0274C3.79718 15.1132 3.088 15.4087 2.5 15.88V11.51C2.5 9.44 4.19 7.75 6.26 7.75H17.74C19.81 7.75 21.5 9.44 21.5 11.51V12.95H19.48C18.92 12.95 18.41 13.17 18.04 13.55Z" stroke="#2DCAC2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M2.5 12.41V7.84003C2.5 6.65003 3.23 5.59003 4.34 5.17003L12.28 2.17003C12.5677 2.06161 12.8774 2.02463 13.1825 2.06225C13.4876 2.09988 13.7791 2.21098 14.0318 2.38603C14.2846 2.56108 14.491 2.79484 14.6335 3.06725C14.7761 3.33966 14.8503 3.64259 14.85 3.95003V7.75003M7 12H14M22.559 13.97V16.03C22.559 16.58 22.119 17.03 21.559 17.05H19.599C18.519 17.05 17.529 16.26 17.439 15.18C17.379 14.55 17.619 13.96 18.039 13.55C18.409 13.17 18.919 12.95 19.479 12.95H21.559C22.119 12.97 22.559 13.42 22.559 13.97Z" stroke="#2DCAC2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M9.00001 19.0001C9.00001 19.7501 8.79001 20.46 8.42001 21.0601C8.21001 21.4201 7.94001 21.7401 7.63001 22.0001C6.93001 22.6301 6.01001 23.0001 5.00001 23.0001C4.31057 23.0015 3.63265 22.8233 3.03297 22.4832C2.43329 22.143 1.93254 21.6525 1.58001 21.0601C1.19935 20.4405 0.998531 19.7272 1.00001 19.0001C1.00001 17.7401 1.58001 16.6101 2.50001 15.8801C3.088 15.4088 3.79719 15.1132 4.54585 15.0275C5.2945 14.9417 6.05216 15.0692 6.7315 15.3953C7.41084 15.7214 7.98423 16.2328 8.38559 16.8705C8.78694 17.5083 8.99993 18.2465 9.00001 19.0001Z" stroke="#2DCAC2" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M3.44141 19L4.43141 19.99L6.56141 18.02" stroke="#2DCAC2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
                <span class="round-item-title">{{$t('Step 1')}}</span>
                <span class="round-item-value">{{$t('Connect Wallet')}}</span>
              </div>
              <div class="round-item">
                <div class="round-item-icon">
                  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.1875 13.6327L19.1484 12.6717C21.0703 10.7498 21.0608 7.58374 19.1131 5.63598C17.1717 3.69465 14.0056 3.67235 12.0774 5.60063L11.1164 6.56158M7.30438 10.3736L6.34979 11.3282C5.4237 12.2577 4.90743 13.52 4.91406 14.8387C4.92068 16.1575 5.44967 17.4251 6.38514 18.3639C8.32647 20.3052 11.4926 20.3275 13.4209 18.3993L14.3754 17.4447M10.2035 14.5455L15.2947 9.45436" stroke="#2DCAC2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
                <span class="round-item-title">{{$t('Step 2')}}</span>
                <span class="round-item-value">{{$t('Generate Link')}}</span>
              </div>
              <div class="round-item">
                <div class="round-item-icon">
                  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.4608 6.17001C19.4608 7.56001 20.8408 9.77001 21.1208 12.32M3.99078 12.37C4.11651 11.1472 4.50289 9.96552 5.12389 8.90464C5.74489 7.84376 6.58609 6.92832 7.59078 6.22001M8.69078 20.94C9.85078 21.53 11.1708 21.86 12.5608 21.86C13.9008 21.86 15.1608 21.56 16.2908 21.01M12.5608 7.70001C13.2981 7.70001 14.0052 7.40712 14.5265 6.88577C15.0479 6.36442 15.3408 5.65732 15.3408 4.92001C15.3408 4.18271 15.0479 3.47561 14.5265 2.95426C14.0052 2.43291 13.2981 2.14001 12.5608 2.14001C11.8235 2.14001 11.1164 2.43291 10.595 2.95426C10.0737 3.47561 9.78078 4.18271 9.78078 4.92001C9.78078 5.65732 10.0737 6.36442 10.595 6.88577C11.1164 7.40712 11.8235 7.70001 12.5608 7.70001ZM5.33078 19.92C6.06808 19.92 6.77519 19.6271 7.29654 19.1058C7.81789 18.5844 8.11078 17.8773 8.11078 17.14C8.11078 16.4027 7.81789 15.6956 7.29654 15.1743C6.77519 14.6529 6.06808 14.36 5.33078 14.36C4.59348 14.36 3.88638 14.6529 3.36502 15.1743C2.84367 15.6956 2.55078 16.4027 2.55078 17.14C2.55078 17.8773 2.84367 18.5844 3.36502 19.1058C3.88638 19.6271 4.59348 19.92 5.33078 19.92ZM19.6708 19.92C20.4081 19.92 21.1152 19.6271 21.6365 19.1058C22.1579 18.5844 22.4508 17.8773 22.4508 17.14C22.4508 16.4027 22.1579 15.6956 21.6365 15.1743C21.1152 14.6529 20.4081 14.36 19.6708 14.36C18.9335 14.36 18.2264 14.6529 17.705 15.1743C17.1837 15.6956 16.8908 16.4027 16.8908 17.14C16.8908 17.8773 17.1837 18.5844 17.705 19.1058C18.2264 19.6271 18.9335 19.92 19.6708 19.92Z" stroke="#02C9BF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
                <span class="round-item-title">{{$t('Step 3')}}</span>
                <span class="round-item-value">{{$t('Share Link')}}</span>
              </div>
              <div class="round-item">
                <div class="round-item-icon">
                  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.0016 16.86V18.89C11.0016 20.61 9.40156 22 7.43156 22C5.46156 22 3.85156 20.61 3.85156 18.89V16.86C3.85156 18.58 5.45156 19.8 7.43156 19.8C9.40156 19.8 11.0016 18.57 11.0016 16.86Z" stroke="#02C9BF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10.9998 14.11C10.9998 14.61 10.8598 15.07 10.6198 15.47C10.0298 16.44 8.81984 17.05 7.41984 17.05C6.01984 17.05 4.80984 16.43 4.21984 15.47C3.97984 15.07 3.83984 14.61 3.83984 14.11C3.83984 13.25 4.23984 12.48 4.87984 11.92C5.52984 11.35 6.41984 11.01 7.40984 11.01C8.39984 11.01 9.28984 11.36 9.93984 11.92C10.5998 12.47 10.9998 13.25 10.9998 14.11Z" stroke="#02C9BF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M11.0016 14.11V16.86C11.0016 18.58 9.40156 19.8 7.43156 19.8C5.46156 19.8 3.85156 18.57 3.85156 16.86V14.11C3.85156 12.39 5.45156 11 7.43156 11C8.42156 11 9.31156 11.35 9.96156 11.91C10.6016 12.47 11.0016 13.25 11.0016 14.11ZM22.2496 10.97V13.03C22.2496 13.58 21.8096 14.03 21.2496 14.05H19.2896C18.2096 14.05 17.2196 13.26 17.1296 12.18C17.0696 11.55 17.3096 10.96 17.7296 10.55C18.0996 10.17 18.6096 9.94995 19.1696 9.94995H21.2496C21.8096 9.96995 22.2496 10.42 22.2496 10.97Z" stroke="#02C9BF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M2.25 10.5V8.5C2.25 5.78 3.89 3.88 6.44 3.56C6.7 3.52 6.97 3.5 7.25 3.5H16.25C16.51 3.5 16.76 3.51 17 3.55C19.58 3.85 21.25 5.76 21.25 8.5V9.95H19.17C18.61 9.95 18.1 10.17 17.73 10.55C17.31 10.96 17.07 11.55 17.13 12.18C17.22 13.26 18.21 14.05 19.29 14.05H21.25V15.5C21.25 18.5 19.25 20.5 16.25 20.5H13.75" stroke="#02C9BF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
                <span class="round-item-title">{{$t('Step 4')}}</span>
                <span class="round-item-value">{{$t('Get Rewards')}}</span>
              </div>
            </div>
          </div>
          <div class="referral-program-description">
            <div class="referal-subtitle-wrap">
              <h2 class="public-page-subtitle referal-subtitle">Description</h2>
            </div>
            <p>{{$t('Dexilon provides you an opportunity to profit from your network. If you invite a user and he uses your invite link, you will receive a portion of the fee he will pay. The more users you invite, the bigger is the chunk from each user. Be an ambassador by promoting Dexilon, and enjoy the profit stream.')}}</p>
            <div class="referral-program-description-notes">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.20868 5.19649C7.20868 4.82968 7.55615 4.51114 8.00019 4.51114C8.43455 4.51114 8.78211 4.82968 8.78211 5.19649C8.78211 5.56333 8.43458 5.89152 8.00019 5.89152C7.55618 5.89152 7.20868 5.56333 7.20868 5.19649ZM7.25686 7.23327C7.25686 6.963 7.56575 6.77955 8.00013 6.77955C8.41522 6.77955 8.75304 6.96297 8.75304 7.23322V10.911C8.75304 11.1427 8.41522 11.3743 8.00013 11.3743C7.56575 11.3743 7.25686 11.1427 7.25686 10.911V7.23327Z" fill="#92939C"/>
                <circle cx="8" cy="8" r="7" stroke="#92939C" stroke-width="1.5"/>
              </svg>
              <p>{{$t('You will only receive rewards from users who have a trading volume above $5,000')}}</p>
            </div>
          </div>
        </div>
        <div class="referral-program-table">
          <div class="referal-subtitle-wrap">
            <h2 class="public-page-subtitle referal-subtitle">Invited Users & Your Rewards</h2>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <svg v-bind="attrs" v-on="on" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.20868 5.19649C7.20868 4.82968 7.55615 4.51114 8.00019 4.51114C8.43455 4.51114 8.78211 4.82968 8.78211 5.19649C8.78211 5.56333 8.43458 5.89152 8.00019 5.89152C7.55618 5.89152 7.20868 5.56333 7.20868 5.19649ZM7.25686 7.23327C7.25686 6.963 7.56575 6.77955 8.00013 6.77955C8.41522 6.77955 8.75304 6.96297 8.75304 7.23322V10.911C8.75304 11.1427 8.41522 11.3743 8.00013 11.3743C7.56575 11.3743 7.25686 11.1427 7.25686 10.911V7.23327Z" fill="#92939C"/>
                  <circle cx="8" cy="8" r="7" stroke="#92939C" stroke-width="1.5"/>
                </svg>
              </template>
              <span>{{ $t('This table shows all users who have used your invite link.') }}</span>
            </v-tooltip>
            <div class="referal-subtitle-info">
              <span class="referal-subtitle-info-value">
                <span class="secondary-text">{{$t('Total Profit:')}}</span>
              </span>
              <div class="">
                {{ convertTotalProfit }}
                <span class="secondary-text">{{ quoteCurrency}}</span>
              </div>
            </div>
          </div>
          <div class="referral-program-table-wrap">
            <div class="preloader new-preloader" v-if="isLoading">
              <lottie-animation
                  ref="preloader"
                  :animationData="require('@/assets/img/Loader.json')"
                  :loop="true"
                  :autoPlay="true"
                  :speed="1"
              />
            </div>
            <div v-if="!token || !isLoading && isDataEmpty" class="holder-not-logged-text report-empty-wrap">
              <div class="report-empty-list">
                <p class="secondary-text holder-not-logged-text">{{ $t('There are no invited users yet.') }}</p>
              </div>
            </div>
            <invited-users-list
              v-if="rewardsInvitedUsers.length"
              :invitedUsersList="rewardsInvitedUsers"
              :token="token"
              :isLoading="isLoading"
            />
            <div class="table-footer" v-if="rewardsInvitedUsers.length">
              <div
                  class="report-download"
              >
              </div>
              <b-pagination-nav
                  v-if="rewardsInvitedUsers.length && count !== 1"
                  v-model="page"
                  :link-gen="linkGen"
                  :number-of-pages="count"
                  :per-page="pageSize"
                  :total-rows="count"
                  class="pagination"
                  first-number
                  last-number
                  next-text="»"
                  prev-text="«"
                  use-router
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LottieAnimation from "lottie-web-vue";
import InvitedUsersList from "@/components/rewards/ReferralProgram/InvitedUsersList.vue";
import ConnectWallet from "@/components/popups/ConnectWallet.vue";
import { mapActions } from 'vuex';
import ToasterSuccess from "@/components/toasters/ToasterSuccess.vue";

export default {
  name: 'ReferralProgram',
  props: {
    token: {},
  },
  data() {
    return {
      quoteCurrency: "USDT",
      referralProgram: [],
      rewardsInvitedUsers: [],
      totalProfit: 0,
      walletAddress: null,
      referralLink: "",
      counters: {},
      page: 1,
      count: 1,
      pageSize: 20,
      isLoading: false,
      isDataEmpty: true,
      disableBtn: false,
    };
  },
  components: {
    LottieAnimation,
    InvitedUsersList,
    ConnectWallet,
  },
  watch: {
    token(newVal, oldValue) {
      if(oldValue === null && newVal) {
        this.getReferralLinkAction();
        this.getTotalProfitAction();
        this.getRewardsInvitedUsersAction();
      }

      if(newVal === null) {
        this.referralLink = "";
        this.totalProfit = 0;
        this.getRewardsInvitedUsers = [];
      }
    },
    '$route.query.page': {
      deep: true,
      handler(newVal) {
        if(newVal) {
          this.page = newVal;
          this.getRewardsInvitedUsersAction();
        } else {
          this.page = 1;
        }
      },
    },
  },
  computed: {
    convertTotalProfit() {
      return this.prettify(String(this.aroundNumber(this.totalProfit) || 0)) || '-'
    },
  },
  methods: {
    ...mapActions({
      getReferralLink: 'rewards/getReferralLink',
      getRewardsSettings: 'rewards/getRewardsSettings',
      getTotalProfit: 'rewards/getTotalProfit',
      getRewardsInvitedUsers: 'rewards/getRewardsInvitedUsers',
    }),
    openConnectWalletDialog() {
      this.$refs.connectWallet.openPopups();
    },
    linkGen(pageNum) {
      return {
        path: '/rewards/referral-program',
        query: { page: pageNum },
      };
    },
    aroundNumber(num) {
      if(num === undefined || num === null) {
        return 0
      }
      if(num === 0) {
        return num
      }else {
        // return num ? parseFloat(Number(num).toFixed(2)) : null
        if(+num > 0) {
          return Math.trunc(num * 100) / 100;
        }else {
          return Math.ceil(num * 100) / 100;
        }
      }
    },
    prettify(num) {
      if(num === 0) return 0
      const options = {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2
      }
      num = Number(num).toLocaleString("en-US", options).replace('$', '')
      return num
    },
    getRewardsSettingsAction() {
      this.getRewardsSettings().then(res => {
        const referralProgram = res.data.referralProgram.items

        if(referralProgram.length) {
          referralProgram.map((program, i) => {
            if(i === 0 ) {
              program.finalNumberOfUsers = `<${referralProgram[1].numberOfUsers}`
            }
            if(i === 1 ) {
              program.finalNumberOfUsers = `${referralProgram[i].numberOfUsers}-${referralProgram[i + 1].numberOfUsers - 1}`
            }
            if(i === referralProgram.length - 1) {
              program.finalNumberOfUsers = `${referralProgram[i].numberOfUsers}+`
            }
            if(i !== referralProgram.length - 1 && i > 1) {
              program.finalNumberOfUsers = `${referralProgram[i].numberOfUsers}-${referralProgram[i + 1].numberOfUsers - 1}`
            }
          })
        }

        this.referralProgram = referralProgram;
      });
    },
    getRewardsInvitedUsersAction() {
      this.isLoading = true;
      this.isDataEmpty = false;
      this.tradingTableInfoOpenOrders = [];

      const params = {};
      params.size = this.pageSize
      params.page = this.page - 1

      this.getRewardsInvitedUsers({params}).then(res => {
        this.rewardsInvitedUsers = res.data.content;
        this.count = res.data.totalPages;
        this.isLoading = false;
        if(!this.rewardsInvitedUsers.length) {
          this.isDataEmpty = true;
        }
      });
    },
    getReferralLinkAction() {
      this.getReferralLink().then(res => {
        this.referralLink = res.link
      });
    },
    getTotalProfitAction() {
      this.getTotalProfit().then(res => {
        this.totalProfit = res.totalProfit
      });
    },
    async copyLink() {
      this.disableBtn = true;
      const text = this.referralLink;
      navigator.clipboard.writeText(text).then((text) => {
        this.$toast.success({
          component: ToasterSuccess,
          props:  {
            title: this.$t('Referral Link Copied'),
            description: this.$t('Content copied to clipboard'),
          },
        });
        setTimeout(() => {
          this.disableBtn = false;
        }, 5000)
      });

    }
  },
  mounted() {
    this.page = +this.$route.query.page || 1;

    this.getRewardsSettingsAction();

    if(this.token) {
      this.getReferralLinkAction();
      this.getTotalProfitAction();
      this.getRewardsInvitedUsersAction();
    }
  },
};
</script>
<style lang="scss" scoped>
.referral-program-wrapper {
  display: flex;
  align-items: initial;
  justify-content: space-between;
  flex-wrap: wrap;
}

.referral-program-left {
  width: 45%;
  padding-bottom: 24px;
  border-right: 1px solid #1E1E1E;
}

.referal-subtitle-wrap {
  margin-top: 15px;
  padding: 0 20px;
}

.referal-subtitle-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 15px;
}

.round-list {
  display: flex;
  padding: 18px 0px;
  margin: 0px auto;
  width: calc(100% - 40px);
  align-items: flex-start;
  justify-content: space-between;
  border-top: 1px solid #1E1E1E;
  border-bottom: 1px solid #1E1E1E;
}

.round-item {
  width: calc(25% - 20px);
}

.round-item-icon {
  margin-bottom: 5px;
}

.round-item-icon svg {

}

.round-item-title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #92939C;
  display: block;
  width: 100%;
  margin-bottom: 2px;
}

.round-item-value {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #FFFFFF;
}

.referral-program-description p {
  padding: 0 20px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  margin-bottom: 15px;
  color: #E0E0E6;
}

.referral-program-description-notes {
  background: #393939;
  border-radius: 6px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 20px;
  margin-top: 25px;
}

.referral-program-description-notes svg {

}

.referral-program-description-notes p {
  width: calc(100% - 25px);
  padding-left: 10px;
  margin-bottom: 0;
}

.referral-program-generate-form {
  display: flex;
  align-items: initial;
  justify-content: space-between;
  gap: 10px;
  margin: 0px 20px;
  margin-top: 10px;
  padding-bottom: 25px;
  margin-bottom: 10px;
  border-bottom: 1px solid #1E1E1E;
}

.referral-program-generate-input {
  background: #464646;
  border-radius: 6px;
  padding: 14px 12px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #92939C;
  width: calc(100% - 150px);
  height: 48px;
  padding-right: 20px;
  text-overflow: ellipsis;
}

.referral-program-generate-input:focus {
  outline: none;
}

.referral-program-generate-btn:hover {
  background-color: #42E8E0;
}

.report-content {
  margin-top: 0;
}

.referal-subtitle-info {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

.referal-subtitle-info-value {
  display: inline-block;
  margin-right: 5px;
}








.secondary-text {
  color: var(--v-textSecondaryColor-base)
}

.referral-program-right {
  width: 55%;
  padding-bottom: 24px;
}

.referral-program-table-wrap {
  min-height: 500px;
}

.referral-program-table {
  width: 100%;
  position: relative;
  border-top: 1px solid #1E1E1E;
}

.referral-program-generate-btn {
  transition: background-color 0.3s;
  width: calc(50% - 5px);
  background-color: #02C9BF;
  color: #fff;
}

.table-body td {
  padding: 6px 0;
}

.referral-program-table-wrap .report-empty-wrap {
  min-height: 300px;
}

.referral-program-table {
  width: 100%;
}


.referal-affiliate-table {
  width: 100%;
  .table-head {
    th {
      width: 50%;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .people-attracted {
      text-align: left;
      padding-left: 20px;
      p {
        text-align: left;
      }
    }

    .referral-bonus {
      padding-right: 20px;
      p {
        text-align: right;
        margin-bottom: 0px;
      }
    }

    p {
      margin-bottom: 0px;
    }
  }
  .table-body {
    th {
      width: 50%;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .people-attracted {
      text-align: left;
      padding-left: 20px;
      p {
        text-align: left;
      }
    }

    .referral-bonus {
      padding-right: 20px;
      text-align: right;
      color: #02C9BF;
      p {
        text-align: right;
        margin-bottom: 0px;
      }
    }

    p {
      margin-bottom: 0px;
    }
  }
  .holder-table {
    border-bottom: none !important;
  }
  .holder-table:before,
  .holder-table:after{
    left: 20px;
    right: 20px;
    width: calc(100% - 40px);
  }
}

.referral-program-table-wrap .report-empty-list .secondary-text {
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #A4A5AF;
}

</style>
