import Web3Utils from "web3-utils";

const defaultParams = [{
    eth_accounts: {}
}];

let accounts = null
let walletAddress = null
window.intervalSpotBalance = null
import {getFromLocalStorage, saveToLocalStorage} from '../../../helpers';
import axios from 'axios';
import store from "../../index";
import Web3 from 'web3';

import {BlockchainAPI, DexilonClient} from '@dexilon-exchange/dexilon-chain-js-sdk';
import { getSignature } from '../../../utils/ecdsa';
import {
    CosmosWalletData,
    getRandomCosmosAddress,
    getRandomEthAddress,
    // randomInteger,
} from '../../../utils/randomizer';

const errorTextUnlogin = "🦊 Connect to Metamask using the button on the top right.";

function noExponents(number) {
    var data = String(number).split(/[eE]/);
    if (data.length == 1) return data[0];

    var z = '',
        sign = this < 0 ? '-' : '',
        str = data[0].replace('.', ''),
        mag = Number(data[1]) + 1;

    if (mag < 0) {
        z = sign + '0.';
        while (mag++) z += '0';
        return z + str.replace(/^\-/, '');
    }
    mag -= str.length;
    while (mag--) z += '0';
    return str + z;
}

window.dataLayer = window.dataLayer || [];

// metamask

async function commitMetamaskAction({commit, dispatch, getters}, method, data) {
    const chainId = '80001' // Polygon Testnet
    switch(method) {
        case 'wallet_requestPermissions':
        case 'eth_requestAccounts':
            if (window.ethereum.networkVersion !== chainId) {
                try {
                    await window.ethereum.request({
                        method: 'wallet_switchEthereumChain',
                        params: [{ chainId: web3.utils.toHex(chainId) }],
                    });
                } catch (err) {
                    // This error code indicates that the chain has not been added to MetaMask.
                    if (err.code === 4902) {
                        await window.ethereum.request({
                            method: 'wallet_addEthereumChain',
                            params: [
                                {
                                    chainName: 'Polygon Testnet',
                                    chainId: web3.utils.toHex(chainId),
                                    nativeCurrency: { name: 'MATIC', decimals: 18, symbol: 'MATIC' },
                                    rpcUrls: ['https://rpc-mumbai.matic.today'],
                                },
                            ],
                        })
                        return
                    }
                }
            }
           // dispatch('onMetaMaskNetworkChanged', chainId, data)
            commit('SET_METAMASK_ADDRESS', data);
            await dispatch('metamaskRequest', {method: 'eth_chainId'});
            await dispatch('metamaskRequest', {
                method: 'eth_getBalance',
                params: [getters.getMetamaskWallet.address, 'latest']
            });
            commit('CONVERT_METAMASK_BALANCE', 'ether');
        break;
        case 'eth_getBalance':
            commit('SET_METAMASK_BALANCE', data);
        break;
        case 'eth_chainId':
            commit('SET_METAMASK_NETWORK_ID', data);
            commit('SET_METAMASK_NETWORK_NAME', data);
        break;
        case 'personal_sign':
            commit('SET_METAMASK_SIGNATURE', data);
    }
}

async function commitMetaMaskOnSubscription({commit, dispatch, getters}, event, data) {
    switch(event) {
        case 'chainChanged':
            commit('SET_METAMASK_NETWORK_ID', data);
            commit('SET_METAMASK_NETWORK_NAME', data);

            await dispatch('metamaskRequest', {
                method: 'eth_getBalance',
                params: [getters.getMetamaskWallet.address, 'latest']
            });
            commit('CONVERT_METAMASK_BALANCE', 'ether'); //!!!change to dynamic currency
        break;
        // case 'accountsChanged':
        //     if (!data.length) {
        //         localStorage.removeItem('token')
        //         commit('SET_METAMASK_DISCONECT', true);
        //     }
        // break;
    }

}

async function commitBinanceOnSubscription({commit, dispatch, getters}, event, data) {
    switch(event) {
        case 'chainChanged':
            commit('SET_BINANCE_NETWORK_ID', data);
            commit('SET_BINANCE_NETWORK_NAME', data);

            await dispatch('binanceRequest', {
                method: 'eth_getBalance',
                params: [getters.getBinanceWallet.address, 'latest']
            });
        break;
        case 'accountsChanged':
            commit('SET_BINANCE_ADDRESS', data);

            if (!data.length) {
                commit('SET_BINANCE_DISCONECT', true);
            }
        break;
    }

}

//BinanceF
async function commitBinanceAction({commit, dispatch, getters}, method, data) {
    switch(method) {
        case 'eth_accounts':
            commit('SET_BINANCE_ADDRESS', data);

            await dispatch('binanceRequest', {method: 'net_version'});

            await dispatch('binanceRequest', {
                method: 'eth_getBalance',
                params: [getters.getBinanceWallet.address, 'latest']
            });

            if (!getFromLocalStorage('binance').signature) {
                await dispatch('binanceRequest', {
                    method: 'eth_sign',
                    params: [getters.getBinanceWallet.address, 'HYDRO-AUTHENTICATION']
                });
                location.reload(); // temporary until figure out http request chain on app init
            }
        break;
        case 'net_version':
            commit('SET_BINANCE_NETWORK_ID', data);
            commit('SET_BINANCE_NETWORK_NAME', data);
        break;
        case 'eth_getBalance':
            commit('SET_BINANCE_BALANCE', data);
        break;
        case 'eth_sign':
            commit('SET_BINANCE_SIGNATURE', data);
    }
}
// END Binance


let ethAddress = null;
let etherWallet = null;
let granterClient = DexilonClient;
let granteeClient = DexilonClient;
let granterWallet = CosmosWalletData;
let granteeWallet = CosmosWalletData;
const config = {
    blockchainApiUrl: process.env.VUE_APP_BLOCKCHAIN_API_URL,
    chainId: process.env.VUE_APP_CHAIN_ID,
    bondDenom: 'dxln',
};

export default {
//metamask

    async beforeAuth({ commit, dispatch, getters }, dataWallet) {
        // commit('CHANGE_PRELOAD_STATUS', true);
        await dispatch('metamaskRequest', {method: 'eth_chainId'});
        const ethNetwork = parseInt(getters.getMetamaskWallet.networkId);
        const wallet = dataWallet.ethWallet;
        etherWallet = wallet;
        ethAddress = wallet;

        const api = new BlockchainAPI(config);
        let mirrorMapping = null;

        try {
            mirrorMapping = await DexilonClient.getMirrorAddressMapping(
                config.blockchainApiUrl,
                ethNetwork,
                etherWallet,
            );
        } catch (err) {
            console.log(err);
        }

        if(mirrorMapping && mirrorMapping.cosmosAddress) {
            granterWallet = await getRandomCosmosAddress(mirrorMapping.cosmosAddress);
        }else {
            granterWallet = await getRandomCosmosAddress();
        }

        granteeWallet = await getRandomCosmosAddress();

        await Promise.all([api.faucet(granterWallet.address), api.faucet(granteeWallet.address)]);

        granterClient = new DexilonClient(granterWallet.wallet, api, config);
        await granterClient.init();
        granteeClient = new DexilonClient(granteeWallet.wallet, api, config);
        await granteeClient.init();

        const data = {granterWallet, granteeWallet, granterClient, granteeClient, etherWallet, ethAddress, ethNetwork}
        commit('SET_AUTH_DATA', data);

        data.type = mirrorMapping && mirrorMapping.cosmosAddress ? 'login' : 'register'
        return data
    },

    async registration({dispatch}, data) {
        const {granterClient, granterWallet, ethNetwork, ethAddress} = data;
        const dataStructure = ['string'];

        return new Promise((resolve, reject) => {

            // const timestamp = Date.now();
            const signedMessage = `${granterWallet.address}`;

            const granterSinature = getSignature(ethAddress, [signedMessage], dataStructure)
                .then((res) => {
                    const signature = res[1]
                    granterClient.createAddressMapping(ethNetwork, ethAddress, signedMessage, signature)
                        .then((res) => {
                        window.dataLayer.push({
                            event: 'authentication',
                            action: 'user-sign-up',
                            userId: store.getters["wallets/getMetamaskWallet"].address
                        });
                        resolve(res);
                    })
                        .catch((err) => {
                            reject(err);
                        });
                }).catch((err) => {
                    reject(err);
                });
        });
    },

    async grantPermissions({commit, dispatch}, data) {
        const {granterWallet, ethAddress, ethNetwork} = data;
        const dataStructure = ['string'];

        return new Promise((resolve, reject) => {

            const timestamp = Date.now();
            const signedMessage = `${timestamp}#${granteeWallet.address}`;
            const granterSinature = getSignature(ethAddress, [signedMessage], dataStructure).then(async res => {
                const signature = res

                const expirationTime = 1440 * 60;
                const result = await granteeClient.grantPermissions(
                    ethAddress,
                    signature[1],
                    signedMessage,
                    expirationTime,
                    ethNetwork
                );

                const grant = {granterWallet, granteeWallet, signedMessage, hashString: signature[0], granterSinature: signature[1]}
                resolve(grant);
            }).catch(err => {
                reject(err);
            })
        });
    },

    async depositTrading({ commit }, data) {
        const {granteeClient, granterWallet} = store.getters["wallets/getMetamaskWallet"].authData
        const balance = data.amount;
        const asset = data.asset;
        // console.log('balance', balance)
        const res = await granteeClient.depositTrading(granterWallet.address, balance, asset).then(() => {});
    },

    async getTradingVolume30d({ commit }) {
        const {granteeClient, granterWallet} = store.getters["wallets/getMetamaskWallet"].authData
        await granteeClient.get30dTradingVolume(granterWallet.address).then((res) => {
            commit('SET_TRADING_VOLUME_30D', res);
        });
    },

    async withdrawTrading({ commit }, amount) {
        const {ethNetwork, granteeClient, granterWallet} = store.getters["wallets/getMetamaskWallet"].authData
        const denom = 'usdt';
        return await granteeClient.withdraw(granterWallet.address, denom, amount, ethNetwork).then(() => {
            window.dataLayer.push({
                event: 'money_transaction',
                action: 'withdraw', //transfer || withdraw
                to: store.getters["wallets/getMetamaskWallet"].address, //куда пополнение/трансфер: spot_account|futures_account; если вывод - можно указывать кошелёк/идентификатор юзера, либо что-то статичное вроде 'user_account'
                amount: noExponents(+amount / Math.pow(10, 18)), //сумма депозита/трансфера/вывода
                token: denom, //токен операции`x
                fee: 2 //сумма комиссия
            });
        });
    },

    async swapAction({ commit, dispatch }, data) {
        const swap = async () => {

            const {granterClient, granteeClient, granterWallet} = store.getters["wallets/getMetamaskWallet"].authData

            const beforeBalances = await granterClient.getBankBalances(granterWallet.address);
            const beforeUsdc = beforeBalances.filter((c) => c.denom === 'usdt')[0];
            const beforeDxln = beforeBalances.filter((c) => c.denom === granteeClient.bondDenom)[0];

            const wishDenom = data.wishDenom;
            const hasCoin = data.hasCoin
            const { usdcToDxln, dxlnToUsdc } = await granteeClient.getDxlnUsdcPrice();
            let price = data.hasCoin.denom === 'usdt' ? dxlnToUsdc.mul(1.0).toString() : usdcToDxln.mul(1.0).toString()

            // if (Number(price.mul(1.0).toString()) < 1) {
            //     price = price.mul(0.95).toString()
            // }else {
            //     price = price.mul(1.05).toString()
            // }

            // console.log('usdcToDxln', usdcToDxln.mul(1).toString())
            // console.log('dxlnToUsdc', dxlnToUsdc.mul(1).toString())
            // console.log('hasCoin', hasCoin)
            // console.log('price', price)
            // console.log('wishDenom', wishDenom.toLowerCase())
            // console.log('granterClient', granterClient)
            // console.log('granteeClient', granteeClient)
            // console.log('granterWallet', granterWallet.address)

            const res = await granteeClient.swap(granterWallet.address, wishDenom.toLowerCase(), hasCoin, price);

            // console.log(JSON.stringify(res, null, 2));

            const afterBalances = await granterClient.getBankBalances(granterWallet.address);
            const afterUsdc = afterBalances.filter((c) => c.denom === 'usdt')[0];
            const afterDxln = afterBalances.filter((c) => c.denom === granteeClient.bondDenom)[0];

            // console.log({
            //     originalPriceDxlnToUsdc: dxlnToUsdc.toString(),
            //     originalPriceUsdcToDxln: usdcToDxln.toString(),
            //     price: Number(price).toFixed(4),
            //     beforeBalances,
            //     afterBalances,
            //     diffUsdc: (BigInt(afterUsdc.amount) - BigInt(beforeUsdc.amount)).toString(),
            //     diffDxln: (BigInt(afterDxln.amount) - BigInt(beforeDxln.amount)).toString(),
            //     diffUsdcLenght: (BigInt(afterUsdc.amount) - BigInt(beforeUsdc.amount)).toString().length,
            //     diffDxlnLenght: (BigInt(afterDxln.amount) - BigInt(beforeDxln.amount)).toString().length,
            // });

            setTimeout(async () => {
                await dispatch('getSwapInfo');
            },8000)

        };
        await swap();
    },

    async getSwapInfo({commit}) {
        const {granterClient, granteeClient, granterWallet} = store.getters["wallets/getMetamaskWallet"].authData

        const beforeBalances = await granterClient.getBankBalances(granterWallet.address);
        let beforeUsdc = beforeBalances.filter((c) => c.denom === 'usdt')[0];
        const beforeDxln = beforeBalances.filter((c) => c.denom === granteeClient.bondDenom)[0];

        const { usdtToDxln, dxlnToUsdt } = await granteeClient.getDxlnUsdtPrice();

        const priceDxln = dxlnToUsdt.mul(1.0).toString();
        const priceUsdc = usdtToDxln.mul(1.0).toString();

        const data = {
            beforeUsdc: beforeUsdc,
            beforeDxln: beforeDxln,
            priceDxln: priceDxln,
            priceUsdc: priceUsdc
        }

        commit('SET_SPOT_BALANCE', data);

    },

    authRequest({ commit, dispatch }, data) {
        return new Promise((resolve, reject) => {
            $axios.post(`/auth/accessToken`,{
                "nonce": data.nonce,
                "signedNonce": data.signedNonce,
                "ethAddress": data.ethAddress
            }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then((res) => {
                commit('JWT', res.data);
                saveToLocalStorage('token', res.data.accessToken)
                saveToLocalStorage('refreshToken', res.data.refreshToken)
                window.dispatchEvent(new CustomEvent('token-localstorage-changed', {
                    detail: {
                        token: localStorage.getItem('token')
                    }
                }));

                const params = new Proxy(new URLSearchParams(window.location.search), {
                    get: (searchParams, prop) => searchParams.get(prop),
                });

                if(params.token && params.token.length) {
                    const token = params.token

                    $axios.post(`/referralProgram/followLink?token=${token}`, '').then(({data}) => {
                        resolve(data);
                    })
                        .catch((err) => {
                            reject(err);
                        });
                }

                resolve(res.data);
            }).catch((err) => {
                    reject(err);
                });
        });
    },

    metamaskRequest(state, { method, params }) {
        return new Promise((resolve, reject) => {
            if (window.ethereum) { //check if Metamask is installed
                try {
                    ethereum.request({
                        method,
                        params: params|| defaultParams
                    }).then((data) => {
                        commitMetamaskAction(state, method, data)
                        resolve(data)
                    }).catch((err) => {
                        localStorage.removeItem('metamask')
                        localStorage.removeItem('token')
                        if(err.code !== 4001) {
                            store.commit("wallets/METAMASK_ERROR", err.message || errorTextUnlogin)
                        }
                        reject(err);
                    })
                } catch (error) {
                    store.commit("wallets/METAMASK_ERROR", error.message || errorTextUnlogin)
                }

            } else {
                localStorage.removeItem('token')
                store.commit("wallets/METAMASK_ERROR", errorTextUnlogin)
            }

        })
      },

    subscribeToMetaMaskEvent({commit}, store, event) {
        if (window.ethereum) {
            try {
                window.ethereum.on(event, (data) => {
                    commitMetaMaskOnSubscription(store, event, data);
                });
            } catch (error) {
                store.commit("wallets/METAMASK_ERROR", error.message || errorTextUnlogin)
            }

            // window.ethereum.on('chainChanged', () => {
            //     alert('chainChanged')
            // });
        }else {
            localStorage.removeItem('token')
            commit("METAMASK_ERROR", errorTextUnlogin)
        }
      },

    // check maybe should commit from component
    metamaskConnection({commit}, isDisconected) {
        commit('SET_METAMASK_DISCONECT', isDisconected);
    },

    onMetaMaskAccountsChanged({commit}, wallet) {
        commit('SET_METAMASK_ADDRESS', wallet);
    },

    async onMetaMaskNetworkChanged({commit, dispatch}, data) {
        const chainId = '80001' // Polygon Testnet
        const tokenAddress = '0x7592A72A46D3165Dcc7BF0802D70812Af19471B3';
        const tokenSymbol = 'USDC';
        const tokenDecimals = 6;
        const tokenImage = 'https://seeklogo.com/images/T/tether-usdt-logo-FA55C7F397-seeklogo.com.png';
        if (window.ethereum.networkVersion !== chainId) {
            try {
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: web3.utils.toHex(chainId) }],
                });
            } catch (err) {
                // This error code indicates that the chain has not been added to MetaMask.
                if (err.code === 4902) {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                            {
                                chainName: 'mumbai poligon',
                                chainId: web3.utils.toHex(chainId),
                                nativeCurrency: { name: 'MATIC', decimals: 18, symbol: 'MATIC' },
                                rpcUrls: [process.env.VUE_APP_WALLET_PROVIDER],
                            },
                        ],
                    }).then(() => {
                        setTimeout(async ()=> {
                            try {
                                // wasAdded is a boolean. Like any RPC method, an error may be thrown.
                                await window.ethereum.request({
                                    method: 'wallet_watchAsset',
                                    params: {
                                        type: 'ERC20', // Initially only supports ERC20, but eventually more!
                                        options: {
                                            address: tokenAddress, // The address that the token is at.
                                            symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
                                            decimals: tokenDecimals, // The number of decimals in the token
                                            image: tokenImage, // A string url of the token logo
                                        },
                                    },
                                }).then( () => {
                                    setTimeout(async () => {
                                        commit('SET_METAMASK_ADDRESS', data);
                                        await dispatch('metamaskRequest', {method: 'eth_chainId'});
                                        await dispatch('metamaskRequest', {
                                            method: 'eth_getBalance',
                                            params: [getters.getMetamaskWallet.address, 'latest']
                                        })
                                        commit('CONVERT_METAMASK_BALANCE', 'ether'); //!!!change to dynamic currency
                                    }, 1000)
                                });
                            } catch (error) {
                                commit('METAMASK_ERROR', error.message);
                            }
                        }, 1000)
                    }).catch((error) => {
                        commit('METAMASK_ERROR', error.message);
                    })
                    return
                }

                if(err) {
                    commit('METAMASK_ERROR', err.message);
                }

            }
        }else {
            commit('SET_METAMASK_ADDRESS', data);
            let networkId = null;
            await dispatch('metamaskRequest', {method: 'eth_chainId'}).then(async (res) => {
                commit('SET_METAMASK_NETWORK_ID', res);
            }).catch((error) => {});

            await dispatch('metamaskRequest', {
                method: 'eth_getBalance',
                params: [getters.getMetamaskWallet.address, 'latest']
            })
            commit('CONVERT_METAMASK_BALANCE', 'ether'); //!!!change to dynamic currency
        }
    },

    convertBalance({commit}, currency) {
        commit('CONVERT_METAMASK_BALANCE', currency);
    },

    //END metamask

    binanceRequest(state, { method, params }) {
        return new Promise((resolve, reject) => {
            try {
                BinanceChain.request({
                    method,
                    params: params|| defaultParams
                }).then((data) => {
                    commitBinanceAction(state, method, data);
                    resolve(data)
                }).catch((err) => {
                    localStorage.removeItem('binance')
                    // location.reload();
                    reject(err);
                })
            } catch (error) {
                // console.log(error)
            }
          })
      },

    binanceConnection({commit}, isDisconected) {
        commit('SET_BINANCE_DISCONECT', isDisconected);
    },

    subscribeToBinanceEvent(store, event) {
        BinanceChain.on(event, (data) => {
            commitBinanceOnSubscription(store, event, data);
          });
      },
}
