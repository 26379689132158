<template>
  <div class="public-page-content" v-html="$t('Privacy Policy')">
  </div>
</template>

<script>

export default {
  name: "PrivacyPolicy",
  methods: {
    changeTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem(
          "theme",
          this.$vuetify.theme.dark ? "dark" : "light"
      );
    },
  },
}
</script>

<style scoped>

</style>