<template>
  <div id="my-content">
    <Header
        @changeTheme="changeTheme"
        @windowWidth="windowWidthListener"
    />
    <main id="root">
      <div class="public-page rewards-public-page">
        <div class="public-page-sidebar rewards-page-sidebar" v-if="desktop">
          <PagesSidebar
              :itemList="sidebarItemList"
              :title="$t('Rewards')"
              :icon="require('@/assets/img/reward-icon.svg')"
          />
        </div>
        <div class="public-page-content-wrap">
          <div class="sidebarBurger" @click="$refs.TabletVersionOfSidebar.openSidebar()" v-if="tablet">
            <span class="burger-lines"></span>
          </div>
          <TabletVersionOfSidebar ref="TabletVersionOfSidebar" :itemList="sidebarItemList" v-if="tablet"/>
          <router-view :token="token" />
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import Header from "../components/header/Header";
import TabletVersionOfSidebar from "../components/layout/TabletVersionOfSidebar";
import PagesSidebar from "@/components/layout/PagesSidebar.vue";

export default {
  name: "Rewards",
  components: {
    Header,
    PagesSidebar,
    TabletVersionOfSidebar
  },
  data() {
    return {
      desktop: true,
      tablet: false,
      mobile: false,
      token: localStorage.getItem('token'),
      sidebarItemList: [
        {
          name: 'Rounds of Rewards',
          routeTo: '/rewards/rounds-of-rewards',
        },
        {
          name: 'Referral Program',
          routeTo: '/rewards/referral-program',
        },
        {
          name: 'Rewards History',
          routeTo: '/rewards/rewards-history',
        },
      ]
    }
  },
  methods: {
    sidebarClickOutside(event) {
      if (
          !this.desktop
          && !event.target.closest('.nav-wrapper')
          && !event.target.closest('.sidebarBurger')
      ) {
        this.$refs.TabletVersionOfSidebar.closeSideBar()
      }
    },
    changeTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem(
          "theme",
          this.$vuetify.theme.dark ? "dark" : "light"
      );
    },
    windowWidthListener(e) {
      if(e < 1025) {
        this.desktop = false
        this.tablet = true
        this.mobile = false
        // return
      } else {
        this.desktop = true
        this.tablet = false
        this.mobile = false
      }
    }
  },
  mounted() {
    document.addEventListener('click', this.sidebarClickOutside)
    let width = window.innerWidth
    this.windowWidthListener(width)

    window.addEventListener('token-localstorage-changed', (event) => {
      this.token = event.detail.token;
    });

    if(localStorage.getItem('token') !== null) {
      this.token = localStorage.getItem('token')
    }

    window.addEventListener('token-localstorage-remove', (event) => {
      this.token = null
    });
  },
  beforeDestroy() {
    document.removeEventListener('click', this.sidebarClickOutside)
  }
}
</script>

<style scoped>

</style>