<template>
  <div class="report-template">
    <div class="report-header">
      <h1 class="report-title textMainColor">{{ $t('Trade History') }}</h1>
    </div>
    <div class="report-filter-wrapper">
      <div class="report-filter-item">
        <div class="report-filter-item-title">{{ $t('Date') }}</div>
        <div class="report-filter-item-input-wrap">
          <date-picker
              v-model="dateRange"
              :disabled-date="disabledRange"
              :editable="false"
              :format="'DD/MM/YYYY'"
              :input-class="$vuetify.theme.dark ? 'theme-dark' : 'theme-light'"
              :lang="$i18n.locale"
              :placeholder="$t('Date Range')"
              :popup-class="$vuetify.theme.dark ? 'theme-dark' : 'theme-light'"
              :range-separator="' - '"
              range
          ></date-picker>
        </div>
      </div>
      <div class="report-filter-item">
        <div class="report-filter-item-title">{{ $t('Symbol') }}</div>
        <div class="report-filter-item-input-wrap">
          <CustomSelect
              ref="selectSymbol"
              :options="symbolOptionsList"
              custom-class="support-form"
              default="All"
              @update:modelValue="formData.symbol = $event"
          />
        </div>
      </div>
      <div class="report-filter-item">
        <div class="report-filter-item-title">{{ $t('Side') }}</div>
        <div class="report-filter-item-input-wrap">
          <CustomSelect
              ref="selectSide"
              :options="sideOptionsList"
              custom-class="support-form"
              default="All"
              @update:modelValue="formData.side = $event"
          />
        </div>
      </div>
<!--      <div class="report-filter-item">-->
<!--        <div class="report-filter-item-title">{{ $t('Search') }}</div>-->
<!--        <div class="search-input-wrap">-->
<!--          <input-->
<!--              id="search"-->
<!--              v-model="formData.searchedText"-->
<!--              v-debounce:1000ms="changeSelectFilter"-->
<!--              :placeholder="$t('Search')"-->
<!--              class="search-input"-->
<!--              name="search-result"-->
<!--              type="search"-->
<!--          >-->
<!--          <div class="search-icon search">-->
<!--            <svg-->
<!--                fill="none"-->
<!--                height="16"-->
<!--                viewBox="0 0 16 16"-->
<!--                width="16"-->
<!--                xmlns="http://www.w3.org/2000/svg"-->
<!--            >-->
<!--              <path-->
<!--                  d="M7.33334 12.6667C10.2789 12.6667 12.6667 10.2789 12.6667 7.33334C12.6667 4.38782 10.2789 2 7.33334 2C4.38782 2 2 4.38782 2 7.33334C2 10.2789 4.38782 12.6667 7.33334 12.6667Z"-->
<!--                  stroke="#42E8E0"-->
<!--                  stroke-linecap="round"-->
<!--                  stroke-linejoin="round"-->
<!--                  stroke-width="1.5"-->
<!--              />-->
<!--              <path-->
<!--                  d="M13.9996 13.9996L11.0996 11.0996"-->
<!--                  stroke="#42E8E0"-->
<!--                  stroke-linecap="round"-->
<!--                  stroke-linejoin="round"-->
<!--                  stroke-width="1.5"-->
<!--              />-->
<!--            </svg>-->
<!--          </div>-->
<!--          <div-->
<!--              v-if="formData.searchedText.length"-->
<!--              class="search-icon reset"-->
<!--              @click.stop="resetQuestion"-->
<!--          >-->
<!--            <svg-->
<!--                fill="none"-->
<!--                height="16"-->
<!--                viewBox="0 0 16 16"-->
<!--                width="16"-->
<!--                xmlns="http://www.w3.org/2000/svg"-->
<!--            >-->
<!--              <path-->
<!--                  d="M2.25 2.25L7.99995 7.99995M7.99995 7.99995L13.7499 2.25M7.99995 7.99995L13.75 13.7498M7.99995 7.99995L2.2501 13.7498"-->
<!--                  stroke="#E9E9E9"-->
<!--                  stroke-linecap="round"-->
<!--                  stroke-linejoin="round"-->
<!--                  stroke-width="1.5"-->
<!--              />-->
<!--            </svg>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
      <div class="report-filter-button-wrap">
        <button @click="resetFilterParams">{{ $t('Reset') }}</button>
      </div>
    </div>
    <div class="report-content">
      <div class="preloader new-preloader" v-if="isLoading">
        <lottie-animation
            ref="preloader"
            :animationData="require('@/assets/img/Loader.json')"
            :loop="true"
            :autoPlay="true"
            :speed="1"
        />
      </div>
      <div v-if="!isLoading && isDataEmpty" class="holder-not-logged-text report-empty-wrap">
        <div class="report-empty-list">
          <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M42.1351 5.89334C41.0418 4.8 39.1484 5.54667 39.1484 7.06667V16.3733C39.1484 20.2667 42.4551 23.4933 46.4818 23.4933C49.0151 23.52 52.5351 23.52 55.5484 23.52C57.0684 23.52 57.8684 21.7333 56.8018 20.6667C52.9618 16.8 46.0818 9.84 42.1351 5.89334Z" fill="#92939C"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M46.9613 27.1733H54.668C56.1346 27.1733 57.3346 28.3733 57.3346 29.84V39.6441C55.2687 38.1884 52.7495 37.3333 50.0315 37.3333C43.0218 37.3333 37.3346 43.0205 37.3346 50.0302C37.3346 53.365 38.6218 56.4005 40.7262 58.6666H21.5213C13.308 58.6666 6.66797 53.3333 6.66797 43.8133V20.1866C6.66797 10.6666 13.308 5.33331 21.5213 5.33331H32.828C34.2946 5.33331 35.4946 6.53331 35.4946 7.99998V15.7066C35.4946 22.0266 40.6413 27.1733 46.9613 27.1733ZM57.3346 43.755V43.8133C57.3346 52.1992 52.1825 57.3365 45.3411 58.4415C42.3948 56.7941 40.4015 53.6436 40.4015 50.0302C40.4015 44.7143 44.7156 40.4002 50.0315 40.4002C52.9509 40.4002 55.5682 41.7013 57.3346 43.755ZM20.0013 47.3333H30.668C31.7613 47.3333 32.668 46.4266 32.668 45.3333C32.668 44.24 31.7613 43.3333 30.668 43.3333H20.0013C18.908 43.3333 18.0013 44.24 18.0013 45.3333C18.0013 46.4266 18.908 47.3333 20.0013 47.3333ZM20.0013 36.6666H36.0013C37.0946 36.6666 38.0013 35.76 38.0013 34.6666C38.0013 33.5733 37.0946 32.6666 36.0013 32.6666H20.0013C18.908 32.6666 18.0013 33.5733 18.0013 34.6666C18.0013 35.76 18.908 36.6666 20.0013 36.6666Z" fill="#92939C"/>
            <path d="M59.9855 57.2476L58.0636 55.3257C59.0655 53.8127 59.6584 51.993 59.6584 50.0302C59.6584 44.7143 55.3443 40.4002 50.0284 40.4002C44.7125 40.4002 40.3984 44.7143 40.3984 50.0302C40.3984 55.3461 44.7125 59.6602 50.0284 59.6602C51.9912 59.6602 53.7905 59.0672 55.3239 58.0654L57.2458 59.9873C57.6343 60.3758 58.125 60.5598 58.6361 60.5598C59.1473 60.5598 59.638 60.3758 60.0264 59.9873C60.742 59.2513 60.742 58.0245 59.9855 57.2476Z" fill="#92939C"/>
          </svg>
          <p class="secondary-text holder-not-logged-text">{{ $t('No records found') }}</p>
        </div>
      </div>
      <table v-if="tradingTableInfoHistoryOrders.length" class="history-orders-table">
        <thead class="holder-table">
        <tr class="table-head">
          <!-- 1 -->
          <th class="pair">
            <p>
              <span class="textSecondaryColor">{{ $t('Symbol') }}</span>
            </p>
          </th>
          <!-- 3 -->
          <th class="amount" :data-label="$t('amount')">
            <p>
              <span class="textSecondaryColor">{{ $t('amount') }}</span>
            </p>
          </th>
          <!-- 4 -->
          <th class="price">
            <p>
              <span class="textSecondaryColor">{{ $t('priceTextPrice') }}</span>
            </p>
          </th>
          <!-- 5 -->
          <th class="side">
            <p>
              <span class="textSecondaryColor">{{ $t('Side') }}</span>
            </p>
          </th>
          <!-- 6 -->
          <th class="filled">
            <p>
              <span class="textSecondaryColor">{{ $t('Fee') }}</span>
            </p>
          </th>
          <!-- 7 -->
          <th class="status">
            <p>
              <span class="textSecondaryColor">{{ $t('Realized Profit') }}</span>
            </p>
          </th>
          <!-- 8 -->
          <th class="time">
            <p>
              <span class="textSecondaryColor">{{ $t('time') }}</span>
            </p>
          </th>
        </tr>
        </thead>
        <tbody class="open-orders-table-main">
        <TradingOrderHistoryItems
            v-for="(historyOrdersItems, index) in tradingTableInfoHistoryOrders"
            :id="historyOrdersItems"
            :key="index"
            :historyOrdersItems="historyOrdersItems"
            :isCheckedOrders="isCheckedOrders"
        />
        </tbody>
      </table>
      <div class="table-footer" v-if="tradingTableInfoHistoryOrders.length">
        <div
            class="report-download"
            @click="openExportPopup"
        >
          <svg
              fill="none"
              height="16"
              viewBox="0 0 17 16"
              width="17"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                clip-rule="evenodd"
                d="M9.73633 2C9.73633 1.58579 9.40054 1.25 8.98633 1.25C8.57211 1.25 8.23633 1.58579 8.23633 2V8.18934L6.18328 6.1363C5.89039 5.8434 5.41552 5.8434 5.12262 6.1363C4.82973 6.42919 4.82973 6.90406 5.12262 7.19696L8.4558 10.5301C8.59154 10.666 8.77912 10.75 8.98633 10.75C9.19679 10.75 9.38701 10.6633 9.52322 10.5237L12.85 7.19696C13.1428 6.90406 13.1428 6.42919 12.85 6.1363C12.5571 5.8434 12.0822 5.8434 11.7893 6.1363L9.73633 8.18926V2ZM2.98633 9.25C3.40054 9.25 3.73633 9.58579 3.73633 10V12.6667C3.73633 12.8214 3.79779 12.9697 3.90718 13.0791C4.01658 13.1885 4.16495 13.25 4.31966 13.25H13.653C13.8077 13.25 13.9561 13.1885 14.0655 13.0791C14.1749 12.9697 14.2363 12.8214 14.2363 12.6667V10C14.2363 9.58579 14.5721 9.25 14.9863 9.25C15.4005 9.25 15.7363 9.58579 15.7363 10V12.6667C15.7363 13.2192 15.5168 13.7491 15.1261 14.1398C14.7354 14.5305 14.2055 14.75 13.653 14.75H4.31966C3.76713 14.75 3.23722 14.5305 2.84652 14.1398C2.45582 13.7491 2.23633 13.2192 2.23633 12.6667V10C2.23633 9.58579 2.57211 9.25 2.98633 9.25Z"
                fill="white"
                fill-rule="evenodd"
            />
          </svg>
          <span>{{ $t('Export Trade History') }}</span>
        </div>
        <b-pagination-nav
            v-if="!isLoading && tradingTableInfoHistoryOrders.length && count !== 1"
            v-model="page"
            :link-gen="linkGen"
            :number-of-pages="count"
            :per-page="pageSize"
            :total-rows="count"
            class="pagination"
            first-number
            last-number
            next-text="»"
            prev-text="«"
            use-router
        />
      </div>
    </div>
    <ExportHistoryPopup
        ref="exportPopups"
        v-if="!isLoading && tradingTableInfoHistoryOrders.length"
        :periodList="periodList"
        :popup-title="$t('Export Trade History')"
        :form-data="formData"
        @exportForm="getTradeHistory"
    />
  </div>
</template>
<script>
import LottieAnimation from "lottie-web-vue";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';
import 'vue2-datepicker/locale/hi';
import 'vue2-datepicker/locale/es';
import 'vue2-datepicker/locale/zh-cn';
import TradingOrderHistoryItems from './trade-history/TradingOrderTradeItems';
import CustomSelect from '../inputs/CustomSelect';
import ExportHistoryPopup from '../popups/ExportHistoryPopup';
import { mapActions } from 'vuex';

export default {
  name: 'ReportsTradeHistory',
  data() {
    return {
      dateRange: [new Date(new Date().getTime() - 604800000), new Date()],
      formData: {
        symbol: '',
        dateFrom: '',
        dateTo: '',
        side: '',
        searchedText: '',
      },
      isCheckedOrders: false,
      symbolOptionsList: [
        {
          name: 'All',
          value: '',
        },
        {
          name: 'BTC/USDT',
          value: 'btc_usdt',
        },
        {
          name: 'ETH/USDT',
          value: 'eth_usdt',
        },
        {
          name: 'SOL/USDT',
          value: 'sol_usdt',
        },
        {
          name: 'ADA/USDT',
          value: 'ada_usdt',
        },
        {
          name: 'NEAR/USDT',
          value: 'near_usdt',
        },
        {
          name: 'APE/USDT',
          value: 'ape_usdt',
        },
        {
          name: 'ATOM/USDT',
          value: 'atom_usdt',
        },
        {
          name: 'AVAX/USDT',
          value: 'avax_usdt',
        },
        {
          name: 'DOT/USDT',
          value: 'dot_usdt',
        },
        {
          name: 'GMT/USDT',
          value: 'gmt_usdt',
        },
        {
          name: 'MATIC/USDT',
          value: 'matic_usdt',
        },
      ],
      sideOptionsList: [
        {
          name: 'All',
          value: '',
        },
        {
          name: 'Buy',
          value: 'BUY',
        },
        {
          name: 'Sell',
          value: 'SELL',
        },
      ],
      periodList: [
        {
          name: 'Last 24 hours',
          val: 'LAST_24_HOURS',
        },
        {
          name: 'Yesterday',
          val: 'YESTERDAY',
        },
        {
          name: 'Last 2 weeks',
          val: 'LAST_2_WEEKS',
        },
        {
          name: 'Past month',
          val: 'PAST_MONTH',
        },
        {
          name: 'Past 3 months',
          val: 'PAST_3_MONTHS',
        },
        {
          name: 'Month to date',
          val: 'MOUNT_TO_DATE',
        },
      ],
      tradingTableInfoHistoryOrders: [],
      walletAddress: null,
      counters: {},
      page: 1,
      count: null,
      pageSize: 20,
      isLoading: true,
      isDataEmpty: false,
    };
  },
  watch: {
    'dateRange'(value) {
      this.formData.dateFrom = isNaN(Date.parse(this.dateRange[0])) == false ? (String(Date.parse(this.dateRange[0])) / 1000) : '';
      this.formData.dateTo = isNaN(Date.parse(this.dateRange[1])) == false ? (String(Date.parse(this.dateRange[1]) + 86400000) / 1000) : '';
      if(value.length) {
        this.changeSelectFilter();
      }
    },
    'formData.symbol'() {
      this.changeSelectFilter();
    },
    'formData.side'() {
      this.changeSelectFilter();
    },
    'formData.searchedText'(val) {
      this.formData.searchedText = val.toLowerCase();
    },
    '$route.query.page': {
      deep: true,
      handler(newVal) {
        if(newVal) {
          this.page = newVal;
          this.getOpenOrdersData();
        } else {
          this.page = 1;
        }
      },
    },
  },
  methods: {
    ...mapActions({
      getOrdersReportTrade: 'orders/getOrdersReportTrade',
      getTradeHistoryData: 'reports/getTradeHistory',
    }),
    disabledRange: function (date) {
      return date > new Date();
    },
    openExportPopup() {
      this.$refs.exportPopups.openPopups();
    },
    linkGen(pageNum) {
      return {
        path: '/reports/trade-history',
        query: { page: pageNum },
      };
    },
    resetPagination() {
      this.$router.push({path: '/reports/trade-history', query: { page: 1 }}).catch(() => {});
    },
    changeSelectFilter() {
      this.resetPagination()
      if(this.page === 1) {
        this.getOpenOrdersData();
      }
    },
    resetQuestion() {
      this.formData.searchedText = '';
      this.getOpenOrdersData();
    },
    resetFilterParams() {
      this.dateRange = [];
      this.formData.searchedText = '';
      this.formData.dateFrom = '';
      this.formData.dateTo = '';
      this.formData.symbol = '';
      this.formData.side = '';
      this.resetPagination()
      if(this.page === 1) {
        this.getOpenOrdersData();
      }

      this.resetFilterToDefault();
    },
    resetFilterToDefault() {
      this.$refs.selectSymbol.reset();
      this.$refs.selectSide.reset();
    },
    onDeleteTradingOrder(order) {
      this.infoDialogData = {
        dialogTitle: `Cancel ${ order.length ? 'Orders' : 'Order' }`,
        dialogText: `Are you sure you want to cancel ${ order.length ? order.length + ' Orders' : 'Order' }?`,
        confirmBtnText: 'Yes',
        cancelBtnText: 'No',
      };

      this.$refs.infoDialog.showDialog(order);
    },
    onDeleteTradingOrderAll() {
      this.infoDialogData = {
        dialogTitle: `Cancel Orders`,
        dialogText: `Are you sure you want to cancel Orders?`,
        confirmBtnText: 'Yes',
        cancelBtnText: 'No',
      };

      this.$refs.infoDialogDeleteOrderAll.showDialog();
    },
    getRequestParams(formData) {
      const params = {};

      for (const data in formData) {
        if(formData[data]) {
          params[data] = formData[data];
        }
      }

      return params;
    },
    getTradeHistory(params) {
      this.getTradeHistoryData({ params }).then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'report.csv');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
    },
    getOpenOrdersData() {
      this.isLoading = true;
      this.isDataEmpty = false;
      this.tradingTableInfoHistoryOrders = [];
      const params = this.getRequestParams(this.formData)

      params.size = this.pageSize
      params.page = this.page - 1

      this.getOrdersReportTrade({params}).then((data) => {
        this.counters.tradingTableInfoHistoryOrders = 0;
        let counterHistoryOrders = 0;
        // this.tradingTableInfoOpenOrders = data.body.slice(0, 1)
        this.tradingTableInfoHistoryOrders = data.content;
        this.isLoading = false;
        this.count = data.totalPages

        if(!this.tradingTableInfoHistoryOrders.length) {
          this.isDataEmpty = true;
        }
      });
    },
  },
  mounted() {
    this.page = this.$route.query.page || 1
    this.getOpenOrdersData();
  },
  components: {
    LottieAnimation,
    DatePicker,
    CustomSelect,
    TradingOrderHistoryItems,
    ExportHistoryPopup,
  },
};
</script>
<style lang="scss">
.table-body td {
  padding: 6px 0;
}

.history-orders-table {
  width: 100%;

  p {
    padding-left: 0px;
    text-align: left;
  }

  .up {
    top: 4px;
    left: 0;
  }

  .down {
    bottom: 4px;
    left: 0;
  }

  .pair {
    width: 10%;
  }

  .amount {
    width: 10%;
    text-align: right;
  }

  .side {
    width: 10%;
    text-align: center;
  }

  .filled {
    width: 15%;
    text-align: right;
  }

  .type {
    width: 10%;
    text-align: left;
  }

  .price {
    width: 15%;
    text-align: right;
  }

  .status {
    width: 15%;
    padding-left: 50px;
    text-align: right;
  }

  .time {
    width: 15%;
    padding-left: 50px;
    text-align: left;
  }

  .table-head {
    .status p {
      text-align: right;
    }

    .price p {
      text-align: right;
    }

    .filled p {
      text-align: right;
    }

    .status p {
      text-align: right;
    }

    .amount p {
      text-align: right;
    }

    .side p {
      text-align: center;
    }

    .time p {
      text-align: left;
    }
  }
}
</style>
