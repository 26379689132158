<template>
  <tr class="table-body">
    <td class="pair" :data-label="$t('Symbol')">
      <span
          :style="openOrdersItems.side === 'BUY' ? 'border-left: 4px solid #42E8E0;' : 'border-left: 4px solid #f27870'">{{ splitCoin }}
      </span>
    </td>
    <td class="type" :data-label="$t('type')">
      <span>{{
          $t(openOrdersItems.type.toLowerCase()[0].toUpperCase() + openOrdersItems.type.toLowerCase().slice(1).replace('_', ' '))
        }}</span>
    </td>
    <td class="amount" :data-label="$t('amount') + ' / ' + $t('Filled')">
      <span>{{ openOrdersItems.amount.toFixed(2) }} / {{ openOrdersItems.filled.toFixed(2) }}</span>
      <span class="secondary-text"> {{ coinName }}</span>
    </td>
    <td class="price" :data-label="$t('priceTextPrice')">
      <span>{{ toPrecisionNumber(openOrdersItems.price) }}</span>
      <span class="secondary-text"> {{ tokenName }}</span>
    </td>
    <td class="side" :data-label="$t('Side')">
      <span :class="openOrdersItems.side === 'BUY' ? 'buy' : 'sell'">
        {{ $t(openOrdersItems.side.toLowerCase()[0].toUpperCase() + openOrdersItems.side.toLowerCase().slice(1)) }}
      </span>
    </td>
    <td class="notional-value" :data-label="$t('Notional Value')">
      <span>{{ prettify(openOrdersItems.notionalValue) }}</span>
      <span class="secondary-text"> {{ tokenName }}</span>
    </td>
    <td class="placed" :data-label="$t('accordeonOptionsPlaced')">
      <span>{{formatterDate(openOrdersItems.placedAt)}} </span>
      <span class="secondary-text">{{ formatterTime(openOrdersItems.placedAt)}}</span>
    </td>
<!--    <td class="placed">-->
<!--      <span>{{openOrdersItems.placedAt.split(' ')[0]}} </span>-->
<!--      <span class="secondary-text">{{ openOrdersItems.createdAt.split(' ')[1] }}</span>-->
<!--    </td>-->
    <!-- <div class="holder-checkbox" style="width: 5%; text-align: right">
      <label class="orange-checkbox-container">
        <input type="checkbox" :checked="isCheckedOrders" />
        <span class="checkmark"></span>
      </label>
    </div> -->
    <td class="cancel-button-wrpper">
      <button type="button" class="cancel-button-desktop" @click="onDeleteOrder(openOrdersItems)">
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
          <path
              d="M2.25 2.25L7.99995 7.99995M7.99995 7.99995L13.7499 2.25M7.99995 7.99995L13.75 13.7498M7.99995 7.99995L2.2501 13.7498"
              stroke="#A4A5AF"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
          />
        </svg>
      </button>
      <button type="button" class="cancel-button-mobile" @click="onDeleteOrder(openOrdersItems)">
        cancel
      </button>
    </td>
  </tr>
</template>

<script>
import moment from "moment";
import toPrecisionNumber from "../../../mixins/toPrecisionNumber";

export default {
  name: "TradingOpenOrdersItems",
  props: {
    openOrdersItems: {
      type: Object,
    },
    index: {
      type: Number,
    },
    isCheckedOrders: {
      type: Boolean,
    }
  },
  mixins: [toPrecisionNumber],
  data() {
    return {}
  },
  computed: {
    coinName() {
      return this.openOrdersItems.symbol.split('_')[0].toUpperCase()
    },
    tokenName() {
      return this.openOrdersItems.symbol.split('_')[1].toUpperCase()
    },
    splitCoin() {
      return this.openOrdersItems.symbol.split('_').join('/').toUpperCase();
    },
  },
  methods: {
    onDeleteOrder(order) {
      this.$emit('onDeleteOrder', order);
    },
    prettify(num) {
      num = Number(num).toLocaleString("en-US")
      return num
    },
    formatterDate: function (value) {
      return moment.utc(value).local().format('DD/MM/yy');
    },
    formatterTime: function (value) {
      return moment.utc(value).local().format('HH:mm');
    }
  }
};
</script>

<style lang="scss" scoped>
.table-body {
}

.list {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 12px;
}

.pair {
  width: 10%;
  span {
    display: inline-block;
    padding-left: 4px;
  }
}

.type {
  text-align: right;
  width: 20%;
}

.amount {
  text-align: right;
  width: 20%;
}

.price {
  text-align: right;
  width: 20%;
}

.placed {
  text-align: right;
  width: 20%;
}

.side .buy {
  color: #02C9BF;
}

.v-application.theme--dark .side .buy {
  color: #1BB8B0;
}

.side .sell {
  color: #f27870;
}

// checkbox
.orange-checkbox-container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.orange-checkbox-container input {
  display: none;
}

.checkmark {
  display: block;
  height: 14px;
  width: 14px;
  border: 1px solid var(--v-textMainColor-base);
  border-radius: 3px;
}

.orange-checkbox-container:hover input ~ .checkmark {
  background-color: var(--v-textAccentColor-base);
}

.orange-checkbox-container input:checked ~ .checkmark {
  background-color: #FFB000;
  border: none;
  border-radius: 3px;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.orange-checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.holder-checkbox {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

// end checkbox
.secondary-text {
  color: var(--v-textSecondaryColor-base)
}

.cancel-button-wrpper {
  text-align: right;
  width: 5%;
}

.cancel-button-mobile {
  display: none;
  padding: 10px 30px;
  background: var(--v-orderFormProgressItemBorderActive-base);
  text-transform: capitalize;
}
</style>
