<template>
  <table class="rewards-table referral-program-table" v-if="token && invitedUsersList.length">
    <thead class="holder-table">
    <tr class="table-head">
      <th class="wallet">
        <p>
          <span class="textSecondaryColor">{{ $t('User Adress') }}</span>
        </p>
      </th>
      <th class="register-date">
        <p>
          <span class="textSecondaryColor">{{ $t('Registration Date') }}</span>
        </p>
      </th>
      <th class="current-month">
        <p>
          <span class="textSecondaryColor">{{ $t('Current Month') }}</span>
        </p>
      </th>
      <th class="total">
        <p>
          <span class="textSecondaryColor">{{ $t('Total') }}</span>
        </p>
      </th>
    </tr>
    </thead>
    <tbody class="order-history-table-main">
    <invited-users-items
        v-for="user in invitedUsersList"
        :key="user.userAddress"
        :invitedUser="user"
        :token="token"
    />
    </tbody>
  </table>
</template>

<script>
import InvitedUsersItems from "@/components/rewards/ReferralProgram/InvitedUsersItems.vue";
import moment from "moment/moment";
export default {
  name: "InvitedUsersList",
  components: {
    InvitedUsersItems,
  },
  props: {
    invitedUsersList: {
      type: Array,
    },
    token: {},
    isLoading: {},
  },
}
</script>

<style lang="scss" scoped>
p {
  padding-left: 0px;
  text-align: right;
}

.table-head {
  th {
    width: 25%;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .wallet {
    text-align: left;
    padding-left: 20px;
  p {
    text-align: left;
  }
  }

  .total {
    padding-right: 20px;
  }


  p {
    text-align: right;
    margin-bottom: 0px;
  }
}
</style>