<template>
  <div class="chart-item">
    <h2 class="trading-title">{{ $t('Real-Time Funding Rate') }}</h2>
    <div class="chart-item-container">
      <div>
        <p class="chart-item-description textSecondaryColor textSecondaryColor">
          {{ $t('Funding Rate = Premium Index + clamp (Interest Rate - Premium Index, 0.05%, -0.05%)') }}
        </p>
      </div>
    </div>
    <div class="chart-item-table">
      <div class="content-wrapper">
        <div class="preloader new-preloader" v-if="isLoading">
          <lottie-animation
              ref="preloader"
              :animationData="require('@/assets/img/Loader.json')"
              :loop="true"
              :autoPlay="true"
              :speed="1"
          />
        </div>
        <table v-if="fundingRateRealTimeData.length && foundingCountdown">
          <thead>
          <tr>
            <th class="asset-name">{{ $t('Contracts') }}</th>
            <th class="funding-next">{{ $t('Time to Next Funding') }}</th>
            <th class="interest-rate">{{ $t('Interest Rate') }}</th>
            <th class="funding-rate">{{ $t('Funding Rate') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="(rate, index) in fundingRateRealTimeData"
              :key="index"
              class="table-body"
          >
            <td class="asset-name">
              <div class="asset-logo">
                <img :src="rate.icon" alt="">
                <span>{{ splitCoin(rate.symbol) }}</span>
              </div>
            </td>
            <td class="funding-next">{{ foundingCountdown }}</td>
            <td class="interest-rate">{{ rate.interestRate }}</td>
            <td
                class="funding-rate"
                :style="{color: rate.fundingRate > 0  ? '#1BB8B0' : '#F27870'}"
            >{{ rate.fundingRate }}</td>
          </tr>
          </tbody>
        </table>
<!--        <div v-else class="report-empty-list">-->
<!--          <svg fill="none" height="60" viewBox="0 0 60 60" width="60" xmlns="http://www.w3.org/2000/svg">-->
<!--            <path d="M12.2052 45.9199C13.1671 46.8775 14.4703 47.4137 15.8277 47.4103H15.8514L31.4101 47.3208C32.8019 50.9394 35.8998 53.631 39.6772 54.5034C43.4547 55.3762 47.4189 54.3162 50.2573 51.6745L54.3863 55.8027C54.5529 55.9718 54.7973 56.039 55.0267 55.9779C55.2561 55.9172 55.4356 55.7377 55.4963 55.5083C55.5574 55.2785 55.4902 55.034 55.3211 54.8675L51.1735 50.7212C53.9732 47.4576 54.7521 42.9197 53.2015 38.9094C51.6508 34.8991 48.0214 32.066 43.7546 31.5344L43.6694 16.1217L43.6681 15.9366C43.6681 15.7601 43.5976 15.5906 43.4719 15.4666L32.0875 4.21304C31.9782 4.10544 31.8353 4.03873 31.6825 4.02496L31.4471 4.00301C31.4251 4.00086 31.4032 4.00043 31.3825 4L15.6426 4.07618C12.8004 4.08909 10.5069 6.40453 10.5216 9.24676L10.6804 42.2875C10.6825 43.6535 11.2317 44.9614 12.2052 45.9199ZM52.6678 43.1241C52.6678 48.8439 48.0309 53.4808 42.3111 53.4808C36.591 53.4808 31.9545 48.8439 31.9545 43.1241C31.9545 37.4044 36.591 32.7675 42.3111 32.7675C42.5616 32.7675 42.8082 32.7761 43.0441 32.7933H43.0458C48.4411 33.1716 52.6678 37.71 52.6678 43.1241ZM41.4297 15.3065L36.1348 15.3319H36.1158C34.0126 15.3289 32.3052 13.6298 32.2928 11.5265L32.2674 6.2496L41.4297 15.3065ZM12.9502 6.53064C13.6625 5.8076 14.6347 5.3996 15.6495 5.39831L30.9409 5.32428L30.9706 11.5329C30.9874 14.3636 33.2852 16.6506 36.1163 16.654H36.1416L42.3507 16.6243L42.4329 31.4475C42.3921 31.4475 42.3525 31.4453 42.3116 31.4453C38.7076 31.4466 35.3059 33.1109 33.0933 35.9557C30.8807 38.801 30.1047 42.5074 30.9909 46.0012L15.8445 46.0877H15.8268C13.7214 46.0882 12.012 44.3864 12.0025 42.281L11.8437 9.24031C11.8359 8.22547 12.2345 7.2498 12.9502 6.53064Z" fill="#828392" />-->
<!--            <path d="M28.7341 28.4062H26.4931C26.1281 28.4062 25.832 28.7024 25.832 29.0673C25.832 29.4327 26.1281 29.7284 26.4931 29.7284H28.7341C29.0995 29.7284 29.3952 29.4327 29.3952 29.0673C29.3952 28.7024 29.0995 28.4062 28.7341 28.4062Z" fill="#828392" />-->
<!--            <path d="M17.8525 24.1229H36.4647C36.8296 24.1229 37.1257 23.8268 37.1257 23.4618C37.1257 23.0969 36.8296 22.8008 36.4647 22.8008H17.8525C17.4871 22.8008 17.1914 23.0969 17.1914 23.4618C17.1914 23.8268 17.4871 24.1229 17.8525 24.1229Z" fill="#828392" />-->
<!--            <path d="M17.8525 29.7284H23.6544C24.0194 29.7284 24.3155 29.4327 24.3155 29.0673C24.3155 28.7024 24.0194 28.4062 23.6544 28.4062H17.8525C17.4871 28.4062 17.1914 28.7024 17.1914 29.0673C17.1914 29.4327 17.4871 29.7284 17.8525 29.7284Z" fill="#828392" />-->
<!--          </svg>-->
<!--          <p class="secondary-text holder-not-logged-text">{{ $t('No records found') }}</p>-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import LottieAnimation from "lottie-web-vue";
import {ws} from "@/utils/socket";
import moment from "moment";
import {useReactiveMap} from "vue-reactive-collection";

export default {
  name: 'FundingRate',
  data() {
    return {
      fundingRateRealTimeData: [],
      symbolsMap: null,
      isLoading: false,
      interval: null,
      countdownFlag: true,
      foundingCountdown: 0,
    };
  },
  components: {
    LottieAnimation,
  },
  methods: {
    splitCoin(coin) {
      return coin.split('_').join('/').toUpperCase();
    },
    getFundingRateRealTime() {
      ws.subscribe(
          `FoundingRate`,
          {
            method: "SUBSCRIBE",
            params: [`!markPrice_arr_1s`]
          },
          (res) => {
            if (res.constructor.name == "Array") {
              if(res.length && res[0].eventType === 'markPrice') {
                this.fundingRateRealTimeData = res
                this.fundingRateRealTimeData.forEach(item => {
                  item.interestRate = 0.1
                  item.icon = this.symbolsMap.value.get(item.symbol).icon
                  if(this.countdownFlag) {
                    this.countdown( moment(item.leftSecondsToFunding * 1000))
                    this.countdownFlag = false
                  }
                })
              }
            }
          }
      );
      // setTimeout(() => {
      //   this.isLoading = false
      // }, 2000)
    },
    countdown(time) {

      let duration = moment.duration(time, 'milliseconds');

      const interval = 1000;

      const intervalId = setInterval(() => {
        duration = moment.duration(duration - interval, 'milliseconds');

        let hours = duration.hours() < 10 ? '0' + duration.hours() : duration.hours()
        let minutes = duration.minutes() < 10 ? '0' + duration.minutes() : duration.minutes()
        let seconds = duration.seconds() < 10 ? '0' + duration.seconds() : duration.seconds()

        this.foundingCountdown = hours + ":" + minutes + ":" + seconds

        this.isLoading = false

        if (duration <= 0) {
          clearInterval(intervalId);
          this.countdownFlag = true
        }
      }, interval);
    },
  },
  beforeDestroy() {
    ws.unsubscribe(
        `FoundingRate`,
        {
          method: "UNSUBSCRIBE",
          params: [`!markPrice_arr_1s`]
        },
    );
    // ws.close()
  },
  created() {
    ws.close();
    ws.initWebSocket();
    this.symbolsMap = useReactiveMap(new Map([
      ['btc_usdt', {
        symbol: 'btc_usdt',
        icon: require('../../assets/img/coin/btc.svg'),
      }],
      ['eth_usdt',  {
        symbol: 'eth_usdt',
        icon: require('../../assets/img/coin/eth.svg'),
      }],
      ['sol_usdt', {
        symbol: 'sol_usdt',
        icon: require('../../assets/img/coin/sol.svg'),
      }],
      ['ada_usdt',{
        symbol: 'ada_usdt',
        icon: require('../../assets/img/coin/ada.svg'),
      }],
      ['near_usdt', {
        symbol: 'near_usdt',
        icon: require('../../assets/img/coin/near.svg'),
      }],
      ['ape_usdt', {
        symbol: 'ape_usdt',
        icon: require('../../assets/img/coin/ape.svg'),
      }],
      ['atom_usdt', {
        symbol: 'atom_usdt',
        icon: require('../../assets/img/coin/atom.svg'),
      },],
      ['avax_usdt', {
        symbol: 'avax_usdt',
        icon: require('../../assets/img/coin/avax.svg'),
      }],
      ['dot_usdt', {
        symbol: 'dot_usdt',
        icon: require('../../assets/img/coin/dot.svg'),
      }],
      ['gmt_usdt', {
        symbol: 'gmt_usdt',
        icon: require('../../assets/img/coin/gmt.svg'),
      }],
      ['matic_usdt', {
        symbol: 'matic_usdt',
        icon: require('../../assets/img/coin/matic.svg'),
      }],
    ]));
  },
  mounted() {
    this.isLoading = true
    this.getFundingRateRealTime()
  }
};
</script>

<style scoped>
.chart-item-container {
  display: flex;
  justify-content: space-between;
}

.chart-item-description {
  margin: 0 20px 30px 0;
  font-weight: 600;
  font-size: 14px;
}

.content-wrapper {
  position: relative;
}

.chart-item-table table {
  width: 100%;
  border-collapse: collapse;
  font-size: 16px;
  line-height: 24px;
}

.chart-item-table thead {
  border-top: 1px solid var(--v-loginDropduwnHoverBackgroundColor-base);
  border-bottom: 1px solid var(--v-loginDropduwnHoverBackgroundColor-base);
  text-align: left;
  color: var(--v-tableSecondaryColor-base);
}

.chart-item-table thead span {
  cursor: pointer;
}

.chart-item-table th {
  padding: 12px 0;
}

.chart-item-table thead svg {
  width: 10px;
  height: 10px;
}

.chart-item-table tbody tr {
  font-size: 12px;
  color: var(--v-selectColor-base);
}
.chart-item-table tbody td {

}
.chart-item-table tbody td:first-child {
  padding-right: 10px;
  font-size: 14px;
  font-weight: 600;
}

.chart-item-table tbody td:nth-child(3n):after {
  content: " %";
  color: var(--v-tableSecondaryColor-base);
}

.chart-item-table tbody td:nth-child(4):after {
  content: " %";
  color: var(--v-tableSecondaryColor-base);
}

.preloader-overlay {
  background-color: var(--v-primary-base);
  align-items: flex-start;
}

.holder-not-logged-text {
  color: #A4A5AF;
}

.report-empty-list {
  min-height: 468px;
  margin-top: 0;
}

.chart-item-table .content-wrapper{
  min-height: 450px;
}

tr td {
  width: 25%;
  font-size: 16px;
  line-height: 24px;
  vertical-align: baseline;
}

th td {
  width: 25%;
  font-size: 16px;
  line-height: 24px;
}

/*tr td.funding-rate {*/
/*  color: #42e8e0;*/
/*}*/

.funding-rate {
  text-align: right;
  padding-right: 16px;
}

.chart-item-table th.funding-rate {
  text-align: right;
  padding-right: 16px;
}

.interest-rate {
  text-align: right;
}

.chart-item-table th.interest-rate {
  text-align: right;
}

.funding-next {
  text-align: right;
}

.asset-name {
  padding-left: 16px;
}

.chart-item-table th.asset-name {
  padding-left: 16px;
}

.asset-logo  {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.asset-logo svg,
.asset-logo img {
  width: 24px;
  height: 24px;
  display: block;
  margin-right: 10px;
}

@media (max-width: 500px) {
  .chart-item-container {
    flex-direction: column;
  }
}
</style>
