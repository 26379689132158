import axios from 'axios';
import {getFromLocalStorage, saveToLocalStorage} from '../helpers';
import router from "../router";
import store from "../store";
import { v4 as uuidv4 } from 'uuid';

const metamask = getFromLocalStorage('metamask');
// const token = getFromLocalStorage('token');
const binance = getFromLocalStorage('binance');
const cosmosAddress = getFromLocalStorage('cosmosAddress');
const currentWallet = wallet();

const customAxios = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
    },
    // timeout: 20000
});

function wallet() {
    if (metamask) {
        if (!metamask.disconected) {
            return metamask;
        }
    }

    if (binance) {
        if (!binance.disconected) {
            return binance;
        }
    }

    return null;
}

function setHeaders(token) {
    if(token) {
        return `Bearer ${token}`
    }
}

const requestHandler = request => {
    if (getFromLocalStorage('token') && getFromLocalStorage('metamask')) {
        request.headers['Authorization'] = setHeaders(getFromLocalStorage('token'));
        // request.headers['MetamaskAddress'] = getFromLocalStorage('metamask').address;
        request.headers['CosmosAddress'] = getFromLocalStorage('cosmosAddress');
    }
    request.headers['X-Correlation-ID'] = uuidv4()
    return request;
};

const responseHandler = response => {
    let fe_version = response.headers['x-app-hash'] || 'default'
    if(fe_version !== localStorage.getItem('fe-version') && response.config.method == 'get'){
        localStorage.removeItem('metamask')
        localStorage.removeItem('cosmosAddress')
        localStorage.removeItem('token')
        localStorage.removeItem('refreshToken')
        window.dispatchEvent(new CustomEvent('token-localstorage-remove'));
        localStorage.setItem('fe-version', fe_version)
        window.location.reload()
        return response;
    }

    return response;
};

const errorHandler = error => {

    const originalRequest = error.config;

    // console.log(error)

    if(error.response) {
        // console.log(error.response)
        if (error.response.status === 401 && originalRequest.url === `/auth/refreshToken`) {
            localStorage.removeItem('metamask')
            localStorage.removeItem('token')
            localStorage.removeItem('refreshToken')
            window.dispatchEvent(new CustomEvent('token-localstorage-remove'));
            window.location.reload()
            return Promise.reject(error);
        }

        if (error.response.status === 401 && Boolean(!originalRequest._retry)) {

            if (!getFromLocalStorage('token') || !getFromLocalStorage('metamask')) {
                localStorage.removeItem('metamask')
                localStorage.removeItem('token')
                localStorage.removeItem('refreshToken')
                window.dispatchEvent(new CustomEvent('token-localstorage-remove'));
                // window.location.reload()
            }

            originalRequest._retry = true;
            const refreshToken = getFromLocalStorage('refreshToken');
            return customAxios.post(`${originalRequest.baseURL}/auth/refreshToken`,
                {
                    "refreshToken": refreshToken
                })
                .then((res) => {
                    saveToLocalStorage('token', res.data.accessToken)
                    saveToLocalStorage('refreshToken', res.data.refreshToken)

                    const token = getFromLocalStorage('token');
                    const cosmosAddress = getFromLocalStorage('cosmosAddress');
                    customAxios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
                    customAxios.defaults.headers.common['CosmosAddress'] = cosmosAddress;
                    return customAxios(originalRequest);
                }, () => {
                });
        }
    }

    return Promise.reject(error);
};

customAxios.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
);

customAxios.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
 );



export default customAxios;
