export default {
  SET_TOURNAMENTS_MEMBERS(state, data) {
    state.tournamentsMembersData = data;
  },
  SET_CURRENT_TOURNAMENTS(state, data) {
    state.currentRoundData = data;
  },
  SET_TOURNAMENTS_MY_RATING(state, data) {
    state.tournamentsMyRatingData = data;
  },
};