<template>
  <div>
    <div
        class="api-list-header"
        @click="show = !show"
    >
      <p>{{ apiItem.title }}</p>
      <div class="buttons-wrapper">
        <button
            class="btn-edit"
            type="button"
        >
          <span class="btn-edit-mobile-text">{{ $t('Edit') }}</span>
          <span class="btn-edit-desktop-text">{{ $t('Edit Restriction') }}</span>
        </button>
        <button
            type="button"
            class="btn-delete"
            @click.stop="openDeleteApiPopup"
        >{{ $t('Delete') }}
        </button>
        <svg
            :class="{rotated: !show}"
            fill="none"
            height="16"
            viewBox="0 0 16 16"
            width="16"
            xmlns="http://www.w3.org/2000/svg"
        >
          <path
              d="M13.334 10.666L8.00065 5.33268L2.66732 10.666"
              stroke="#42E8E0"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
          />
        </svg>
      </div>
    </div>
    <transition name="fade">
      <div
          v-if="show"
          class="api-list-body"
      >
        <div>
          <p class="block-title">{{ $t('Label API key to proceed') }}</p>
          <div class="key-wrapper">
            <span class="api-key">{{ apiItem.key }}</span>
            <div
                :class="{'pointer': !isCopied}"
                class="copy-key"
                @click.once="copyKey"
            >
              <template v-if="isCopied">
                <svg
                    fill="none"
                    height="13"
                    viewBox="0 0 12 13"
                    width="12"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      d="M0.75 6.24038L4.425 9.875L11.25 3.125"
                      stroke="var(--v-loginButtonIconHoverColor-base)"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                  />
                </svg>
                <span class="copied">{{ $t('Copied') }}</span>
              </template>
              <template v-else>
                <svg
                    class="svg"
                    fill="none"
                    height="13"
                    viewBox="0 0 16 16"
                    width="11"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      d="M3.66802 3.08532L3.66602 4.50065V11.5033C3.66602 12.078 3.89429 12.6291 4.30062 13.0354C4.70695 13.4417 5.25805 13.67 5.83268 13.67H11.5767C11.4732 13.9625 11.2816 14.2157 11.0282 14.3947C10.7749 14.5738 10.4723 14.67 10.162 14.67H5.83268C4.99283 14.67 4.18738 14.3364 3.59351 13.7425C2.99965 13.1486 2.66602 12.3432 2.66602 11.5033V4.50065C2.66602 3.84732 3.08402 3.29065 3.66802 3.08532ZM11.8327 1.33398C12.0297 1.33398 12.2247 1.37278 12.4067 1.44817C12.5887 1.52355 12.7541 1.63404 12.8933 1.77332C13.0326 1.91261 13.1431 2.07797 13.2185 2.25996C13.2939 2.44195 13.3327 2.637 13.3327 2.83398V11.5007C13.3327 11.6976 13.2939 11.8927 13.2185 12.0747C13.1431 12.2567 13.0326 12.422 12.8933 12.5613C12.7541 12.7006 12.5887 12.8111 12.4067 12.8865C12.2247 12.9619 12.0297 13.0007 11.8327 13.0007H5.83268C5.43486 13.0007 5.05333 12.8426 4.77202 12.5613C4.49072 12.28 4.33268 11.8985 4.33268 11.5007V2.83398C4.33268 2.43616 4.49072 2.05463 4.77202 1.77332C5.05333 1.49202 5.43486 1.33398 5.83268 1.33398H11.8327ZM11.8327 2.33398H5.83268C5.70007 2.33398 5.5729 2.38666 5.47913 2.48043C5.38536 2.5742 5.33268 2.70138 5.33268 2.83398V11.5007C5.33268 11.7767 5.55668 12.0007 5.83268 12.0007H11.8327C11.9653 12.0007 12.0925 11.948 12.1862 11.8542C12.28 11.7604 12.3327 11.6333 12.3327 11.5007V2.83398C12.3327 2.70138 12.28 2.5742 12.1862 2.48043C12.0925 2.38666 11.9653 2.33398 11.8327 2.33398Z"
                      fill="#FFC233"
                  />
                </svg>
                <span class="not-copied">{{ $t('Copy') }}</span>
              </template>
            </div>
          </div>
        </div>
        <div>
          <p class="block-title">{{ $t('Secret Key') }}</p>
          <span class="api-key">{{ apiItem.secretKey }}</span>
        </div>
        <div>
          <p class="block-title">{{ $t('API restrictions') }}</p>
          <ul class="api-restrictions-list">
            <li
                v-for="(restriction, index) in apiRestrictions"
                :key="index"
                class="api-restrictions-item"
            >
              <label
                  :for="`checkbox_${index}`"
                  class="label-checkbox"
              >
                <div
                    :class="{active: selectedRestriction === restriction.val}"
                    class="api-restrictions-checkbox-wrap"
                >
                  <input
                      :id="`checkbox_${index}`"
                      v-model="selectedRestriction"
                      :value="restriction.val"
                      class="input-radio"
                      name="group"
                      type="radio"
                  >
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">{{ $t(restriction.name) }}</span>
                    </template>
                    <span>{{ $t(restriction.name) }}</span>
                  </v-tooltip>
                </div>
              </label>
            </li>
          </ul>
        </div>
        <div class="ip-access">
          <p class="block-title">{{ $t('IP access restrictions') }}</p>
          <div
              :class="{active: 'unrestricted' === ipAccess}"
              class="ip-access-radiobox-wrap"
          >
            <label for="unrestricted">
              {{ $t('Unrestricted (Less Secure)') }}
              <span>{{ $t('This API key allows access from any IP address. This is not recommended.') }}</span>
              <input
                  id="unrestricted"
                  v-model="ipAccess"
                  type="radio"
                  value="unrestricted"
              >
            </label>
          </div>
          <div
              :class="{active: 'restricted' === ipAccess}"
              class="ip-access-radiobox-wrap"
          >
            <label for="restricted">
              {{ $t('Restrict access to trusted IPs only (Recommended)') }}
              <input
                  id="restricted"
                  v-model="ipAccess"
                  type="radio"
                  value="restricted"
              >
            </label>
          </div>
        </div>
      </div>
    </transition>
    <delete-api
        ref="deleteApi"
        :apiKey="apiItem.key"
    />
  </div>
</template>
<script>
import DeleteApi from '../popups/DeleteApi';

export default {
  name: 'ApiListItem',
  components: { DeleteApi },
  data() {
    return {
      show: false,
      isCopied: false,
      apiRestrictions: [
        {
          name: 'Enable Reading',
          val: 'Enable_Reading',
        },
        {
          name: 'Enable Margin Loan, Repay & Transfer',
          val: 'Enable Margin',
        },
        {
          name: 'Enable Spot & Margin Trading',
          val: 'Enable_Spot',
        },
        {
          name: 'Permits Universal Transfer',
          val: 'Permits_Universal_Transfer',
        },
        {
          name: 'Enable Withdrawals',
          val: 'Enable_Withdrawals',
        },
        {
          name: 'Enable Vanilla Options',
          val: 'Enable_Vanilla_Options',
        },
      ],
      selectedRestriction: null,
      ipAccess: null,
    };
  },
  props: {
    apiItem: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  methods: {
    openDeleteApiPopup() {
      this.$refs.deleteApi.openPopup();
    },
    copyKey() {
      navigator.clipboard.writeText(this.apiItem.key);
      this.isCopied = true;
    },
  },
  mounted() {
    if(this.index === 0) {
      this.show = true;
    }
  },
};
</script>
<style scoped>
.api-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #E0E0E5;
  padding: 14px 0;
}

.v-application.theme--dark .api-list-header {
  border-top: 1px solid #1E1E1E;
}

.api-list-header p {
  margin: 0 8px 0 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.buttons-wrapper {
  display: flex;
  align-items: center;
}

.buttons-wrapper button {
  transition: background-color 0.5s;
  margin-right: 16px;
  border-radius: 6px;
  padding: 12px;
}

.buttons-wrapper button:first-child {
  width: 150px;
  background: var(--v-loginButtonIconHoverColor-base);
  color: #fff;
}

.buttons-wrapper button:nth-child(2) {
  width: 123px;
  background: #454545;
  color: #fff;
}

.btn-edit-mobile-text {
  display: none;
}

.buttons-wrapper svg {
  transition: transform 0.5s;
  margin-left: 10px;
  cursor: pointer;
}

.rotated {
  transform: rotate(180deg);
}

.block-title {
  font-size: 14px;
  font-weight: 600;
  color: #92939c;
}

.key-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.api-key {
  word-break: break-all;
  margin-right: 10px;
  font-weight: 700;
  color: var(--v-primaryColor-base);
}

.copy-key {
  display: flex;
  align-items: center;
  font-size: 11px;
  font-weight: 400;
}

.pointer {
  cursor: pointer
}

.copied {
  pointer-events: none;
  color: var(--v-loginButtonIconHoverColor-base);
}

.not-copied {
  color: #ffc233;
}

.api-restrictions-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.api-restrictions-checkbox-wrap::v-deep .v-tooltip {
  display: none;
}

.api-restrictions-item {
  position: relative;
  width: 33.33%;
  cursor: pointer;
  font-size: 12px;
}

.api-restrictions-item span {
  display: inline-block;
  overflow: hidden;
  width: calc(100% - 30px);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ip-access-radiobox-wrap,
.api-restrictions-checkbox-wrap {
  display: flex;
  position: relative;
  margin-bottom: 15px;
  cursor: pointer;
}

.ip-access-radiobox-wrap span,
.api-restrictions-checkbox-wrap span {
  margin-left: 20px;
}

.ip-access-radiobox-wrap:before,
.api-restrictions-checkbox-wrap:before {
  content: "";
  position: absolute;
  border: 1px solid #3e3f55;
}

.api-restrictions-checkbox-wrap:before {
  top: 2px;
  border-radius: 5px;
  width: 15px;
  height: 15px;
}

.api-restrictions-checkbox-wrap.active:before {
  background: url(../../assets/img/check-mark.svg) 50% 50%;
}

.ip-access input {
  display: none;
}

.ip-access label {
  padding-left: 24px;
  cursor: pointer;
  font-size: 12px;
  position: relative;
  z-index: 2;
}

.ip-access label span {
  color: #F27870;
}

.api-list-body {
  padding-bottom: 14px;
}

.api-list-body > div {
  margin-top: 30px;
}

.ip-access-radiobox-wrap:before {
  top: 2px;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  z-index: 1;
}

.ip-access-radiobox-wrap.active:before,
.api-restrictions-checkbox-wrap.active:before {
  border-color: #42e8e0;
}

.ip-access-radiobox-wrap.active:after {
  content: "";
  position: absolute;
  top: 11px;
  left: 4px;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  background: #02c9bf;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s;
  max-height: 404px;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  overflow: hidden;
  max-height: 0;
}

.buttons-wrapper button:first-child:hover {
  background-color: #67ca9d;
}

.buttons-wrapper button:nth-child(2):hover {
  background-color: #3f3f3f;
}

.copy-key:hover span {
  color: var(--v-loginButtonIconHoverColor-base);
}

.copy-key:hover svg {
  stroke: var(--v-loginButtonIconHoverColor-base);
}
</style>
