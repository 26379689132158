import { keccak256 as solidityKeccak256 } from '@ethersproject/solidity';
import { ethers, Wallet } from 'ethers';
import store from "@/store";

export async function getSignature(wallet, data, dataStructure,){
    const solidityKeccak256Hash = await solidityKeccak256(dataStructure, data);
    let errorSign = null

    const messageHash = solidityKeccak256Hash;
    let messageHashBytes = ethers.utils.arrayify(messageHash);

    return new Promise((resolve, reject) => {

        const sign = window.ethereum.request({
            method: 'personal_sign',
            params: [messageHash, wallet],
        }).then((res) => {
            const signData = [messageHash, res]
            resolve(signData);
        }).catch(err => {
            reject(err);
        });
    });
}
