import pako from "pako";
// import dayJS from "dayjs";
import { EventEmitter } from "events";
import {getFromLocalStorage, saveToLocalStorage} from "../helpers";
import customAxios from "../api/$axios";
import store from "../store";

function isOpen(ws) { return ws.readyState === ws.OPEN }

class TvWebSocket {
  url = process.env.VUE_APP_WS_URL;
  wsPrivate = null;
  success = {};
  failure = {};
  timer = null;
  closeFlag = null;
  pingPongInterval = null;
  evt = new EventEmitter();

  isConnected = false;

  initWebSocket(token, cosmosAddress) {
    if(!this.isConnected) {
      this.isConnected = true;
      let JwtToken = getFromLocalStorage('token');
      JwtToken = JwtToken.replaceAll('.', '%2E');
      this.wsPrivate = new WebSocket(`${this.url}/user?token=${JwtToken}&cosmosAddress=${cosmosAddress}`,);
      this.wsPrivate.binaryType = "arraybuffer";
      this.wsPrivate.onopen = this.onopen.bind(this);
      this.wsPrivate.onclose = this.onclose.bind(this);
      this.wsPrivate.onerror = this.onerror.bind(this);
      this.wsPrivate.onmessage = this.onmessage.bind(this);
      // console.log(" >> WebSocket init :", this.url);
    }
  }

  onopen() {
    clearInterval(this.pingPongInterval)
    if (!isOpen(this.wsPrivate)) return;
    if (!this.wsPrivate) {
      return;
    }
    // this.closeFlag = false
    // console.log(" >> WebSocket open...");
    // console.log(" >> this.timer", this.timer);
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
      for (const key in this.success) {
        this.wsPrivate.send(this.success[key]);
        // console.log(` >> WebSocket send: ${this.success[key]}`);
      }
    }
    for (const key in this.failure) {
      if (this.success[key]) {
        continue;
      }
      this.wsPrivate.send(this.failure[key]);
      this.success[key] = this.failure[key];
      // console.log(` >> WebSocket send: ${this.failure[key]}`);
    }
    this.failure = {};

    this.pingPongInterval = setInterval(() => {
      this.heartbeat()
    }, 5000)
  }

  onclose(event) {
    // console.log(" >> Websocket Close...");
    this.isConnected = false;
    clearInterval(this.pingPongInterval)
    if (event.wasClean) {
      // if(this.wsPrivate !== null) {
      //   this.close()
      // }
      this.wsPrivate.close();
      this.wsPrivate = null;
    } else {
      if (!this.timer) {
        const url = this.wsPrivate.url
        this.wsPrivate.close();
        this.wsPrivate = null
        this.onReconnection(url)
      }
    }
  }

  close() {
    // if(this.wsPrivate !== null) {
    //   this.closeFlag = true
    //   this.wsPrivate.close()
    // }
    clearInterval(this.pingPongInterval)
    this.closeFlag = true
    if(this.wsPrivate) {
      this.wsPrivate.close();
    }
  }

  onerror(event) {
    $axios.get(`/system/ping`).then((res) => {
      $axios.post(`/auth/refreshToken`,
          {
            "refreshToken": getFromLocalStorage('refreshToken')
          })
          .then((res) => {
            saveToLocalStorage('token', res.data.accessToken)
            saveToLocalStorage('refreshToken', res.data.refreshToken)

            const token = getFromLocalStorage('token');
            $axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
          }).catch((err) => {
            localStorage.removeItem('metamask')
            localStorage.removeItem('token')
            localStorage.removeItem('refreshToken')
            window.dispatchEvent(new CustomEvent('token-localstorage-remove'));
            window.location.reload()
          });
    }).catch((err) => {
      // console.log(err)
    });
    // console.log(" >> Websocket Error...", event);
  }

  onmessage(event) {
    if (!event.data) {
      return;
    }
    const data = JSON.parse(event.data);
    this.onBroadcast(data);
  }

  heartbeat() {
      if(!this.wsPrivate || this.wsPrivate.readyState !== 1) {
        return
      }
    this.wsPrivate?.send(JSON.stringify({"method": "PING","params":[]}))
  }

  onBroadcast(msg) {

    if(msg.constructor.name == "Array" && msg.length === 0) {
      return
    }

    if (msg.eventType === 'expiredSessionWarning') {
      this.wsPrivate?.send(JSON.stringify({"method": "EXTEND_SESSION","params":["MIN_5"]}))
    }

    if(msg.eventType === 'l2SpotDeposit') {
      this.evt.emit(`l2Update`, msg);
      return
    }

    if(msg.eventType === 'l2SpotTransfer') {
      this.evt.emit(`l2Update`, msg);
      return
    }

    if(msg.eventType === 'l2SpotWithdrawBatchReadyToBridge') {
      this.evt.emit(`l2Update`, msg);
      return
    }

    if(msg.eventType === 'l2SpotWithdrawSetInBatch') {
      this.evt.emit(`l2Update`, msg);
      return
    }

    if(msg.eventType === 'l2SpotWithdrawSignBatch') {
      this.evt.emit(`l2Update`, msg);
      return
    }

    if(msg.eventType === 'l2SpotWithdrawFinalize') {
      this.evt.emit(`l2Update`, msg);
      return
    }

    // if (msg.eventType.includes('l2Spot') || msg.eventType === 'l2Update' ) {
    //   // this.evt.removeListener(`getAllDataL2`);
    //   this.evt.emit(`l2Update`, msg);
    //   return
    // }

    // console.log(this.evt)

    // if (!this.success[`${msg.eventType}`]) {
    //   return;
    // }
    this.evt.emit(`getAllData`,msg);
  }

  subscribe(name, params, callback) {
    setTimeout(() => {
      let ee;
      if (!this.wsPrivate || this.wsPrivate.readyState !== WebSocket.OPEN) {
        ee = this.failurePush(name, params, callback);
      } else {
        ee = this.successPush(name, params, callback);
      }
      return {
        remove: () => {
          console.log('pppppppp')
          this.unsubscribe(name, params);
          ee.removeAllListeners(name);
        },
      };
    }, 1000)
  }

  unsubscribe(name, params) {
    if (this.failure[name]) {
      delete this.failure[name];
    }
    if (!this.success[name]) {
      return;
    }
    if (!this.wsPrivate) {
      delete this.success[name];
      return;
    }

    const unsub = params
    this.success[name] = JSON.stringify(unsub);
    this.wsPrivate?.send(JSON.stringify(params));
    // console.log('unsub', unsub)
    // unsub.method = "UNSUBSCRIBE";
    // this.ws.send(JSON.stringify(unsub));
    this.evt.removeAllListeners(name);
    // console.log(` >> WebSocket send: ${JSON.stringify(unsub)}`);
    delete this.success[name];
  }

  successPush(name, params, callback) {
    this.success[name] = JSON.stringify(params);
    this.wsPrivate?.send(this.success[name]);
    // console.log(` >> WebSocket send: ${this.success[name]}`);
    return this.evt.on(name, callback);
  }

  failurePush(name, params, callback) {
    this.failure[name] = JSON.stringify(params);
    // console.log(` >> WebSocket fail: ${this.failure[name]}`);
    // console.dir(this.evt.on(name, callback))
    return this.evt.on(name, callback);
  }

  onReconnection(url) {
    this.wsPrivate = null;
    let urlParam = new URL(url);
    let token = getFromLocalStorage('token');
    if(token) {
      token = token.replaceAll('.', '%2E');
    }
    // const cosmosAddress = JSON.parse(localStorage.getItem('cosmosAddress'))
    const cosmosAddress = store.getters["wallets/getMetamaskWallet"].authData.granterWallet.address

    this.initWebSocket(token, cosmosAddress);
    this.timer = setInterval(() => {
      this.initWebSocket(token, cosmosAddress)
      // const now = dayJS().format("YYYY-MM-DD HH:mm:ss");
      // console.log(` >> [${now}] WebSocket Reconnect....`);
    }, 10000);
  }
}

export const wsPrivate = new TvWebSocket();
