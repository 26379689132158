export default {
  SET_TRANSACTION_HISTORY(state, data) {
    state.transactionHistoryData = data;
  },
  // SET_TRANSACTION_HISTORY_item(state, item) {
  //   state.transactionHistoryData.forEach((item, i) => {
  //     if(item[0] === item.uuid)  {
  //       state.transactionHistoryData[i]
  //     }
  //   })
  //   state.transactionHistoryData = data;
  // },
  SET_TRADE_HISTORY(state, data) {
    state.tradeHistoryData = data;
  },
  SET_ORDER_HISTORY(state, data) {
    state.orderHistoryData = data;
  },
};