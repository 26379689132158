<template>
  <div class="tiers-table">
    <table>
      <caption>
        <div class="caption">
          <h6>
            {{ $t('Tiers') }}
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <svg v-bind="attrs" v-on="on" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.20868 5.19649C7.20868 4.82968 7.55615 4.51114 8.00019 4.51114C8.43455 4.51114 8.78211 4.82968 8.78211 5.19649C8.78211 5.56333 8.43458 5.89152 8.00019 5.89152C7.55618 5.89152 7.20868 5.56333 7.20868 5.19649ZM7.25686 7.23327C7.25686 6.963 7.56575 6.77955 8.00013 6.77955C8.41522 6.77955 8.75304 6.96297 8.75304 7.23322V10.911C8.75304 11.1427 8.41522 11.3743 8.00013 11.3743C7.56575 11.3743 7.25686 11.1427 7.25686 10.911V7.23327Z" fill="#92939C"/>
                  <circle cx="8" cy="8" r="7" stroke="#92939C" stroke-width="1.5"/>
                </svg>
              </template>
              <span>{{ $t('Trading fee depends on trading volume.') }}</span>
            </v-tooltip>
          </h6>
        </div>
      </caption>
      <thead class="holder-table">
      <tr>
        <th :data-label="$t('Tier')" class="tier">{{ $t('Tier') }}</th>
        <th :data-label="$t('Taker')" class="taker">{{ $t('Taker') }}</th>
        <th :data-label="$t('Maker')" class="maker">{{ $t('Maker') }}</th>
        <th :data-label="$t('Final Taker')" class="final-taker">{{ $t('Final Taker') }}</th>
        <th :data-label="$t('Vol for 30D')" class="vol-30">{{ $t('Vol for 30D') }}</th>
        <th :data-label="$t('Vol to Next Tier')" class="vol-next-tier">{{ $t('Vol to Next Tier') }}</th>
      </tr>
      </thead>
      <tbody>
      <tr
          class="table-body"
          v-for="(item, i) in feeTiersStructureList"
          :key="i"
      >
        <td :data-label="$t('Tier')" class="tier">
          {{ $t('Tier') }} {{ item.tier }}
        </td>
        <td :data-label="$t('Taker')" class="taker">
          <span>{{item.taker.toFixed(3)}}</span>
          <span class="secondary-text"> %</span>
        </td>
        <td :data-label="$t('Maker')" class="maker">
          <span class="up">{{item.maker}}</span>
          <span class="secondary-text"> %</span>
        </td>
        <td :data-label="$t('Final Taker')" class="final-taker">
          <span class="up">{{ (Math.floor( item.finalTaker * 1000) / 1000).toFixed(3) }}</span>
          <span class="secondary-text"> {{quoteCurrency}}</span>
        </td>
        <td :data-label="$t('Vol for 30D')" class="vol-30">
          <span>{{i === 0 ? '<=' : '>'}}{{prettify(item.volume30D)}}</span>
          <span class="secondary-text"> {{quoteCurrency}}</span>
        </td>
        <td :data-label="$t('Vol to Next Tier')" class="vol-next-tier">
          <span>{{i === 0 ? 0 : prettify(item.volume30D)}}</span>
          <span class="secondary-text"> {{quoteCurrency}}</span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "TiersTable",
  props: {
    feeTiersStructureList: {
      type: Array
    },
    spotUsdcAmount: {
      type: [Number, String]
    },
  },
  data() {
    return {
      quoteCurrency: 'USDT',
    }
  },
  methods: {
    prettify(num) {
      if(num === 0) return 0
      const options = {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2
      }
      num = Number(num).toLocaleString("en-US", options).replace('$', '')
      return num
    },
    prettifyModify(num) {
      if(num === 0) return 0
      num = Number(num).toLocaleString("en-US")
      return num
    },
  }
};
</script>

<style lang="scss" scoped>
.secondary-text {
  color: var(--v-textSecondaryColor-base);
}
.tiers-table {
  @media (min-width: 1400px) {
    border-right: 1px solid var(--v-mainBorderColor-base);
    width: 65.5%;
    padding-right: 20px;
  }
}

.tier {
  text-align: left;
}

.maker,
.final-taker {
  text-align: center;
}

.taker,
.vol-next-tier,
.vol-30 {
  text-align: right;
}
</style>
