<template>
  <div id="my-content">
    <Header
        @changeTheme="changeTheme"
        @windowWidth="windowWidthListener"
    />
    <main id="root">
      <div class="public-page">
        <div class="public-page-content-wrap" v-if="isDisableLiquidationAuction">
          <ComingSoon />
        </div>
        <div class="public-page-content-wrap" v-else>
          <vue-custom-scrollbar
              class="scroll-area"
              :settings="scrollSettings"
          >
            <LotsSection
                class="lots-section"
                :lots="lots"
                :token="token"
                @placeBid="placeBid"
            />
          </vue-custom-scrollbar>


          <HistorySection
            :history="history"
            :is-loading="isLoading"
            :is-data-empty="isDataEmpty"
            :count="count"
            :page-size="pageSize"
            :page="page"
            @changeSelectFilter="changeSelectFilter"
            @resetPagination="resetPagination"
          />
        </div>
      </div>
    </main>
    <PlaceBid
      ref="placeBid"
      :maxToBuy="maxToBuy"
      :maxToSell="maxToSell"
      :lots="lots"
      @updateMaxBuy="getOrderMaxQty($event)"
      @onConfirm="placeBidAction"
    />
  </div>
</template>

<script>
import {ws} from "@/utils/socket";
import {wsPrivate} from "@/utils/socketPrivate";
import Header from '@/components/header/Header';
import LotsSection from '@/components/liquidation-auction/LotsSection';
import HistorySection from '@/components/liquidation-auction/HistorySection';
import PlaceBid from '@/components/popups/PlaceBid';
import ComingSoon from "../components/coming-soon/ComingSoon";
import {mapActions} from 'vuex'
import moment from "moment";
import {useReactiveMap} from "vue-reactive-collection";
import ToasterSuccess from "@/components/toasters/ToasterSuccess.vue";
import ToasterError from "@/components/toasters/ToasterError.vue";

export default {
  name: 'LiquidationAuction',
  components: {
    PlaceBid,
    HistorySection,
    LotsSection,
    ComingSoon,
    Header,
  },
  data() {
    return {
      isDisableLiquidationAuction: JSON.parse(process.env.VUE_APP_IS_DISABLE_LIQUIDATION_AUCTION),
      token: null,
      desktop: true,
      tablet: false,
      mobile: false,
      LIMIT_SAFETY_COEFF: 0.99,
      MARKET_SAFETY_COEFF: 0.85,
      accountInfo: null,
      marginMode: 'CROSS',
      positionList: [],
      tradingRules: [],
      tradingRulesMaxBuy: {},
      maxToSell: 0,
      maxToBuy: 0,
      maxToSellPresentation: 0,
      maxToBuyPresentation: 0,
      scrollSettings: {
        suppressScrollY: false,
        suppressScrollX: false,
        wheelPropagation: true,
        swipeEasing: false
      },
      symbolsMap:  [],
      tradingTableInfoPositions:  [],
      symbolСonformityList: [
        {
          name: 'btc_usdt',
          icon: require('../assets/img/coin/btc.svg'),
          sumSizeForOpenBuyOrders: 0,
          sumSizeForOpenSellOrders: 0,
          lockedForOpenBuyOrders: 0,
          lockedForOpenSellOrders: 0,
          lockedBalance: 0,
          amount: 0,
          marginMode: 'CROSS',
          markPrice: '',
        },
        {
          name: 'eth_usdt',
          icon: require('../assets/img/coin/eth.svg'),
          sumSizeForOpenBuyOrders: 0,
          sumSizeForOpenSellOrders: 0,
          lockedForOpenBuyOrders: 0,
          lockedForOpenSellOrders: 0,
          lockedBalance: 0,
          amount: 0,
          marginMode: 'CROSS',
          markPrice: '',
        },
        {
          name: 'sol_usdt',
          icon: require('../assets/img/coin/sol.svg'),
          sumSizeForOpenBuyOrders: 0,
          sumSizeForOpenSellOrders: 0,
          lockedForOpenBuyOrders: 0,
          lockedForOpenSellOrders: 0,
          lockedBalance: 0,
          amount: 0,
          marginMode: 'CROSS',
          markPrice: '',
        },
        {
          name: 'ada_usdt',
          icon: require('../assets/img/coin/ada.svg'),
          sumSizeForOpenBuyOrders: 0,
          sumSizeForOpenSellOrders: 0,
          lockedForOpenBuyOrders: 0,
          lockedForOpenSellOrders: 0,
          lockedBalance: 0,
          amount: 0,
          marginMode: 'CROSS',
          markPrice: '',
        },
        {
          name: 'near_usdt',
          icon: require('../assets/img/coin/near.svg'),
          sumSizeForOpenBuyOrders: 0,
          sumSizeForOpenSellOrders: 0,
          lockedForOpenBuyOrders: 0,
          lockedForOpenSellOrders: 0,
          lockedBalance: 0,
          amount: 0,
          marginMode: 'CROSS',
          markPrice: '',
        },
        {
          name: 'ape_usdt',
          icon: require('../assets/img/coin/ape.svg'),
          sumSizeForOpenBuyOrders: 0,
          sumSizeForOpenSellOrders: 0,
          lockedForOpenBuyOrders: 0,
          lockedForOpenSellOrders: 0,
          lockedBalance: 0,
          amount: 0,
          marginMode: 'CROSS',
          markPrice: '',
        },
        {
          name: 'atom_usdt',
          icon: require('../assets/img/coin/atom.svg'),
          sumSizeForOpenBuyOrders: 0,
          sumSizeForOpenSellOrders: 0,
          lockedForOpenBuyOrders: 0,
          lockedForOpenSellOrders: 0,
          lockedBalance: 0,
          amount: 0,
          marginMode: 'CROSS',
          markPrice: '',
        },
        {
          name: 'avax_usdt',
          icon: require('../assets/img/coin/avax.svg'),
          sumSizeForOpenBuyOrders: 0,
          sumSizeForOpenSellOrders: 0,
          lockedForOpenBuyOrders: 0,
          lockedForOpenSellOrders: 0,
          lockedBalance: 0,
          amount: 0,
          marginMode: 'CROSS',
          markPrice: '',
        },
        {
          name: 'dot_usdt',
          icon: require('../assets/img/coin/dot.svg'),
          sumSizeForOpenBuyOrders: 0,
          sumSizeForOpenSellOrders: 0,
          lockedForOpenBuyOrders: 0,
          lockedForOpenSellOrders: 0,
          lockedBalance: 0,
          amount: 0,
          marginMode: 'CROSS',
          markPrice: '',
        },
        {
          name: 'gmt_usdt',
          icon: require('../assets/img/coin/gmt.svg'),
          sumSizeForOpenBuyOrders: 0,
          sumSizeForOpenSellOrders: 0,
          lockedForOpenBuyOrders: 0,
          lockedForOpenSellOrders: 0,
          lockedBalance: 0,
          amount: 0,
          marginMode: 'CROSS',
          markPrice: '',
        },
        {
          name: 'matic_usdt',
          icon: require('../assets/img/coin/matic.svg'),
          sumSizeForOpenBuyOrders: 0,
          sumSizeForOpenSellOrders: 0,
          lockedForOpenBuyOrders: 0,
          lockedForOpenSellOrders: 0,
          lockedBalance: 0,
          amount: 0,
          marginMode: 'CROSS',
          markPrice: '',
        },
      ],
      lots: [],
      history: [],
      page: 1,
      count: null,
      pageSize: 20,
      isLoading: true,
      isDataEmpty: false,
    };
  },
  watch: {
    '$route.query.page': {
      deep: true,
      handler(newVal) {
        if(newVal) {
          this.page = newVal;
        } else {
          this.page = 1;
        }
      },
    },
    token: {
      deep: true,
      handler(newValue, oldValue) {
        if(newValue) {
          this.getAccountInfoData()
          setTimeout(() => {
            const cosmosAddress = JSON.parse(localStorage.getItem('cosmosAddress'))
            if(cosmosAddress) {
              if(wsPrivate.wsPrivate === null ||  wsPrivate.wsPrivate !== null && wsPrivate.wsPrivate.readyState === 3) {
                this.getUserData()
              }
            }
          }, 1000)
        }else {
          wsPrivate.close()
          localStorage.removeItem('metamask')
          localStorage.removeItem('cosmosAddress')
        }
      }
    },
  },
  methods: {
    ...mapActions({
      getAccountInfo: 'orders/getAccountInfo',
      getPositionNotional: 'markets/getPositionNotional',
      getTradinRules: 'markets/getTradinRules',
      getLiquidationAuctionOpen: 'liquidationAuction/getLiquidationAuctionOpen',
      getLiquidationAuctionHistory: 'liquidationAuction/getLiquidationAuctionHistory',
      liquidationAuctionPlace: 'liquidationAuction/liquidationAuctionPlace',
    }),
    changeTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem(
          "theme",
          this.$vuetify.theme.dark ? 'dark' : 'light'
      );
    },
    getRequestParams(formData) {
      const params = {};

      for (const data in formData) {
        if(formData[data]) {
          params[data] = formData[data];
        }
      }

      return params;
    },
    windowWidthListener(e) {
      if (e < 1025) {
        this.desktop = false;
        this.tablet = true;
        this.mobile = false;
        // return
      } else {
        this.desktop = true;
        this.tablet = false;
        this.mobile = false;
      }
    },
    chooseValueType(type) {
      this.type = type;
    },
    changeSelectFilter(data) {
      this.resetPagination()
      this.getLiquidationAuctionHistoryAction(data);
    },
    prettifyNumber(num) {
      if(num === 0) return 0
      const options = {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2
      }
      num = Number(num).toLocaleString("en-US", options).replace('$', '')
      return num
    },
    aroundNumber(num) {
      if(num === undefined || num === null) {
        return 0
      }
      if(num === 0) {
        return num
      }else {
        // return num ? parseFloat(Number(num).toFixed(2)) : null
        if(+num > 0) {
          return Math.trunc(num * 100) / 100;
        }else {
          return Math.ceil(num * 100) / 100;
        }
      }
    },
    setPositionNotional() {
      this.symbolsMap.value.forEach((symbolItem) => {
        let rule = this.tradingRules.filter(rule => symbolItem.leverage === rule.leverage)[0]
        if(rule !== undefined) {
          this.symbolsMap.value.set(symbolItem.symbol, {
            ...this.symbolsMap.value.get(symbolItem.symbol),
            maxPositionNotionalValue: rule.positionNotional,
            positionNotional: rule.positionNotional,
            initialMarginPercent: rule.initialMargin,
            maintenanceMargin: rule.maintenanceMargin,
            maintenanceMarginPercent: rule.maintenanceMargin
          })
        }
      })
      this.tradingTableInfoPositions.value.forEach((positionItem, i) => {
        let rule = this.tradingRules.filter(rule => positionItem.leverage === rule.leverage)[0]
        if(rule !== undefined) {
          this.tradingTableInfoPositions.value.set(positionItem.symbol, {
            ...this.tradingTableInfoPositions.value.get(positionItem.symbol),
            maxPositionNotionalValue: rule.positionNotional,
            initialMarginPercent: rule.initialMargin,
            maintenanceMargin: rule.maintenanceMargin,
            maintenanceMarginPercent: rule.maintenanceMargin,
            positionNotionalValue: Math.abs(positionItem.amount) * positionItem.basePrice,
            maintenanceMarginCurrency: (Math.abs(positionItem.amount) * positionItem.basePrice) * rule.maintenanceMargin,
            uplCurrency: (positionItem.markPrice - positionItem.basePrice) * positionItem.amount
          })
        }
      })
    },

    getPositionNotionalAction() {
      this.getPositionNotional().then((data) => {
        this.tradingRules = data
        this.setPositionNotional();
      })
    },

    getAccountInfoData() {
      this.getAccountInfo().then((data) => {
        this.accountInfo = data
        const usdtCoin = this.accountInfo.assets.filter((c) => c.name === 'usdt')[0]
        const dxlnCoin = this.accountInfo.assets.filter((c) => c.name === 'dxln')[0]

        this.accountInfo['margin'] = usdtCoin !== undefined ? usdtCoin.deposited - usdtCoin.locked : 0
        this.accountInfo['deposited'] = usdtCoin !== undefined ? usdtCoin.deposited : 0
        this.accountInfo['locked'] = usdtCoin !== undefined ? usdtCoin.locked : 0
        this.deposited = usdtCoin !== undefined ? usdtCoin.deposited : 0
        this.typeFee = this.accountInfo.tradeFeeAsset

        this.$store.commit("wallets/UPDATE_FEATURES_BALANCE", this.accountInfo.margin)

        if(data.positions.length) {
          data.positions.forEach(position => {

            this.symbolsMap.value.set(position.symbol, {
              ...this.symbolsMap.value.get(position.symbol),
              lockedBalance: position.lockedBalance,
              basePrice: position.basePrice,
              markPrice: position.markPrice,
              leverage: position.leverage,
              amount: position.amount !== undefined ? position.amount : 0,
              marginMode: position.marginMode,
            })

            this.tradingTableInfoPositions.value.set(position.symbol, {...position})

            if(position.marginMode === "ISOLATED") {
              this.symbolsMap.value.set(position.symbol, {
                ...this.symbolsMap.value.get(position.symbol),
                leverage: position.leverage,
              })
              this.tradingTableInfoPositions.value.set(position.symbol, {
                ...position,
                finalPositionLocked: position.lockedBalance
              })
            }
          })
        }

        if (this.accountInfo.orders.length) {
          this.accountInfo.orders.forEach((order) => {

            this.symbolsMap.value.set(order.symbol, {
              ...this.symbolsMap.value.get(order.symbol),
              lockedForOpenBuyOrders: order.lockedBid,
              lockedForOpenSellOrders: order.lockedAsk,
              sumSizeForOpenBuyOrders: order.sumSizeBid,
              sumSizeForOpenSellOrders: order.sumSizeAsk
            })
          })
        }

        this.getPositionNotionalAction();
        this.getTradinRulesAction();
      });
    },
    unsubscribeWsPrivateData() {
      ws.unsubscribe(
          `accountUpdate`,
          {
            method: "UNSUBSCRIBE",
            params: [`accountUpdate`]
          },
      );
    },
    unsubscribeWsPublicData(symbolName) {
      ws.unsubscribe(
          ['liquidationAuction'],
          {
            method: "UNSUBSCRIBE",
            params: ['liquidationAuction']
          },(res) => {}
      );
      ws.unsubscribe(
          [`!markPrice_arr_1s`],
          {
            method: "UNSUBSCRIBE",
            params: [`!markPrice_arr_1s`]
          },(res) => {}
      );
    },
    getUserData() {
      wsPrivate.subscribe(
          `getAllData`,
          {
            method: "SUBSCRIBE",
            params: [`accountUpdate`, `orderUpdate`]
          },
          (res) => {
            if (res.eventType === 'accountUpdate') {
              // console.log('accountUpdate', res)
              // if (res.reasonType === 'UPDATE_TRADE_FEE_ASSET') {
              //   this.typeFee = res.tradeFeeAsset
              // }
            }
          }
      );
    },
    wsPublic() {
      ws.subscribe(
          'AllPublicData',
          {
            method: "SUBSCRIBE",
            params: ['liquidationAuction', `!markPrice_arr_1s`]
          },
          (res) => {
            if(res.eventType === 'liquidationAuction') {

              if(res.status === 'NEW') {
                let currentLot = this.history.value.has(res.lotId)
                if(!currentLot) {
                  return;
                }
                res.image = this.symbolsMap.value.get(res.symbol).icon;

                const fetchedItemsArray = [...this.lots.value.entries()]

                this.lots = useReactiveMap(new Map([
                  [res.lotId, {...res}], ...fetchedItemsArray
                ]))

                this.$store.commit('liquidationAuction/UPDATE_LIQUIDATION_AUCTION_DATA', this.lots)

                this.isDataEmpty = false;

                return
              }
              if(res.status === 'NEXT_STEP') {
                let currentLot = null
                if(this.lots.value.size) {
                  currentLot = this.lots.value.has(res.lotId)
                }

                if(!currentLot) {
                  return
                }

                this.lots.value.set(res.lotId, {
                  ...this.lots.value.get(res.lotId),
                  currentBidPrice: res.currentBidPrice,
                  nextStepTime: res.nextStepTime,
                  notionalValue: res.notionalValue
                })

                this.$store.commit('liquidationAuction/UPDATE_LIQUIDATION_AUCTION_DATA', this.lots)
                return
              }

              if(res.status === 'MATCH' && res.amount > res.filledAmount) {

                this.lots.value.set(res.lotId, {
                  ...this.lots.value.get(res.lotId),
                  amount: res.amount,
                  filledAmount: res.filledAmount,
                  totalBids: res.totalBids
                })

                this.$store.commit('liquidationAuction/UPDATE_LIQUIDATION_AUCTION_DATA', this.lots)
                return
              }

              if(res.status === 'MATCH') {
                // eslint-disable-next-line

                if(this.lots.value && this.lots.value.has(res.lotId)) {
                  const getLastKeyInMap = map => Array.from(map)[map.size - 1][0]
                  this.lots.value.delete(res.lotId)

                  if (this.history.value.size === 20) {
                    this.history.value.delete(getLastKeyInMap(this.history.value))
                  }

                  const fetchedItemsArray = [...this.history.value.entries()]

                  this.history = useReactiveMap(new Map([
                    [res.orderId,
                      {
                        ...res,
                        image: this.symbolsMap.value.get(res.symbol).icon,
                        lotId: res.lotId,
                        symbol: res.symbol,
                        side: res.side,
                        amount: res.amount,
                        totalBids: res.totalBids,
                        averageLiquidationPrice: res.averagePrice,
                        marginCallPrice: res.marginCallPrice,
                        discount: res.side === 'BUY' ? (res.marginCallPrice / res.averagePrice) - 1 : 1 - (res.marginCallPrice / res.averagePrice),
                        updatedAt: res.updatedAt
                      }
                    ],
                    ...fetchedItemsArray
                  ]))

                  this.$store.commit('liquidationAuction/UPDATE_LIQUIDATION_AUCTION_DATA', this.history)
                }
                this.$store.commit('liquidationAuction/UPDATE_LIQUIDATION_AUCTION_DATA', this.lots)

                return
              }

            }
            if (res.constructor.name == "Array") {
              if(res.length && res[0].eventType === 'markPrice') {

                res.forEach(item => {
                  this.lots.value.forEach(lot => {
                    if(lot.symbol === item.symbol) {
                      this.lots.value.set(lot.lotId, {
                        ...this.lots.value.get(lot.lotId),
                        markPrice: item.markPrice,
                        indexPrice: item.indexPrice,
                      })
                    }
                  })
                })

                this.$store.commit('liquidationAuction/UPDATE_LIQUIDATION_AUCTION_DATA', this.lots)
              }
            }
          }
      );
    },
    getTradinRulesAction() {
      this.getTradinRules().then((res) => {
        this.tradingRulesMaxBuy = res;
      })
    },
    getLiquidationAuctionOpenAction() {
      this.getLiquidationAuctionOpen().then(res => {
        const arr = res
        if(arr.length) {
          arr.map(lot => {

            this.lots.value.set(lot.lotId, {
              ...lot,
              image: this.symbolsMap.value.get(lot.symbol).icon,
              // currentBidPrice: this.symbolsMap.value.get(lot.symbol).bidPrice,
              currentBidPrice: lot.bidPrice,
            })

            // let el = this.symbolСonformityList.filter(item => item.name === lot.symbol)[0]
            // lot.image = el.icon
            // lot.currentBidPrice = el.bidPrice
            // return lot
          })
        }
        // this.lots = arr
      })
    },
    getLiquidationAuctionHistoryAction(data) {
      this.isLoading = true;
      this.isDataEmpty = false;

      const params = this.getRequestParams(data)
      params.size = this.pageSize
      params.page = this.page - 1

      this.history = useReactiveMap(new Map());

      this.getLiquidationAuctionHistory({params}).then(res => {

        const arr = res.content
        if(arr.length) {
          arr.map(lot => {

            this.history.value.set(lot.lotId, {
              ...lot,
              image: this.symbolsMap.value.get(lot.symbol).icon,
            })
          })
        }
        this.count = res.totalPages
        this.isLoading = false;

        if(!this.history.value.size) {
          this.isDataEmpty = true;
        }
      })
    },
    placeBidAction(data) {
      this.liquidationAuctionPlace(data).then(() => {
        this.showToasterSuccess(
            this.$t('Bid Is Placed And Executed'),
            this.$t('Liquidation auction’s lot has been successfully execueted via your bid.'),
        );
      }).catch((err) => {
        if(err && err.response) {
          this.showToasterError(
              this.$t('Bid Is Rejected'),
              this.$t(`${err.response.data.details[0]}`),
          );
        }
      })
    },

    maxSell(margin, price, positionNotional, minAvailableMargin, positionQty, sumSizeForOpenSellOrders, leverageMargin, ratio, basePrice, lockedForOpenSellOrders) {

      let longPositionDelta = null
      let maxSellMargin = null

      if(positionQty >= 0) {
        longPositionDelta = Math.abs(positionQty) - sumSizeForOpenSellOrders
      }else {
        longPositionDelta = 0
      }

      // =IF(D3>=0;D45/D54+IF(D43>0;D43;0);D45/D54)

      if(positionQty >= 0) {
        if(longPositionDelta > 0) {
          maxSellMargin = ratio + longPositionDelta;
        }else {
          maxSellMargin = ratio;
        }
      }else {
        maxSellMargin = minAvailableMargin / +price
      }

      let maxSellNotionalAmount = null
      let maxSellNotionalTemp = null

      if(positionQty > 0) {
        maxSellNotionalTemp = positionNotional + (positionQty * basePrice)
      }else {
        maxSellNotionalTemp = positionNotional
      }

      if(price !== 0 ) {
        maxSellNotionalAmount = (maxSellNotionalTemp - lockedForOpenSellOrders) / price
      }else {
        maxSellNotionalAmount = 0
      }

      let maxSellNotionalFinal = null

      if(longPositionDelta > 0) {
        maxSellNotionalFinal = Math.max(maxSellNotionalAmount, longPositionDelta)
      }else {
        maxSellNotionalFinal = maxSellNotionalAmount
      }
      return Math.min(maxSellNotionalFinal, maxSellMargin)

    },

    maxBuy(margin, price, positionNotional, minAvailableMargin, positionQty, sumSizeForOpenBuyOrders, leverageMargin, ratio, basePrice, lockedForOpenBuyOrders) {

      let shortPositionDelta = null
      let maxBuyMargin = null

      if(positionQty <= 0) {
        shortPositionDelta = Math.abs(positionQty) - sumSizeForOpenBuyOrders
      }else {
        shortPositionDelta = 0
      }

      // =IF(D3<=0;D42/D51+IF(D41>0;D41;0);D42/D51)
      // =IF(C3<=0;C45/C54+IF(C44>0;C44;0);C45/C54)

      if(positionQty <= 0) {
        if(shortPositionDelta > 0) {
          maxBuyMargin = ratio + shortPositionDelta
        } else {
          maxBuyMargin = ratio;
        }
      }else {
        maxBuyMargin = minAvailableMargin / price
      }

      let maxBuyNotionalAmount = null
      let maxBuyNotionalTemp = null

      if(positionQty < 0) {
        maxBuyNotionalTemp = positionNotional - (positionQty * basePrice)
      }else {
        maxBuyNotionalTemp = positionNotional
      }

      maxBuyNotionalAmount = (maxBuyNotionalTemp - lockedForOpenBuyOrders) / price

      let maxBuyNotionalFinal = null

      if(positionQty > 0) {
        maxBuyNotionalFinal = Math.max(maxBuyNotionalAmount, shortPositionDelta)
      }else {
        maxBuyNotionalFinal = maxBuyNotionalAmount
      }

      return Math.min(maxBuyNotionalFinal, maxBuyMargin)

    },

    getOrderMaxQty(lot) {

      if(lot === null) {
        return;
      }

      this.currentSymbol = {...this.symbolsMap.value.get(lot.symbol), ...this.lots.value.get(lot.lotId)}

      let positionQty = 0
      let basePrice = 0
      const margin = this.accountInfo.margin
      const {lockedForOpenBuyOrders, lockedForOpenSellOrders, sumSizeForOpenBuyOrders, sumSizeForOpenSellOrders, leverage, markPrice} = this.currentSymbol;
      const positionNotional = this.currentSymbol.positionNotional !== undefined ? +this.currentSymbol.positionNotional : 0;

      if (margin === undefined || leverage === undefined || markPrice === undefined) {
        return
      }

      if(this.tradingTableInfoPositions.value.has(this.currentSymbol.symbol)) {
        positionQty = this.tradingTableInfoPositions.value.get(this.currentSymbol.symbol).amount;
        basePrice = this.tradingTableInfoPositions.value.get(this.currentSymbol.symbol).basePrice;
      }
      // else {
      //   positionQty = 0;
      //   basePrice = 0;
      // }

      const leverageMargin = leverage * margin
      const minAvailableMargin = Math.min(leverageMargin, positionNotional)
      let price = lot.currentBidPrice
      let ratio = null
      if(price === 0) {
        ratio = 0
      }else {
        ratio = minAvailableMargin / +price
      }

      let availableBuyNotional = null
      let availableSellNotional = null

      if(price === 0) {
        this.maxToSell = 0
        this.maxToBuy = 0
      }
      else {

        let availableBuyNotional = this.maxBuy(margin, price, positionNotional, minAvailableMargin, positionQty, sumSizeForOpenBuyOrders, leverageMargin, ratio, basePrice, lockedForOpenBuyOrders)
        let availableSellNotional = this.maxSell(margin, price, positionNotional, minAvailableMargin, positionQty, sumSizeForOpenSellOrders, leverageMargin, ratio, basePrice, lockedForOpenSellOrders)

        this.maxToSell = this.aroundNumber(availableSellNotional * this.LIMIT_SAFETY_COEFF) || 0
        this.maxToBuy = this.aroundNumber(availableBuyNotional * this.LIMIT_SAFETY_COEFF) || 0

        const limit = this.tradingRulesMaxBuy.maxMarketNotionalOrder / markPrice
        if (this.maxToBuy > limit) {
          this.maxToBuy = this.aroundNumber(limit)
        }

        if (this.maxToSell > limit) {
          this.maxToSell = this.aroundNumber(limit)
        }
      }

      this.maxToBuyPresentation = this.prettifyNumber(String(this.maxToBuy))
      this.maxToSellPresentation = this.prettifyNumber(String(this.maxToSell))
    },

    placeBid(lot) {
      this.getOrderMaxQty(lot)

      this.$refs.placeBid.openPopups(lot);
    },

    showToasterSuccess(title, description) {
      this.$toast.success({
        component: ToasterSuccess,
        props:  {
          title,
          description,
        },
      });
    },

    showToasterError(title, description) {
      this.$toast.error({
        component: ToasterError,
        props:  {
          title,
          description,
        },
      });
    },

    resetPagination() {
      if(+this.$route.query.page !== 1) {
        this.$router.push({path: '/liquidation-auction', query: { page: 1 }}).catch(() => {});
      }
    },
  },
  created() {
    this.tradingTableInfoPositions = useReactiveMap(new Map());
    this.lots = useReactiveMap(new Map());
    this.history = useReactiveMap(new Map());

    const subProperty = {
      sumSizeForOpenBuyOrders: 0,
      sumSizeForOpenSellOrders: 0,
      lockedForOpenBuyOrders: 0,
      lockedForOpenSellOrders: 0,
      initialMargin: 0,
      maintenanceMargin: 0,
      positionNotional: 0,
      lockedBalance: 0,
      amount: 0,
      currentBidPrice: 0,
      marginMode: 'CROSS',
      leverage: 5,
      markPrice: 0,
      change: 0,
      changePercentage : null,
      price24Percentage: 0,
      premiumIndex: 0,
      quoteVolume: 0,
      highPrice: 0,
      lowPrice: 0,
      indexPrice: 0,
      symbolVolume: 0,
      fundingRate: 0,
      baseVolume: 0,
      lastPrice: 0,
      leftSecondsToFunding: "",
      isFavourite: false,
    }
    this.symbolsMap = useReactiveMap(new Map([
      ['btc_usdt', {
        symbol: 'btc_usdt',
        fullName: 'bitcoin',
        symbolName: "BTC/USDT",
        baseCurrency: "BTC",
        quoteCurrency: "USDT",
        owner: 'BTC',
        icon: require('../assets/img/coin/btc.svg'),
        description: 'btc description',
        ...subProperty,
      }],
      ['eth_usdt',  {
        symbol: 'eth_usdt',
        fullName: 'ethereum',
        symbolName: "ETH/USDT",
        baseCurrency: "ETH",
        quoteCurrency: "USDT",
        owner: 'ETH',
        icon: require('../assets/img/coin/eth.svg'),
        description: 'ethereum description',
        ...subProperty,
      }],
      ['sol_usdt', {
        symbol: 'sol_usdt',
        fullName: 'solana',
        symbolName: "SOL/USDT",
        baseCurrency: "SOL",
        quoteCurrency: "USDT",
        owner: 'SOL',
        icon: require('../assets/img/coin/sol.svg'),
        description: 'solana description',
        ...subProperty,
      }],
      ['ada_usdt',{
        symbol: 'ada_usdt',
        fullName: 'Cardano',
        symbolName: "ADA/USDT",
        baseCurrency: "ADA",
        quoteCurrency: "USDT",
        owner: 'ADA',
        icon: require('../assets/img/coin/ada.svg'),
        description: 'cardana description',
        ...subProperty,
      }],
      ['near_usdt', {
        symbol: 'near_usdt',
        fullName: 'NEAR Protocol',
        symbolName: "NEAR/USDT",
        baseCurrency: "NEAR",
        quoteCurrency: "USDT",
        owner: 'NEAR',
        icon: require('../assets/img/coin/near.svg'),
        description: 'near description',
        ...subProperty,
      }],
      ['ape_usdt', {
        symbol: 'ape_usdt',
        fullName: 'ApeCoin',
        symbolName: "APE/USDT",
        baseCurrency: "APE",
        quoteCurrency: "USDT",
        owner: 'APE',
        icon: require('../assets/img/coin/ape.svg'),
        description: 'ape description',
        ...subProperty,
      }],
      ['atom_usdt', {
        symbol: 'atom_usdt',
        fullName: 'Cosmos',
        symbolName: "ATOM/USDT",
        baseCurrency: "ATOM",
        quoteCurrency: "USDT",
        owner: 'ATOM',
        icon: require('../assets/img/coin/atom.svg'),
        description: 'atom description',
        ...subProperty,
      },],
      ['avax_usdt', {
        symbol: 'avax_usdt',
        fullName: 'Avalanche',
        symbolName: "AVAX/USDT",
        baseCurrency: "AVAX",
        quoteCurrency: "USDT",
        owner: 'AVAX',
        icon: require('../assets/img/coin/avax.svg'),
        description: 'avax description',
        ...subProperty,
      }],
      ['dot_usdt', {
        symbol: 'dot_usdt',
        fullName: 'Polkadot',
        symbolName: "DOT/USDT",
        baseCurrency: "DOT",
        quoteCurrency: "USDT",
        owner: 'DOT',
        icon: require('../assets/img/coin/dot.svg'),
        description: 'dot description',
        ...subProperty,
      }],
      ['gmt_usdt', {
        symbol: 'gmt_usdt',
        fullName: 'STEPN',
        symbolName: "GTM/USDT",
        baseCurrency: "GTM",
        quoteCurrency: "USDT",
        owner: 'GMT',
        icon: require('../assets/img/coin/gmt.svg'),
        description: 'gmt description',
        ...subProperty,
      }],
      ['matic_usdt', {
        symbol: 'matic_usdt',
        fullName: 'Polygon',
        symbolName: "MATIC/USDT",
        baseCurrency: "MATIC",
        quoteCurrency: "USDT",
        owner: 'MATIC',
        icon: require('../assets/img/coin/matic.svg'),
        description: 'matic description',
        ...subProperty,
      }],
    ]));
  },
  mounted() {

    window.addEventListener('token-localstorage-changed', (event) => {
      this.token = event.detail.token;
    });

    if (localStorage.getItem('token') !== null) {
      this.token = localStorage.getItem('token')
    }

    window.addEventListener('token-localstorage-remove', () => {
      this.token = null
    });

    this.page = +this.$route.query.page || 1;

    if(ws.ws === null ||  ws.ws !== null && ws.ws.readyState === 3) {
      setTimeout(() => {
        ws.initWebSocket();
        this.wsPublic()
      }, 200)
    }else {
      this.wsPublic()
    }

    this.getLiquidationAuctionOpenAction()
    this.getLiquidationAuctionHistoryAction()
  },
  destroyed() {
    ws.unsubscribe(
        'AllPublicData',
        {
          method: "UNSUBSCRIBE",
          params: ['liquidationAuction', `!markPrice_arr_1s`]
        },
    );

    // ws.close()


    // if (this.token) {
    //   wsPrivate.close()
    // }
  }
};
</script>

<style lang="scss" scoped>
.public-page-content-wrap {
  width: 100%;
  padding: 20px;
}

section::v-deep {
  table {
    width: 100%;
  }

  .section-title {
    margin-bottom: 20px;
    color: var(--v-selectColor-base);
  }

  p {
    margin: 0;
  }

  table {
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    color: var(--v-tableSecondaryColor-base);
  }

  th {
    padding: 10px 0;
    text-align: left;
    width: unset;
  }

  &.lots-section tbody tr {
    border-style: solid;
    border-color: #1e1e1e;
    border-top-width: 1px;
    border-right-width: 0;
    border-bottom-width: 1px;
    border-left-width: 0;

    @media (max-width: 900px) {
      border: 1px solid var(--v-primaryColor-base);
      padding-left: 10px;
    }
  }

  .holder-not-logged-text {
    padding: 20px 0px;
  }

  tbody {
    &::before {
      content: unset;
    }
  }

  .holder-not-logged-text {
    text-align: center;
  }


  .table-body td:before {
    color: var(--v-textSecondary-color);
  }
}

</style>
