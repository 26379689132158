<template>
  <div class="public-page-content">
    <div class="public-page-header">
      <h1>What is Dexilon?</h1>
      <span>Last updated 27 May, 2021</span>
    </div>
    <p>Dexilon is a digital asset trading platform offering state-of-the-art services for digital currency traders and global liquidity providers.</p>
    <p>Founded in 2012, Bitfinex was one of the first professional platforms set up to accommodate for the booming interest in cryptocurrency trading. Since then, the team has gained invaluable experience whilst cementing their spot as the go-to platform for digital asset traders and institutions.</p>
    <p>In addition to a suite of advanced trading features and charting tools, Bitfinex provides access to Exchange Trading, Margin Trading & Funding (P2P Financing) Over the Counter (OTC) and Derivatives* for a wide selection of digital assets.</p>
    <p>Bitfinex’s strategy focuses on providing unparalleled support, tools, and innovation for professional traders and liquidity providers around the world.</p>
  </div>
</template>

<script>

export default {
  name: "WhatIsDexilon",
  methods: {
    changeTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem(
          "theme",
          this.$vuetify.theme.dark ? "dark" : "light"
      );
    },
  },
}
</script>

<style scoped>

</style>