<template>
  <div class="main_info_sell">
    <div class="price_butsell">
      <div>{{ $t('amount') }}</div>
    </div>
    <input :id="inputId" name="price"
           class="sellBuyInput sellInputPriceLimit js-pending-input"
           :type="type || 'number'"
           autocomplete="off"
           :value="inputValue"
           @focus="focus"
           @blur="blur"
           @input="change"
           :readonly="inputReadOnly"
    />
    <span>{{ quoteCurrency }}</span>
    <span class="input-value-percent" v-if="sliderValue">%</span>
  </div>
</template>

<script>
export default {
  emits:['update:modelValue'],
  name: "TradingInputPricePopup",
  props: {
    quoteCurrency: String,
    modelValue: [String, Number],
    inputReadOnly: Boolean,
    inputId: String,
    type: String,
    sliderValue: Number,
  },
  data() {
    return {
      inputValue: "",
    }
  },
  watch: {
    modelValue(val) {
      this.inputValue = val;
    }
  },
  methods: {
    focus() {
      this.$emit('focus');
      this.inputValue = +this.inputValue === 0 ? '' : this.inputValue
    },
    blur() {
      this.inputValue = this.inputValue == '' ? 0 : this.inputValue
    },
    change(event) {
     let inputValue = Number(event.target.value)
     this.$emit('update:modelValue', inputValue)
    }
  },
  mounted() {
    this.inputValue = this.modelValue;
  }
}
</script>

<style scoped>

.v-dialog .sellBuyInput {
  max-width: 100%;
}

.sellBuyInput {
  padding: 0 80px;
}

.input-value-percent {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  color: #A4A5AF;
  display: block;
  position: absolute;
  top: 50%;
  right: 68px !important;
  transform: translateY(-50%);
}

.v-dialog .sellBuyInput:read-only:focus,
.v-dialog .sellBuyInput:read-only:hover {
  background: var(--v-marketHeaderButton-base) !important;
  color: var(--v-revertPrimary-base) !important;
  border: 1px solid transparent;
  border-color: transparent !important;
  cursor: not-allowed;
}

</style>
