<template>
  <div id="my-content">
    <Header
        @changeTheme="changeTheme"
        @windowWidth="windowWidthListener"
    />
    <main id="root">
      <div class="public-page">
        <div class="public-page-sidebar" v-if="desktop">
          <PagesSidebar
              :itemList="sidebarItemList"
              :title="$t('Info')"
              :icon="require('@/assets/img/info-icon.svg')"
          />
        </div>
        <div class="public-page-content-wrap">
          <div class="sidebarBurger" @click="$refs.TabletVersionOfSidebar.openSidebar()" v-if="tablet">
            <span class="burger-lines"></span>
          </div>
          <TabletVersionOfSidebar ref="TabletVersionOfSidebar" :itemList="sidebarItemList" v-if="tablet"/>
          <router-view/>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import Header from "../components/header/Header";
import PagesSidebar from "../components/layout/PagesSidebar";
import TabletVersionOfSidebar from "../components/layout/TabletVersionOfSidebar";

export default {
  name: "PublicInfo",
  components: {
    Header,
    PagesSidebar,
    TabletVersionOfSidebar
  },
  data() {
    return {
      desktop: true,
      tablet: false,
      mobile: false,
      sidebarItemList: [
        {
          name: 'Fees',
          routeTo: '/info/fees',
        },
        {
          name: 'FAQ',
          routeTo: '/info/faq',
        },
      ]
    }
  },
  methods: {
    changeTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem(
          "theme",
          this.$vuetify.theme.dark ? "dark" : "light"
      );
    },
    sidebarClickOutside(event) {
      if (
          !this.desktop
          && !event.target.closest('.nav-wrapper')
          && !event.target.closest('.sidebarBurger')
      ) {
        this.$refs.TabletVersionOfSidebar.closeSideBar()
      }
    },
    windowWidthListener(e) {
      if (e < 1025) {
        this.desktop = false
        this.tablet = true
        this.mobile = false
        // return
      } else {
        this.desktop = true
        this.tablet = false
        this.mobile = false
      }
    }
  },
  mounted() {
    document.addEventListener('click', this.sidebarClickOutside)
    let width = window.innerWidth
    this.windowWidthListener(width)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.sidebarClickOutside)
  }
}
</script>

<style scoped>

</style>