<template>
  <div
      class="custom-select"
      :class="customClass"
      :tabindex="tabindex"
      @blur="open = false"
  >
    <div
        class="selected"
        :class="{ open: open }"
        @click="open = !open"
    >
      {{ $t(selected) }}
      <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5.99059 5.99797C5.79067 5.99836 5.59692 5.92865 5.44298 5.80093L0.309104 1.51743C0.134367 1.37202 0.0244811 1.16306 0.00362066 0.936524C-0.0172397 0.70999 0.0526339 0.484437 0.19787 0.309485C0.343106 0.134532 0.551808 0.0245113 0.778063 0.00362514C1.00432 -0.017261 1.22959 0.0526984 1.40433 0.198113L5.99059 4.03613L10.5768 0.335186C10.6644 0.264023 10.7651 0.210881 10.8732 0.178813C10.9813 0.146745 11.0946 0.136385 11.2068 0.148326C11.3189 0.160268 11.4275 0.194276 11.5265 0.248397C11.6254 0.302518 11.7127 0.375685 11.7833 0.463691C11.8617 0.551778 11.921 0.655119 11.9576 0.767238C11.9942 0.879357 12.0073 0.99784 11.9961 1.11526C11.9848 1.23268 11.9495 1.34652 11.8923 1.44963C11.835 1.55273 11.7572 1.6429 11.6635 1.71447L6.52964 5.85233C6.37128 5.95986 6.18148 6.01114 5.99059 5.99797Z"/>
      </svg>
    </div>
<!--    :class="{ selectHide: !open }"-->
    <div class="items" :class="{ selectHide: !open }">
      <div
          v-for="(option, i) of options"
          :key="i"
          :class="option.name === selected ? 'active' : option.disabled ? 'disabled' : ''"
          @click="selectedAction(option)"
      >
        {{ $t(option.name) }}
        <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.75 4.74038L4.425 8.375L11.25 1.625" stroke="#42E8E0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span class="disabled-text">{{$t('Coming Soon')}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomSelect",
  props: {
    customClass: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      required: true,
    },
    default: {
      type: String,
      required: false,
      default: '',
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      selected: this.default
          ? this.default
          : this.options.length > 0
              ? this.options[0]
              : null,
      open: false,
    };
  },
  methods: {
    selectedAction(option) {

      if(option.disabled) {
        return
      }

      this.selected = this.selected = option.name;
      this.open = false;
      // $emit('input', option.name);
      this.$emit('update:modelValue', option.value,)
      this.$emit('input', null)
    },
    change(val) {
      this.$emit('update:modelValue', val)
    },
    reset() {
      this.selected = this.default
    }
  }
}
</script>

<style scoped>

.custom-select {
  position: relative;
  outline: none;
}

.custom-select .selected {
  position: relative;
  padding: 0 51px 0 12px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--v-selectBackgroundColor-base);
  box-shadow: 0px 4px 17px rgba(21, 83, 80, 0.08);
  border-radius: 6px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: var(--v-selectColor-base);
  cursor: pointer;
  border: 1px solid transparent;
  user-select: none;
  transition: all .3s ease-in-out;
}

.custom-select .selected:hover {
  background-color: #FCFCFC;
}

.v-application.theme--dark .custom-select .selected:hover {
  background-color: #1E1E1E;
}

.custom-select .selected.open {
  background-color: var(--v-selectColorHover-base);
}

.custom-select .selected svg {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  transition: all .5s;
}

.custom-select .selected.open svg {
  transform: translateY(-50%) rotateX(180deg);
}

.report-filter-item-input-wrap .selected svg path {
  fill: #92939C;
  cursor: pointer;
  transition: .3s all;
}

.report-filter-item-input-wrap .selected:hover svg path {
  fill: #464646;
}

.report-filter-item-input-wrap .selected.open svg path {
  fill: #464646;
}

.v-application.theme--dark .report-filter-item-input-wrap .selected svg path {
  fill: #92939C;
}

.v-application.theme--dark .report-filter-item-input-wrap .selected:hover svg path {
  fill: #E9E9E9;
}

.v-application.theme--dark .report-filter-item-input-wrap .selected.open svg path {
  fill: #E9E9E9;
}

.custom-select .selected.open {
  border: 1px solid #42E8E0;
}

.custom-select .items {
  margin-top: 5px;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 2;
  border-radius: 6px;
  filter: drop-shadow(0px 4px 17px rgba(0, 71, 68, 0.1));
}

.v-application.theme--dark .custom-select .items {
  filter: none;
}

.custom-select .items div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  user-select: none;
  background: var(--v-selectBackgroundColor-base);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: var(--v-selectColor-base);
  padding: 6px 13px;
  transition: all .5s;
}

/*.custom-select .items div:first-child {*/
/*  padding-top: 11px;*/
/*}*/

.custom-select .items div:last-child {
  padding-bottom: 11px;
}

.custom-select .items div.active {
  background: #F7F7FB;
}

.v-application.theme--dark .items div.active {
  background: #2E2D2D;
}

.custom-select .items div:hover {
  background: #F7F7FB;
}

.v-application.theme--dark  .custom-select .items div:hover {
  background: #2E2D2D;
}

.custom-select .items div svg {
  display: none;
}

.custom-select .items div.active svg {
  display: block;
}

.selectHide {
  display: none;
}

.support-form .selected {

}

.support-form .selected svg path{
  fill: #8692A6;
}

.support-form .selected:hover {
  filter: none;
}

.custom-select .items div.disabled {
  background: var(--v-marketHeaderButton-base) !important;
}

.disabled-text {
  display: none;
}

/*.custom-select .items div.disabled .disabled-text{*/
/*  display: inline-block;*/
/*  color: var(--v-textAccentColor-base);*/
/*  font-size: 12px;*/
/*}*/

.custom-select .items div.disabled .disabled-text {
  display: inline-block;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #2DCAC2;
  padding: 4px 6px;
  background: rgba(45, 202, 194, 0.1);
  border-radius: 6px;
  margin-left: auto;
}

</style>
