<template>
  <div class="report-template">
    <div class="report-header">
      <h1 class="report-title textMainColor">{{ $t('Fees') }}</h1>
    </div>
    <div class="report-tables">
      <TiersTable
          :feeTiersStructureList="feeTiersStructureList"
          :spotUsdcAmount="spotUsdcAmount"
      />
      <DiscountTable
          :feeTiersDiscountList="feeTiersDiscountList"
          :activeIndexDiscount="activeIndexDiscount"
      />
    </div>
  </div>
</template>
<script>
import TiersTable from '@/components/public/fees/TiersTable';
import DiscountTable from '@/components/public/fees/DiscountTable';
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'ReportsFees',
  components: { DiscountTable, TiersTable },
  data() {
    return {
      token: null,
      feeTiersStructureList: [],
      feeTiersDiscountList: [],
      feeTierStructure: null,
      feeTierDiscount: null,
      activeIndexDiscount: null,
    }
  },
  computed: {
    ...mapGetters({
      spotUsdcAmount: 'wallets/getSpotUsdcBalance',
    }),
    cosmosWallet() {
      return this.$store.state.wallets.metaMaskWallet.authData ? this.$store.state.wallets.metaMaskWallet.authData.granterWallet.address : ''
    },
    tradingVolume30d() {
      // return +this.$store.state.wallets.tradingVolume30d / Math.pow(10, 28)
      // return this.prettify(String(this.aroundNumber(this.noExponents(+this.$store.state.wallets.tradingVolume30d / Math.pow(10, 18)) || 0))) || '-'
      return this.prettify(String(this.aroundNumber(this.noExponents(+this.$store.state.wallets.tradingVolume30d / Math.pow(10, 18))))) || 0
    },
  },
  watch: {
  },
  methods: {
    ...mapActions({
      getFeeTiersStructure: 'reports/getFeeTiersStructure',
      getFeeTiersDiscount: 'reports/getFeeTiersDiscount',
    }),
    noExponents(number) {
      var data = String(number).split(/[eE]/);
      if (data.length == 1) return data[0];

      var z = '',
          sign = this < 0 ? '-' : '',
          str = data[0].replace('.', ''),
          mag = Number(data[1]) + 1;

      if (mag < 0) {
        z = sign + '0.';
        while (mag++) z += '0';
        return z + str.replace(/^\-/, '');
      }
      mag -= str.length;
      while (mag--) z += '0';
      return str + z;
    },
    aroundNumber(num) {
      if(num === undefined || num === null) {
        return 0
      }
      if(num === 0) {
        return num
      }else {
        // return num ? parseFloat(Number(num).toFixed(2)) : null
        if(+num > 0) {
          return Math.trunc(num * 100) / 100;
        }else {
          return Math.ceil(num * 100) / 100;
        }
      }
    },
    prettify(num) {
      if(num === 0) return 0
      const options = {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2
      }
      num = Number(num).toLocaleString("en-US", options).replace('$', '')
      return num
    },
    getAccountInfoData() {
      this.getFeeTiersData()
    },

    getFeeTiersData() {
      this.getFeeTiersDiscount().then((data) => {
        this.feeTiersDiscountList = data.data;

        this.getFeeTiersStructure().then((data) => {
          this.feeTiersStructureList = data.data;
          this.feeTiersStructureList[0].volume30D = this.feeTiersStructureList[1].volume30D

          this.feeTiersStructureList.map(feeTier => feeTier.finalTaker = feeTier.taker)
        });
      });
    },
  },
  mounted() {

    if (localStorage.getItem('token') !== null) {
      this.token = localStorage.getItem('token')
    }

    this.getAccountInfoData()
  }
};
</script>
<style lang="scss" scoped>
$negative-padding: -20px;

.report-tables::after,
.report-header::after {
  content: "";
  display: block;
  position: absolute;
  z-index: 2;
  right: $negative-padding;
  bottom: 0;
  left: $negative-padding;
  height: 1px;
  background-color: var(--v-mainBorderColor-base);
}

.report-header {
  position: relative !important;

  .report-title {
    margin: 0 0 14px;
  }
}

.report-tables::v-deep {
  position: relative;

  table {
    width: 100%;
  }

  @media (max-width: 900px) {
    overflow: hidden;
  }

  @media (min-width: 1400px) {
    display: flex;
  }

  .caption {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0 15px;
    cursor: default;

    h6 {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      color: var(--v-textMainColor-base);
      display: flex;
      align-items: center;
      justify-content: flex-start;

      svg {
        width: 14px;
        height: 14px;
        cursor: help;
        vertical-align: middle;
        display: inline-block;
        margin-left: 5px;
      }
    }

    p {
      margin: 0;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      color: var(--v-textSecondaryColor-base);
    }
  }

  thead {
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    color: #92939c;
  }

  tbody {
    &::before,
    &::after {
      content: unset;
    }

    tr {
      position: relative;
      z-index: 1;

      &::after,
      &:nth-child(even)::after {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        right: $negative-padding;
        bottom: 0;
        left: $negative-padding;
      }

      &:nth-child(even)::after {
        background-color: var(--v-switchColor-base);
        opacity: 0.2;
      }

      &.active::after {
        background-color: #42e8e019;
        opacity: 1;
      }
    }
  }

  th,
  td {
    padding: 7px 0 8px;
    white-space: nowrap;
  }

  td {
    font-size: 14px;
    font-weight: 400;
    color: var(--v-selectColor-base);
  }
}
</style>
