import Router from "vue-router";
import {alert} from "./alert.module";
// import Trading from "../pages/Trading"
const Trading = () => import('../pages/Trading')
import LegalPrivacy from "../pages/LegalPrivacy";
import Info from '../pages/Info';
import PublicInfo from '../pages/PublicInfo';
import PrivacyPolicy from "../components/privacy/PrivacyPolicy";
import CookiePolicy from "../components/privacy/CookiePolicy";
import TermsConditions from "../components/privacy/TermsConditions";
import Faq from "../components/privacy/Faq";
import KnowledgeBase from "../pages/KnowledgeBase";
import WhatIsDexilon from "../components/knowledge-base/WhatIsDexilon";
import OpenInterest from "../components/trading-data/OpenInterest";
import FundingRate from "../components/trading-data/FundingRate";
import FundingRateHistory from "../components/trading-data/FundingRateHistory";
import IndexPrice from "../components/trading-data/IndexPrice";
import TraderRatioAccounts from "../components/trading-data/TraderRatioAccounts";
import TraderRatioPositions from "../components/trading-data/TraderRatioPositions";
import LongShortRatio from "../components/trading-data/LongShortRatio";
import TakerVolume from "../components/trading-data/TakerVolume";
import HowToEarn from "../components/knowledge-base/HowToEarn";
import Tutorial from "../components/knowledge-base/Tutorial";
import HowToMakeDeposit from "../components/knowledge-base/HowToMakeDeposit";
import HowToMakeWithdrawal from "../components/knowledge-base/HowToMakeWithdrawal";
import Reports from "../pages/Reports";
import ReportsOpenOrders from "../components/reports/ReportsOpenOrders";
import ReportsOrderHistory from "../components/reports/ReportsOrderHistory";
import ReportsTradeHistory from "../components/reports/ReportsTradeHistory";
import ReportsTransactionHistory from "../components/reports/ReportsTransactionHistory";
import ReportsFees from '../components/reports/ReportsFees';
import PublicFees from '../components/public/ReportsFees';
import ReportsPositions from '../components/reports/ReportsPositions';
import Tournaments from "../components/reports/Tournaments";
import ApiManagement from "../components/reports/ApiManagement";
import TradingData from "../pages/TradingData";
import Rewards from "@/pages/Rewards.vue";
import RoundsOfRewards from "@/components/rewards/RoundsOfRewards.vue";
import ReferralProgram from "@/components/rewards/ReferralProgram.vue";
import RewardsHistory from "@/components/rewards/RewardsHistory.vue";
import LiquidationAuction from '../pages/LiquidationAuction';
import ErrorPageNotFound from "../pages/ErrorPageNotFound";

const routes = [
    { path: '/', redirect: '/swaps/eth_usdt' },
    { path: '/swaps', redirect: '/swaps/eth_usdt' },
    {
        path: "/swaps/:coin",
        name: "Trading",
        component: Trading,
    },
    // {
    //     path: "/:id?",
    //     name: "Trading",
    //     component: Trading,
    // },
    {
        path: "/legal-privacy",
        name: "Legal end Privacy",
        component: LegalPrivacy,
        redirect: "/legal-privacy/privacy",
        children: [
            // {
            //     path: "privacy",
            //     name: "Privacy Policy",
            //     component: PrivacyPolicy,
            // },
            // {
            //     path: "cookie",
            //     name: "Cookie Policy",
            //     component: CookiePolicy,
            // },
            // {
            //     path: "terms-and-conditions",
            //     name: "Terms and Conditions",
            //     component: TermsConditions,
            // },
        ]
    },
    {
        path: "/info",
        name: "PublicInfo",
        component: PublicInfo,
        children: [
            {
                path: "fees",
                name: "Fees",
                component: PublicFees,
            },
            {
                path: "faq",
                name: "Faq",
                component: Faq,
            },
        ]
    },
    {
        path: "/trading-data",
        name: "Trading Data",
        component: TradingData,
        redirect: "/trading-data/open-interest",
        children: [
            {
                path: "open-interest",
                name: "OpenInterest",
                component: OpenInterest,
            },
            {
                path: "trader-ratio-accounts",
                name: "trader-ratio-accounts",
                component: TraderRatioAccounts,
            },
            {
                path: "trader-ratio-positions",
                name: "trader-ratio-positions",
                component: TraderRatioPositions,
            },
            {
                path: "long-short-ratio",
                name: "long-short-ratio",
                component: LongShortRatio,
            },
            {
                path: "taker-volume",
                name: "taker-volume",
                component: TakerVolume,
            },
            {
                path: "funding-rate",
                name: "funding-rate",
                component: FundingRate,
            },
            {
                path: "funding-rate-history",
                name: "funding-rate-history",
                component: FundingRateHistory,
            },
            {
                path: "index-price",
                name: "index-price",
                component: IndexPrice,
            },
        ]
    },
    {
        path: '/liquidation-auction',
        name: 'Liquidation Auction',
        component: LiquidationAuction,
    },
    {
        path: "/knowledge-base",
        name: "Knowledge Base",
        component: KnowledgeBase,
        redirect: "/knowledge-base/how-to-make-deposit",
        children: [
            {
                path: "what-is-dexilon",
                name: "WhatIsDexilon",
                component: WhatIsDexilon,
            },
            {
                path: "how-to-earn",
                name: "How to earn on Dexilon without trading",
                component: HowToEarn,
            },
            {
                path: "tutorial",
                name: "Tutorial",
                component: Tutorial,
            },
            {
                path: "how-to-make-deposit",
                name: "How to make a deposit at Dexilon",
                component: HowToMakeDeposit,
            },
            {
                path: "how-to-make-withdrawal",
                name: "How to make a crypto withdrawal at Dexilon",
                component: HowToMakeWithdrawal,
            },
        ]
    },
    {
        // redirect: this.getMetamaskWallet.address && !this.getMetamaskWallet.disconected ? "/reports/open-orders" : "",
        path: "/reports",
        name: "Reports",
        component: Reports,
        redirect: "/reports/open-orders",
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: "open-orders",
                name: "ReportsOpenOrders",
                component: ReportsOpenOrders,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "order-history",
                name: "ReportsOrderHistory",
                component: ReportsOrderHistory,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "trade-history",
                name: "ReportsTradeHistory",
                component: ReportsTradeHistory,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "transaction-history",
                name: "ReportsTransactionHistory",
                component: ReportsTransactionHistory,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "tournaments",
                name: "Tournaments",
                component: Tournaments,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "api-management",
                name: "ApiManagement",
                component: ApiManagement,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "fees",
                name: "ReportsFees",
                component: ReportsFees,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: "positions",
                name: "ReportsPositions",
                component: ReportsPositions,
                meta: {
                    requiresAuth: true
                }
            },
        ]
    },
    {
        path: "/rewards",
        name: "Rewards",
        component: Rewards,
        children: [
            {
                path: "rounds-of-rewards",
                name: "Rounds of Rewards",
                component: RoundsOfRewards,
            },
            {
                path: "referral-program",
                name: "Referral Program",
                component: ReferralProgram,
            },
            {
                path: "rewards-history",
                name: "Rewards History",
                component: RewardsHistory,
            },
        ]
    },
    {
        path: "/404",
        name: 'ErrorPageNotFound',
        component: ErrorPageNotFound,
    },
    {
        path: "*",
        redirect: "/404"
    },
];

export const router = new Router({
    mode: 'history',
    routes,
    scrollBehavior (to) {
        if(to.name !== 'Trading') {
            if (to.hash) {
                return window.scrollTo({
                    top: document.querySelector(to.hash).offsetTop,
                    behavior: 'smooth'
                })
            } else {
                return { x: 0, y: 0 }
            }
        }
    },
    linkActiveClass: "active",
    modules: {
        alert,
        status,
    }
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (localStorage.getItem('token') === null) {
            return next('/swaps/eth_usdt');
        } else {
            return next()
        }

    } else {
        return next();
    }
})

export default router;
