export default {
    SET_CANDLES_DATA(state, data) {
        state.candles = data;
    },

    SET_LAST_CANDLES_DATA(state, data) {
        state.lastCandles = data;
    },

    SET_ACCOUNTS_TRADES(state, data) {
        state.accountTrades = data;
    },

    SET_MARKET(state, data) {
        state.market = data;
    },

    SET_MARKET_LAST_PRICE(state, data) {
        state.marketLastPrice = data;
    },

    SET_MARKET_INDEX(state, data) {
        state.marketIndex = data;
    },

    SET_MARKET_TRADES(state, data) {
        state.marketTrades = data;
    },

    SET_COINS_DATA(state, data) {
        state.coins = data;
    },

    SET_COINS_FAVORITE(state, data) {
        state.coinsFavorite = data;
    },

    DELETE_COINS_FAVORITE(state, data) {
        state.coinsFavorite = data;
    },

    GET_COINS_FAVORITE(state, data) {
        state.coinsFavorite = data;
    },

    GET_COINS_INFO(state, data) {
        state.coinsInfo = data;
    },

    GET_COINS_LEVERAGE(state, data) {
        state.coinsLeverage = data;
    },

    SET_COINS_LEVERAGE(state, data) {
        state.coinsLeverage = data;
    },

    SET_COINS_LOCKED_BALANCE(state, data) {
        state.coinsLeverage = data;
    },

    GET_WITHDRAW(state, data) {
        state.withdrawAmount = data;
    },

    SET_MAINTENANCE(state, data) {
        state.maintenance = data;
    },
    SET_ORDER_DEPOSIT_STATE(state, data) {
        state.orderDepositState = data;
    },

}
