import vueCustomScrollbar from 'vue-custom-scrollbar'
import "vue-custom-scrollbar/dist/vueScrollbar.css"
import Vue from 'vue'
import App from './App.vue'
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import VueCompositionAPI from '@vue/composition-api'
import Toast from "vue-toastification";
import LottieAnimation from 'lottie-web-vue'
import './registerServiceWorker'
import Vuelidate from 'vuelidate'
import vueDebounce from 'vue-debounce'
import {PaginationNavPlugin} from 'bootstrap-vue'
import vuetify from '@/plugins/vuetify'
import { i18n } from './i18n'
import { Trans } from './plugins/translation'
import router from './router'
import store from './store'
import '@/assets/css/normalize.css';
import "vue-toastification/dist/index.css";
import '@/assets/css/main.css'
import customAxios from './api/$axios';
import customAxiosFiles from './api/axiosFiles';
import VueGtag from "vue-gtag";
import rate from 'vue-rate'
import VueSmartWidget from 'vue-smart-widget'
import 'vue-rate/dist/vue-rate.css'
import HighchartsVue from 'highcharts-vue'
import '@/assets/css/response.css';
import CloseButton from "@/components/toasters/CloseButton.vue";
import './gtm.js'

window.$axios = customAxios;
window.$axiosFiles = customAxiosFiles;

// if (process.env.VUE_APP_ENVIRONMENT === 'production') {
//     console.log('process.env.VUE_APP_SENTRY_DSN', process.env.VUE_APP_SENTRY_DSN)
//     Sentry.init({
//         Vue,
//         environment: process.env.VUE_APP_ENVIRONMENT,
//         release: `${process.env.VUE_APP_NAME}@${process.env.VUE_APP_VERSION}`,
//         dsn: 'https://63d423b2d2f042ca8d19fb60c06e33b4@o4504616910913536.ingest.sentry.io/4504616917336064',
//         integrations: [
//             new Integrations.BrowserTracing({
//                 tracingOrigins: [process.env.VUE_APP_BASE_URL, /^\//],
//             }),
//         ],
//         debug: process.env.VUE_APP_ENVIRONMENT  !== 'production',
//         tracesSampleRate: process.env.VUE_APP_ENVIRONMENT === 'production' ? 1 : 1,
//         tracingOptions: {
//             trackComponents: false,
//         },
//         // Vue specific
//         logErrors: process.env.VUE_APP_ENVIRONMENT === 'production' ? false : true,
//         attachProps: true,
//         attachStacktrace: true,
//     });
// }

const options = {
    maxToasts: 3,
    newestOnTop: true,
    position: "bottom-center",
    closeButton: CloseButton,
    timeout: 5000,
    closeOnClick: true,
    pauseOnFocusLoss: false,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    transition: "Vue-Toastification__fade",
    rtl: false,
    icon: false
};

Vue.use(Toast, options);

Vue.use(LottieAnimation)
Vue.prototype.$i18nRoute = Trans.i18nRoute.bind(Trans)
Vue.use(VueCompositionAPI)
Vue.use(Vuelidate)
Vue.use(vueDebounce, {
    listenTo: ['input', 'keyup'],
    defaultTime: '700ms'
})
Vue.use(rate)
Vue.use(HighchartsVue, {tagName: 'charts'})
Vue.use(PaginationNavPlugin)

Vue.component('vueCustomScrollbar', vueCustomScrollbar)
Vue.use(VueSmartWidget)

Vue.directive('click-outside', {
    bind(el, binding, vnode) {
        var vm = vnode.context;
        var callback = binding.value;

        el.clickOutsideEvent = function (event) {
            if (!(el == event.target || el.contains(event.target))) {
                return callback.call(vm, event);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
    },
    unbind(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
    }
});

Vue.use(VueGtag, {
    config: { id: "GTM-5WQ38CL" }
});


export default new Vue({
    el: '#app',
    i18n,
    router,
    store,
    vuetify,
    render: h => h(App),
})

