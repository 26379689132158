<template>
  <tr class="table-body transaction-history-wrap">
    <td class="pair" :data-label="$t('Asset')">
      <div class="asset-logo" v-if="historyOrdersItems.asset === 'dxln'">
        <svg style="border-radius: 50%;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 102 102" fill="none">
          <path d="M52.5399 33.6321C55.2894 33.6648 57.728 34.1394 59.8556 35.0559C61.9831 35.9724 63.7507 37.1999 65.1582 38.7383C66.5984 40.244 67.6785 42.0115 68.3986 44.0409C69.1515 46.0375 69.5279 48.1651 69.5279 50.4237V50.8164C69.5279 53.1077 69.1187 55.2844 68.3004 57.3465C67.4821 59.4086 66.3201 61.2252 64.8145 62.7964C63.3088 64.3348 61.5085 65.5786 59.4137 66.5278C57.3188 67.4443 54.9948 67.9353 52.4417 68.0008H38.7433V33.6321H52.5399ZM61.9177 50.7673C61.9504 49.2289 61.754 47.8214 61.3285 46.5449C60.903 45.2356 60.2483 44.1227 59.3646 43.2062C58.4808 42.257 57.3516 41.5205 55.9768 40.9968C54.6021 40.4404 52.9982 40.1621 51.1652 40.1621H46.1572V61.4707H50.6251C52.4908 61.438 54.1111 61.1434 55.4858 60.587C56.8933 60.0305 58.0717 59.2941 59.0209 58.3776C59.9701 57.4283 60.6902 56.3318 61.1812 55.088C61.6722 53.8114 61.9177 52.453 61.9177 51.0128V50.7673Z" fill="#42E8E0"></path>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M51.0737 85.9575C70.2671 85.9575 85.8265 70.5147 85.8265 51.4651C85.8265 32.4155 70.2671 16.9728 51.0737 16.9728C31.8802 16.9728 16.3209 32.4155 16.3209 51.4651C16.3209 70.5147 31.8802 85.9575 51.0737 85.9575ZM51.0737 91.5978C73.4057 91.5978 91.5094 73.6298 91.5094 51.4651C91.5094 29.3005 73.4057 11.3325 51.0737 11.3325C28.7417 11.3325 10.638 29.3005 10.638 51.4651C10.638 73.6298 28.7417 91.5978 51.0737 91.5978Z" fill="#42E8E0"></path>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M51 94.8683C75.1928 94.8683 94.8049 75.4031 94.8049 51.3916C94.8049 27.3802 75.1928 7.91505 51 7.91505C26.8072 7.91505 7.19511 27.3802 7.19511 51.3916C7.19511 75.4031 26.8072 94.8683 51 94.8683ZM51 101.978C79.1488 101.978 101.968 79.3295 101.968 51.3916C101.968 23.4538 79.1488 0.805664 51 0.805664C22.8512 0.805664 0.0320435 23.4538 0.0320435 51.3916C0.0320435 79.3295 22.8512 101.978 51 101.978Z" fill="#42E8E0"></path>
        </svg>
        <span>{{ historyOrdersItems.asset.toUpperCase() }}</span>
      </div>
      <div class="asset-logo" v-if="historyOrdersItems.asset === 'usdt'">
        <img :src="usdtIcon" alt="coin logo">
        <span>{{ historyOrdersItems.asset.toUpperCase() }}</span>
      </div>
    </td>
    <td class="amount" :data-label="$t('amount')">
      <span>{{ prettify(historyOrdersItems.amount.toFixed(2)) }}</span>
      <span class="secondary-text"> {{ historyOrdersItems.asset.toUpperCase() }}</span>
    </td>
    <td class="type" :data-label="$t('type')">
      <span>
        {{ $t(transformString(' ' + historyOrdersItems.type))}}
      </span>
    </td>
    <td class="time" :data-label="$t('time')">
      <span>{{formatterDate(historyOrdersItems.time)}} </span>
      <span class="secondary-text">{{ formatterTime(historyOrdersItems.time)}}</span>
    </td>
    <td class="status" :data-label="$t('Status')">
      <div class="transaction-link-wrap">
        <div class="transaction-pending" v-if="historyOrdersItems.type === 'WITHDRAW' && historyOrdersItems.accountType === 'SPOT' && currentStep.stepCount !== 4 " >
          <div class="transaction-pending-header" v-if="currentStep.stepCount">
            <span class="transaction-pending-status">{{currentStep.status}}</span>
            <span class="transaction-pending-step">{{currentStep.stepCount}}/4</span>
          </div>
          <div class="transaction-pending-progress-bar" v-if="currentStep.stepCount">
            <div class="transaction-pending-progress-bar-line" :style="{ width: `${currentStep.stepCount}` * 25 + '%' }"></div>
          </div>
        </div>
        <span v-else class="transaction-link-done">{{$t('Done')}}</span>
      </div>
    </td>
    <td class="transaction-link" :data-label="$t('Explorer')">
      <span class="secondary-text" v-if="historyOrdersItems.link">
        <a :href="historyOrdersItems.link" target="_blank">
          <span class="link-text">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.99828 5.97162C7.45221 5.42577 6.71172 5.11914 5.93962 5.11914C5.16752 5.11914 4.42702 5.42577 3.88095 5.97162L1.82162 8.03028C1.27553 8.57636 0.96875 9.31701 0.96875 10.0893C0.96875 10.8616 1.27553 11.6022 1.82162 12.1483C2.3677 12.6944 3.10834 13.0012 3.88062 13.0012C4.65289 13.0012 5.39353 12.6944 5.93962 12.1483L6.96895 11.119" stroke="#92939C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5.93945 8.02953C6.48552 8.57538 7.22602 8.88201 7.99812 8.88201C8.77022 8.88201 9.51072 8.57538 10.0568 8.02953L12.1161 5.97087C12.6622 5.42479 12.969 4.68414 12.969 3.91187C12.969 3.13959 12.6622 2.39895 12.1161 1.85287C11.57 1.30678 10.8294 1 10.0571 1C9.28484 1 8.5442 1.30678 7.99812 1.85287L6.96879 2.8822" stroke="#92939C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </span>
        </a>
      </span>
      <span v-else class="secondary-text">-</span>
    </td>
  </tr>
</template>

<script>
import moment from "moment";

export default {
  name: "TradingOrderHistoryItems",
  props: {
    historyOrdersItems: {
      type: Object,
    },
    index: {
      type: Number,
    },
    isCheckedOrders: {
      type: Boolean,
    }
  },
  data() {
    return {
      bigDipperUrl: process.env.VUE_APP_BIG_DIPPER_URL,
      usdtIcon: require('@/assets/img/coin/usdt.svg'),
      steps: [
        {
          status: 'Withdraw Initialized',
          stepCount: 1,
        },
        {
          status: 'Batch Building',
          stepCount: 2,
        },
        {
          status: 'Batch Signing',
          stepCount: 3,
        },
        {
          status: 'Withdraw Finished',
          stepCount: 4,
        },
      ],
      //   isCheckedOrders: isCheckedOrders
    }
  },
  computed: {
    currentStep() {
      return this.historyOrdersItems.status === 'ethereumbridge.WithdrawSetInBatchEvent' ?
          this.steps[0] :
            this.historyOrdersItems.status === 'ethereumbridge.WithdrawSignBatchEvent' ?
          this.steps[1] :
            this.historyOrdersItems.status === 'ethereumbridge.WithdrawBatchReadyToBridgeEvent' ?
          this.steps[2] :
            this.historyOrdersItems.status === 'ethereumbridge.WithdrawFinalizeEvent' ?
          this.steps[3] : ''
    }
  },
  methods: {
    transformString(str) {
      str = str.replaceAll('_', ' ');
      let newStr = '';
      for (let i = 0; i < str.length; i++) {
        newStr += (str[i - 1] == ' ') ? str[i].toUpperCase() : str[i].toLowerCase();
      }
      return newStr
    },
    onDeleteOrder(order) {
      this.$emit('onDeleteOrder', order);
    },
    prettify(num) {
      num = num.toLocaleString("en-US")
      return num
    },
    formatterDate: function (value) {
      return moment.utc(value).local().format('DD/MM/yy');
    },
    formatterTime: function (value) {
      return moment.utc(value).local().format('HH:mm');
    }
  }
};
</script>

<style lang="scss" scoped>
.table-body {
}

.transaction-link-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.transaction-link-done {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #E9E9E9;
}

.list {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 12px;
}

.pair {
  width: 10%;

  span {
    display: inline-block;
    padding-left: 4px;
  }
}

.type {
  text-align: right;
  width: 20%;
}

.amount {
  text-align: right;
  width: 20%;
}

.price {
  text-align: right;
  width: 20%;
}

.time {
  text-align: right;
  width: 20%;
}

.side .buy {
  color: #1BB8B0;
}

.side .sell {
  color: #f27870;
}

// checkbox
.orange-checkbox-container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.orange-checkbox-container input {
  display: none;
}

.checkmark {
  display: block;
  height: 14px;
  width: 14px;
  border: 1px solid var(--v-textMainColor-base);
  border-radius: 3px;
}

.orange-checkbox-container:hover input ~ .checkmark {
  background-color: var(--v-textAccentColor-base);
}

.orange-checkbox-container input:checked ~ .checkmark {
  background-color: #FFB000;
  border: none;
  border-radius: 3px;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.orange-checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.holder-checkbox {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

// end checkbox
.secondary-text {
  color: var(--v-textSecondaryColor-base)
}

.asset-logo svg,
.asset-logo img {
  width: 23px;
  height: 23px;
  display: block;
  margin-right: 10px;
}

.asset-logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.link-text {
  color: rgba(0,0,0,.87) !important;
  text-decoration: underline;
}

.theme--dark.v-application .link-text {
  color: #fff !important;
}

.link-text svg path {
  transition: all .5s;
  stroke: #02C9BF;
}

.link-text:hover svg path {
  stroke: #42E8E0;
}

.transaction-link {
  text-align: right;
  padding-right: 25px;
}

.transaction-pending {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 165px;
  width: 100%;
  margin-left: 0px;
  margin-right: 20px;
}

.transaction-pending-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 5px;
}

.transaction-pending-status {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #E9E9E9;
}

.transaction-pending-step {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #A4A5AF;
}

.transaction-pending-progress-bar {
  width: 165px;
  height: 3px;
  background: rgba(255, 255, 255, 0.1);
  position: relative;
  border-radius: 2px;
  display: flex;
  overflow: hidden;
}

.transaction-pending-progress-bar-line {
  display: inline-block;
  height: 3px;
  background: #1BB8B0;
}

</style>
