export function getFromLocalStorage(item) {
    if(localStorage.getItem(item) !== 'undefined') {
        return JSON.parse(localStorage.getItem(item))
    }
}

export function saveToLocalStorage(key, item) {
    localStorage.setItem(key, JSON.stringify(item));
}

export function deepCopyObject(item) {
    return JSON.parse(JSON.stringify(item));
}

export function getWindowSize() {
    const windowWidth = window.innerWidth;

    if (windowWidth < 920) {
        return 'sm'
    } else if (windowWidth > 1920) {
        return 'lg';
    }

    return 'md';
}
