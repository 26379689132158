<template>
  <v-dialog v-model="active" persistent>
    <div class="service-message-wrapper leaderboard-popup-wrapper">
      <div class="close-dialog" @click="close">
        <img src="../../assets/img/close.svg" alt="close-icon" />
      </div>
      <h4 class="leaderboard-popup-title">
        <span class="leaderboard-popup-icon">🏆</span>
        <span>{{ $t(title) }}</span>
      </h4>
      <div class="leaderboard-popup-time textSecondaryColor">
        {{ time }}
      </div>
      <p class="leaderboard-popup-description">{{ $t(text) }}</p>
      <members-items-list
          :topReferrals="tournamentsMembersData"
          :currentReferralUser="currentTournamentsMember"
          :looserReferralUser="looserTournamentsMember"
          :token="token"
          :isLoading="isLoading"
      />
    </div>
  </v-dialog>
</template>

<script>
import membersItemsList from "@/components/leaderboard/MembersItemsList.vue";
import {mapActions} from "vuex";
import moment from "moment";

export default {
  name: "LeaderboardPopup",
  components: {
    membersItemsList,
  },
  props: {
    token: {
      type: String
    },
  },
  data() {
    return {
      quoteCurrency: 'USDT',
      active: false,
      isLoading: false,
      title: 'Leaderboard',
      text: 'Compete with other traders to earn reward. Claim tokens and use them for trading. Leaders are determined by the biggest gain relative to their initial capital.',
      tournamentsInfo: {},
      tournamentsMembersData: [],
      currentTournamentsMember: null,
      looserTournamentsMember: null,
      paylod: null
    };
  },

  computed: {
    cosmosWallet() {
      return this.$store.state.wallets.metaMaskWallet.authData ? this.$store.state.wallets.metaMaskWallet.authData.granterWallet.address : ''
    },
    time() {
      return `${moment.utc(this.tournamentsInfo.startedAt).local().format('DD.MM.yy')} - ${moment.utc(this.tournamentsInfo.finishedAt).local().format('DD.MM.yy')}`;
    }
  },
  methods: {
    ...mapActions({
      getTournamentsMembers: 'tournaments/getTournamentsMembers',
      getCurrentTournaments: 'tournaments/getCurrentTournaments',
      getTournamentsMyRating: 'tournaments/getTournamentsMyRating',
    }),
    aroundNumber(num) {
      if(num === undefined || num === null) {
        return 0
      }
      if(num === 0) {
        return num
      }else {
        // return num ? parseFloat(Number(num).toFixed(2)) : null
        if(+num > 0) {
          return Math.trunc(num * 100) / 100;
        }else {
          return Math.ceil(num * 100) / 100;
        }
      }
    },

    prettify(num) {
      if(num === 0) return 0
      const options = {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2
      }
      num = Number(num).toLocaleString("en-US", options).replace('$', '')
      return num
    },

    getTournamentsMembersAction(tournamentId) {
      this.tournamentsMembersData = [];
      const params = {
        tournamentId: tournamentId,
        from: 1,
        limit: 8,
        head: true,
      };
      this.getTournamentsMembers({params}).then(async (res) => {
        this.tournamentsMembersData = res.data;

        if(this.token) {
          const currentMember = this.tournamentsMembersData.filter(referral => referral.userAddress === this.cosmosWallet)[0]
          if(!currentMember) {
            const params = {
              tournamentId: tournamentId,
            };
            const current = await this.getTournamentsMyRating({params});
            this.currentTournamentsMember = current.data;
          }else {
            this.currentTournamentsMember = currentMember;
            return
          }

          const membersArray = this.tournamentsMembersData;
          let coincidenceMemberIndex = null

          this.tournamentsMembersData.forEach((member, i) => {
            if(member.rating === this.currentTournamentsMember.rating) {
              coincidenceMemberIndex = i
            }
          })

          if(this.tournamentsMembersData.length === 0 || coincidenceMemberIndex !== null) {
            if(this.tournamentsMembersData.length === 0)  {
              this.currentTournamentsMember.rating = 1;
            }

            if(this.currentTournamentsMember.rating) {
              membersArray.splice(coincidenceMemberIndex, 0, this.currentTournamentsMember);
            }
          }

          if(this.tournamentsMembersData.length > 1 && this.tournamentsMembersData.length <= 8 && this.currentTournamentsMember.rating <= 8 && coincidenceMemberIndex === null) {
            this.tournamentsMembersData.push(this.currentTournamentsMember)
          }

          membersArray.map((item, i) => {
            if(coincidenceMemberIndex !== null && i > coincidenceMemberIndex && item.rating >= this.currentTournamentsMember.rating) {
              item.rating += 1
            }
            return item
          })

          if(this.tournamentsMembersData[this.tournamentsMembersData.length - 1].rating > 8) {
            membersArray.pop()
          }

          this.tournamentsMembersData = membersArray;
        }
      })
    },

    getCurrentTournamentsAction() {
      this.isLoading = true;
      this.getCurrentTournaments().then((res) => {
        this.tournamentsInfo = res.data;
        this.getTournamentsMembersAction(res.data.id);
        this.looserTournamentsMemberAction(res.data.id);
      }).catch(() => {
        this.isLoading = false;
      })
    },

    looserTournamentsMemberAction(tournamentId) {
      const params = {
        tournamentId: tournamentId,
        from: 1,
        limit: 5,
        head: false,
      };
      this.getTournamentsMembers({params}).then((res) => {
        this.looserTournamentsMember = res.data[res.data.length - 1];

        if(this.looserTournamentsMember) {
          if(this.token && this.currentTournamentsMember && this.currentTournamentsMember.rating > this.looserTournamentsMember.rating) {
            this.looserTournamentsMember.rating = this.looserTournamentsMember.rating + 1
          }
        }

        this.isLoading = false;
      })

    },

    showDialog(payload) {
      this.getCurrentTournamentsAction();
      this.paylod = payload;
      this.active = true;
    },

    onConfirm() {
      this.active = false;
      this.$emit('onConfirm', this.paylod);
    },

    close() {
      this.active = false;
      this.isLoading = false;
      this.tournamentsMembersData = [];
    }
  },
  mounted() {

  }
}
</script>

<style scoped>
.leaderboard-popup-wrapper {
  padding: 20px 0px;
  min-height: 492px;
}

.leaderboard-popup-wrapper .table-body {
  min-height: 36px;
}

.leaderboard-popup-wrapper tbody::before {
  display: none;
}

.leaderboard-popup-title {
  margin-bottom: 5px;
  padding: 0px 19px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.leaderboard-icon {
  width: 24px;
  heifgt: 24px;
}

.leaderboard-popup-title span {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #E9E9E9;
  display: inline-block;
  margin-left: 8px;
}

.leaderboard-popup-time {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #92939C;
  margin-bottom: 20px;
  padding: 0px 19px;
  text-align: center;
}

.leaderboard-popup-description {
  text-align: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 20px;
  color: #E9E9E9;
  padding: 0px 19px;
}

.history-orders-table {

}

.leaderboard-popup-wrapper .rank {
  width: 65px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #E9E9E9;
  padding-left: 19px;
}

.leaderboard-popup-wrapper .table-rank-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.leaderboard-popup-wrapper .rank svg {
  display: inline-block;
  margin-left: 10px;
}

.leaderboard-popup-wrapper .user-name {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #E9E9E9;

}

.leaderboard-popup-wrapper .total-profit {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  color: #E9E9E9;
  padding-right: 19px;
}

.top-referral-empty-row {
  padding: 0 20px;
}

.leaderboard-popup-wrapper .current-leader {
  background: rgba(66, 232, 224, 0.1);
}

.leaderboard-popup-wrapper .current-leader .total-profit {
  color: #02C9BF;
}

.leaderboard-popup-title span.leaderboard-popup-icon {
  margin: 0;
}

</style>