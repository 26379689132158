export default {

    getMarketSymbolInfo({commit}, {symbol}) {
        return new Promise((resolve, reject) => {
            $axios.get(`/symbols/info/full?symbol=${symbol}`,).then((res) => {
                commit('SET_MARKET_INDEX', res);
                resolve(res);
            })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getMarketsTrades({commit}, {symbol}) {
        return new Promise((resolve, reject) => {
            $axios.get(`/trades?symbol=${symbol}`).then((res) => {
                commit('SET_ACCOUNTS_TRADES', res);
                resolve(res);
            })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getMarketsCandles({commit}, {params}) {
        return new Promise((resolve, reject) => {
            $axios.get(`/candles?dateTo=${params.dateTo}&symbol=${params.symbol}&limit=${params.limit}&timeFrame=${params.timeFrame}`).then(({data}) => {
                commit('SET_CANDLES_DATA', data);
                resolve(data);
            })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getMarketLastCandle({commit}, {params}) {
        return new Promise((resolve, reject) => {
            $axios.post(`/candles/last`, params).then(({data}) => {
                commit('SET_LAST_CANDLES_DATA', data);
                resolve(data);
            })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getMarketCoinsAuth({commit}, {MetamaskAddress}) {
        return new Promise((resolve, reject) => {
            $axios.get(`/symbols/auth`, {headers: {MetamaskAddress: MetamaskAddress}}).then((res) => {
                commit('SET_COINS_DATA', res.data);
                resolve(res);
            })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getMarketCoins({commit}) {
        return new Promise((resolve, reject) => {
            $axios.get(`/symbols`).then((res) => {
                commit('SET_COINS_DATA', res.data);
                resolve(res);
            })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    addedCoinToFavorite({commit}, {symbol}) {
        return new Promise((resolve, reject) => {
            $axios.post(`/symbols/favorites?symbol=${symbol}`, {}, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((res) => {
                commit('SET_COINS_FAVORITE', symbol);
                resolve(res.data);
            })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    deleteCoinToFavorite({commit}, {symbol}) {
        return new Promise((resolve, reject) => {
            $axios.delete(`/symbols/favorites?symbol=${symbol}`, {}, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((res) => {
                commit('DELETE_COINS_FAVORITE', symbol);
                resolve(res.data);
            })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getFavoritesCoins({commit}) {
        return new Promise((resolve, reject) => {
            $axios.get(`/symbols/favorites`).then((res) => {
                commit('GET_COINS_FAVORITE', res.data);
                resolve(res.data);
            })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    updateLeverage({commit}, params) {
        return new Promise((resolve, reject) => {
            $axios.put(`/accounts/leverage`, params, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((res) => {
                commit('SET_COINS_LEVERAGE', res.data);
                resolve(res.data);
            })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    updateLockedBalance({commit}, params) {
        return new Promise((resolve, reject) => {
            $axios.put(`/accounts/lockedBalance`, params, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((res) => {
                commit('SET_COINS_LOCKED_BALANCE', res.data);
                resolve(res.data);
            })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    updateMarginMode({commit}, params) {
        return new Promise((resolve, reject) => {
            $axios.put(`/accounts/marginMode`, params, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((res) => {
                commit('SET_COINS_LOCKED_BALANCE', res.data);
                resolve(res.data);
            })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    withdrawAction({commit}, params) {
        return new Promise((resolve, reject) => {
            $axios.post(`/balance/withdraw`, params, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((res) => {
                commit('GET_WITHDRAW', res.data);
                resolve(res.data);
            })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getPositionNotional({commit}) {
        return new Promise((resolve, reject) => {
            $axios.get(`/tradingRules/leverage`)
                .then((res) => {
                    commit('SET_MAINTENANCE', res.data);
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getOpenOrdersLocked({commit}, {symbol, side}) {
        return new Promise((resolve, reject) => {
            $axios.get(`/orders/open/locked?symbol=${symbol}&side=${side}`).then((res) => {
                // commit('SET_ORDERS_DATA', res.data);
                resolve(res.data);
            })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    checkDepositEntry() {
        return new Promise((resolve, reject) => {
            $axios.get(`/balance/deposit/entry`).then((res) => {
                resolve(res.data);
            })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    setDepositEntry({commit}) {
        return new Promise((resolve, reject) => {
            $axios.post(`/balance/deposit/entry`, {}, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((res) => {
                resolve(res.data);
            })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getTradinRules() {
        return new Promise((resolve, reject) => {
            $axios.get(`/tradingRules/symbol`).then((res) => {
                resolve(res.data);
            })
                .catch((err) => {
                    reject(err);
                });
        });
    },
}
