import axios from 'axios'
import LocalStorageService from "../services/LocalStorageService";

const localStorageService = LocalStorageService.getService();

const customAxiosFiles = axios.create({
    baseURL: `${process.env.VUE_APP_API}/api/v1`,
    headers: {
        accept: 'application/octet-stream',
        'Content-Type': 'application/octet-stream',
    }
})

// Add a request interceptor
customAxiosFiles.interceptors.request.use(
    config => {
        // config.headers['Content-Type'] = 'application/json';
        const token = localStorageService.getAccessToken();
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
            config.headers['Cache-Control'] = 'no-cache';
            config.headers['Pragma'] = 'no-cache';
            config.headers['Expires'] = '0';
        }
        return config;
    },
    error => {
        Promise.reject(error)
    }
);

//Add a response interceptor

customAxiosFiles.interceptors.response.use((response) => {
    return response
}, function (error) {

    return Promise.reject(error);
});

export default customAxiosFiles;