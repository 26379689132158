<template>
  <div class="leaderboard-wrapper">
    <div class="preloader new-preloader" v-if="isLoading">
      <lottie-animation
          ref="preloader"
          :animationData="require('@/assets/img/Loader.json')"
          :loop="true"
          :autoPlay="true"
          :speed="1"
      />
    </div>
    <table class="history-orders-table" v-if="!isLoading && topReferrals.length">
      <tbody class="order-history-table-main">
      <members-items
          v-for="(referral) in topReferrals"
          :key="referral.rating"
          :customClass="token && currentReferralUser && referral.userAddress === currentReferralUser.userAddress ? 'current-leader' : ''"
          :referral="referral"
      />
      <tr class="table-body" v-if="token && currentReferralUser && currentReferralUser.rating > topReferrals.length && topReferrals.length >= 8 && currentReferralUser.rating > 9">
        <td class="top-referral-empty-row">...</td>
        <td class="top-referral-empty-row"></td>
        <td class="top-referral-empty-row"></td>
      </tr>
      <members-items
          v-if="token && currentReferralUser && currentReferralUser.rating > topReferrals.length && topReferrals.length > 1"
          :customClass="'current-leader'"
          :referral="currentReferralUser"
      />

      <tr class="table-body" v-if="token && currentReferralUser && looserReferralUser && looserReferralUser.rating > 8 && looserReferralUser.rating > currentReferralUser.rating && looserReferralUser.rating > 9">
        <td class="top-referral-empty-row">...</td>
        <td class="top-referral-empty-row"></td>
        <td class="top-referral-empty-row"></td>
      </tr>
      <members-items
          v-if="token && looserReferralUser && currentReferralUser && looserReferralUser.rating > 8 && looserReferralUser.rating > currentReferralUser.rating"
          :referral="looserReferralUser"
          :customClass="token && currentReferralUser && looserReferralUser.userAddress === currentReferralUser.userAddress ? 'current-leader' : ''"
      />


      <tr class="table-body"  v-if="!token && looserReferralUser && looserReferralUser.rating > 8 && looserReferralUser.rating > 9">
        <td class="top-referral-empty-row">...</td>
        <td class="top-referral-empty-row"></td>
        <td class="top-referral-empty-row"></td>
      </tr>
      <members-items
          v-if="!token && looserReferralUser && looserReferralUser.rating > 8"
          :referral="looserReferralUser"
          :customClass="currentReferralUser && looserReferralUser.userAddress === currentReferralUser.userAddress ? 'current-leader' : ''"
      />
      </tbody>
    </table>
  </div>
</template>

<script>
import LottieAnimation from "lottie-web-vue";
import MembersItems from "@/components/leaderboard/MembersItems.vue";
export default {
  name: "RoundsOfRewardsItemsList",
  components: {
    LottieAnimation,
    MembersItems,
  },
  props: {
    topReferrals: Array,
    currentReferralUser: {},
    looserReferralUser: {},
    token: String,
    isLoading: Boolean,
  },
  data() {
    return {

    }
  }
}
</script>

<style scoped>
.table-head th.rank {
  width: 48px;
  padding-left: 20px;
}

.table-head th.profit {
  padding-right: 20px;
}

.table-head th.profit p {
  text-align: right;
}

.top-referral-empty-row {
  padding-left: 20px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #E9E9E9;
}

.current-leader {
  background: rgba(66, 232, 224, 0.1) !important;
}

.leaderboard-wrapper {
  position: relative;
  min-height: 320px;
}

</style>