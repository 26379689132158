<template>
  <tr class="table-body">
    <td class="pair" :data-label="$t('Symbol')">
      <span
          :style="historyOrdersItems.side === 'BUY' ? 'border-left: 4px solid #42E8E0;' : 'border-left: 4px solid #f27870'">{{ splitCoin(historyOrdersItems.symbol) }}</span>
    </td>
    <td class="type" :data-label="$t('type')">
      <span>{{
          $t(historyOrdersItems.type.toLowerCase()[0].toUpperCase() + historyOrdersItems.type.toLowerCase().slice(1).replace('_', ' '))
        }}</span>
    </td>
    <td class="amount" :data-label="$t('amount') + ' / ' + $t('Filled')">
      <span>{{ historyOrdersItems.amount.toFixed(2) }} / {{ historyOrdersItems.filled.toFixed(2) }}</span>
      <span class="secondary-text"> {{ coinName }}</span>
    </td>
    <td class="price" :data-label="$t('priceTextPrice')">
      <span>{{
          historyOrdersItems.type === "MARKET"
              ? '-' :
              historyOrdersItems.averageExecutionPrice === null ? toPrecisionNumber(historyOrdersItems.price) : toPrecisionNumber(historyOrdersItems.averageExecutionPrice)
        }}</span>
      <span class="secondary-text"> {{ tokenName }}</span>
    </td>
    <td class="side" :data-label="$t('Side')">
      <span :class="historyOrdersItems.side === 'BUY' ? 'buy' : 'sell'">
        {{ $t(historyOrdersItems.side.toLowerCase()[0].toUpperCase() + historyOrdersItems.side.toLowerCase().slice(1)) }}
      </span>
    </td>
    <td class="status" :data-label="$t('Status')">
      <span>
        {{ $t(historyOrdersItems.status.toLowerCase()[0].toUpperCase() + historyOrdersItems.status.toLowerCase().slice(1).replace('_', ' ')) }}
      </span>
    </td>
    <td class="time" :data-label="$t('time')">
      <span>{{formatterDate(historyOrdersItems.time)}} </span>
      <span class="secondary-text">{{ formatterTime(historyOrdersItems.time)}}</span>
    </td>
    <!-- <div class="holder-checkbox" style="width: 5%; text-align: right">
      <label class="orange-checkbox-container">
        <input type="checkbox" :checked="isCheckedOrders" />
        <span class="checkmark"></span>
      </label>
    </div> -->
  </tr>
</template>

<script>
import moment from "moment";
import toPrecisionNumber from "../../../mixins/toPrecisionNumber";

export default {
  name: "TradingOrderHistoryItems",
  props: {
    historyOrdersItems: {
      type: Object,
    },
    index: {
      type: Number,
    },
    isCheckedOrders: {
      type: Boolean,
    }
  },
  mixins: [toPrecisionNumber],
  data() {
    return {
      //   isCheckedOrders: isCheckedOrders
    }
  },
  // mounted() {
  //   console.log('item', this.openOrdersItems)
  // },
  computed: {
    coinName() {
      return this.historyOrdersItems.symbol.split('_')[0].toUpperCase()
    },
    tokenName() {
      return this.historyOrdersItems.symbol.split('_')[1].toUpperCase()
    }
  },
  methods: {
    onDeleteOrder(order) {
      this.$emit('onDeleteOrder', order);
    },
    prettify(num) {
      num = Number(num).toLocaleString("en-US")
      return num
    },
    splitCoin(coin) {
      return coin.split('_').join('/').toUpperCase();
    },
    formatterDate: function (value) {
      return moment.utc(value).local().format('DD/MM/yy');
    },
    formatterTime: function (value) {
      return moment.utc(value).local().format('HH:mm');
    }
  },
};
</script>

<style lang="scss" scoped>
.table-body {

}

.list {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 12px;
}

.pair {
  width: 10%;

  span {
    display: inline-block;
    padding-left: 4px;
  }
}

.type {
  text-align: right;
  width: 20%;
}

.amount {
  text-align: right;
  width: 20%;
  white-space: nowrap;
}

.price {
  text-align: right;
  width: 20%;
}

.time {
  text-align: right;
  width: 20%;
}

.side .buy {
  color: #1BB8B0;
}

.side .sell {
  color: #f27870;
}

// checkbox
.orange-checkbox-container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.orange-checkbox-container input {
  display: none;
}

.checkmark {
  display: block;
  height: 14px;
  width: 14px;
  border: 1px solid var(--v-textMainColor-base);
  border-radius: 3px;
}

.orange-checkbox-container:hover input ~ .checkmark {
  background-color: var(--v-textAccentColor-base);
}

.orange-checkbox-container input:checked ~ .checkmark {
  background-color: #FFB000;
  border: none;
  border-radius: 3px;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.orange-checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.holder-checkbox {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

// end checkbox
.secondary-text {
  color: var(--v-textSecondaryColor-base)
}
</style>
