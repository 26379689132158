let toPrecisionNumber = {
    methods: {
        toPrecisionNumber(number, ) {
            if(number === 0) return 0
            if(number === null) return 0
            if(number && number !== '-') {
                return +number < 1 ? number.toPrecision(5) : number.toPrecision(6)
            }
            if(number === '-') {
                return '-'
            }
        },
    }
}

export default toPrecisionNumber
