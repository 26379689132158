<template>
  <div id="my-content">
    <Header
        @changeTheme="changeTheme"
        @windowWidth="windowWidthListener"
    />
    <main id="root">
      <div class="public-page">
        <div class="public-page-sidebar" v-if="desktop">
          <PagesSidebar
              :itemList="sidebarItemList"
              :title="$t('Personal Account')"
              :icon="require('@/assets/img/personal-account-icon.svg')"
          />
          <div class="wallet-address-wrapper" v-if="cosmosWallet">
            <div class="wallet-address-info">
              <span class="wallet-address-info-title">Dexilon Chain address:</span>
              <span class="wallet-address-info-description">{{modifyAddress(cosmosWallet)}}</span>
            </div>
            <div class="wallet-address-action">
              <a
                  class="wallet-action"
                  @click="copyText"
              >
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16 13.4V17.6C16 21.1 14.6 22.5 11.1 22.5H6.9C3.4 22.5 2 21.1 2 17.6V13.4C2 9.9 3.4 8.5 6.9 8.5H11.1C14.6 8.5 16 9.9 16 13.4Z" stroke="#2DCAC2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M22 7.4V11.6C22 15.1 20.6 16.5 17.1 16.5H16V13.4C16 9.9 14.6 8.5 11.1 8.5H8V7.4C8 3.9 9.4 2.5 12.9 2.5H17.1C20.6 2.5 22 3.9 22 7.4Z" stroke="#2DCAC2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </a>
              <a
                  :href="`${bigDipperUrl}/accounts/${cosmosWallet}`"
                  class="wallet-action"
                  target="_blank"
              >
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13 11.5L21.2 3.3M22 7.3V2.5H17.2M11 2.5H9C4 2.5 2 4.5 2 9.5V15.5C2 20.5 4 22.5 9 22.5H15C20 22.5 22 20.5 22 15.5V13.5" stroke="#2DCAC2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div class="public-page-content-wrap">
          <div class="sidebarBurger" @click="$refs.TabletVersionOfSidebar.openSidebar()" v-if="tablet">
            <span class="burger-lines"></span>
          </div>
          <TabletVersionOfSidebar ref="TabletVersionOfSidebar" :itemList="sidebarItemList" v-if="tablet"/>
          <router-view />
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import Header from "../components/header/Header";
import PagesSidebar from "@/components/layout/PagesSidebar";
import TabletVersionOfSidebar from "../components/layout/TabletVersionOfSidebar";
import {mapGetters} from "vuex";

export default {
  name: "KnowledgeBase",
  data() {
    return {
      bigDipperUrl: process.env.VUE_APP_BIG_DIPPER_URL,
      desktop: true,
      tablet: false,
      mobile: false,
      sidebarItemList: [
        {
          name: 'Open Orders',
          routeTo: '/reports/open-orders',
        },
        {
          name: 'Order History',
          routeTo: '/reports/order-history',
        },
        {
          name: 'Trade History',
          routeTo: '/reports/trade-history',
        },
        {
          name: 'Transaction History',
          routeTo: '/reports/transaction-history',
          query: { page: 1, type: 'FUTURE' }
        },
        {
          name: 'Fees',
          routeTo: '/reports/fees',
        },
        {
          name: 'Positions',
          routeTo: '/reports/positions',
        },
        {
          name: 'API Management',
          routeTo: '/reports/api-management',
        },
      ]
    }
  },
  methods: {
    modifyAddress(str) {
      return `${str.substring(0,6)}....${str.slice(str.length - 4)}`;
    },
    copyText() {
      navigator.clipboard.writeText(`${this.cosmosWallet}`);
    },
    changeTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem(
          "theme",
          this.$vuetify.theme.dark ? "dark" : "light"
      );
    },
    sidebarClickOutside(event) {
      if (
          !this.desktop
          && !event.target.closest('.nav-wrapper')
          && !event.target.closest('.sidebarBurger')
      ) {
        this.$refs.TabletVersionOfSidebar.closeSideBar()
      }
    },
    windowWidthListener(e) {
      if (e < 1025) {
        this.desktop = false
        this.tablet = true
        this.mobile = false
        // return
      } else {
        this.desktop = true
        this.tablet = false
        this.mobile = false
      }
    }
  },
  computed: {
    ...mapGetters('wallets', ['getMetamaskWallet', 'getBinanceWallet']),
    cosmosWallet() {
      return this.$store.state.wallets.metaMaskWallet.authData ? this.$store.state.wallets.metaMaskWallet.authData.granterWallet.address : ''
    }
  },
  mounted() {
    document.addEventListener('click', this.sidebarClickOutside)
    let width = window.innerWidth
    this.windowWidthListener(width)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.sidebarClickOutside)
  },
  components: {
    Header,
    PagesSidebar,
    TabletVersionOfSidebar
  },
}
</script>

<style scoped>
.wallet-address-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 20px;
  width: calc(100% - 40px);
  margin: 0 auto;
  padding: 10px;
  background: #393939;
  border-radius: 6px;
}

.wallet-address-info {
  width: calc(100% - 50px);
}

.wallet-address-info-title {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #92939C;
  display: block;
  width: 100%;
}

.wallet-address-info-description {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #E9E9E9;
}

.wallet-address-action {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.wallet-action {
  width: 20px;
  height: 20px;
}

.wallet-action:not(:last-child) {
  margin-right: 14px;
}

.wallet-action svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.wallet-action svg path {
  transition: all .5s;
}

.wallet-action:hover svg path {
  stroke: #fff;
}

</style>
