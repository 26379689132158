// import pako from "pako";
// import dayJS from "dayjs";
import { EventEmitter } from "events";
function isOpen(ws) { return ws.readyState === ws.OPEN }
class TvWebSocket {
  url = `${process.env.VUE_APP_WS_URL}/stream`;
  ws = null;
  success = {};
  failure = {};
  timer = null;
  closeFlag = null;
  pingPongInterval = null;
  evt = new EventEmitter();

  initWebSocket() {
    if(this.ws && this.ws.readyState === 1) {
      return
    }
    this.ws = new WebSocket(this.url, [], {handshakeTimeout: 2000});
    this.ws.binaryType = "arraybuffer";
    this.ws.onopen = this.onopen.bind(this);
    this.ws.onclose = this.onclose.bind(this);
    this.ws.onerror = this.onerror.bind(this);
    this.ws.onmessage = this.onmessage.bind(this);
    // console.log(" >> WebSocket init :", this.url);
  }

  onopen() {
    clearInterval(this.pingPongInterval)
    // console.log(" >> WebSocket open...");
    if (!isOpen(this.ws)) return;
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
      for (const key in this.success) {
        this.ws.send(this.success[key]);
        // console.log('this.success', this.success)
        // console.log(` >> WebSocket send: ${this.success[key]}`);
      }
    }
    for (const key in this.failure) {
      // if (this.success[key]) {
      //   continue;
      // }
      this.ws.send(this.failure[key]);
      this.success[key] = this.failure[key];
      // console.log(` >> WebSocket send failure: ${this.failure[key]}`);
    }
    // this.failure = {};

    this.pingPongInterval = setInterval(() => {
      this.heartbeat()
    }, 5000)
  }

  // onopen() {
  //   if (!this.ws) {
  //     return;
  //   }
  //   if (!isOpen(this.ws)) return;
  //   // console.log(" >> WebSocket open...");
  //   if (this.timer) {
  //     clearInterval(this.timer);
  //     this.timer = null;
  //     // 断线重新订阅
  //     for (const key in this.success) {
  //       this.ws.send(this.success[key]);
  //       // console.log(` >> WebSocket send: ${this.success[key]}`);
  //     }
  //   }
  //   // 失败重新订阅
  //   for (const key in this.failure) {
  //     if (this.success[key]) {
  //       continue;
  //     }
  //     this.ws.send(this.failure[key]);
  //     this.success[key] = this.failure[key];
  //     // console.log(` >> WebSocket send: ${this.failure[key]}`);
  //   }
  //   this.failure = {};
  //
  //   this.pingPongInterval = setInterval(() => {
  //     this.heartbeat()
  //   }, 5000)
  // }

  onclose(event) {
    // console.log('event', event)
    clearInterval(this.pingPongInterval)
    if (event.wasClean) {
      this.close()
    } else {
      // if (!this.timer) {
      //   if(!this.closeFlag) {
      //     this.close()
      //     this.onReconnection()
      //   }
      // }
      if (!this.timer) {
        this.close()
        this.onReconnection()
      }
    }
    // console.log(" >> Websocket Close...");
  }

  close() {
    clearInterval(this.pingPongInterval)
    this.closeFlag = true
    if(this.ws) {
      this.ws.close();
      // this.ws.terminate();
    }

    // console.log(" >> Websocket Close2...");
    // clearInterval(this.timer);
    // this.timer = null;
  }

  onerror(event) {
    if(this.ws) {
      this.ws.close();
    }
    // console.log(" >> Websocket Error...", event);
  }
  onmessage(event) {
    if (!event.data) {
      return;
    }
    // const text = pako.inflate(event.data, {
    //   to: "string",
    // });

    const text = event.data
    const data = JSON.parse(text);
    // console.log("---inflate----", data);
    if (data && data.ping) {
      this.ws?.send(
          JSON.stringify({
            pong: Date.now(),
          })
      );
      return;
    }
    this.onBroadcast(data);
  }

  heartbeat() {
    if(!this.ws || this.ws.readyState !== 1) {
      return
    }

    this.ws?.send(JSON.stringify({"method": "PING","params":[]}))
  }

  onBroadcast(msg) {

    if(msg.constructor.name == "Array" && msg.length === 0) {
      return
    }

    if (msg.eventType === 'expiredSessionWarning') {
      this.ws?.send(JSON.stringify({"method": "EXTEND_SESSION","params":["MIN_30"]}))
    }

    let topic = null

    if(msg.eventType === 'candlestick') {
      topic = `${msg.symbol}@${msg.eventType}_${msg.interval.toLowerCase()}`
    }

    if(msg.eventType === 'aggTrade' || msg.eventType === 'liquidationAuction' || msg.eventType === 'orderBook' || msg.constructor.name == "Array" && msg[0].eventType === 'markPrice' || msg.constructor.name == "Array" && msg[0].eventType === 'ticker'){
      topic = `AllPublicData`
    }

    // if(msg.eventType === 'aggTrade' || msg.eventType === 'orderBook'){
    //   topic = `${msg.symbol}@orderBook,${msg.symbol}@aggTrade`
    // }
    //
    // if(msg.constructor.name == "Array" && msg[0].eventType === 'markPrice'){
    //   topic = `!markPrice_arr_1s`
    // }
    //
    if(msg.constructor.name == "Array" && window.location.pathname === '/trading-data/funding-rate' && msg[0].eventType === 'markPrice'){
      topic = `FoundingRate`
    }
    //
    // if( msg.eventType === 'liquidationAuction'){
    //   topic = `liquidationAuction`
    // }
    //
    // if(msg.constructor.name == "Array" && msg[0].eventType === 'ticker'){
    //   topic = `!ticker_arr`
    // }


    // if(msg.constructor.name == "Array"){
    //   if (msg[0].eventType === 'markPrice' || msg[0].eventType === 'ticker') {
    //     topic = `!markPrice_arr_3s,!ticker_arr`
    //   }
    // }

    // if (!this.success[msg.eventType]) {
    //   return;
    // }

    // alert(1)

    // console.log('msg', msg)

    this.evt.emit(topic, msg);
  }

  subscribe(name, params, callback) {
    // console.log('name11111', name)
    // console.log('name1111122', params)
    let ee;
    if (!this.ws || this.ws.readyState !== WebSocket.OPEN) {
      ee = this.failurePush(name, params, callback);
    } else {
      ee = this.successPush(name, params, callback);
    }
    return {
      remove: () => {
        this.unsubscribe(name);
        ee.removeAllListeners(name);
      },
    };
  }

  unsubscribe(name) {
    if (this.failure[name]) {
      delete this.failure[name];
    }
    if (!this.success[name]) {
      return;
    }
    if (!this.ws) {
      delete this.success[name];
      return;
    }

    const unsub = JSON.parse(this.success[name]);
    unsub.method = "UNSUBSCRIBE";
    this.ws?.send(JSON.stringify(unsub));
    this.evt.removeAllListeners(name);
    // console.log(` >> WebSocket send: ${JSON.stringify(unsub)}`);
    delete this.success[name];
  }

  successPush(name, params, callback) {
    // console.log('params', params)
    this.success[name] = JSON.stringify(params);
    this.ws?.send(JSON.stringify(params));
    // console.log(` >> WebSocket send: ${this.success[name]}`);
    // console.log(` >> name: ${name}`);
    // console.log(` >> callback: ${callback}`);
    return this.evt.on(name, callback);
  }

  failurePush(name, params, callback) {
    this.failure[name] = JSON.stringify(params);
    // console.log(` >> WebSocket 准备订阅: ${this.failure[name]}`);
    return this.evt.on(name, callback);
  }

  onReconnection() {
    if (!this.url) {
      return;
    }
    this.initWebSocket();
    clearInterval(this.timer);
    this.timer = setInterval(() => {
      this.initWebSocket();
      // const now = dayJS().format("YYYY-MM-DD HH:mm:ss");
      // console.log(` >> [${now}] WebSocket Reconnect....`);
    }, 10000);
  }
}


export const ws = new TvWebSocket();