export default {
    getTakerVolume({commit}, {symbol, dimension}) {
        return new Promise((resolve, reject) => {
            $axios.get(`tradingData/takerVolume?symbol=${symbol}&dimension=${dimension}`).then((res) => {
                commit('SET_TAKER_VOLUME', res.data);
                resolve(res);
            })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getOpenInterest({commit}, {symbol, dimension}) {
        return new Promise((resolve, reject) => {
            $axios.get(`tradingData/openInterest?symbol=${symbol}&dimension=${dimension}`).then((res) => {
                commit('SET_OPEN_INTEREST', res.data);
                resolve(res.data);
            })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getIndexPriceHistory({commit}, {symbol, timeScope}) {
        return new Promise((resolve, reject) => {
            $axios.get(`tradingData/indexPrice/history?symbol=${symbol}&timeScope=${timeScope}`).then((res) => {
                commit('SET_INDEX_PRICE_HISTORY', res.data);
                resolve(res);
            })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getFundingRateHistory({commit}, {symbol, timeScope}) {
        return new Promise((resolve, reject) => {
            $axios.get(`tradingData/fundingRate/history?symbol=${symbol}&timeScope=${timeScope}`).then((res) => {
                commit('SET_FUNDING_RATE_HISTORY', res.data);
                resolve(res);
            })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};
