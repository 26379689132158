export default {
    metaMaskWallet: {
        address: null,
        balance: null,
        networkName: null,
        networkId: null,
        disconected: false,
        currency: null,
        convertedBalance: null,
        auth: null,
        jwt: null,
        error: null,
        errorTitle: null,
        authData: null,
      },

      binanceWallet: {
        address: null,
        balance: null,
        networkName: null,
        networkId: null,
        disconected: false,
        currency: null,
        convertedBalance: null,
        auth: null,
        jwt: null,
      },
    preloader: false,
    spotBalance: {},
    featuresBalance: 0,
    tradingVolume30d: null,
  };
