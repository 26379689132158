import { ethers } from 'ethers';
import { Bip39, Random } from '@cosmjs/crypto';
import { DirectSecp256k1HdWallet } from '@cosmjs/proto-signing';

export function randomInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function getRandomEthAddress() {
    return ethers.Wallet.createRandom();
}

export function CosmosWalletData() {
    const walletData = {
        wallet: DirectSecp256k1HdWallet,
        address: '',
        mnemonic: ''
    }
    return walletData
}

export async function getRandomCosmosAddress(mainWallet) {
    const mnemonic = Bip39.encode(Random.getBytes(16)).toString();
    const wallet = await DirectSecp256k1HdWallet.fromMnemonic(mnemonic);
    // const [{ address }] = await wallet.getAccounts();


    if(mainWallet) {
        let address = mainWallet;
        return { wallet, address, mnemonic };
    }else {
        const [{ address }] = await wallet.getAccounts();
        return { wallet, address, mnemonic };
    }
}
