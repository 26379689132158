<template>
  <div id="my-content">
    <Header
        @changeTheme="changeTheme"
        @windowWidth="windowWidthListener"
    />
    <main id="root">
      <div class="chart-page">
        <div class="public-page-sidebar" v-if="desktop">
          <PagesSidebar
              :itemList="sidebarItemList"
              :title="$t('Trading Data')"
              :icon="require('@/assets/img/report-icon.svg')"
          />
        </div>
        <div class="public-page-content-wrap">
          <div
              class="sidebarBurger"
              @click="$refs.TabletVersionOfSidebar.openSidebar()"
              v-if="tablet"
          >
            <span class="burger-lines"></span>
          </div>
          <TabletVersionOfSidebar
              ref="TabletVersionOfSidebar"
              :itemList="sidebarItemList"
              v-if="tablet"
          />
<!--          <h2 class="trading-title">{{ $t('Trading Data') }}</h2>-->
          <router-view/>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import Header from "../components/header/Header";
import PagesSidebar from "../components/layout/PagesSidebar";
import TabletVersionOfSidebar from "@/components/layout/TabletVersionOfSidebar";

export default {
  name: "TradingData",
  data() {
    return {
      desktop: true,
      tablet: false,
      mobile: false,
      sidebarItemList: [
        {
          name: 'Open Interest',
          routeTo: '/trading-data/open-interest',
        },
        // {
        //   name: 'Top Trader Long/Short Ratio (Accounts)',
        //   routeTo: '/trading-data/trader-ratio-accounts',
        // },
        // {
        //   name: 'Top Trader Long\/Short Ratio (Position)',
        //   routeTo: '/trading-data/trader-ratio-positions',
        // },
        // {
        //   name: 'Long\/Short Ratio',
        //   routeTo: '/trading-data/long-short-ratio',
        // },
        {
          name: 'Taker Buy\/Sell Volume',
          routeTo: '/trading-data/taker-volume',
        },
        {
          name: 'Real-Time Funding Rate',
          routeTo: '/trading-data/funding-rate',
        },
        {
          name: 'Funding Rate History',
          routeTo: '/trading-data/funding-rate-history',
        },
        {
          name: 'Index Price',
          routeTo: '/trading-data/index-price',
        },
      ]
    }
  },
  methods: {
    sidebarClickOutside(event) {
      if (
          !this.desktop
          && !event.target.closest('.nav-wrapper')
          && !event.target.closest('.sidebarBurger')
      ) {
        this.$refs.TabletVersionOfSidebar.closeSideBar()
      }
    },
    changeTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem(
          "theme",
          this.$vuetify.theme.dark ? "dark" : "light"
      );
      this.chartOptionsOpenInterest.chart.backgroundColor = this.$vuetify.theme.dark ? "#2E2D2D" : "#FCFCFC"
      this.chartOptionsTopTraderAccounts.chart.backgroundColor = this.$vuetify.theme.dark ? "#2E2D2D" : "#FCFCFC"
      this.chartOptionsTopTraderPositions.chart.backgroundColor = this.$vuetify.theme.dark ? "#2E2D2D" : "#FCFCFC"
      this.chartOptions.chart.backgroundColor = this.$vuetify.theme.dark ? "#2E2D2D" : "#FCFCFC"
      this.chartOptionsTaker.chart.backgroundColor = this.$vuetify.theme.dark ? "#2E2D2D" : "#FCFCFC"
    },
    windowWidthListener(e) {
      if (e < 1025) {
        this.desktop = false
        this.tablet = true
        this.mobile = false
      } else {
        this.desktop = true
        this.tablet = false
        this.mobile = false
      }
    }
  },
  components: {
    Header,
    PagesSidebar,
    TabletVersionOfSidebar
  },
  mounted() {
    document.addEventListener('click', this.sidebarClickOutside)
    let width = window.innerWidth
    this.windowWidthListener(width)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.sidebarClickOutside)
  }
}
</script>

<style>
.v-application .chart-page {
  min-height: calc(100vh - 81px); /* 80px - высота  header */
  background: var(--v-primary-base) !important;
}

.chart-page {
  display: flex;
}

.chart-item {
  margin-bottom: 80px;
}

.chart-item-title {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #FFFFFF;
  margin-top: 0;
  margin-bottom: 20px;
}

.trading-title {
  margin-bottom: 15px;
  font-size: 36px;
}
</style>
