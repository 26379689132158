<template>
  <v-dialog v-model="active">
    <div class="message-wrapper primary mainBorderColor">
      <div
          class="close-dialog"
          @click="active = false"
      >
        <img
            alt="close-icon"
            src="../../assets/img/close.svg"
        />
      </div>
      <h2 class="message-wrapper-title textMainColor">{{ $t('Create API') }}</h2>
      <p class="message-text">{{ $t('Creating Api Description') }}</p>
      <div class="create-api-form">
        <label>
          {{ $t('Label API key to proceed') }}
          <input
              v-model="apiKeyLabel"
              :placeholder="$t('API key label')"
              type="text"
              v-on:keyup.enter="createApi"
          >
        </label>
        <span
            v-if="!isValid"
            class="message-warning"
        >
          <svg
              fill="none"
              height="12"
              viewBox="0 0 12 13"
              width="12"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                d="M1.6875 2.1875L5.99996 6.49996M5.99996 6.49996L10.3124 2.1875M5.99996 6.49996L10.3125 10.8124M5.99996 6.49996L1.68758 10.8124"
                stroke="#FF7337"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
            />
          </svg>
          Please enter API key label
        </span>
        <button
            type="button"
            @click="createApi"
        >{{ $t('Create API') }}
        </button>
        <router-link to="/reports/api-management">View API documentation</router-link>
      </div>
    </div>
  </v-dialog>
</template>
<script>
export default {
  name: 'CreateApi',
  data() {
    return {
      active: true,
      apiKeyLabel: '',
      isValid: true,
    };
  },

  methods: {
    createApi() {
      if(this.apiKeyLabel) {
        this.apiKeyLabel = '';
      } else {
        this.isValid = false;
      }
    },
  },

  watch: {
    apiKeyLabel() {
      this.isValid = true;
    },
  },
};
</script>
<style scoped>
.message-wrapper {
  box-shadow: 0 15px 20px -6px rgba(0, 0, 0, 0.25);
  padding: 20px 50px 25px;
  position: relative;
  margin: 0 auto;
  border: 1px solid;
  border-radius: 8px;
  max-width: 460px;
}

.message-wrapper-title {
  margin-bottom: 15px;
  text-align: center;
}

.message-text {
  margin-bottom: 25px;
  font-size: 12px;
  text-align: center;
  color: #a4a5af;
}

.create-api-form label {
  font-size: 14px;
  font-weight: 600;
  color: #cecece;
}

.create-api-form input,
.create-api-form button {
  border-radius: 6px;
  padding: 12px;
}

.create-api-form input {
  display: block;
  margin-right: 8px;
  width: 100%;
  background-color: var(--v-loginDropduwnBackgroundColor-base);
  color: var(--v-revertPrimary-base);
}

.create-api-form button {
  display: block;
  transition: background-color 0.5s;
  margin: 36px auto 8px;
  width: 150px;
  background: var(--v-loginButtonIconHoverColor-base);
  color: #fff;
}

.message-warning {
  display: flex;
  position: absolute;
  align-items: center;
  font-size: 11px;
  color: #F27870;
}

.create-api-form a {
  display: block;
  font-size: 12px;
  text-align: center;
  color: #42e8e0;
}

.create-api-form button:hover {
  background: #67ca9d;
}

.create-api-form input:hover,
.create-api-form input:focus-visible {
  outline: 1px solid #42e8e0;
}

</style>
