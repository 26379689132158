<template>
  <div class="notification-block" :class="{'inactive': isShowPopup}" v-if="showPopup">
    <div class="notification-content">
      <span class="notification-title">{{$t('A new update is available!')}}</span>
      <p class="notification-description">{{$t('Update to get the latest version.')}}</p>
      <div class="notification-btn-wrapper">
        <button class="notification-btn notification-transparent-btn" @click="closePopup">
          <span>{{$t('Remind Later')}}</span>
        </button>
        <button class="notification-btn notification-btn-confirm" @click="confirm">
          <span>{{$t('Confirm')}}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotificationsPopup",
  data() {
    return {
      isShowPopup: false,
    }
  },
  methods: {
    confirm() {
      this.$emit('confirm');
      this.isShowPopup = false;
    },
    showPopup() {
      this.isShowPopup = true;
    },
    closePopup() {
      this.isShowPopup = false;
    }
  }
}
</script>

<style scoped>

.notification-block {
  background-image: url('https://dexilon.io/wp-content/themes/dexilon/assets/img/notification-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  width: 600px;
  background-color: #1E1E1E;
  border-width: 1px 1px 0px 1px;
  border-style: solid;
  border-color: #2E2D2D;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-radius: 16px;
  padding: 32px;
  position: fixed;
  right: -1000px;
  opacity: 0;
  bottom: 20px;
  z-index: 100;
  transition: all 1s;
}

.notification-block.inactive {
  right: 20px;
  opacity: 1;
}

.notification-content {
  padding-left: 145px;
}

.notification-title {
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  display: block;
  margin-bottom: 10px;
}

.notification-description {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #92939C;
  opacity: 0.9;

}
</style>