<template>
  <div id="my-content">
    <Header
        @changeTheme="changeTheme"
        @windowWidth="windowWidthListener"
    />
    <main id="root">
      <div class="public-page">
        <div
            v-if="desktop"
            class="public-page-sidebar"
        >
          <nav>
            <li
                v-for="(item, index) in infoList"
                :key="index"
                :class="{active: isLinkActive(item.routeTo)}"
            >
              <router-link
                  class="textSecondaryColor"
                  :to="{name: 'Info', hash: `#${item.routeTo}`}"
              >
                <span>{{ $t(item.title) }}</span>
              </router-link>
            </li>
          </nav>
        </div>
        <div class="public-page-content-wrap">
          <article
              v-for="(item, index) in infoList"
              :key="index"
          >
            <h3
                class="textMainColor"
                :id="item.routeTo"
                v-text="$t(item.title)"
                :ref="item.routeTo"
            />
            <div v-html="$t(item.content)" />
          </article>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import Header from '../components/header/Header';

export default {
  name: 'Info',
  components: {
    Header,
  },
  data() {
    return {
      desktop: true,
      tablet: false,
      mobile: false,
      infoList: [
        {
          title: 'WhatIsDexilon',
          routeTo: 'what-is-dexilon',
          content: 'WhatIsDexilonContent',
        },
        {
          title: 'Testnet Description',
          routeTo: 'testnet-description',
          content: 'TestnetDescriptionContent',
        },
        {
          title: 'Perpetual Swap Contract',
          routeTo: 'perpetual-swap-contract',
          content: 'PerpetualSwapContractContent',
        },
        {
          title: 'Matching Engine',
          routeTo: 'matching-engine',
          content: 'MatchingEngineContent',
        },
        {
          title: 'Order types on Dexilon',
          routeTo: 'order-types-on-dexilon',
          content: 'OrderTypesOnDexilonContent',
        },
        {
          title: 'Margin Modes',
          routeTo: 'margin-modes',
          content: 'MarginModesContent',
        },
        {
          title: 'Number Formats',
          routeTo: 'number-formats',
          content: 'NumberFormatsContent',
        },
        {
          title: 'Rate Limits',
          routeTo: 'rate-limits',
          content: 'RateLimitsContent',
        },
        {
          title: 'Mark Price',
          routeTo: 'mark-price',
          content: 'MarkPriceContent',
        },
      ],
    };
  },
  methods: {
    changeTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem(
          'theme',
          this.$vuetify.theme.dark ? 'dark' : 'light',
      );
    },
    windowWidthListener(e) {
      if(e < 1025) {
        this.desktop = false;
        this.tablet = true;
        this.mobile = false;
        // return
      } else {
        this.desktop = true;
        this.tablet = false;
        this.mobile = false;
      }
    },
    isLinkActive(routeTo) {
      return this.$route.hash === `#${routeTo}`
    }
  },
};
</script>
<style
    lang="scss"
    scoped
>
.public-page-sidebar nav {
  position: sticky;
  top: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.public-page-sidebar nav li {
  display: block;
  margin-bottom: 15px;
  width: 100%;
}

.public-page-sidebar nav li a {
  position: relative;
  transition: all .5s;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  line-height: 24px;
  text-decoration: none;
}

.public-page-sidebar nav li a.textSecondaryColor:hover {
  color: var(--v-textMainColor-base) !important;
}

.public-page-sidebar nav li a span {
  display: inline-block;
  position: relative;
}

.public-page-sidebar nav li a span:before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  opacity: 0;
  transition: all .5s;
  width: 100%;
  height: 1px;
  background: #42e8e0;
}

.public-page-sidebar nav li a:hover span:before {
  opacity: 1;
}

.public-page-sidebar nav li.active a span:before {
  opacity: 1;
}

.public-page-sidebar nav li.active a {
  color: var(--v-textMainColor-base) !important;
}

.public-page-content-wrap h3 {
  margin-bottom: 20px;
}
</style>
