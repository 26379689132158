<!--<template>-->
<!--  <div class="report-template">-->
<!--    <div class="report-header">-->
<!--      <h1 class="report-title textMainColor">{{ $t('Tournaments') }}</h1>-->
<!--    </div>-->
<!--    <div class="report-filter-wrapper">-->
<!--      <h1 class="report-subtitle textSecondaryColor">{{ $t('Agree to participate') }}</h1>-->
<!--      <div class="switcher-wrapper">-->
<!--        <span>{{ $t('No') }}</span>-->
<!--        <v-switch-->
<!--            v-model="participation"-->
<!--            @change="$emit('changeParticipation')"-->
<!--            class="participation-switcher"-->
<!--        />-->
<!--        <span>{{ $t('Yes') }}</span>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="tournament-table">-->
<!--      <table>-->
<!--        <thead class="holder-table">-->
<!--        <tr class="table-head">-->
<!--          <th class="medal"></th>-->
<!--          <th class="rank">{{ $t('Rank') }}</th>-->
<!--          <th class="metamask-wallet">{{ $t('Metamask Wallet') }}</th>-->
<!--          <th class="p-l-per">PL %</th>-->
<!--          <th class="p-l">PL $</th>-->
<!--        </tr>-->
<!--        </thead>-->
<!--        <tbody>-->
<!--        <tr class="table-body transaction-history-wrap">-->
<!--          <td class="medal" :data-label="$t('Your Rank')">-->
<!--            <div class="user-rank">{{ $t('Your Rank') }}</div>-->
<!--          </td>-->
<!--          <td class="rank" :data-label="$t('Rank')">245</td>-->
<!--          <td class="metamask-wallet" :data-label="$t('Metamask Wallet')">-->
<!--            <span>0xaec766463922244a091fcb7b8f11045839eeeb3d</span>-->
<!--          </td>-->
<!--          <td class="p-l-per" :data-label="$t('PL %')">25</td>-->
<!--          <td class="p-l" :data-label="$t('PL $')">34 256</td>-->
<!--        </tr>-->
<!--        <tr-->
<!--            class="table-body transaction-history-wrap"-->
<!--            v-for="(item, index) in 10"-->
<!--            :key="item"-->
<!--        >-->
<!--          <td class="medal">-->
<!--            <img v-if="index === 0" src="@/assets/img/gold-cup.png" alt="first user">-->
<!--            <img v-else-if="index === 1" src="@/assets/img/silver-cup.png" alt="second user">-->
<!--            <img v-else-if="index === 2" src="@/assets/img/bronze-cup.png" alt="third user">-->
<!--          </td>-->
<!--          <td class="rank" :data-label="$t('Rank')">1</td>-->
<!--          <td class="metamask-wallet" :data-label="$t('Metamask Wallet')">2</td>-->
<!--          <td class="p-l-per" :data-label="$t('PL %')">3</td>-->
<!--          <td class="p-l" :data-label="$t('PL $')">34 256</td>-->
<!--        </tr>-->
<!--        </tbody>-->
<!--      </table>-->
<!--    </div>-->
<!--  </div>-->
<!--</template>-->
<template>
  <ComingSoon />
</template>

<script>
import ComingSoon from "../coming-soon/ComingSoon";

export default {
  name: 'Tournaments',
  data() {
    return {
      participation: '',
    };
  },
  watch: {
    'participation'(val) {
      alert(val)
    }
  },
  methods: {
    changeParticipation() {

    }
  },
  components: { ComingSoon },
};
</script>

<style scoped>
.report-filter-wrapper {
  align-items: center;
  gap: 64px;
  margin-bottom: 10px;
}

.report-subtitle {
  font-size: 14px;
}

.switcher-wrapper {
  display: flex;
  gap: 8px;
}

.switcher-wrapper span {
  font-weight: 600;
  font-size: 14px;
  color: var(--v-selectColor-base);
}

.switcher-wrapper span:last-of-type {
  margin-left: 6px;
}

.v-input--selection-controls {
  margin-top: 0;
}

.theme--dark.v-input--switch::v-deep .v-input--switch__thumb,
.theme--light.v-input--switch::v-deep .v-input--switch__thumb {
  background: #42E8E0 !important;
}

table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
}

thead {
  text-align: left;
  color: #92939C;
}

th {
  padding: 12px 0;
}

tbody tr {
  font-weight: 600;
  color: var(--v-selectColor-base);
}

td {
  padding-top: 10px;
}

td span {
  position: relative;
  z-index: 1;
  display: inline-block;
  overflow: hidden;
  padding-left: 15px;
  width: calc(100% - 125px);
  background: var(--v-primary-base);
}

.medal {
  padding-top: 17px;
  display: flex;
  justify-content: center;
}

.p-l {
  text-align: center;
}

.user-rank {
  padding: 3px 8px;
  border: 1px solid var(--v-textAccentColor-base);
  border-radius: 4px;
  max-width: 73px;
  background-color: var(--v-switchColor-base);
  font-size: 12px;
  color: var(--v-textAccentColor-base);
  font-weight: 500;
}

tbody .p-l:after {
  content: 'USDT';
}

tbody .p-l-per:after {
  content: '%';
}

.p-l:after,
.p-l-per:after {
  margin-left: 6px;
  font-size: 12px;
  font-weight: normal;
  color: #92939C;
}
</style>
