export default {
    getTournamentsMembers({commit}, {params}) {
        return new Promise((resolve, reject) => {
            $axios.get('/tournaments/members', {params})
                .then((res) => {
                    commit('SET_TOURNAMENTS_MEMBERS', res.data);
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getCurrentTournaments({commit}) {
        return new Promise((resolve, reject) => {
            $axios.get('/tournaments/current')
                .then((res) => {
                    commit('SET_CURRENT_TOURNAMENTS', res.data);
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getTournamentsMyRating({commit}, {params}) {
        return new Promise((resolve, reject) => {
            $axios.get('/tournaments/myRating', {params})
                .then((res) => {
                    commit('SET_TOURNAMENTS_MY_RATING', res.data);
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};
