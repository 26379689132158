<template>
  <div class="faq-item"
       :class="open ? 'open': ''"
  >
    <div class="faq-item-header" @click="openItem">
      <h2 class="faq-item-title">{{ title }}</h2>
      <div class="faq-item-icon" :class="opens ? 'active' : open ? 'active': ''">
        <img src="../../assets/img/faq-accordeon-icon.svg" alt="item-icon">
      </div>
    </div>
    <div v-if="opens || open">
<!--      <FaqSubAccordeon-->
<!--          v-for="(problem,idx) in poblemsVariants"-->
<!--          :key="idx"-->
<!--          :answer="problem.answer"-->
<!--          :question="problem.question"-->
<!--      />-->
      <div class="faq-answer">
        <div v-if="Array.isArray(answer)">
          <ul>
            <li
                v-for="(item, index) in answer"
                :key="index"
            >
              {{item}}
            </li>
          </ul>
        </div>
        <div v-else><p>{{answer}}</p></div>
      </div>
    </div>
  </div>
</template>

<script>
// import FaqSubAccordeon from "./FaqSubAccordeon";

export default {
  name: "FaqAccordeon",
  props: {
    title: {},
    answer: {},
    opens: {},
  },
  data() {
    return {
      open: false,
    }
  },
  components: {
    // FaqSubAccordeon
  },
  methods: {
    openItem() {
      this.open = !this.open
    }
  }
}
</script>

<style scoped>

.faq-item {
  margin: 15px 0 0 0;
  cursor: pointer;
  width: 100%;
  position: relative;
  transition: .5s all;
  border-bottom: 3px solid var(--v-publicPagesBorder-base);
}

.faq-item.open {
  padding-bottom: 25px;
}

.faq-item-header {

}

.faq-item:first-child {
  margin: 0;
}

.faq-item:hover {
}

.faq-item-title {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  padding-bottom: 25px;
  color: var(--v-textMainColor-base);
}

.public-page-content h2.faq-item-title {
  margin-bottom: 0;
}

.faq-item-icon {
  position: absolute;
  top: 0;
  right: 20px;
  transform: none;
  transition: all .5s;
}

.faq-item-icon.active {
  transform: rotate(180deg);
}

.faq-answer {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: var(--v-textMainColor-base);
  padding-right: 50px;
}

.faq-answer ul {
  padding-left: 20px !important;
  margin-left: 0;
}

.faq-answer ul li {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 10px;
  display: list-item;
  list-style-type: disc;
}


</style>