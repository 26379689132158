<template>
  <v-dialog v-model="message" persistent>
    <div class="service-message-wrapper">
      <div class="close-dialog" @click="closePopups">
        <img alt="close-icon" src="../../assets/img/close.svg" />
      </div>
      <h2 class="sevice-message-title">{{ $t('Place a bid') }}</h2>
      <div class="popup-content">
<!--        <p class="service-message-description textSecondaryColor">-->
<!--          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ornare sit sagittis in.-->
<!--        </p>-->
        <div class="main_info_sell main_info_sell_price">
          <div class="price_butsell">
            <div>{{ $t('priceTextPrice') }}</div>
          </div>
          <input
              id="InputPriceBid"
              v-model="price"
              autocomplete="off"
              class="sellBuyInput sellInputPriceLimit js-pending-input price"
              name="price"
              type="number"
              @keydown.prevent
              @paste.prevent
          />
          <span>{{quoteCurrency}}</span>
        </div>
        <div class="main_info_sell">
          <div class="price_butsell">
            <div>{{ $t('size') }}</div>
          </div>
          <input
              id="InputPriceLimit"
              v-model="size"
              autocomplete="off"
              class="sellBuyInput sellInputSizeLimit js-pending-input"
              name="size"
              type="number"
              @focus="focusInputSize"
          />
          <span>
            {{baseCurrency}}
          </span>
          <span v-if="sliderValue !== 0" class="input-value-percent">%</span>
        </div>
        <p v-if="popupMessageError" class="deposit-value-error">
          <svg
              data-v-3478b0a6=""
              width="10"
              height="10"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg">
            <path
                data-v-3478b0a6=""
                d="M2 2L7.99995 7.99995M7.99995 7.99995L13.9999 2M7.99995 7.99995L14 13.9998M7.99995 7.99995L2.0001 13.9998"
                stroke="#FF7337"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
          </svg>
          {{ $t(popupMessageError) }}
        </p>
        <div class="max-value-list">
          <div class="max-value-item">{{$t('Max buy')}} <span>{{maxToBuy}} {{baseCurrency}}</span></div>
          <div class="max-value-item">{{$t('Max sell')}} <span>{{maxToSell}} {{baseCurrency}}</span></div>
        </div>
        <div class="main_input-order-form">
          <vue-slider
              v-model="sliderValue"
              :marks="marks"
              :step="10"
              @change="changedSliderSize"
          >
            <template v-slot:tooltip>
              <div class="vue-slider-dot-tooltip vue-slider-dot-tooltip-top">
                <div class="vue-slider-dot-tooltip-inner vue-slider-dot-tooltip-inner-top">
                  <span class="vue-slider-dot-tooltip-text">{{sliderValue}}%</span>
                </div>
              </div>
            </template>
            <template v-slot:step="{ active }">
              <div :class="['custom-step', { active }]"></div>
            </template>

          </vue-slider>
        </div>
      </div>
      <div class="service-btn-block">
        <button class="accent-btn-revert cancel-btn" @click="closePopups">{{ $t('Cancel') }}</button>
        <button class="service-btn btn-try accent" @click="onConfirm(size)" :disabled="!!!+size">{{ $t('Confirm') }}</button>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import VueSlider from 'vue-slider-component';

export default {
  name: 'PlaceBid',
  components: {
    VueSlider,
  },
  props: {
    maxToSell: {},
    maxToBuy: {},
    lots: {
      default: () => [],
    },
  },
  data() {
    return {
      currentLot: null,
      price: 0,
      size: 0,
      sliderValue: 0,
      marks: {
        0: '0%',
        25: '25%',
        50: '50%',
        75: '75%',
        100: '100%',
      },
      message: false,
      messageTitle: 'wrongConnection',
      messageText: 'wrongConnectionMessage',
      popupMessageError: '',
    };
  },
  computed: {
    baseCurrency() {
      return this.currentLot ? this.currentLot.symbol.split('_')[0].toUpperCase() : ''
    },
    quoteCurrency() {
      return this.currentLot ? this.currentLot.symbol.split('_')[1].toUpperCase() : ''
    },
  },
  watch: {
    lots: {
      deep: true,
      handler(newValue) {
        if(this.currentLot && newValue.value.get(this.currentLot.lotId).lotId === this.currentLot.lotId) {
          let item = newValue.value.get(this.currentLot.lotId)
          this.price = item.currentBidPrice.toFixed(2)
          this.$emit('updateMaxBuy', item)
        }
      }
    },
  },
  methods: {
    openPopups(lot) {
      this.currentLot = lot

      this.price = lot.currentBidPrice
      this.message = true;

    },
    closePopups() {
      this.currentLot = null;
      this.sliderValue = 0;
      this.size = 0;
      this.price = 0;
      this.message = false;
    },
    onConfirm(size) {

      if (this.currentLot.side === 'BUY') {
        if (Number(size) > Number(this.maxToBuy) && this.sliderValue === 0) {
          this.popupMessageError = 'Insufficient funds'
          return
        }
      } else if (this.currentLot.side === 'SELL') {
        if (Number(size) > Number(this.maxToSell) && this.sliderValue === 0) {
          this.popupMessageError = 'Insufficient funds'
          return
        }
      }

      let amount = 0

      if (this.sliderValue !== 0) {
        if (this.currentLot.side === 'BUY') {
          amount = +(this.maxToBuy / 100 * this.sliderValue).toFixed(2)
        } else if (this.currentLot.side === 'SELL') {
          amount = +(this.maxToSell / 100 * this.sliderValue).toFixed(2)
        }
      } else {
        amount = Number(size)
      }

      const data = {
        lotId: this.currentLot.lotId,
        amount: +amount,
        symbol: this.currentLot.symbol,
      }
      this.closePopups();
      this.$emit('onConfirm', data);
    },
    focusInputSize() {
      this.sliderValue = 0;
    },
    changedSliderSize(size) {
      this.size = size;
    },

  },
};
</script>

<style scoped>
.v-dialog {
  margin: 0;
  box-shadow: none;
  border-radius: 0;
}

.sevice-message-title {
  margin-bottom: 20px;
}

.close-dialog {
  position: absolute;
  top: 25px;
  right: 23px;
  width: 12px;
  cursor: pointer;
}

.close-dialog img {
  display: block;
  width: 100%;
}

.popup-content {
  margin: 0 auto;
  max-width: 280px;
}

.service-message-description {
  margin: 20px 0;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.45px;
  text-align: center;
}

.service-btn {
  transition: .5s all;
  border-radius: 6px;
  min-width: 120px;
  min-height: 48px;
  font-size: 16px;
  font-weight: bold;
}

.main_input-order-form {
  margin-top: 42px;
}

.btn-try {
  color: #111222;
}

.service-btn-block {
  margin-top: 30px;
}

.input-value-percent {
  top: 50%;
  right: 55px !important;
  font-size: 14px;
  font-weight: bold;
  color: #a4a5af;
}

.cancel-btn {
  margin-right: 20px;
  width: 120px;
}

.sellInputPriceLimit.price,
.main_info_sell_price .price_butsell,
.sellInputPriceLimit.price,
.sellInputPriceLimit.price + span {
  color: #646464 !important;
  pointer-events: none;
}

.max-value-list {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.max-value-item {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  color: #a4a5af;
}

.max-value-item:nth-child(2) {
  text-align: right;
}

.max-value-item span {
  display: flex;
  width: 100%;
  font-weight: normal;
  color: #fff;
  background: none !important;
}

.deposit-value-error {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: var(--v-error-base);
}

.deposit-value-error svg {
  display: block;
  margin-right: 5px;
}

</style>
