import { render, staticRenderFns } from "./PublicInfo.vue?vue&type=template&id=373ee424&scoped=true&"
import script from "./PublicInfo.vue?vue&type=script&lang=js&"
export * from "./PublicInfo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "373ee424",
  null
  
)

export default component.exports