export default {
    candles: {},
    accountTrades: {},
    marketTrades: {},
    coins: {},
    coinsFavorite: {},
    coinsInfo: {},
    coinsLeverage: {},
    withdrawAmount: 0,
    maintenance: 0,
    orderDepositState: false,
}
