<template>
  <div>
    <table class="history-orders-table">
      <thead class="holder-table">
      <tr class="table-head">
        <th class="rank">
          <p>
            <span class="textSecondaryColor">{{ $t('#') }}</span>
          </p>
        </th>
        <th class="user-name">
          <p>
            <span class="textSecondaryColor">{{ $t('User Adress') }}</span>
          </p>
        </th>
        <th class="profit">
          <p>
            <span class="textSecondaryColor">{{ $t('Profit') }}</span>
          </p>
        </th>
      </tr>
      </thead>
      <tbody class="order-history-table-main">
      <rounds-of-rewards-items
          v-for="(referral, i) in topReferrals"
          :key="i"
          :customClass="token && currentReferralUser && referral.userAddress === currentReferralUser.userAddress ? 'current-leader' : ''"
          :referral="referral"
      />
      <tr class="table-body" v-if="token && currentReferralUser && currentReferralUser.number > topReferrals.length && topReferrals.length >= 8 && currentReferralUser.number > 9">
        <td class="top-referral-empty-row">...</td>
        <td class="top-referral-empty-row"></td>
        <td class="top-referral-empty-row"></td>
      </tr>
      <rounds-of-rewards-items
          v-if="token && currentReferralUser && currentReferralUser.number > topReferrals.length && topReferrals.length >= 8"
          :customClass="'current-leader'"
          :referral="currentReferralUser"
      />
      <tr class="table-body" v-if="token && currentReferralUser && looserReferralUser && looserReferralUser.number > 8 && looserReferralUser.number > currentReferralUser.number && looserReferralUser.number > 9">
        <td class="top-referral-empty-row">...</td>
        <td class="top-referral-empty-row"></td>
        <td class="top-referral-empty-row"></td>
      </tr>
      <rounds-of-rewards-items
          v-if="token && looserReferralUser && currentReferralUser && looserReferralUser.number > 8 && looserReferralUser.number > currentReferralUser.number"
          :referral="looserReferralUser"
          :customClass="token && currentReferralUser && looserReferralUser.userAddress === currentReferralUser.userAddress ? 'current-leader' : ''"
      />
      <tr class="table-body" v-if="!token && looserReferralUser && looserReferralUser.number > 8 && looserReferralUser.number > 9">
        <td class="top-referral-empty-row">...</td>
        <td class="top-referral-empty-row"></td>
        <td class="top-referral-empty-row"></td>
      </tr>
      <rounds-of-rewards-items
          v-if="!token && looserReferralUser && looserReferralUser.number > 8"
          :referral="looserReferralUser"
          :customClass="currentReferralUser && looserReferralUser.userAddress === currentReferralUser.userAddress ? 'current-leader' : ''"
      />
      </tbody>
    </table>
  </div>
</template>

<script>
import RoundsOfRewardsItems from "@/components/rewards/RoundsOfRewards/RoundsOfRewardsItems.vue";
export default {
  name: "RoundsOfRewardsItemsList",
  components: {
    RoundsOfRewardsItems,
  },
  props: {
    topReferrals: Array,
    currentReferralUser: {},
    looserReferralUser: {},
    token: String,
  },
  data() {
    return {

    }
  }
}
</script>

<style scoped>
.table-head th.rank {
  width: 48px;
  padding-left: 20px;
}

.table-head th.profit {
  padding-right: 20px;
}

.table-head th.profit p {
  text-align: right;
}

.top-referral-empty-row {
  padding-left: 20px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #E9E9E9;
}

.current-leader {
  background: rgba(66, 232, 224, 0.1);
}

</style>