<template>
  <tr class="table-body" :class="customClass">
    <td class="rank" :data-label="$t('#')">
      <div class="table-rank-wrap">
        <span>{{ number }}</span>
        <div class="rank-icon" v-if="number < 4">
          <svg v-if="number === 1" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.1768 6.82501L11.9629 9.96331L12.7219 14.3958C12.7549 14.5896 12.6755 14.7855 12.5166 14.9013C12.4268 14.9669 12.32 15 12.2132 15C12.1311 15 12.0486 14.9804 11.9733 14.9406L7.99935 12.8478L4.02596 14.94C3.8526 15.032 3.64157 15.0171 3.48265 14.9008C3.32374 14.785 3.24428 14.5891 3.2773 14.3953L4.03628 9.9628L0.821859 6.82501C0.681519 6.68753 0.630439 6.48182 0.691322 6.29524C0.752205 6.10866 0.9137 5.97169 1.10822 5.94327L5.5501 5.29721L7.53653 1.26476C7.71041 0.911748 8.28829 0.911748 8.46216 1.26476L10.4486 5.29721L14.8905 5.94327C15.085 5.97169 15.2465 6.10814 15.3074 6.29524C15.3683 6.48234 15.3172 6.68701 15.1768 6.82501Z" fill="url(#paint0_linear_12860_322412)"/>
            <defs>
              <linearGradient id="paint0_linear_12860_322412" x1="0.606341" y1="2.36121" x2="15.3453" y2="2.37592" gradientUnits="userSpaceOnUse">
                <stop stop-color="#DDD400"/>
                <stop offset="0.549588" stop-color="#FFEE56"/>
                <stop offset="1" stop-color="#CBC300"/>
              </linearGradient>
            </defs>
          </svg>
          <svg v-if="number === 2" width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.1768 5.82501L11.9629 8.96331L12.7219 13.3958C12.7549 13.5896 12.6755 13.7855 12.5166 13.9013C12.4268 13.9669 12.32 14 12.2132 14C12.1311 14 12.0486 13.9804 11.9733 13.9406L7.99935 11.8478L4.02596 13.94C3.8526 14.032 3.64157 14.0171 3.48265 13.9008C3.32374 13.785 3.24428 13.5891 3.2773 13.3953L4.03628 8.9628L0.821859 5.82501C0.681519 5.68753 0.630439 5.48182 0.691322 5.29524C0.752205 5.10866 0.9137 4.97169 1.10822 4.94327L5.5501 4.29721L7.53653 0.264756C7.71041 -0.0882519 8.28829 -0.0882519 8.46216 0.264756L10.4486 4.29721L14.8905 4.94327C15.085 4.97169 15.2465 5.10814 15.3074 5.29524C15.3683 5.48234 15.3172 5.68701 15.1768 5.82501Z" fill="url(#paint0_linear_13119_241100)"/>
            <defs>
              <linearGradient id="paint0_linear_13119_241100" x1="0.666016" y1="0" x2="15.3482" y2="0.0162963" gradientUnits="userSpaceOnUse">
                <stop stop-color="#A2B6C9"/>
                <stop offset="0.549588" stop-color="#B3D0EE"/>
                <stop offset="1" stop-color="#A2B6C9"/>
              </linearGradient>
            </defs>
          </svg>
          <svg v-if="number === 3" width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.1768 5.82501L11.9629 8.96331L12.7219 13.3958C12.7549 13.5896 12.6755 13.7855 12.5166 13.9013C12.4268 13.9669 12.32 14 12.2132 14C12.1311 14 12.0486 13.9804 11.9733 13.9406L7.99935 11.8478L4.02596 13.94C3.8526 14.032 3.64157 14.0171 3.48265 13.9008C3.32374 13.785 3.24428 13.5891 3.2773 13.3953L4.03628 8.9628L0.821859 5.82501C0.681519 5.68753 0.630439 5.48182 0.691322 5.29524C0.752205 5.10866 0.9137 4.97169 1.10822 4.94327L5.5501 4.29721L7.53653 0.264756C7.71041 -0.0882519 8.28829 -0.0882519 8.46216 0.264756L10.4486 4.29721L14.8905 4.94327C15.085 4.97169 15.2465 5.10814 15.3074 5.29524C15.3683 5.48234 15.3172 5.68701 15.1768 5.82501Z" fill="url(#paint0_linear_13119_241102)"/>
            <defs>
              <linearGradient id="paint0_linear_13119_241102" x1="0.573189" y1="0.000185207" x2="15.2483" y2="0.0121751" gradientUnits="userSpaceOnUse">
                <stop stop-color="#CB8600"/>
                <stop offset="0.484375" stop-color="#FFB546"/>
                <stop offset="1" stop-color="#CB8600"/>
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
    </td>
    <td class="user-name" :data-label="$t('User Name')">
      <span>{{ userAddress }}</span>
    </td>
    <td class="total-profit" :data-label="$t('Profit')">
      <span>{{ totalProfit }}</span>
      <span class="textSecondaryColor"> {{ quoteCurrency }}</span>
    </td>
  </tr>
</template>

<script>
export default {
  name: "RoundsOfRewardsItems",
  data() {
    return {
      quoteCurrency: 'USDT',
    }
  },
  props: {
    referral: Object,
    customClass: String
  },
  computed: {
    number() {
      return this.referral.number
    },
    userAddress() {
       return `${this.referral.userAddress.substring(0,10)}....${this.referral.userAddress.slice(this.referral.userAddress.length - 4)}`;
    },
    totalProfit() {
      return this.prettify(String(this.aroundNumber(this.referral.totalProfit) || 0))
    },
  },
  methods: {
    aroundNumber(num) {
      if(num === undefined || num === null) {
        return 0
      }
      if(num === 0) {
        return num
      }else {
        // return num ? parseFloat(Number(num).toFixed(2)) : null
        if(+num > 0) {
          return Math.trunc(num * 100) / 100;
        }else {
          return Math.ceil(num * 100) / 100;
        }
      }
    },
    prettify(num) {
      if(num === 0) return 0
      const options = {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2
      }
      num = Number(num).toLocaleString("en-US", options).replace('$', '')
      return num
    },
  }
}
</script>

<style scoped>
.table-body .rank {
  width: 68px;
  padding-left: 20px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #E9E9E9;
}

.table-body .user-name {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #E9E9E9;
}

.table-body .total-profit {
  padding-right: 20px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  color: #E9E9E9;
}

.table-rank-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.table-rank-wrap svg {
  display: inline-block;
  margin-left: 10px;
}

.current-leader .total-profit span {
  color: #02C9BF;
}

.current-leader {
  background: rgba(66, 232, 224, 0.1) !important;
}

</style>