<template>
  <div class="faq-item-list">
    <FaqAccordeon
        ref="faqList"
        v-for="item in faqList"
        :title="item.title"
        :answer="item.answer"
        :key="item.id"
    />
  </div>
</template>

<script>
import FaqAccordeon from "./FaqAccordeon";

export default {
  name: "FaqItemList",
  props: {
    data: Array,
  },
  data() {
    return {
      faqItemsEn: [
        {
          title: 'What is Dexilon?',
          answer: 'This is the exchange you have always dreamed for. Dexilon is a best in kind Decentralized Derivatives Exchange which run on custom blockchain with the central limit order book. Our product offers self-custody, lightspeed execution, best trading fees and radical transparency.',
          id: Math.random(),
        },
        {
          title: 'What assets does Dexilon offer?',
          answer: 'Dexilon offers USDC margin perpetual futures at the beginning. In the future we will expand into delivery futures and options.',
          id: Math.random(),
        },
        {
          title: 'How to trade on Dexilon?',
          answer: 'Easy peasy. You would need to complete just a few steps outlined in the guide.',
          id: Math.random(),
        },
        {
          title: 'How to trade crypto derivatives?',
          answer: 'Hehe… with the Gods help',
          id: Math.random(),
        },
        {
          title: 'Is KYC needed to get started?',
          answer: 'No, KYC isn’t needed. Customers can authorize for trading using their crypto wallet. Please refer to the step by step guide on how to connect to our exchange.',
          id: Math.random(),
        },
        {
          title: 'How safe is Dexilon? Where will my funds be stored?',
          answer: 'You are in charge of your money. Dexilon is very safe, because contrary to the Centralized Exchanges, Dexilon does not store any of the customer funds. Customers deposit funds to the smart contract, which can be verified and audited.',
          id: Math.random(),
        },
        {
          title: 'Why is Dexilon the best exchange in the world?',
          answer: [
              'You are in charge of your money',
              'Dexilon isn\'t a Black Box. This is a radically transparent environment, unlike typical exchanges where users are segregated by their amount of capital, relations, etc..',
              'Dexilon has been designed to give equal opportunities for all counterparties',
              'You think all DEX designed like this? You are wrong - they are made as a manipulation environment.'
          ],
          id: Math.random(),
        },
      ],
      faqItemsRu: [
        {
          title: 'Что такое Дексилон?',
          answer: 'Это тот обмен, о котором вы всегда мечтали. Dexilon — лучшая в своем роде децентрализованная биржа деривативов, которая работает на пользовательской цепочке блоков с центральной книгой лимитных ордеров. Наш продукт предлагает самостоятельное хранение, быстрое исполнение, лучшие торговые комиссии и полную прозрачность.',
          id: Math.random(),
        },
        {
          title: 'Какие активы предлагает Dexilon?',
          answer: 'Dexilon предлагает бессрочные фьючерсы на маржу USDC в начале. В будущем мы расширимся до фьючерсов и вариантов доставки.',
          id: Math.random(),
        },
        {
          title: 'Как торговать на Dexilon?',
          answer: 'Легко peasy. Вам нужно будет выполнить всего несколько шагов, описанных в руководстве.',
          id: Math.random(),
        },
        {
          title: 'Как торговать криптодеривативами?',
          answer: 'Хе-хе… с божьей помощью',
          id: Math.random(),
        },
        {
          title: 'Нужен ли KYC для начала?',
          answer: 'Нет, KYC не нужен. Клиенты могут авторизоваться для торговли, используя свой крипто-кошелек. Пожалуйста, обратитесь к пошаговому руководству о том, как подключиться к нашей бирже.',
          id: Math.random(),
        },
        {
          title: 'Насколько безопасен Дексилон? Где будут храниться мои средства?',
          answer: 'Вы распоряжаетесь своими деньгами. Dexilon очень безопасен, потому что, в отличие от централизованных бирж, Dexilon не хранит средства клиентов. Клиенты вносят средства в смарт-контракт, который можно проверить и проверить.',
          id: Math.random(),
        },
        {
          title: 'Почему Dexilon лучшая биржа в мире?',
          answer: [
            'Вы распоряжаетесь своими деньгами',
            'Dexilon — это не «черный ящик». Это радикально прозрачная среда, в отличие от типичных бирж, где пользователи разделены по размеру капитала, связям и т.д.',
            'Dexilon был разработан, чтобы предоставить равные возможности для всех контрагентов',
            'Ты думаешь, что все DEX спроектированы так? Вы ошибаетесь — они созданы как манипуляционная среда'
          ],
          id: Math.random(),
        },
      ],
      faqItemsEs: [
        {
          title: 'Qué es Dexilon?',
          answer: 'Este es el intercambio que siempre has soñado. Dexilon es el mejor intercambio de derivados descentralizados que se ejecuta en una cadena de bloques personalizada con el libro de órdenes de límite central. Nuestro producto ofrece autocustodia, ejecución a la velocidad de la luz, las mejores tarifas comerciales y una transparencia radical.',
          id: Math.random(),
        },
        {
          title: 'Qué activos ofrece Dexilon?',
          answer: 'Dexilon ofrece futuros perpetuos de margen USDC al principio. En el futuro, nos expandiremos a opciones y futuros de entrega.',
          id: Math.random(),
        },
        {
          title: 'Cómo comerciar en Dexilon?',
          answer: 'Fácil, fácil. Deberá completar solo algunos pasos descritos en la guía.',
          id: Math.random(),
        },
        {
          title: 'Cómo operar con criptoderivados?',
          answer: 'Jeje… con la ayuda de Dios',
          id: Math.random(),
        },
        {
          title: 'Se necesita KYC para empezar?',
          answer: 'No, KYC no es necesario. Los clientes pueden autorizar el comercio utilizando su billetera criptográfica. Consulte la guía paso a paso sobre cómo conectarse a nuestro intercambio.',
          id: Math.random(),
        },
        {
          title: 'Qué tan seguro es Dexilon? ¿Dónde se almacenarán mis fondos?',
          answer: 'Usted está a cargo de su dinero. Dexilon es muy seguro porque, a diferencia de los intercambios centralizados, Dexilon no almacena ninguno de los fondos de los clientes. Los clientes depositan fondos en el contrato inteligente, que se puede verificar y auditar.',
          id: Math.random(),
        },
        {
          title: 'Por qué Dexilon es el mejor intercambio del mundo?',
          answer: [
            'Usted está a cargo de su dinero',
            'Dexilon no es una caja negra. Este es un entorno radicalmente transparente, a diferencia de los intercambios típicos donde los usuarios están segregados por su cantidad de capital, relaciones, etc.',
            'Dexilon ha sido diseñado para dar igualdad de oportunidades a todas las contrapartes',
            'Crees que todos los DEX están diseñados así? Estás equivocado, están hechos como un entorno de manipulación.'
          ],
          id: Math.random(),
        },
      ],
      faqItemsHi: [
        {
          title: 'डेक्सिलॉन क्या है?',
          answer: 'यह वह विनिमय है जिसका आपने हमेशा सपना देखा है। डेक्सिलॉन एक बेहतरीन विकेंद्रीकृत डेरिवेटिव एक्सचेंज है जो केंद्रीय सीमा ऑर्डर बुक के साथ कस्टम ब्लॉकचैन पर चलता है। हमारा उत्पाद सेल्फ-कस्टडी, लाइटस्पीड एक्जीक्यूशन, बेस्ट ट्रेडिंग फीस और आमूल-चूल पारदर्शिता प्रदान करता है।',
          id: Math.random(),
        },
        {
          title: 'डेक्सिलॉन क्या संपत्ति प्रदान करता है?',
          answer: 'डेक्सिलॉन शुरुआत में यूएसडीसी मार्जिन परपेचुअल फ्यूचर्स की पेशकश करता है। भविष्य में हम डिलीवरी फ्यूचर्स और विकल्पों में विस्तार करेंगे।',
          id: Math.random(),
        },
        {
          title: 'डेक्सिलॉन पर व्यापार कैसे करें?',
          answer: 'आसान मटर। आपको गाइड में बताए गए कुछ ही चरणों को पूरा करना होगा।',
          id: Math.random(),
        },
        {
          title: 'क्रिप्टो डेरिवेटिव का व्यापार कैसे करें?',
          answer: 'हेहे... देवताओं की सहायता से',
          id: Math.random(),
        },
        {
          title: 'क्या केवाईसी शुरू करने के लिए आवश्यक है?',
          answer: 'नहीं, केवाईसी की जरूरत नहीं है। ग्राहक अपने क्रिप्टो वॉलेट का उपयोग करके ट्रेडिंग के लिए अधिकृत कर सकते हैं। कृपया हमारे एक्सचेंज से कैसे जुड़ें, इस पर चरण-दर-चरण मार्गदर्शिका देखें।',
          id: Math.random(),
        },
        {
          title: 'डेक्सिलॉन कितना सुरक्षित है? मेरे फंड कहां जमा होंगे?',
          answer: 'आप अपने पैसे के प्रभारी हैं। डेक्सिलॉन बहुत सुरक्षित है, क्योंकि केंद्रीकृत एक्सचेंजों के विपरीत, डेक्सिलॉन ग्राहक के किसी भी फंड को स्टोर नहीं करता है। ग्राहक स्मार्ट कॉन्ट्रैक्ट में फंड जमा करते हैं, जिसे सत्यापित और ऑडिट किया जा सकता है।',
          id: Math.random(),
        },
        {
          title: 'डेक्सिलॉन दुनिया में सबसे अच्छा एक्सचेंज क्यों है?',
          answer: [
            'आप अपने पैसे के प्रभारी हैं',
            'डेक्सिलॉन ब्लैक बॉक्स\' नहीं है। यह एक मौलिक रूप से पारदर्शी वातावरण है, विशिष्ट एक्सचेंजों के विपरीत जहां उपयोगकर्ताओं को उनकी पूंजी, संबंधों, आदि की मात्रा से अलग किया जाता है।',
            'डेक्सिलॉन को सभी प्रतिपक्षकारों के लिए समान अवसर देने के लिए डिज़ाइन किया गया है',
            'आपको लगता है कि सभी DEX इस तरह डिज़ाइन किए गए हैं? आप गलत हैं - उन्हें हेरफेर के माहौल के रूप में बनाया गया है।'
          ],
          id: Math.random(),
        },
      ],
      faqItemsZh: [
        {
          title: 'ड什么是 Dexilon？',
          answer: '这是你一直梦寐以求的交换。 Dexilon 是同类最佳的去中心化衍生品交易所，它在带有中央限价订单簿的自定义区块链上运行。我们的产品提供自我托管、光速执行、最佳交易费用和彻底的透明度。',
          id: Math.random(),
        },
        {
          title: 'Dexilon 提供什么资产？',
          answer: 'Dexilon 一开始就提供USDC保证金永续合约。未来我们将扩展到交割期货和期权。',
          id: Math.random(),
        },
        {
          title: '如何在 Dexilon 上交易？',
          answer: '容易的peasy。您只需完成指南中列出的几个步骤。',
          id: Math.random(),
        },
        {
          title: 'क्रि如何交易加密衍生品？',
          answer: 'ह嘿嘿……在上帝的帮助下',
          id: Math.random(),
        },
        {
          title: '开始需要 KYC 吗？',
          answer: '不，不需要KYC。客户可以授权使用他们的加密钱包进行交易。请参阅有关如何连接到我们的交易所的分步指南。',
          id: Math.random(),
        },
        {
          title: 'Dexilon 的安全性如何？我的资金将存放在哪里？',
          answer: '你负责你的钱。 Dexilon 非常安全，因为与中心化交易所相反，Dexilon 不存储任何客户资金。客户将资金存入智能合约，可以进行验证和审计。',
          id: Math.random(),
        },
        {
          title: 'ड为什么 Dexilon 是世界上最好的交易所？',
          answer: [
            '你负责你的钱',
            'Dexilon 不是黑匣子。这是一个完全透明的环境，不像典型的交易所，用户被他们的资本、关系等隔离。',
            'Dexilon 旨在为所有交易对手提供平等机会',
            '你认为所有的 DEX 都是这样设计的吗？你错了——它们是作为一个操纵环境而制作的'
          ],
          id: Math.random(),
        },
      ],
    }
  },
  components: {
    FaqAccordeon
  },
  watch: {
    '$i18n.locale'() {
      // console.log(this.$i18n.locale)
    }
  },
  computed: {
    faqList() {
      return this.$i18n.locale === 'en' ? this.faqItemsEn
          : this.$i18n.locale === 'ru' ? this.faqItemsRu
          : this.$i18n.locale === 'es' ? this.faqItemsEs
          : this.$i18n.locale === 'hi' ? this.faqItemsHi
          : this.$i18n.locale === 'zh' ? this.faqItemsZh
          : this.faqItemsEn
    }
  },
  mounted() {
    this.$refs.faqList.forEach((item, idx) => {
      if(idx === 0) {
        item.open = true
      }else return
    })
  }
}
</script>

<style scoped>
.faq-item-list {
  width: 100%;
  margin: 40px 0 0 0;
}
</style>
