import { render, staticRenderFns } from "./DeleteApi.vue?vue&type=template&id=61d3c513&scoped=true&"
import script from "./DeleteApi.vue?vue&type=script&lang=js&"
export * from "./DeleteApi.vue?vue&type=script&lang=js&"
import style0 from "./DeleteApi.vue?vue&type=style&index=0&id=61d3c513&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61d3c513",
  null
  
)

export default component.exports