import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)



const opts = {}

export default new Vuetify({
    theme: {
        dark: 'dark',
        themes: {
            light: {
                primary: '#fff',
                revertPrimary: '#111223',
                headerColor: '#FCFCFC',
                textMainColor: '#464646',
                textSecondaryColor: '#92939C',
                textSecondaryRevert: '#111223',
                textAccentColor: '#42E8E0',
                primaryBg: '#fff',
                secondary: '#b0bec5',
                switchColor: '#E0E0E6',
                sliderBackgroundColor: "#EBEBF0",
                stockTableHeaderBorderColor: '#ebebf0',
                accent: '#42E8E0',
                error: '#b71c1c',
                mainBorderColor: '#E0E0E6',
                walletHoverBGColor: '#F3F3F7',
                btnCancelHover: '#F3F3F7',
                btnTryHover: '#67CA9D',
                marketHeaderButton: '#F3F3F7',
                lastPriceTitleBg: '#fcfcfc',
                lastPriceTitleBgHover: '#f7f7fb',
                primaryColor: '#111223',
                assetsBtnsHover: '#02C9BF',
                textFormMaxColor: '#828393',
                orderFormProgressItemBg: '#fff',
                orderFormProgressItemBorderActive: '#42E8E0',
                inputBg: '#FFFFFF',
                searchInputBg: '#f7f7fb',
                blackwhite: '#1C1D38',
                paginationActiveItemColor: '#FCFCFC',
                publicPagesBorder: '#E9E9E9',
                selectBackgroundColor: '#FFF',
                selectColor: '#464646',
                selectColorHover: '#FCFCFC',
                periodBackgroundColor: '#FCFCFC',
                periodHoverColor: '#111222',
                orderBackgroundLineRed: '#FF3D00',
                orderBackgroundLineGreen: '#04B0A6',
                loginButtonBackgroundColor: '#FCFCFC',
                loginButtonBackgroundHoverColor: '#F7F7FB',
                loginButtonBackgroundOpenColor: '#F7F7FB',
                loginButtonBorderOpenColor: '#F7F7FB',
                loginButtonTitleColor: '#92939C',
                loginButtonTitleHoverColor: '#92939C',
                loginButtonTitleOpenColor: '#92939C',
                loginButtonCountColor: '#464646',
                loginButtonCountOpenColor: '#464646',
                loginButtonCountHoverColor: '#464646',
                loginDropduwnBackgroundColor: '#FCFCFC',
                loginDropduwnHoverBackgroundColor: '#F7F7FB',
                loginButtonIconColor: '#02C9BF',
                loginButtonIconOpenColor: '#02C9BF',
                chartGeneralBtnColor: '#fff',
                loginButtonIconHoverColor: '#02C9BF',
                graphLineColor: '#48515D',
                graphBackgroundLineColor: '#EAECEF',
                evenRowBackground: '#48515D',
                graphBottomLine: '#48515D',
                tableSecondaryColor: '#92939C',
                disableButtonColor: '#b7dfdd',
                pieChartBackgroundColor: '#FCFCFC',
                pieChartTextColor: '#464646',
                pieChartTextProcentColor: '#92939C',
                downColor: '#F27870',
                upColor: '#02C9BF'
            },
            dark: {
                primary: '#2E2D2D',
                revertPrimary: '#E9E9E9',
                headerColor: '#262626',
                textMainColor: '#E9E9E9',
                textSecondaryColor: '#92939C',
                textSecondaryRevert: '#fff',
                textAccentColor: '#42E8E0',
                primaryBg: '#2E2D2D',
                secondary: '#424242',
                switchColor: '#464646',
                sliderBackgroundColor: "#464646",
                stockTableHeaderBorderColor: '#2e2d2d',
                accent: '#42E8E0',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',
                mainBorderColor: '#1E1E1E',
                walletHoverBGColor: '#1e1e1e',
                btnCancelHover: '#3E3F55',
                btnTryHover: '#67CA9D',
                marketHeaderButton: '#464646',
                lastPriceTitleBg: '#3a3a3a',
                lastPriceTitleBgHover: '#606060',
                primaryColor: '#E9E9E9',
                assetsBtnsHover: '#464646',
                textFormMaxColor: '#A4A5AF',
                orderFormProgressItemBg: '#282942',
                orderFormProgressItemBorderActive: '#42E8E0',
                inputBg: '#2E2D2D',
                searchInputBg: '#383838',
                blackwhite: '#E9E9E9',
                paginationActiveItemColor: '#111222',
                publicPagesBorder: '#3F3F3F',
                selectBackgroundColor: '#464646',
                selectColor: '#E9E9E9',
                selectColorHover: '#1E1E1E',
                periodBackgroundColor: '#1F1F1F',
                periodHoverColor: '#E9E9E9',
                orderBackgroundLineRed: '#422332',
                orderBackgroundLineGreen: '#23383E',
                loginButtonBackgroundColor: '#02C9BF',
                loginButtonBackgroundHoverColor: '#fff',
                loginButtonBackgroundOpenColor: '#262626',
                loginButtonBorderOpenColor: '#42E8E0',
                loginButtonTitleColor: '#B7F6F3',
                loginButtonTitleHoverColor: '#E9E9E9',
                loginButtonTitleOpenColor: '#A4A5AF',
                loginButtonCountColor: '#04B0A6',
                loginButtonCountOpenColor: '#FFFFFF',
                loginButtonCountHoverColor: '#02C9BF',
                loginDropduwnBackgroundColor: '#464646',
                loginDropduwnHoverBackgroundColor: '#1E1E1E',
                loginButtonIconColor: '#E9E9E9',
                loginButtonIconOpenColor: '#42E8E0',
                chartGeneralBtnColor: '#42e8e0',
                loginButtonIconHoverColor: '#02C9BF',
                graphLineColor: '#E9E9E9',
                graphBackgroundLineColor: '#464646',
                evenRowBackground: '#333232',
                graphBottomLine: '#48515D',
                tableSecondaryColor: '#92939C',
                disableButtonColor: '#b7dfdd',
                pieChartBackgroundColor: '#2E2D2D',
                pieChartTextColor: '#E9E9E9',
                pieChartTextProcentColor: '#A4A5AF',
                downColor: '#F27870',
                upColor: '#02C9BF'
            },
        },
        options: {
            customProperties: true,
        },
    },
})
