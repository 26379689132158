
import Vue from 'vue';
import Vuex from 'vuex';
import VueRouter from 'vue-router';
import { alert } from './alert.module';
import { authentication } from './authentication.module';
import { users } from './users.module';
import { intervals } from './setIntervals';
import { charts } from  './chart-config'

import wallets from './modules/wallets';
import markets from './modules/markets';
import orders from './modules/orders';
import invite from './modules/invite';
import tradingData from './modules/trading-data';
import reports from './modules/reports';
import rewards from './modules/rewards';
import tournaments from './modules/tournaments';
import liquidationAuction from './modules/liquidation-auction';
import layouts from './modules/layouts';

Vue.use(VueRouter).use(Vuex);


const store = new Vuex.Store({
  modules: {
    alert,
    authentication,
    users,
    intervals,
    charts,
    wallets,
    markets,
    orders,
    invite,
    tradingData,
    reports,
    rewards,
    tournaments,
    liquidationAuction,
    layouts,
  }
});

export default store;
