<template>
  <div class="chart-item">
    <h3 class="chart-item-title textMainColor">
      {{ $t('Top Trader Long/Short Ratio (Position)') }}
    </h3>
    <highcharts :options="chartOptionsTopTraderPositions"></highcharts>
  </div>
</template>

<script>
import moment from "moment";
import {Chart} from "highcharts-vue";

export default {
  name: "TraderRatioPosition",
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      chartOptionsTopTraderPositions: {
        title: null,
        credits: {
          enabled: false
        },
        chart: {
          backgroundColor: this.$vuetify.theme.dark,
          height: 250,
        },
        xAxis: [{
          categories: [
            1643525100000,
            1643525400000,
            1643525700000,
            1643526000000,
            1643526300000,
            1643526600000,
            1643526900000,
            1643527200000,
            1643527500000,
            1643527800000,
            1643528100000,
            1643528400000,
            1643528700000,
            1643529000000,
            1643529300000,
            1643529600000,
            1643529900000,
            1643530200000,
            1643530500000,
            1643530800000,
            1643531100000,
            1643531400000,
            1643531700000,
            1643532000000,
            1643532300000,
            1643532600000,
            1643532900000,
            1643533200000,
            1643533500000,
            1643533800000
          ],
          labels: {
            formatter: function () {
              return moment.utc(this.value).format('MM/DD  HH:mm');
            }
          },
          lineColor: "#48515D",
        }],
        plotOptions: {
          column: {
            stacking: 'percent',
          },
        },
        tooltip: {
          shared: true,
        },
        yAxis: [
          {
            gridLineColor: '#464646',
            lineColor: '#464646',
            minorGridLineColor: '#464646',
            tickColor: '#464646',
            plotLines: [{
              color: '#464646'
            }],
            id: 'y-axis',
            title: null,
            labels: {
              formatter: function () {
                return this.value + '%';
              }
            },
          },
          {
            gridLineColor: '#464646',
            lineColor: '#464646',
            minorGridLineColor: '#464646',
            tickColor: '#464646',
            plotLines: [{
              color: '#464646'
            }],
            id: 'y-axis1',
            title: null,
            opposite: true,
            labels: {
              formatter: function () {
                return this.value.toFixed(2);
              }
            },
          }
        ],
        legend: {
          align: "center",
          itemStyle: {"color": "#E9E9E9", "cursor": "pointer", "fontSize": "12px", "lineHeight": "18px", "fontWeight": "normal", "textOverflow": "ellipsis"},
          itemHoverStyle: {"color": "#42E8E0", "cursor": "pointer", "fontSize": "12px", "lineHeight": "18px", "fontWeight": "normal", "textOverflow": "ellipsis"},
          squareSymbol: true,
          symbolHeight: 14,
          symbolWidth: 14,
          symbolRadius: 0,
          labelFormatter: function () {
            if(this.index !== 2) {
              return this.name + ' %';
            }else {
              return this.name
            }
          }
        },
        series: [
          {
            yAxis: 'y-axis',
            type: 'column',
            color: '#F27870',
            tooltip: {
              valueSuffix: '%'
            },
            borderColor: 'transparent',
            data: [
              51.38,
              51.34,
              51.35,
              51.36,
              51.49,
              51.40,
              51.34,
              51.29,
              51.29,
              51.30,
              51.28,
              51.27,
              51.29,
              51.32,
              51.29,
              51.23,
              51.24,
              51.18,
              51.19,
              51.26,
              51.19,
              51.15,
              51.21,
              51.24,
              51.40,
              51.52,
              51.58,
              51.59,
              51.71,
              51.75
            ],
            name: "Short Position",
            stack: 'male'
          },
          {
            yAxis: 'y-axis',
            type: 'column',
            color: '#67CA9D',
            tooltip: {
              valueSuffix: '%'
            },
            borderColor: 'transparent',
            data: [
              48.62,
              48.66,
              48.65,
              48.64,
              48.51,
              48.60,
              48.66,
              48.71,
              48.71,
              48.70,
              48.72,
              48.73,
              48.71,
              48.68,
              48.71,
              48.77,
              48.76,
              48.82,
              48.81,
              48.74,
              48.81,
              48.85,
              48.79,
              48.76,
              48.60,
              48.48,
              48.42,
              48.41,
              48.29,
              48.25
            ],
            name: "Long Position",
            stack: 'male'
          },
          {
            yAxis: 'y-axis1',
            type: 'line',
            color: '#E0E0E6',
            marker: {
              fillColor: 'white',
              lineWidth: 2,
              lineColor: '#fff',
            },
            stickyTracking: false,
            data:  [
              1.1378,
              1.1383,
              1.1389,
              1.1394,
              1.1391,
              1.1384,
              1.1388,
              1.1387,
              1.1402,
              1.1398,
              1.1402,
              1.1407,
              1.141,
              1.1409,
              1.1409,
              1.1408,
              1.14,
              1.1397,
              1.1412,
              1.1416,
              1.1407,
              1.1414,
              1.1414,
              1.1406,
              1.1388,
              1.1387,
              1.1375,
              1.1366,
              1.1361,
              1.136
            ],
            name: "Long/Short Ratio (Positions)"
          },
        ]
      },
    }
  },
}
</script>

<style scoped>

</style>