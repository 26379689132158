<!--<template>-->
<!--  <div class="report-template">-->
<!--    <h1 class="report-title textMainColor">{{ $t('API Management') }}</h1>-->
<!--    <div class="create-api-form">-->
<!--      <label for="api-key">{{ $t('Label API key to proceed') }}</label>-->
<!--      <input-->
<!--          id="api-key"-->
<!--          v-model="apiKeyLabel"-->
<!--          :placeholder="$t('API key label')"-->
<!--          type="text"-->
<!--          @keyup.enter="createApi"-->
<!--      >-->
<!--      <button-->
<!--          type="button"-->
<!--          @click="createApi"-->
<!--      >{{ $t('Create API') }}-->
<!--      </button>-->
<!--      <span-->
<!--          v-if="!isValid"-->
<!--          class="message-warning"-->
<!--      >-->
<!--          <svg-->
<!--              fill="none"-->
<!--              height="12"-->
<!--              viewBox="0 0 12 13"-->
<!--              width="12"-->
<!--              xmlns="http://www.w3.org/2000/svg"-->
<!--          >-->
<!--            <path-->
<!--                d="M1.6875 2.1875L5.99996 6.49996M5.99996 6.49996L10.3124 2.1875M5.99996 6.49996L10.3125 10.8124M5.99996 6.49996L1.68758 10.8124"-->
<!--                stroke="#FF7337"-->
<!--                stroke-linecap="round"-->
<!--                stroke-linejoin="round"-->
<!--                stroke-width="1.5"-->
<!--            />-->
<!--          </svg>-->
<!--          Please enter API key label-->
<!--        </span>-->
<!--    </div>-->
<!--    <ul class="report-list">-->
<!--      <li>Creating an API private key provides access to market</li>-->
<!--      <li>чм</li>-->
<!--      <li>вап</li>-->
<!--    </ul>-->
<!--    <div class="api-list-wrapper">-->
<!--      <div class="delete-api">-->
<!--        <h1 class="textMainColor">{{ $t('API List') }}</h1>-->
<!--        <button type="button">{{ $t('Delete all API') }}</button>-->
<!--      </div>-->
<!--      <div class="api-list">-->
<!--        <div-->
<!--            v-for="(apiItem, index) in apiList"-->
<!--            :key="index"-->
<!--            :class="{'border-bottom': apiList.length - 1 === index}"-->
<!--            class="api-list-item"-->
<!--        >-->
<!--          <api-list-item-->
<!--              :apiItem="apiItem"-->
<!--              :index="index"-->
<!--          />-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <create-api-popup />-->
<!--  </div>-->
<!--</template>-->
<template>
  <ComingSoon />
</template>
<script>
import CreateApiPopup from '../popups/CreateApiPopup';
import ApiListItem from '../api-list-item/ApiListItem';
import ComingSoon from "../coming-soon/ComingSoon";

export default {
  name: 'ApiManagement',
  components: {
    // ApiListItem,
    // CreateApiPopup,
    ComingSoon,
  },
  data() {
    return {
      apiKeyLabel: '',
      isValid: true,
      apiList: [
        {
          title: 'MyAPIKeySet',
          key: '0xaec766463922244a091fcb7b8f11045839eeeb3d',
          secretKey: '0xaec766412318244a091fcb7b8f11987839ebbbb3d',
        },
        {
          title: 'MyAPIKeySet',
          key: '0xaec766463922244a091fcb7b8f11045839eeeb3d',
          secretKey: '0xaec768456546546244a091fcb7b8f11987839ebbbb2a',
        },
      ],
    };
  },

  methods: {
    createApi() {
      if(this.apiKeyLabel) {
        this.apiKeyLabel = '';
      } else {
        this.isValid = false;
      }
    },
  },

  watch: {
    apiKeyLabel() {
      this.isValid = true;
    },
  },
};
</script>
<style scoped>
.report-title {
  margin: 0 0 32px;
}

.create-api-form {
  position: relative;
}

.create-api-form label {
  position: absolute;
  top: -20px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 600;
  color: #cecece;
}

.create-api-form input,
.create-api-form button {
  border-radius: 6px;
  padding: 12px;
}

.create-api-form input {
  margin-right: 8px;
  width: 320px;
  box-shadow: 0 4px 17px rgb(21 83 80 / 8%);
  background-color: var(--v-loginDropduwnBackgroundColor-base);
  font-weight: 600;
  color: var(--v-revertPrimary-base);
}

.create-api-form input::placeholder {
  font-size: 14px;
  font-weight: 600;
  color: #92939c;
}

.create-api-form button {
  transition: background-color 0.5s;
  width: 150px;
  background: var(--v-loginButtonIconHoverColor-base);
  color: #fff;
}

.message-warning {
  display: flex;
  position: absolute;
  align-items: center;
  font-size: 11px;
  color: #f27870;
}

.report-list {
  margin: 30px 0 55px;
  font-size: 11px;
  color: #a4a5af;
}

.report-list li {
  list-style: decimal inside;
}

.delete-api {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.delete-api h1 {
  margin: 0;
}

.delete-api button {
  transition: background-color 0.5s, color 0.5s;
  border: 1px solid var(--v-marketHeaderButton-base);
  border-radius: 6px;
  padding: 12px 10px;
  font-weight: 700;
  color: var(--v-textMainColor-base);
}

.border-bottom {
  border-bottom: 1px solid #E0E0E5;
}

.v-application.theme--dark .border-bottom {
  border-bottom: 1px solid #1E1E1E;
}

.create-api-form input:hover,
.create-api-form input:focus-visible {
  outline: 1px solid #42e8e0;
}

.create-api-form button:hover {
  background: #67ca9d;
}

.delete-api button:hover {
  background-color: var(--v-loginButtonIconHoverColor-base);
  color: #fff;
}
</style>
