<template>
  <div class="open-interest-period">
    <h2 class="chart-item-title textMainColor">
      <slot></slot>
    </h2>
    <div class="period">
      <div class="period-time">
        <div
            v-for="(period, index) in periods"
            :key="index"
            @click="$emit('selectedPeriod', period.dimension)"
            class="button"
            :class="{
              active: period.dimension === dimension,
            }"
        >{{ $t(period.buttonText) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TimeFrame",
  emits: ['selectedPeriod'],
  props: {
    periods: {
      type: Array,
    },
    dimension: {
      type: String,
      default: 'MIN_5'
    },
  },
};
</script>

<style scoped>
.open-interest-period {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
}

.open-interest-period .chart-item-title {
  margin: 0;
}

.period {
  display: flex;
  gap: 20px;
  color: #92939C;
}

.period-time {
  display: flex;
  gap: 10px;
}

.period .button {
  padding: 3px 8px;
  border-radius: 4px;
  background-color: var(--v-periodBackgroundColor-base);
  font-weight: 500;
  font-size: 12px;
  text-transform: capitalize;
  cursor: pointer;
  border: 1px solid transparent;
  transition: all .5s;
}

.period .button:hover,
.period .active {
  background-color: var(--v-selectBackgroundColor-base);
  box-shadow: 0 4px 13px rgba(0, 71, 68, 0.08);
  color: var(--v-periodHoverColor-base);
  border: 1px solid #42e8e0;
}
</style>