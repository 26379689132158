export default {
    SET_ORDERS_POSITION_DATA(state, data) {
        state.ordersPosition = data;
    },
    SET_ORDERS_OPEN_DATA(state, data) {
        state.ordersOpen = data;
    },
    SET_ORDERS_HISTORY_DATA(state, data) {
        state.ordersHistory = data;
    }
  };