export default {
  topReferralsData: [],
  currentRoundData: {},
  rewardsSettingsData: {},
  rewardsMyRatingData: {},
  rewardsMyRoundProfit: {},
  rewardsDistributionHistory: [],
  rewardsUserDistributionHistory: {},
  referralLink: "",
  totalProfit: null,
  invitedUsers: [],
};