<template>
  <div class="toast-close">
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.25 1.25L6.99995 6.99995M6.99995 6.99995L12.7499 1.25M6.99995 6.99995L12.75 12.7498M6.99995 6.99995L1.2501 12.7498" stroke="#92939C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </div>
</template>

<script>
export default {
  name: "CloseButton"
}
</script>

<style scoped>

</style>