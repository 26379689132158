export default {
    sendInvite({commit}, {params}) {
        return new Promise((resolve, reject) => {
            $axios.post(`/invites/send`, {emails: params}).then((res) => {
                resolve(res.data);
            })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    sendRate({commit}, {params}) {
        return new Promise((resolve, reject) => {
            $axios.post(`/rating`, params).then((res) => {
                resolve(res.data);
            })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    isUserRated({commit}) {
        return new Promise((resolve, reject) => {
            $axios.get(`/rating/isUserRated`).then((res) => {
                resolve(res.data);
            })
                .catch((err) => {
                    reject(err);
                });
        });
    },
}