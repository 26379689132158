<template>
  <nav>
    <div class="sidebar-title-wrap">
      <img :src="icon" alt="">
      <span class="sidebar-title">{{$t(title || 'Rewards')}}</span>
    </div>
    <router-link
        v-for="(item, index) in itemList"
        :to="{ path: item.routeTo, query: item.query}"
        tag="li"
        :key="index"
    >
      <a href="" class="textSecondaryColor">
        <span>{{ $t(item.name) }}</span>
      </a>
    </router-link>
  </nav>
</template>

<script>
export default {
  name: "PublicPageSidebar",
  props: {
    itemList: Array,
    icon: {},
    title: {},
  },
  methods: {},
}
</script>

<style scoped>
.public-page-sidebar nav {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
}

.public-page-sidebar nav li {
  display: block;
  width: 100%;
  margin-bottom: 15px;
}

.public-page-sidebar nav li a {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  transition: all .5s;
  position: relative;
}

.public-page-sidebar nav li a.textSecondaryColor:hover {
  color: var(--v-textMainColor-base) !important;
}

.public-page-sidebar nav li a span {
  display: inline-block;
  position: relative;
}

.public-page-sidebar nav li a span:before {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 1px;
  background: #42E8E0;
  transition: all .5s;
}

.public-page-sidebar nav li a:hover span:before {
  opacity: 1;
}

.public-page-sidebar nav li.active a span:before {
  opacity: 1;
}

.public-page-sidebar nav li.active a {
  color: var(--v-textMainColor-base) !important;
}
</style>