export default {
  SET_TOP_REFERRALS(state, data) {
    state.topReferralsData = data;
  },
  SET_CURRENT_ROUND(state, data) {
    state.currentRoundData = data;
  },
  SET_REWARDS_SETTINGS(state, data) {
    state.rewardsSettingsData = data;
  },
  SET_REWARDS_MY_RATING(state, data) {
    state.rewardsMyRatingData = data;
  },
  SET_REWARDS_MY_ROUND_PROFIT(state, data) {
    state.rewardsMyRoundProfit = data;
  },
  SET_REWARDS_DISTRIBUTION_HISTORY(state, data) {
    state.rewardsDistributionHistory = data;
  },
  SET_REWARDS_USER_DISTRIBUTION_HISTORY(state, data) {
    state.rewardsUserDistributionHistory = data;
  },
  SET_REFERRAL_LINK(state, data) {
    state.referralLink = data;
  },

  SET_REFERRAL_INVITED_USERS(state, data) {
    state.invitedUsers = data;
  },

  SET_TOTAL_PROFIT(state, data) {
    state.totalProfit = data;
  },
};