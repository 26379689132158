export default {
    SET_TAKER_VOLUME(state, data) {
        state.takerVolumeData = data;
    },
    SET_OPEN_INTEREST(state, data) {
        state.openInterestData = data;
    },
    SET_INDEX_PRICE_HISTORY(state, data) {
        state.indexPriceHistoryData = data;
    },
    SET_FUNDING_RATE_REAL_TIME(state, data) {
        state.fundingRateRealTimeData = data;
    },
    SET_FUNDING_RATE_HISTORY(state, data) {
        state.fundingRateHistoryData = data;
    },
};