<template>
  <div class="rewards-content">
    <ConnectWallet
        ref="connectWallet"
    />
    <div class="round-main-content">
      <div class="round-info">
        <div class="round-content">
          <div class="round-title-wrap">
            <h1 class="public-page-title round-title">Round #{{roundNumber}}</h1>
          </div>
          <div class="round-list">
            <div class="round-item">
              <div class="round-item-icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.2391 2H8.75906C4.99906 2 4.70906 5.38 6.73906 7.22L17.2591 16.78C19.2891 18.62 18.9991 22 15.2391 22H8.75906C4.99906 22 4.70906 18.62 6.73906 16.78L17.2591 7.22C19.2891 5.38 18.9991 2 15.2391 2Z" stroke="#2DCAC2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
              <span class="round-item-title">Coundown</span>
              <span class="round-item-value">{{countdown}}</span>
            </div>
            <div class="round-item">
              <div class="round-item-icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 8V13M12 22C7.17 22 3.25 18.08 3.25 13.25C3.25 8.42 7.17 4.5 12 4.5C16.83 4.5 20.75 8.42 20.75 13.25" stroke="#2DCAC2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M9 2H15M14.9 18.5V17.34C14.9 15.91 15.92 15.32 17.16 16.04L18.16 16.62L19.16 17.2C20.4 17.92 20.4 19.09 19.16 19.81L18.16 20.39L17.16 20.97C15.92 21.69 14.9 21.1 14.9 19.67V18.5Z" stroke="#2DCAC2" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
              <span class="round-item-title">Start Date</span>
              <span class="round-item-value">{{startedAt}}</span>
            </div>
            <div class="round-item">
              <div class="round-item-icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M22 15C22 18.87 18.87 22 15 22L16.05 20.25M2 9C2 5.13 5.13 2 9 2L7.95 3.75M11 16.15V18.85C11 21.1 10.1 22 7.85 22H5.15C2.9 22 2 21.1 2 18.85V16.15C2 13.9 2.9 13 5.15 13H7.85C10.1 13 11 13.9 11 16.15ZM17.5 11C18.0909 11 18.6761 10.8836 19.2221 10.6575C19.768 10.4313 20.2641 10.0998 20.682 9.68198C21.0998 9.26412 21.4313 8.76804 21.6575 8.22208C21.8836 7.67611 22 7.09095 22 6.5C22 5.90905 21.8836 5.32389 21.6575 4.77792C21.4313 4.23196 21.0998 3.73588 20.682 3.31802C20.2641 2.90016 19.768 2.56869 19.2221 2.34254C18.6761 2.1164 18.0909 2 17.5 2C16.3065 2 15.1619 2.47411 14.318 3.31802C13.4741 4.16193 13 5.30653 13 6.5C13 7.69347 13.4741 8.83807 14.318 9.68198C15.1619 10.5259 16.3065 11 17.5 11Z" stroke="#2DCAC2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
              <span class="round-item-title">Distribution Date</span>
              <span class="round-item-value">{{distributedAt}}</span>
            </div>
            <div class="round-item">
              <div class="round-item-icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18.5 12.65V16.35C18.5 19.47 15.59 22 12 22C8.41 22 5.5 19.47 5.5 16.35V12.65C5.5 15.77 8.41 18 12 18C15.59 18 18.5 15.77 18.5 12.65Z" stroke="#2DCAC2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M18.5 7.65C18.5 8.56 18.25 9.4 17.81 10.12C16.74 11.88 14.54 13 12 13C9.46 13 7.26 11.88 6.19 10.12C5.75 9.4 5.5 8.56 5.5 7.65C5.5 6.09 6.23 4.68 7.4 3.66C8.58 2.63 10.2 2 12 2C13.8 2 15.42 2.63 16.6 3.65C17.77 4.68 18.5 6.09 18.5 7.65Z" stroke="#2DCAC2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M18.5 7.65V12.65C18.5 15.77 15.59 18 12 18C8.41 18 5.5 15.77 5.5 12.65V7.65C5.5 4.53 8.41 2 12 2C13.8 2 15.42 2.63 16.6 3.65C17.77 4.68 18.5 6.09 18.5 7.65Z" stroke="#2DCAC2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
              <span class="round-item-title">Pool Size</span>
              <span class="round-item-value">{{totalRewardPoolSize}} USDT</span>
            </div>
          </div>
          <div class="pool-info">
            <div class="pool-content">
              <div class="referal-subtitle-wrap">
                <h2 class="public-page-subtitle referal-subtitle">Pool Details</h2>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <svg v-bind="attrs" v-on="on" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.20868 5.19649C7.20868 4.82968 7.55615 4.51114 8.00019 4.51114C8.43455 4.51114 8.78211 4.82968 8.78211 5.19649C8.78211 5.56333 8.43458 5.89152 8.00019 5.89152C7.55618 5.89152 7.20868 5.56333 7.20868 5.19649ZM7.25686 7.23327C7.25686 6.963 7.56575 6.77955 8.00013 6.77955C8.41522 6.77955 8.75304 6.96297 8.75304 7.23322V10.911C8.75304 11.1427 8.41522 11.3743 8.00013 11.3743C7.56575 11.3743 7.25686 11.1427 7.25686 10.911V7.23327Z" fill="#92939C"/>
                      <circle cx="8" cy="8" r="7" stroke="#92939C" stroke-width="1.5"/>
                    </svg>
                  </template>
                  <span>{{ $t('Reward pool distribution between referral and Robinhood programs.') }}</span>
                </v-tooltip>
              </div>
              <div class="pool-list">
                <div class="pool-item">
                  <span class="pool-item-title pool-item-title-referral">Referral Program</span>
                  <span class="pool-item-currency-value">
                    {{totalReferralProgramPoolSize}}
                    <span class="secondary-text">USDT</span>
                  </span>
                  <span class="pool-item-percent">
                    {{totalReferralProgramPoolSizePercentage}}
                    <span class="secondary-text">%</span>
                  </span>
                </div>
                <div class="pool-item">
                  <span class="pool-item-title pool-item-title-robinhood">
                    Robinhood Rewards</span>
                  <span class="pool-item-currency-value">
                    {{totalRobbinhoodPoolSize}}
                    <span class="secondary-text">USDT</span>
                  </span>
                  <span class="pool-item-percent">
                    {{totalRobbinhoodPoolSizePercentage}}
                    <span class="secondary-text">%</span>
                  </span>
                </div>
              </div>
            </div>
            <div class="pool-piechart">
              <highcharts
                  :updateArgs="updateArgs"
                  :options="chartOptionsPool"
                  v-if="poolData.length"
              />
            </div>
          </div>
        </div>
        <div class="round-statistic">
          <div class="round-statistic-content" v-if="token">
            <div class="round-statistic-list">
              <div class="round-statistic-item">
                <span class="round-statistic-item-currency-value">{{totalProfit}} USDT</span>
                <div class="round-statistic-item-type">
                  <span class="round-statistic-item-type-value">Your Current Reward</span>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <svg v-bind="attrs" v-on="on" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.20868 5.19649C7.20868 4.82968 7.55615 4.51114 8.00019 4.51114C8.43455 4.51114 8.78211 4.82968 8.78211 5.19649C8.78211 5.56333 8.43458 5.89152 8.00019 5.89152C7.55618 5.89152 7.20868 5.56333 7.20868 5.19649ZM7.25686 7.23327C7.25686 6.963 7.56575 6.77955 8.00013 6.77955C8.41522 6.77955 8.75304 6.96297 8.75304 7.23322V10.911C8.75304 11.1427 8.41522 11.3743 8.00013 11.3743C7.56575 11.3743 7.25686 11.1427 7.25686 10.911V7.23327Z" fill="#92939C"/>
                        <circle cx="8" cy="8" r="7" stroke="#92939C" stroke-width="1.5"/>
                      </svg>
                    </template>
                    <span>{{ $t('Gross reward for the current round.') }}</span>
                  </v-tooltip>
                </div>
              </div>
              <div class="round-statistic-item">
                <span class="round-statistic-item-currency-value">{{referralProgramProfit}} USDT</span>
                <div class="round-statistic-item-type">
                  <span class="round-statistic-item-type-value">Referral Program</span>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <svg v-bind="attrs" v-on="on" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.20868 5.19649C7.20868 4.82968 7.55615 4.51114 8.00019 4.51114C8.43455 4.51114 8.78211 4.82968 8.78211 5.19649C8.78211 5.56333 8.43458 5.89152 8.00019 5.89152C7.55618 5.89152 7.20868 5.56333 7.20868 5.19649ZM7.25686 7.23327C7.25686 6.963 7.56575 6.77955 8.00013 6.77955C8.41522 6.77955 8.75304 6.96297 8.75304 7.23322V10.911C8.75304 11.1427 8.41522 11.3743 8.00013 11.3743C7.56575 11.3743 7.25686 11.1427 7.25686 10.911V7.23327Z" fill="#92939C"/>
                        <circle cx="8" cy="8" r="7" stroke="#92939C" stroke-width="1.5"/>
                      </svg>
                    </template>
                    <span>{{ $t('Your reward generated by affiliates.') }}</span>
                  </v-tooltip>
                </div>
              </div>
              <div class="round-statistic-item">
                <span class="round-statistic-item-currency-value" v-if="isReward">{{ robbinhoodProgramProfit }} USDT</span>
                <span class="round-statistic-item-no-rewards" v-else>{{ $t('No reward ') }}</span>
                <v-tooltip top v-if="!isReward">
                  <template v-slot:activator="{ on, attrs }">
                    <svg v-bind="attrs" v-on="on" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.20868 5.19649C7.20868 4.82968 7.55615 4.51114 8.00019 4.51114C8.43455 4.51114 8.78211 4.82968 8.78211 5.19649C8.78211 5.56333 8.43458 5.89152 8.00019 5.89152C7.55618 5.89152 7.20868 5.56333 7.20868 5.19649ZM7.25686 7.23327C7.25686 6.963 7.56575 6.77955 8.00013 6.77955C8.41522 6.77955 8.75304 6.96297 8.75304 7.23322V10.911C8.75304 11.1427 8.41522 11.3743 8.00013 11.3743C7.56575 11.3743 7.25686 11.1427 7.25686 10.911V7.23327Z" fill="#92939C"/>
                      <circle cx="8" cy="8" r="7" stroke="#92939C" stroke-width="1.5"/>
                    </svg>
                  </template>
                  <span>{{ $t('A reward program designed to reward those users who pay the highest fees.') }}</span>
                </v-tooltip>
                <div class="round-statistic-item-type">
                  <span class="round-statistic-item-type-value">Robinhood Rewards</span>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <svg v-bind="attrs" v-on="on" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.20868 5.19649C7.20868 4.82968 7.55615 4.51114 8.00019 4.51114C8.43455 4.51114 8.78211 4.82968 8.78211 5.19649C8.78211 5.56333 8.43458 5.89152 8.00019 5.89152C7.55618 5.89152 7.20868 5.56333 7.20868 5.19649ZM7.25686 7.23327C7.25686 6.963 7.56575 6.77955 8.00013 6.77955C8.41522 6.77955 8.75304 6.96297 8.75304 7.23322V10.911C8.75304 11.1427 8.41522 11.3743 8.00013 11.3743C7.56575 11.3743 7.25686 11.1427 7.25686 10.911V7.23327Z" fill="#92939C"/>
                        <circle cx="8" cy="8" r="7" stroke="#92939C" stroke-width="1.5"/>
                      </svg>
                    </template>
                    <span>{{ $t('A reward program designed to reward those users who pay the highest fees.') }}</span>
                  </v-tooltip>
                </div>
              </div>
            </div>
            <div class="round-statistic-info">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.20868 5.19649C7.20868 4.82968 7.55615 4.51114 8.00019 4.51114C8.43455 4.51114 8.78211 4.82968 8.78211 5.19649C8.78211 5.56333 8.43458 5.89152 8.00019 5.89152C7.55618 5.89152 7.20868 5.56333 7.20868 5.19649ZM7.25686 7.23327C7.25686 6.963 7.56575 6.77955 8.00013 6.77955C8.41522 6.77955 8.75304 6.96297 8.75304 7.23322V10.911C8.75304 11.1427 8.41522 11.3743 8.00013 11.3743C7.56575 11.3743 7.25686 11.1427 7.25686 10.911V7.23327Z" fill="#92939C"/>
                <circle cx="8" cy="8" r="7" stroke="#92939C" stroke-width="1.5"/>
              </svg>
              <p class="round-statistic-info-text">You will receive rewards upon completion of each round. They will be automatically sent to your wallet.</p>
            </div>
          </div>
          <div class="round-statistic-content" v-else>
            <div class="round-statistic-icon">
              <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.82031 44C6.82031 36.26 14.5203 30 24.0003 30C25.9203 30 27.7803 30.26 29.5203 30.74M24.0003 24C26.6525 24 29.196 22.9464 31.0714 21.0711C32.9467 19.1957 34.0003 16.6522 34.0003 14C34.0003 11.3478 32.9467 8.8043 31.0714 6.92893C29.196 5.05357 26.6525 4 24.0003 4C21.3481 4 18.8046 5.05357 16.9292 6.92893C15.0539 8.8043 14.0003 11.3478 14.0003 14C14.0003 16.6522 15.0539 19.1957 16.9292 21.0711C18.8046 22.9464 21.3481 24 24.0003 24Z" stroke="#E0E0E6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M38.98 35.96H33.02M36 33.04V39.02M44 36C44 36.64 43.92 37.26 43.76 37.86C43.58 38.66 43.26 39.44 42.84 40.12C42.135 41.305 41.1334 42.2859 39.9341 42.9663C38.7347 43.6466 37.3789 44.0029 36 44C34.0309 44.0058 32.1317 43.2704 30.68 41.94C30.08 41.42 29.56 40.8 29.16 40.12C28.3987 38.8808 27.9971 37.4543 28 36C27.9987 34.9491 28.2047 33.9082 28.6063 32.937C29.0079 31.9658 29.5971 31.0833 30.3402 30.3402C31.0834 29.5971 31.9658 29.0079 32.937 28.6063C33.9082 28.2047 34.9491 27.9987 36 28C38.36 28 40.5 29.02 41.94 30.66C43.22 32.08 44 33.96 44 36Z" stroke="#2DCAC2" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <h3 class="round-statistic-title">Join to Current Round</h3>
            <p class="round-statistic-description">This program is only for users of our platform. To participate, please connect your wallet.</p>
            <div class="round-statistic-btn-wrap">
              <button
                  class="round-statistic-btn order-form-callateral-button"
                  @click="openConnectWalletDialog"
              >{{ $t('Connect Wallet') }}</button>
            </div>
          </div>
        </div>
      </div>
      <div class="robinhood-info">
        <div class="referal-subtitle-wrap">
          <h2 class="public-page-subtitle referal-subtitle">Robinhood Rewards</h2>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <svg v-bind="attrs" v-on="on" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.20868 5.19649C7.20868 4.82968 7.55615 4.51114 8.00019 4.51114C8.43455 4.51114 8.78211 4.82968 8.78211 5.19649C8.78211 5.56333 8.43458 5.89152 8.00019 5.89152C7.55618 5.89152 7.20868 5.56333 7.20868 5.19649ZM7.25686 7.23327C7.25686 6.963 7.56575 6.77955 8.00013 6.77955C8.41522 6.77955 8.75304 6.96297 8.75304 7.23322V10.911C8.75304 11.1427 8.41522 11.3743 8.00013 11.3743C7.56575 11.3743 7.25686 11.1427 7.25686 10.911V7.23327Z" fill="#92939C"/>
                <circle cx="8" cy="8" r="7" stroke="#92939C" stroke-width="1.5"/>
              </svg>
            </template>
            <span>{{ $t('Robinhood Rewards - A reward program designed to reward those users who pay the highest fees.') }}</span>
          </v-tooltip>
        </div>
        <div class="robinhood-table-wrap">
          <table class="rewards-table robinhood-table">
            <thead class="holder-table">
            <tr class="table-head">
              <th class="tier">
                <p>
                  <span class="textSecondaryColor">{{ $t('Fee Tier') }}</span>
                </p>
              </th>
              <th class="pool">
                <p>
                  <span class="textSecondaryColor">{{ $t('Share of the Current Pool') }}</span>
                </p>
              </th>
              <th class="equivalent">
                <p>
                  <span class="textSecondaryColor">{{ $t('Monetary Equivalent') }}</span>
                </p>
              </th>
            </tr>
            </thead>
            <tbody class="order-history-table-main">
            <tr
                class="table-body"
                v-for="program in robbinhoodProgram"
                :key="program.tier"
                :class="currentReferralUser && +program.tier === +activeIndexStructure ? 'current-leader' : ''"
            >
              <td class="tier">Tier {{program.tier}}</td>
              <td class="pool">
                {{program.coefficient * 100}}
                <span class="textSecondaryColor">%</span>
              </td>
              <td class="equivalent">
                {{currentRound.totalRobbinhoodPoolSize !== 0 ? prettify(String(aroundNumber(program.coefficient * currentRound.totalRobbinhoodPoolSize) || 0)) : 0}}
                <span class="textSecondaryColor">USDT</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="top-referal-table-wrap">
      <div class="referal-subtitle-wrap">
        <h2 class="public-page-subtitle referal-subtitle">Top Referral</h2>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <svg v-bind="attrs" v-on="on" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M7.20868 5.19649C7.20868 4.82968 7.55615 4.51114 8.00019 4.51114C8.43455 4.51114 8.78211 4.82968 8.78211 5.19649C8.78211 5.56333 8.43458 5.89152 8.00019 5.89152C7.55618 5.89152 7.20868 5.56333 7.20868 5.19649ZM7.25686 7.23327C7.25686 6.963 7.56575 6.77955 8.00013 6.77955C8.41522 6.77955 8.75304 6.96297 8.75304 7.23322V10.911C8.75304 11.1427 8.41522 11.3743 8.00013 11.3743C7.56575 11.3743 7.25686 11.1427 7.25686 10.911V7.23327Z" fill="#92939C"/>
              <circle cx="8" cy="8" r="7" stroke="#92939C" stroke-width="1.5"/>
            </svg>
          </template>
          <span>{{ $t('Highest grossing ambassadors and their returns.') }}</span>
        </v-tooltip>
      </div>
      <rounds-of-rewards-items-list
          :topReferrals="topReferralsData"
          :currentReferralUser="currentReferralUser"
          :looserReferralUser="looserReferralUser"
          :token="token"
      />
    </div>
  </div>
</template>

<script>
import RoundsOfRewardsItemsList from "@/components/rewards/RoundsOfRewards/RoundsOfRewardsItemsList.vue";
import ConnectWallet from "@/components/popups/ConnectWallet.vue";
import { Chart } from 'highcharts-vue';
import {mapActions} from "vuex";
import moment from "moment/moment";
export default {
  name: "RoundsOfRewards",
  props: {
    token: {},
  },
  components: {
    RoundsOfRewardsItemsList,
    ConnectWallet,
    highcharts: Chart,
  },
  data() {
    return {
      myRating: null,
      updateArgs: [true, true, true],
      poolData: [],
      topReferralsData: [],
      robbinhoodProgram: [],
      countdown: '-',
      currentReferralUser: null,
      looserReferralUser: null,
      activeIndexStructure: null,
      rewardsMyRoundProfit: {},
      chartOptionsPool: {
        chart: {
          plotBackgroundColor: 'var(--v-pieChartBackgroundColor-base)',
          backgroundColor: 'var(--v-pieChartBackgroundColor-base)',
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie',
          width: 80,
          height: 85,
        },
        title: {
          text: '',
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.2f}%</b>'
        },
        accessibility: {
          point: {
            valueSuffix: '%'
          }
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            size: 80,
            dataLabels: {
              enabled: true,
              format: '{point.name}<br> {point.percentage:.2f} %<br> {point.value:.2f}',
              color: 'var(--v-pieChartTextColor-base)',
              style: {
                stroke: 'none',
                fontStyle: 'normal',
                fontFamily: 'Play',
                fontWeight: '700',
                fontSize: '13px',
                lineHeight: '14px',
              }
            },
            borderColor: 'transparent',
            plotOptions: '#333',
          }
        },
        credits: {
          enabled: false
        },
        series: [{
          events: {
            click: function(e) {
              e.preventDefault(); // prevent any action from occuring on "click" event
            }
          },
          type: 'pie',
          name: '',
          innerSize: '44',
          colorByPoint: true,
          data: this.poolData
        }]
      },
    }
  },
  computed: {
    cosmosWallet() {
      return this.$store.state.wallets.metaMaskWallet.authData ? this.$store.state.wallets.metaMaskWallet.authData.granterWallet.address : ''
    },
    currentRound() {
      return this.$store.getters["rewards/getCurrentRoundData"];
    },
    roundNumber() {
      return this.currentRound.roundNumber;
    },
    startedAt() {
      return moment.utc(this.currentRound.startedAt).local().format('DD.MM.yy');
    },
    distributedAt() {
      return moment.utc(this.currentRound.distributedAt).local().format('DD.MM.yy');
    },
    totalRewardPoolSize() {
      return this.prettify(String(this.aroundNumber(this.currentRound.totalRewardPoolSize) || 0)) || 0
    },
    totalReferralProgramPoolSize() {
      return this.prettify(String(this.aroundNumber(this.currentRound.totalReferralProgramPoolSize) || 0)) || 0
    },
    totalReferralProgramPoolSizePercentage() {
      return this.currentRound.totalReferralProgramPoolSizePercentage  || 0;
    },
    totalRobbinhoodPoolSize() {
      return this.prettify(String(this.aroundNumber(this.currentRound.totalRobbinhoodPoolSize) || 0)) || 0
    },
    referralProgramProfit() {
      return this.prettify(String(this.aroundNumber(this.rewardsMyRoundProfit.referralProgramProfit) || 0)) || 0
    },
    robbinhoodProgramProfit() {
      return this.prettify(String(this.aroundNumber(this.rewardsMyRoundProfit.robbinhoodProgramProfit) || 0)) || 0
    },
    totalProfit() {
      return this.prettify(String(this.aroundNumber(this.rewardsMyRoundProfit.totalProfit) || 0)) || 0
    },
    referralRoundNumber() {
      return this.rewardsMyRoundProfit.roundNumber
    },
    isReward() {
      return this.robbinhoodProgram.filter(program => program.tier === this.activeIndexStructure)[0]
    },
    totalRobbinhoodPoolSizePercentage() {
      return this.currentRound.totalRobbinhoodPoolSizePercentage  || 0;
    },
  },
  watch: {
    token(newValue, oldValue) {
      if(oldValue === null && newValue) {
        this.getRewardsMyRoundProfitAction();
        if(!this.topReferralsData.length) {
          this.getAccountInfoData();
        }
        if(this.myRating === null) {
          this.getMyRating();
        }
      }
      if(newValue === null) {
        this.myRating = null
        this.topReferralsData.forEach((member, i) => {
          if(member.number === this.currentReferralUser.number) {
            this.topReferralsData.splice(i, 1);
          }
        })
        this.currentReferralUser = null
        this.getTopReferralsAction()
      }
    }
  },
  methods: {
    ...mapActions({
      getAccountInfo: 'orders/getAccountInfo',
      getTopReferrals: 'rewards/getTopReferrals',
      getCurrentRound: 'rewards/getCurrentRound',
      getRewardsSettings: 'rewards/getRewardsSettings',
      getRewardsMyRating: 'rewards/getRewardsMyRating',
      getRewardsMyRoundProfit: 'rewards/getRewardsMyRoundProfit',
    }),
    aroundNumber(num) {
      if(num === undefined || num === null) {
        return 0
      }
      if(num === 0) {
        return num
      }else {
        // return num ? parseFloat(Number(num).toFixed(2)) : null
        if(+num > 0) {
          return Math.trunc(num * 100) / 100;
        }else {
          return Math.ceil(num * 100) / 100;
        }
      }
    },
    prettify(num) {
      if(num === 0) return 0
      const options = {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2
      }
      num = Number(num).toLocaleString("en-US", options).replace('$', '')
      return num
    },
    createCountdown(date) {
      // const countdownDate = new Date(date).getTime();
      const countdownDate = date;

      const now = new Date().getTime();
      const timeRemaining = countdownDate - now;
      const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

      this.countdown = `${days}d ${hours}h ${minutes}m`;

      const countdownInterval = setInterval(() => {

        const now = new Date().getTime();
        const timeRemaining = countdownDate - now;
        const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

        if (+timeRemaining > 0) {
          this.countdown = `${days}d ${hours}h ${minutes}m`;
        }else {
          clearInterval(countdownInterval);
          this.countdown = '-';
        }
      }, 1000);
    },
    getAccountInfoData() {
      this.getAccountInfo().then((data) => {
        this.activeIndexStructure = data.feeTierStructure
      })
    },
    getCurrentRoundAction() {
      this.getCurrentRound().then((res) => {
        this.createCountdown(res.data.distributedAt);
        const poolData = [{
              name: 'Robinhood Rewards',
              y: 0,
              selected: false,
              color: `#FFD600`,
            },
            {
              name: 'Referral Program',
              y: 0,
              selected: false,
              color: `#6D74FF`,
            }]
        poolData[0].y = res.data.totalRobbinhoodPoolSizePercentage > 0 ? res.data.totalRobbinhoodPoolSizePercentage * 100 : 0
        poolData[1].y = res.data.totalReferralProgramPoolSizePercentage > 0 ? res.data.totalReferralProgramPoolSizePercentage * 100 : 0
        this.poolData = poolData;
        this.chartOptionsPool.series[0].data = this.poolData;
      })
    },
    getRewardsSettingsAction() {
      this.getRewardsSettings().then(res => {
        this.robbinhoodProgram = res.data.robbinhoodProgram.items
      });
    },
    getLooserReferal() {
      const params = {
        from: 1,
        limit: 5,
        head: false,
      };

      this.getTopReferrals({params}).then((res) => {
        this.looserReferralUser = res.data[res.data.length - 1];

        if(this.looserReferralUser) {
          if(this.token && this.currentReferralUser && this.currentReferralUser.number > this.looserReferralUser.number) {
            this.looserReferralUser.number = this.looserReferralUser.number + 1
          }
        }
      })
    },
    async getMyRating() {
      const current = await this.getRewardsMyRating()
      this.myRating = current.data;

      if(this.token) {
        const currentMember = this.topReferralsData.filter(referral => referral.userAddress === this.cosmosWallet)[0]
        if(!currentMember) {
          this.currentReferralUser = this.myRating;
        }else {
          this.currentReferralUser = currentMember;
          return
        }

        const membersArray = this.topReferralsData;
        let coincidenceMemberIndex = null

        this.topReferralsData.forEach((member, i) => {
          if(member.number === this.currentReferralUser.number) {
            coincidenceMemberIndex = i
          }
        })

        if(this.topReferralsData.length === 0) {
          if(this.topReferralsData.length && this.currentReferralUser.number === this.topReferralsData[0].number) {
            return
          }
          this.currentReferralUser.number = 1;
        }

        if(coincidenceMemberIndex !== null) {
          membersArray.splice(coincidenceMemberIndex, 0, this.currentReferralUser);
        }

        if(this.topReferralsData.length > 1 && this.topReferralsData.length <= 8 && this.currentReferralUser.number <= 8 && coincidenceMemberIndex === null) {
          this.topReferralsData.push(this.currentReferralUser)
        }

        membersArray.map((item, i) => {
          if(coincidenceMemberIndex !== null && i > coincidenceMemberIndex && item.number >= this.currentReferralUser.number) {
            item.number += 1
          }
          return item
        })

        if(this.topReferralsData.length && this.topReferralsData[this.topReferralsData.length - 1].number > 8) {
          membersArray.pop()
        }

        this.topReferralsData = membersArray;
      }
    },
    getTopReferralsAction() {
      const params = {
        from: 1,
        limit: 7,
        head: true,
      };
      this.getTopReferrals({params}).then((res) => {
        this.topReferralsData = res.data;
        if(this.token && this.myRating === null) {
          this.getMyRating();
        }
        this.getLooserReferal();
      })
    },
    getRewardsMyRoundProfitAction() {
      this.getRewardsMyRoundProfit().then(res => {
        this.rewardsMyRoundProfit = res.data
      })
    },
    openConnectWalletDialog() {
      this.$refs.connectWallet.openPopups();
    },
  },

  created() {
    this.getRewardsSettingsAction();
    this.getCurrentRoundAction();
    this.getTopReferralsAction();
  },
  mounted() {
    if(this.token) {
      this.getAccountInfoData();
      this.getRewardsMyRoundProfitAction();
    }
  }
}
</script>

<style scoped>
.public-page-content-wrap {

}

.rewards-content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-right: 1px solid #1E1E1E;
}

.round-main-content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  width: calc(100% - 340px);
}

.top-referal-table-wrap {
  width: 340px;
  border-left: 1px solid #1E1E1E;
  height: 100vh;
}

.referal-subtitle-wrap {
  margin-top: 15px;
  padding: 0 20px;
}

.round-info {
  width: 100%;
  display: flex;
  align-items: initial;
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: 1px solid #1E1E1E;
}

.round-content {
  width: calc(100% - 280px);
  border-right: 1px solid #1E1E1E;
}

.round-title-wrap {

}

.round-title {

}

.round-list {
  display: flex;
  padding: 18px 0px;
  margin: 0px auto;
  width: calc(100% - 40px);
  align-items: flex-start;
  justify-content: space-between;
  border-top: 1px solid #1E1E1E;
}

.round-item {
  width: calc(25% - 20px);
}

.round-item-icon {
  margin-bottom: 5px;
}

.round-item-icon svg {

}

.round-item-title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #92939C;
  display: block;
  width: 100%;
  margin-bottom: 2px;
}

.round-item-value {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #FFFFFF;
}

.pool-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #1E1E1E;
}

.pool-content {
  width: calc(100% - 140px);
  padding: 15px 20px 20px 20px;
}

.referal-subtitle-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 15px;
}

.pool-info .referal-subtitle-wrap {
  padding-left: 0;
}

.referal-subtitle {

}

.pool-list {

}

.pool-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pool-item:not(:last-child) {
  margin-bottom: 10px;
}

.pool-item-title {
  width: 45%;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #E9E9E9;
  position: relative;
  padding-left: 16px;
}

.pool-item-title:after {
  content: "";
  width: 8px;
  height: 8px;
  position: absolute;
  left: 4px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
}

.pool-item-title-referral:after {
  background: #5D5BDA;
}

.pool-item-title-robinhood:after {
  background: #F3BA2F;
}

.pool-item-currency-value {
  width: 45%;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #FFFFFF;
}

.pool-item-percent {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #FFFFFF;
}

.pool-piechart {
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 5px;
  margin-right: 20px;
}

.round-statistic {
  width: 280px;
}

.round-statistic-content {
  padding: 15px;
  margin: 20px;
  background: #393939;
  border-radius: 6px;
}

.round-statistic-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0px;
  margin-top: 25px;
}

.round-statistic-title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #FFFFFF;
  display: block;
  margin-bottom: 10px;
}

.round-statistic-description {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  color: #E0E0E6;
  margin-bottom: 30px;
}

.round-statistic-btn-wrap {
  margin-bottom: 5px;
}

.round-statistic-btn {

}

.round-statistic-list {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #2E2D2D;
}

.round-statistic-item:not(:last-child) {
  margin-bottom: 14px;
}

.round-statistic-item-currency-value {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #2DCAC2;
}

.round-statistic-item-type {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.round-statistic-item-type-value {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #FFFFFF;
  display: inline-block;
  margin-right: 5px;
}

.round-statistic-item-no-rewards {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #92939C;
}

.round-statistic-info {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.round-statistic-info-text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: #E0E0E6;
  width: calc(100% - 25px);
  margin-left: auto;
  max-width: 180px;
  margin-bottom: 0;
}

.robinhood-info {
  width: 100%;
}

.rewards-table {
  width: 100%;
}

.rewards-table p {
  text-align: left;
  margin-bottom: 0;
  padding: 12px 0;
}

.robinhood-table .tier {
  width: 10%;
  padding-left: 20px;
}

.robinhood-table .pool {
  width: 45%;
  color: #02C9BF;
}

.robinhood-table .pool p,
.robinhood-table td.pool {
  text-align: right;
}

.robinhood-table .equivalent {
  width: 45%;
  color: #02C9BF;
  padding-right: 20px;
}

.robinhood-table .equivalent p,
.robinhood-table td.equivalent  {
  text-align: right;
}

.robinhood-table-wrap {
  padding-top: 0;
}

.robinhood-table .table-body:nth-child(even) {
  background: transparent;
}

.robinhood-table .table-body.current-leader {
  background: rgba(66, 232, 224, 0.1);
}

.secondary-text {
  color: var(--v-textSecondaryColor-base);
}
</style>