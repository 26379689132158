<template>
  <tr class="table-body transaction-history-wrap">
    <td class="number" :data-label="$t('Distribution No.')">
      <span>Round #{{ roundNumber }}</span>
    </td>
    <td class="date" :data-label="$t('Distribution Date')">
      <span>{{ distributedAt }}</span>
    </td>
    <td class="size" :data-label="$t('Total Pool Size')">
      <span>{{ totalRewardPoolSize }}</span>
      <span class="secondary-text"> {{ quoteCurrency }}</span>
    </td>
    <td class="program" :data-label="$t('Referral Program')">
      <span>{{ totalReferralProgramPoolSize }}</span>
      <span class="secondary-text"> {{ quoteCurrency }}</span>
    </td>
    <td class="rewards" :data-label="$t('Robinhood Rewards')">
      <span>{{ totalRobbinhoodPoolSize }}</span>
      <span class="secondary-text"> {{ quoteCurrency }}</span>
    </td>
    <td class="user" :data-label="$t('You Got')">
      <span  v-if="token">{{ totalProfit }}</span>
      <span  v-else>-</span>
      <span class="secondary-text"> {{ quoteCurrency }}</span>
    </td>
  </tr>
</template>

<script>
import moment from "moment";

export default {
  name: "RoundsOfRewardsItems",
  props: {
    distributionHistoryItem: {
      type: Object,
    },
    token: String,
  },
  data() {
    return {
      bigDipperUrl: process.env.VUE_APP_BIG_DIPPER_URL,
      quoteCurrency: "USDT",
      usdtIcon: require('@/assets/img/coin/usdt.svg'),
    }
  },
  computed: {
    distributedAt() {
      return moment.utc(this.distributionHistoryItem.distributedAt).local().format('DD.MM.yy');
    },
    roundNumber() {
      return this.distributionHistoryItem.roundNumber
    },
    totalReferralProgramPoolSize() {
      return this.prettify(String(this.aroundNumber(this.distributionHistoryItem.totalReferralProgramPoolSize) || 0)) || '-'
    },
    totalRewardPoolSize() {
      return this.prettify(String(this.aroundNumber(this.distributionHistoryItem.totalRewardPoolSize) || 0)) || '-'
    },
    totalRobbinhoodPoolSize() {
      return this.prettify(String(this.aroundNumber(this.distributionHistoryItem.totalRobbinhoodPoolSize) || 0)) || '-'
    },
    totalProfit() {
      return this.prettify(String(this.aroundNumber(this.distributionHistoryItem.totalProfit) || 0)) || '-'
    },
  },
  methods: {
    transformString(str) {
      str = str.replaceAll('_', ' ');
      let newStr = '';
      for (let i = 0; i < str.length; i++) {
        newStr += (str[i - 1] == ' ') ? str[i].toUpperCase() : str[i].toLowerCase();
      }
      return newStr
    },
    aroundNumber(num) {
      if(num === undefined || num === null) {
        return 0
      }
      if(num === 0) {
        return num
      }else {
        // return num ? parseFloat(Number(num).toFixed(2)) : null
        if(+num > 0) {
          return Math.trunc(num * 100) / 100;
        }else {
          return Math.ceil(num * 100) / 100;
        }
      }
    },
    prettify(num) {
      if(num === 0) return 0
      const options = {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2
      }
      num = Number(num).toLocaleString("en-US", options).replace('$', '')
      return num
    },
  }
};
</script>

<style lang="scss" scoped>
.table-body {
  td {
    text-align: right;
    padding-left: 40px;
    padding-right: 40px;
  }

  span {
    color: #02C9BF;
  }

  .number {
    text-align: left;
    padding-left: 20px;

    span {
      color: #fff;
    }
  }

  .date {
    span {
      color: #fff;
    }
  }

  .user {
    padding-right: 20px;
  }

  .secondary-text {
    color: var(--v-textSecondaryColor-base)
  }
}
</style>
