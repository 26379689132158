<template>
  <div class="discount-table">
    <table>
      <caption>
        <div class="caption">
          <h6>
            {{ $t('Discount') }}
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <svg v-bind="attrs" v-on="on" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.20868 5.19649C7.20868 4.82968 7.55615 4.51114 8.00019 4.51114C8.43455 4.51114 8.78211 4.82968 8.78211 5.19649C8.78211 5.56333 8.43458 5.89152 8.00019 5.89152C7.55618 5.89152 7.20868 5.56333 7.20868 5.19649ZM7.25686 7.23327C7.25686 6.963 7.56575 6.77955 8.00013 6.77955C8.41522 6.77955 8.75304 6.96297 8.75304 7.23322V10.911C8.75304 11.1427 8.41522 11.3743 8.00013 11.3743C7.56575 11.3743 7.25686 11.1427 7.25686 10.911V7.23327Z" fill="#92939C"/>
                  <circle cx="8" cy="8" r="7" stroke="#92939C" stroke-width="1.5"/>
                </svg>
              </template>
              <span>{{ $t('Holding DXLN token provides you with a discount to your trading fee.') }}</span>
            </v-tooltip>
          </h6>
          <span v-if="isDisableDxln" class="soon-text"><span>{{$t('Coming Soon')}}</span></span>
        </div>
      </caption>
      <thead class="holder-table">
      <tr>
        <th :data-label="$t('Discount level')" class="discount-level">{{ $t('Discount level') }}</th>
        <th :data-label="$t('Discount')" class="discount-percent">{{ $t('Discount') }} %</th>
        <th :data-label="$t('in Wallet')" class="dxln-in-wallet">DXLN {{ $t('in Wallet') }}</th>
      </tr>
      </thead>
      <tbody>
      <tr
          class="table-body"
          v-for="(item, i) in feeTiersDiscountList"
          :key="i"
          :class="i + 1 === activeIndexDiscount ? 'active' : ''"
      >
        <td :data-label="$t('Discount level')" class="discount-level">{{ $t('Level') }} {{ item.level }}</td>
        <td :data-label="$t('Discount')" class="discount-percent">
          <span class="up">{{ item.discountFee }}</span>
          <span class="secondary-text"> %</span>
        </td>
        <td :data-label="$t('in Wallet')" class="dxln-in-wallet">
          <span class="up">{{ prettify(item.dxlnBalance) }}</span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "DiscountTable",
  data() {
    return {
      isDisableDxln: JSON.parse(process.env.VUE_APP_IS_DISABLE_DXLN),
    }
  },
  props: {
    feeTiersDiscountList: {
      type: Array
    },
    activeIndexDiscount: {
      type: Number
    }
  },
  methods: {
    prettify(num) {
      if(num === 0) return 0
      const options = {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2
      }
      num = Number(num).toLocaleString("en-US", options).replace('$', '')
      return num
    },
    prettifyModify(num) {
      if(num === 0) return 0
      num = Number(num).toLocaleString("en-US")
      return num
    },
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
.secondary-text {
  color: var(--v-textSecondaryColor-base);
}
.discount-table {
  @media (min-width: 1400px) {
    width: 34.5%;
    padding-left: 20px;
  }
}

.discount-level {
  text-align: left;
}

.discount-percent,
.dxln-in-wallet {
  text-align: right;
}

.soon-text {
  display: inline-block;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #2DCAC2;
  padding: 4px 6px;
  background: rgba(45, 202, 194, 0.1);
  border-radius: 6px;
  margin-left: auto;
}
</style>
