<template>
  <tr class="table-body transaction-history-wrap">
    <td class="wallet" :data-label="$t('Wallet ID')">
      <span>{{ userAddress }}</span>
    </td>
    <td class="date" :data-label="$t('Registration Date')">
      <span>{{ registeredAt }}</span>
    </td>
    <td class="size" :data-label="$t('Current Month')">
      <span>{{ currentRoundProfit }}</span>
      <span class="secondary-text"> {{ quoteCurrency }}</span>
    </td>
    <td class="total" :data-label="$t('Total')">
      <span>{{ totalProfit }}</span>
      <span class="secondary-text"> {{ quoteCurrency }}</span>
    </td>
  </tr>
</template>

<script>
import moment from "moment";

export default {
  name: "RoundsOfRewardsItems",
  props: {
    invitedUser: {
      type: Object,
    },
    token: String,
  },
  data() {
    return {
      bigDipperUrl: process.env.VUE_APP_BIG_DIPPER_URL,
      quoteCurrency: "USDT",
      usdtIcon: require('@/assets/img/coin/usdt.svg'),
    }
  },
  computed: {
    userAddress() {
      return `${this.invitedUser.userAddress.substring(0,10)}....${this.invitedUser.userAddress.slice(this.invitedUser.userAddress.length - 4)}`;
    },
    registeredAt() {
      return moment.utc(this.invitedUser.registeredAt).local().format('DD.MM.yy');
    },
    currentRoundProfit() {
      return this.prettify(String(this.aroundNumber(this.invitedUser.currentRoundProfit) || 0)) || '-'
    },
    totalProfit() {
      return this.prettify(String(this.aroundNumber(this.invitedUser.totalProfit) || 0)) || '-'
    },
  },
  methods: {
    transformString(str) {
      str = str.replaceAll('_', ' ');
      let newStr = '';
      for (let i = 0; i < str.length; i++) {
        newStr += (str[i - 1] == ' ') ? str[i].toUpperCase() : str[i].toLowerCase();
      }
      return newStr
    },
    aroundNumber(num) {
      if(num === undefined || num === null) {
        return 0
      }
      if(num === 0) {
        return num
      }else {
        // return num ? parseFloat(Number(num).toFixed(2)) : null
        if(+num > 0) {
          return Math.trunc(num * 100) / 100;
        }else {
          return Math.ceil(num * 100) / 100;
        }
      }
    },
    prettify(num) {
      if(num === 0) return 0
      const options = {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2
      }
      num = Number(num).toLocaleString("en-US", options).replace('$', '')
      return num
    },
  }
};
</script>

<style lang="scss" scoped>
.table-body {
  td {
    text-align: right;
    padding-left: 40px;
    padding-right: 40px;
  }

  span {
    color: #02C9BF;
  }

  .wallet {
    text-align: left;
    padding-left: 20px;
    span {
      color: #fff;
    }
  }

  .date {
    span {
      color: #fff;
    }
  }

  .total {
    padding-right: 20px;
  }

  .secondary-text {
    color: var(--v-textSecondaryColor-base)
  }
}
</style>
