<template>
  <div class="chart-item">
    <h3 class="chart-item-title textMainColor">{{$t('Long\/Short Ratio')}}</h3>
    <highcharts :options="chartOptions"></highcharts>
  </div>
</template>

<script>
import moment from "moment";
import {Chart} from "highcharts-vue";

const chartOptionsOpenInterestDataCoin1 = [
  76373.436,
  76292.562,
  76333.412,
  76358.394,
  76398.623,
  76410.619,
  76374.089,
  76353.068,
  76277.497,
  76212.8,
  76193.101,
  76272.889,
  76161.665,
  76200.758,
  76169.371,
  76180.023,
  76237.708,
  76260.27,
  76294.919,
  76351.711,
  76370.869,
  76372.975,
  76418.956,
  76338.137,
  76289.789,
  76352.813,
  76339.919,
  76336.193,
  76451.892,
  76449.216
]
const chartOptionsOpenInterestDataCoin2 = [
  2.9118528491616015E9,
  2.90836248866316E9,
  2.908723312345106E9,
  2.9150181880204496E9,
  2.91293204564761E9,
  2.912919682216194E9,
  2.91098159993542E9,
  2.91131728632756E9,
  2.910409903109045E9,
  2.900515125808E9,
  2.90001685633241E9,
  2.901649516227E9,
  2.896540375701923E9,
  2.89455513531978E9,
  2.90051060533725E9,
  2.90263104315198E9,
  2.90325542572696E9,
  2.9001262111164E9,
  2.89575915461039E9,
  2.8987639136641784E9,
  2.9028842039500494E9,
  2.903347482034671E9,
  2.910089719572164E9,
  2.90605235006032E9,
  2.89587876036577E9,
  2.893939453200489E9,
  2.8898419792540703E9,
  2.895173618436365E9,
  2.89767808154616E9,
  2.89419377803968E9
]

export default {
  name: "LongShortRation",
  data() {
    return {
      updateArgs: [true, true, true],
      chartOptions: {
        title: null,
        credits: {
          enabled: false
        },
        chart: {
          backgroundColor: this.$vuetify.theme.dark,
          height: 250,
        },
        xAxis: [{
          categories: [
            1643525100000,
            1643525400000,
            1643525700000,
            1643526000000,
            1643526300000,
            1643526600000,
            1643526900000,
            1643527200000,
            1643527500000,
            1643527800000,
            1643528100000,
            1643528400000,
            1643528700000,
            1643529000000,
            1643529300000,
            1643529600000,
            1643529900000,
            1643530200000,
            1643530500000,
            1643530800000,
            1643531100000,
            1643531400000,
            1643531700000,
            1643532000000,
            1643532300000,
            1643532600000,
            1643532900000,
            1643533200000,
            1643533500000,
            1643533800000
          ],
          labels: {
            formatter: function () {
              return moment.utc(this.value).format('MM/DD  HH:mm');
            }
          },
          lineColor: "#48515D",
        }],
        plotOptions: {
          column: {
            stacking: 'percent',
          },
        },
        tooltip: {
          shared: true,
        },
        yAxis: [
          {
            gridLineColor: '#464646',
            lineColor: '#464646',
            minorGridLineColor: '#464646',
            tickColor: '#464646',
            plotLines: [{
              color: '#464646'
            }],
            id: 'y-axis',
            title: null,
            labels: {
              formatter: function () {
                return this.value + '%';
              }
            },
          },
          {
            gridLineColor: '#464646',
            lineColor: '#464646',
            minorGridLineColor: '#464646',
            tickColor: '#464646',
            plotLines: [{
              color: '#464646'
            }],
            id: 'y-axis1',
            title: null,
            opposite: true,
            labels: {
              formatter: function () {
                return this.value.toFixed(2);
              }
            },
          }
        ],
        legend: {
          align: "center",
          itemStyle: {"color": "#E9E9E9", "cursor": "pointer", "fontSize": "12px", "lineHeight": "18px", "fontWeight": "normal", "textOverflow": "ellipsis"},
          itemHoverStyle: {"color": "#42E8E0", "cursor": "pointer", "fontSize": "12px", "lineHeight": "18px", "fontWeight": "normal", "textOverflow": "ellipsis"},
          squareSymbol: true,
          symbolHeight: 14,
          symbolWidth: 14,
          symbolRadius: 0,
          labelFormatter: function () {
            if(this.index !== 2) {
              return this.name + ' %';
            }else {
              return this.name
            }
          }
        },
        series: [
          {
            yAxis: 'y-axis',
            type: 'column',
            color: '#F27870',
            tooltip: {
              valueSuffix: '%'
            },
            borderColor: 'transparent',
            data: [
              51.38,
              51.34,
              51.35,
              51.36,
              51.49,
              51.40,
              51.34,
              51.29,
              51.29,
              51.30,
              51.28,
              51.27,
              51.29,
              51.32,
              51.29,
              51.23,
              51.24,
              51.18,
              51.19,
              51.26,
              51.19,
              51.15,
              51.21,
              51.24,
              51.40,
              51.52,
              51.58,
              51.59,
              51.71,
              51.75
            ],
            name: "Short Account",
            stack: 'male'
          },
          {
            yAxis: 'y-axis',
            type: 'column',
            color: '#67CA9D',
            tooltip: {
              valueSuffix: '%'
            },
            borderColor: 'transparent',
            data: [
              48.62,
              48.66,
              48.65,
              48.64,
              48.51,
              48.60,
              48.66,
              48.71,
              48.71,
              48.70,
              48.72,
              48.73,
              48.71,
              48.68,
              48.71,
              48.77,
              48.76,
              48.82,
              48.81,
              48.74,
              48.81,
              48.85,
              48.79,
              48.76,
              48.60,
              48.48,
              48.42,
              48.41,
              48.29,
              48.25
            ],
            name: "Long Account",
            stack: 'male'
          },
          {
            yAxis: 'y-axis1',
            type: 'line',
            color: '#E0E0E6',
            marker: {
              fillColor: 'white',
              lineWidth: 2,
              lineColor: '#fff',
            },
            stickyTracking: false,
            data: [
              1.0153,
              1.0092,
              1.0024,
              1.0,
              1.0008,
              0.9952,
              0.9944,
              0.9889,
              0.9869,
              0.9849,
              0.972,
              0.9712,
              0.965,
              0.9639,
              0.9666,
              0.972,
              0.9759,
              0.9747,
              0.9701,
              0.9759,
              0.981,
              0.9841,
              0.9845,
              0.9841,
              0.9782,
              0.9802,
              0.9853,
              0.9904,
              1.0048,
              1.0044
            ],
            name: "Long/Short Ratio"
          },
        ]
      },
    }
  },
  components: {
    highcharts: Chart,
  },
}
</script>

<style scoped>

</style>