import { getFromLocalStorage, saveToLocalStorage } from '../../../helpers'
import { utils } from 'ethers';

const metaMaskNetworkIds = {
    42: 'Kovan',
    1: 'Mainnet',
    3: 'Ropsten',
    4: 'Rinkeby',
    80001: 'Dexilon Chain',
    5: 'Goerli'
  }

const binanceNetworkIds = {
    56: 'Binance Smart Chain',
    1: 'Ethereum',
    99: 'Binance Chain'
  }
// maybe should use as global
const Web3Utils = require('web3-utils');

function saveMetamaskAccount({metaMaskWallet}, key) {
    let savedWallet = getFromLocalStorage('metamask');

    savedWallet = {...savedWallet, ...{[key]: metaMaskWallet[key]}};

    saveToLocalStorage('metamask', savedWallet);
  }

  function saveBinanceAccount({binanceWallet} ,key) {
    let savedWallet = getFromLocalStorage('binance');
    savedWallet = {...savedWallet, ...{[key]: binanceWallet[key]}};

    saveToLocalStorage('binance', savedWallet);
  }
export default {

    WALLET_AUTH(state, auth) {
        state.metaMaskWallet.auth = auth;
        saveMetamaskAccount(state, 'auth');
    },

    JWT(state, data) {
        state.metaMaskWallet.jwt = data.jwt;
        saveMetamaskAccount(state, 'jwt');
    },

    SET_METAMASK_ADDRESS(state, wallet) {
        if (!wallet.length) {
            state.metaMaskWallet.address = null;
            state.metaMaskWallet.disconected = true;
            localStorage.removeItem('metamask')
            localStorage.removeItem('token')
            localStorage.removeItem('refreshToken')
            window.dispatchEvent(new CustomEvent('token-localstorage-remove'));
            return;
        }
        let address = utils.getAddress(wallet[0].caveats ? wallet[0].caveats[0].value[0] : wallet[0])
        if (!wallet.length) {
            state.metaMaskWallet.address = null;
            return;
        }
        state.metaMaskWallet.address = address;
        saveMetamaskAccount(state, 'address');
    },

    SET_METAMASK_BALANCE(state, balance) {
        state.metaMaskWallet.balance = balance;
        saveMetamaskAccount(state, 'balance');
    },

    SET_METAMASK_NETWORK_ID(state, chainId) {
        state.metaMaskWallet.networkId = chainId;
        saveMetamaskAccount(state, 'networkId');
    },

    SET_METAMASK_NETWORK_NAME(state, chainId) {
        state.metaMaskWallet.networkName = metaMaskNetworkIds[parseInt(chainId)];
        saveMetamaskAccount(state, 'networkName');
    },

    SET_METAMASK_DISCONECT(state, isDisconected) {

        state.metaMaskWallet = {
            address: null,
            balance: null,
            networkName: null,
            networkId: null,
            disconected: false,
            currency: null,
            convertedBalance: null,
            auth: null,
            jwt: null,
            error: null,
            errorTitle: null,
            authData: null,
        }

        localStorage.removeItem('metamask')
        localStorage.removeItem('cosmosAddress')
        localStorage.removeItem('token')
        localStorage.removeItem('refreshToken')

        // state.metaMaskWallet.disconected = isDisconected;
        // saveMetamaskAccount(state, 'disconected');
        //
        // if (isDisconected) {
        //     state.metaMaskWallet.signature = null;
        //     localStorage.removeItem('token')
        //     saveMetamaskAccount(state, 'signature');
        // }
    },

    CONVERT_METAMASK_BALANCE(state, currency) {
        const balance = state.metaMaskWallet.balance;
        // console.log('state.metaMaskWallet.balance', parseInt(balance));


        state.metaMaskWallet.convertedBalance = Number(Web3Utils.fromWei(Web3Utils.toBN(balance), currency)).toFixed(5);
        state.metaMaskWallet.currency = currency;

        saveMetamaskAccount(state, 'convertedBalance');
        saveMetamaskAccount(state, 'currency');
    },

    SET_METAMASK_SIGNATURE(state, signature) {
        state.metaMaskWallet.signature = signature;
        saveMetamaskAccount(state, 'signature');
    },

    METAMASK_ERROR_TITLE(state, errorTitle) {
        state.metaMaskWallet.errorTitle = errorTitle;
    },

    METAMASK_ERROR(state, error) {
        state.metaMaskWallet.error = error;
    },

    SET_AUTH_DATA(state, data) {
        state.metaMaskWallet.authData = data;
        saveToLocalStorage('cosmosAddress', state.metaMaskWallet.authData.granterWallet.address);
    },

    SET_SPOT_BALANCE(state, data) {
        state.spotBalance = data;
    },

    SET_TRADING_VOLUME_30D(state, data) {
        state.tradingVolume30d = data;
    },

    SET_SPOT_BALANCE_USDT(state, data) {
        if(state.spotBalance.beforeUsdc === undefined) {
            state.spotBalance.beforeUsdc = {
                amount: data
            }
        }else {
            state.spotBalance.beforeUsdc.amount = data;
        }
    },

    UPDATE_SPOT_BALANCE(state, data) {
        state.spotBalance = data;
    },

    SET_FEATURES_BALANCE(state, data) {
        state.featuresBalance = data;
    },

    UPDATE_FEATURES_BALANCE(state, data) {
        state.featuresBalance = data;
    },

    //Binance
    SET_BINANCE_ADDRESS(state, wallet) {
        if (!wallet.length) {
            state.binanceWallet.address = null;
            return;
        }
        state.binanceWallet.address = wallet[0];
        saveBinanceAccount(state, 'address');
    },

    SET_BINANCE_NETWORK_ID(state, chainId) {
        state.binanceWallet.networkId = chainId;
        saveBinanceAccount(state, 'networkId');
    },

    SET_BINANCE_NETWORK_NAME(state, chainId) {
        const networkKey = parseInt(chainId) || 99;
        state.binanceWallet.networkName = binanceNetworkIds[parseInt(networkKey)];
        saveBinanceAccount(state, 'networkName');
    },

    SET_BINANCE_BALANCE(state, balance) {
        state.binanceWallet.balance = balance;
        state.binanceWallet.convertedBalance = parseInt(balance);

        saveBinanceAccount(state, 'balance');
        saveBinanceAccount(state, 'convertedBalance');
    },

    SET_BINANCE_DISCONECT(state, isDisconected) {
        state.binanceWallet.disconected = isDisconected;
        saveBinanceAccount(state, 'disconected');

        if (isDisconected) {
            state.binanceWallet.signature = null;
            saveBinanceAccount(state, 'signature');
        }
    },

    SET_BINANCE_SIGNATURE(state, signature) {
        state.binanceWallet.signature = signature;
        saveBinanceAccount(state, 'signature');
    },

    CHANGE_PRELOAD_STATUS(state, preloadStatus) {
        state.preloader = preloadStatus;
    },

    //End Binance
}
