export default {
    getTopReferrals({commit}, {params}) {
        return new Promise((resolve, reject) => {
            $axios.get('/referralProgram/topReferrals', {params})
                .then((res) => {
                    commit('SET_TOP_REFERRALS', res.data);
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    getCurrentRound({commit}) {
        return new Promise((resolve, reject) => {
            $axios.get('/rewards/currentRound')
                .then((res) => {
                    commit('SET_CURRENT_ROUND', res.data);
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    getRewardsSettings({commit}) {
        return new Promise((resolve, reject) => {
            $axios.get('/rewards/settings')
                .then((res) => {
                    commit('SET_REWARDS_SETTINGS', res.data);
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    getRewardsMyRating({commit}) {
        return new Promise((resolve, reject) => {
            $axios.get('/referralProgram/myRating')
                .then((res) => {
                    commit('SET_REWARDS_MY_RATING', res.data);
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    getRewardsMyRoundProfit({commit}) {
        return new Promise((resolve, reject) => {
            $axios.get('/rewards/myRoundProfit')
                .then((res) => {
                    commit('SET_REWARDS_MY_ROUND_PROFIT', res.data);
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getRewardsDistributionHistory({commit}) {
        return new Promise((resolve, reject) => {
            $axios.get('/rewards/distributionHistory')
                .then((res) => {
                    commit('SET_REWARDS_DISTRIBUTION_HISTORY', res.data);
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    getRewardsUserDistributionHistory({commit}) {
        return new Promise((resolve, reject) => {
            $axios.get('/rewards/userDistributionHistory')
                .then((res) => {
                    commit('SET_REWARDS_USER_DISTRIBUTION_HISTORY', res.data);
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getRewardsInvitedUsers({commit}, {params}) {
        return new Promise((resolve, reject) => {
            $axios.get('/referralProgram/invitedUsers', {params})
                .then((res) => {
                    commit('SET_REFERRAL_INVITED_USERS', res.data);
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getReferralLink({commit}) {
        return new Promise((resolve, reject) => {
            $axios.post(`/referralProgram/generateLink`, {}).then(({data}) => {
                commit('SET_REFERRAL_LINK', data);
                resolve(data);
            })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getFollowLink(_, token) {
        return new Promise((resolve, reject) => {
            $axios.post(`/referralProgram/followLink`, {token}).then(({data}) => {
                resolve(data);
            })
                .catch((err) => {
                    reject(err);
                });
        });
    },

    getTotalProfit({commit}) {
        return new Promise((resolve, reject) => {
            $axios.get(`/referralProgram/totalProfit`).then(({data}) => {
                commit('SET_TOTAL_PROFIT', data);
                resolve(data);
            })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};
