<template>
  <v-dialog v-model="active" persistent>
    <div class="service-message-wrapper">
      <div class="close-dialog" @click="active = false">
        <img src="../../assets/img/close.svg" alt="close-icon" />
      </div>
      <h2 class="sevice-message-title">{{ $t(title) }}</h2>
      <p class="sevice-message-description textSecondaryColor">{{ $t(text) }}</p>
      <div class="service-btn-block">
        <button
            class="service-btn btn-cancel mainBorderColor"
            @click="active = false">{{ $t(cancelBtnText || 'Cancel') }}
        </button>
        <button
            class="service-btn btn-try accent"
            @click="onConfirm">{{ $t(confirmBtnText || 'Confirm') }}
        </button>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "InfoDialog",
      props: {
        title: {
            type: String
        },
        text: {
            type: String
        },
        confirmBtnText: {
            type: String
        },
        cancelBtnText: {
            type: String
        }
  },
  data() {
    return {
      active: false,
      paylod: null
    };
  },

  methods: {
      showDialog(payload) {
          this.paylod = payload;
          this.active = true;
      },

      onConfirm() {
          this.active = false;
          this.$emit('onConfirm', this.paylod);
      }
  }
};
</script>

<style>
.v-dialog {
  box-shadow: none;
  margin: 0;
  border-radius: 0;
}

.close-dialog {
  width: 12px;
  position: absolute;
  top: 25px;
  right: 23px;
  cursor: pointer;
}

.close-dialog img {
  display: block;
  width: 100%;
}

.sevice-message-description {
  margin: 16px 0 0 0!important;
  text-align: center;
  font-size: 14px;
  line-height: 150%;
}

.service-btn {
  min-width: 120px;
  min-height: 48px;
  border-radius: 6px;
  font-weight: bold;
  font-size: 16px;
  transition: .5s all;
}

.btn-cancel {
  margin: 0 20px 0 0;
}

.btn-try {
  color: #111222;
}
</style>
