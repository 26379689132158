export default {
    SET_LIQUIDATION_AUCTION_DATA(state, data) {
        state.liquidationAuctionLotsList = data;
    },
    UPDATE_LIQUIDATION_AUCTION_DATA(state, data) {
        state.liquidationAuctionLotsList = data;
    },
    SET_LIQUIDATION_AUCTION_HISTORY(state, data) {
        state.liquidationAuctionLotsHistoryList = data;
    },
    UPDATE_LIQUIDATION_AUCTION_HISTORY(state, data) {
        state.liquidationAuctionLotsHistoryList = data;
    },
};
